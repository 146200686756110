<template>
  <div class="faq">
    <PageTitle tag="h1" class="faq__title">
      {{ $t('cms.faq.title') }}
    </PageTitle>
    <div class="faq__container">
      <div class="faq__body">
        <Spinner v-if="loading" />
        <ErrorAlert v-else-if="error" @close="error = null">{{
          error
        }}</ErrorAlert>
        <ul v-else-if="faqList" class="faq__list">
          <li
            v-for="(item, name, index) in faqList"
            :key="`faq-item-${name}`"
            class="faq__item"
          >
            <FaqItem :item="item" :is-first="index === 0" />
          </li>
        </ul>
      </div>
      <div class="faq__contact">
        <div class="faq__contact-box">
          <h2 class="faq__contact-title">
            {{ $t('cms.faq.contact.title') }}
          </h2>
          <p class="faq__contact-description">
            {{ $t('cms.faq.contact.description') }}
          </p>
          <VACButton
            appearance="primary"
            full-width
            :label="$t('cms.faq.contact.button')"
            class="faq__contact-button"
            @click.prevent="track"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FaqItem from '../../components/faq/FaqItem';
import PageTitle from '@/components/page/PageTitle';
import ContentAPI from '@/api/ContentAPI';
import Spinner from '@/components/Spinner';
import ErrorAlert from '@/components/alert/ErrorAlert';
import RouteName from '@/utils/RouteName';
import { VACButton } from '@webqam-vac-ui/lib';
import { pushToDataLayer } from '@/utils/GTMMaker';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'Faq',
  components: { VACButton, ErrorAlert, Spinner, PageTitle, FaqItem },
  mixins: [Tracking],
  data() {
    return {
      loading: false,
      error: null,
      faqList: null,
      RouteName,
    };
  },
  mounted() {
    this.trackPageView(this.$t('title.faq'));
    this.loading = true;
    ContentAPI.getFaq({ lang: this.$i18n.locale })
      .then((faqResponse) => {
        this.loading = false;
        this.faqList = faqResponse;
      })
      .catch((error) => {
        this.error = error.message;
      });
  },
  methods: {
    track() {
      pushToDataLayer({
        event: 'clickContactFAQ',
      });
      this.$router.push({
        name: RouteName.CONTACT,
      });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.faq')}`,
    };
  },
};
</script>

<style lang="scss" scoped>
.faq {
  font-family: $font-family-prelo-condensed;
  padding-bottom: 1em;

  &__title {
    text-align: center;
    margin: 1rem auto 4.375rem;
    color: black;
    font-weight: normal;
  }

  &__container {
    @extend %base-container;

    @include breakpoint($breakpoint-desktop) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__body {
    flex: 1;
  }

  &__contact {
    position: sticky;
    top: 6.375em;
    font-family: $font-prelo;
    margin-top: 3.75em;
    margin-bottom: 3.75em;
    height: 100%;
    box-shadow: 0 0 10px #00000029;

    @include breakpoint($breakpoint-desktop) {
      width: 20em;
      margin-left: 2.8125em;
      margin-top: 0;
      margin-bottom: 0;
    }

    &-title {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0.75rem;
      color: $color-black;
    }

    &-box {
      padding: 2rem;
    }
  }

  &__list {
    padding: 0;
  }

  &__item {
    display: block;
  }
}
</style>
