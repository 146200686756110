export default {
  methods: {
    async getPushStatus() {
      return (await this.$OneSignal.isPushNotificationsEnabled()) !== 'pending';
    },
    registerPush() {
      Notification.requestPermission()
        .then(() => {
          this.$OneSignal.isPushNotificationsEnabled().then((isAuthorized) => {
            if (isAuthorized) {
              this.$OneSignal.registerForPushNotifications();
            }
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
