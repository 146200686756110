<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal
      is-active
      class="auth-modal"
      transition-name="auth-modal-"
      @close="close"
    >
      <template #container>
        <div class="auth-modal__body">
          <div class="auth-modal__header">
            <VACButton
              class="auth-modal__close"
              appearance="discrete"
              icon="cross"
              @click="close"
            />
          </div>
          <h3 class="auth-modal__title">
            {{ $t('auth.register.title') }}
          </h3>
          <p class="auth-modal__description">
            {{ $t('auth.register.register_piece') }}
          </p>
          <VACButton :href="registerLink" class="auth-modal__button">
            {{ $t('auth.register.form.submit-button') }}
          </VACButton>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import { VACButton } from '@webqam-vac-ui/lib';

import PortalName from '@/utils/PortalName';
import Internationalization from '@/utils/Internationalization';
import LangMap from '@/enums/LangMap';

const registerMap = new Map([
  ['ru', 'RU'],
  ['ja', 'JP'],
  ['ko', 'KR'],
]);

export default {
  name: 'AuthRegisterModal',
  components: {
    BaseModal,
    VACButton,
  },
  data() {
    return {
      PortalName,
    };
  },
  computed: {
    CCPBaseLink() {
      if (Internationalization.isCnCountry()) {
        return process.env.VUE_APP_CCP_LINK_CN;
      } else {
        return process.env.VUE_APP_CCP_LINK;
      }
    },
    CCPLocale() {
      if (LangMap.has(Internationalization.getCurrentLocale())) {
        return LangMap.get(Internationalization.getCurrentLocale());
      }
      return Internationalization.getCurrentLocale();
    },
    realm() {
      return Internationalization.getCountry() === 'cn'
        ? 'CN'
        : registerMap.has(Internationalization.getCurrentLocale())
        ? registerMap.get(Internationalization.getCurrentLocale())
        : 'WW';
    },
    registerLink() {
      return `${this.CCPBaseLink}${this.CCPLocale}/register/manual?location=${this.realm}`;
    },
  },
  methods: {
    close() {
      this.complete = null;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-modal {
  @extend %auth-modal-transition;

  &__body {
    padding: 2.5em 1.25em;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @include breakpoint($breakpoint-tablet) {
      padding: 2.5em;
    }
  }

  &__title {
    @extend %auth-modal__title;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__description {
    text-align: center;
  }

  &__button {
    display: flex;
    width: fit-content;
    margin: 0 auto;
  }

  &::v-deep {
    .base-modal {
      &__container {
        width: 100%;
        max-width: 46.875em;
      }
    }
  }
}
</style>
