<template>
  <div class="profile-form">
    <VForm
      ref="form"
      v-model="formValid"
      lazy-validation
      @submit.prevent="submitForm"
    >
      <p class="profile-form__text">
        {{ $t('conversation.pseudonymModal.notice') }}
      </p>
      <VACInput
        v-model="pseudonym"
        :label="pseudonymLabel"
        name="pseudonym"
        type="text"
        @input="pseudoCheckDebounced"
      >
        <template #append>
          <Spinner v-if="isCheckingPseudo" class="profile-form__spinner" />
        </template>
      </VACInput>
      <p class="profile-form__text">
        {{ $t('conversation.pseudonymModal.ticks') }}
      </p>
      <VACCheckbox
        v-model="share.stories"
        :input-value="share.stories"
        name="share_stories"
        :label="$t('conversation.pseudonymModal.shareStories')"
        class="profile-form__tick"
      />
      <p class="profile-form__text profile-form__text--slim">
        {{ $t('conversation.pseudonymModal.legal.1') }}
        <RouterLink
          :to="{ name: RouteName.CMS, params: { contentId: 'terms-of-use' } }"
          class="profile-form__link"
        >
          {{ $t('conversation.pseudonymModal.forumTou') }}
        </RouterLink>
        {{ $t('dot') }}
        {{ $t('conversation.pseudonymModal.legal.2') }}
        <a
          class="profile-form__link"
          href="https://www.vacheron-constantin.com/en/privacy-policy.html"
          target="_blank"
        >
          {{ $t('conversation.pseudonymModal.privacyPolicies') }}
        </a>
        {{ $t('conversation.pseudonymModal.legal.3') }}
      </p>
      <!-- eslint-enable vue-i18n/no-v-html vue/no-v-html -->
      <VACButton
        v-if="showInteractionsLink"
        :to="{ name: RouteName.ACCOUNT_POSTS }"
        appearance="link"
        class="profile-form__interactions"
        :label="$t('conversation.pseudonymModal.interactions')"
      ></VACButton>
      <VACButton
        :disabled="isSending || isCheckingPseudo"
        class="profile-form__submit"
        appearance="primary"
        type="submit"
      >
        <Spinner v-if="isSending" class="profile-form__btn-spinner" small />
        <template v-else>
          {{ $t('conversation.pseudonymModal.save') }}
        </template>
      </VACButton>
    </VForm>
  </div>
</template>

<script>
import { VACInput, VACCheckbox, VACButton } from '@webqam-vac-ui/lib';
import Spinner from '@/components/Spinner';
import RouteName from '@/utils/RouteName';
import debounce from 'lodash.debounce';
import CommonAccountAPI from '@/api/CommonAccountAPI';

export default {
  name: 'ProfileForm',
  components: { Spinner, VACInput, VACCheckbox, VACButton },
  props: {
    showInteractionsLink: Boolean,
  },
  data() {
    return {
      RouteName,
      pseudoCheckDebounced: undefined,
      isPseudoAvailable: [],
      isCheckingPseudo: false,
      pseudonym: this.$store.state.auth.userInfos.pseudo,
      share: {
        stories: this.$store.state.auth.userInfos.share_social === '1',
      },
      formValid: undefined,
      rules: {
        pseudonym: [
          (v) =>
            !!v || this.$t('conversation.pseudonymModal.pseudonymRequired'),
        ],
      },
      isSending: false,
    };
  },
  computed: {
    pseudonymLabel() {
      const pseudonymLabel = this.$i18n.t(
        'conversation.pseudonymModal.pseudonym'
      );
      return this.required ? `${pseudonymLabel} *` : pseudonymLabel;
    },
  },
  mounted() {
    this.pseudoCheckDebounced = debounce(this.checkPseudonymAvailability, 500);
  },

  methods: {
    checkPseudonymAvailability(pseudo) {
      if (pseudo !== '') {
        this.isPseudoAvailable = [];
        this.isCheckingPseudo = true;
        CommonAccountAPI.checkPseudo(pseudo).then(({ data }) => {
          if (!data.available) {
            this.isPseudoAvailable = [
              this.$t('conversation.pseudonymModal.alreadyTaken'),
            ];
          }
          this.isCheckingPseudo = false;
        });
      } else {
        this.isPseudoAvailable = undefined;
      }
    },
    async submitForm() {
      if (this.$refs.form.validate()) {
        if (this.isPseudoAvailable.length === 0) {
          this.isSending = true;
          await CommonAccountAPI.editProfile(
            this.pseudonym,
            this.share.stories
          );
          await this.$store.dispatch('auth/fetchCurrentUser');
          this.isSending = false;
          this.$emit('submited');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-form {
  &__text {
    font-family: $font-family-prelo;
    color: $color-black;

    &--slim {
      font-family: $font-family-prelo-condensed;
    }
  }

  &__cancel {
    color: $color-crown-of-thorns;
  }

  &__submit {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.2em;
    display: flex;
  }

  &__link {
    margin-left: 0.15em;
    text-decoration: underline;
    color: $color-beaver;

    &:hover {
      text-decoration: none;
    }
  }

  &__btn-spinner {
    height: 1.8em;
    padding-top: 0;
    padding-bottom: 0;
    ::v-deep {
      .spinner__icon {
        font-size: 1em;
      }
    }
  }

  &__interactions {
    display: flex;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
  }

  &__spinner {
    padding: 0;
    &::v-deep {
      .spinner__container {
        height: 1em;
        width: 1em;
      }
      .spinner__icon::before {
        content: '';
      }
    }
  }

  &__tick {
    margin-bottom: 0.5rem;
  }
}
</style>
