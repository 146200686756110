/**
 *
 * @param {Blob} blob
 */
export default function chunkVideo(blob: Blob) {
  const BLOB_SIZE = 2 * 1000 * 1000;
  const blobs: Blob[] = [];
  const bufferSize = blob.size;
  const chunkCount = Math.round(bufferSize / BLOB_SIZE);
  if (bufferSize <= BLOB_SIZE) {
    blobs.push(blob);
  } else {
    for (let i = 0; i < chunkCount; i++) {
      if (i === chunkCount) {
        blobs.push(blob.slice(i * BLOB_SIZE, blob.size));
      } else {
        blobs.push(blob.slice(i * BLOB_SIZE, (i + 1) * BLOB_SIZE));
      }
    }
  }
  return blobs;
}
