<template>
  <VACExpandableSection is-open large class="public-profile-account">
    <template #title>
      {{ $t('account.publicProfile.title') }}
    </template>
    <template #body>
      <ProfileForm show-interactions-link />
    </template>
  </VACExpandableSection>
</template>

<script>
import { VACExpandableSection } from '@webqam-vac-ui/lib';
import ProfileForm from '@/components/account/ProfileForm';
export default {
  name: 'PublicProfileAccount',
  components: { ProfileForm, VACExpandableSection },
};
</script>

<style scoped lang="scss">
.public-profile-account {
}
</style>
