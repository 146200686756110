export const DEFAULT_LANG = 'en';

function getLocale() {
  const locale = localStorage.getItem('currentlocale');
  return locale !== null ? locale : 'en';
}

/**
 * Return realm
 * @return {string}
 */
function getRealm() {
  const realm = localStorage.getItem('auth_session_country');
  return realm !== null ? realm : 'ww';
}

/**
 * Return domain for realm
 * @return {string}
 */
function getDomainForRealm() {
  const realm = getRealm();
  if (realm.toLowerCase() === 'cn') {
    return 'cn';
  }

  return 'com';
}

/**
 * Generate a localized link
 * Object to replace are {domain},{realm} and {lang}
 * @param {string} link
 * @return {string}
 */
export function generateLocalizedLink(link: string) {
  return link
    .replace('{domain}', getDomainForRealm())
    .replace('{realm}', getRealm().toLowerCase())
    .replace('{lang}', getLocale().toLowerCase());
}
