<template>
  <div class="stepper">
    <header class="stepper__header">
      <RouterLink
        v-if="backTo && currentStep <= 1"
        :to="backTo"
        class="stepper__back-btn"
      >
        {{ $t('wizard.header.back') }}
      </RouterLink>
    </header>
    <div class="stepper__body">
      <slot name="background-left" v-bind="{ currentStep, totalSteps: steps }">
        <div
          class="stepper__background stepper__background--left"
          :style="{
            backgroundImage: leftImage && `url('${leftImage}')`,
          }"
        ></div>
      </slot>
      <div class="stepper__steps">
        <WizardBreadcrumb :current-step="currentStep" :total-steps="steps" />
        <transition name="stepper-" mode="out-in">
          <div :key="currentStep" class="stepper__step">
            <slot
              :name="`step-${currentStep}`"
              :current-step="currentStep"
              :total-steps="steps"
              :prev="prev"
              :next="next"
            ></slot>
          </div>
        </transition>
      </div>
      <slot name="background-right" v-bind="{ currentStep, totalSteps: steps }">
        <div
          class="stepper__background stepper__background--right"
          :style="{
            backgroundImage: rightImage && `url('${rightImage}')`,
          }"
        ></div>
      </slot>
    </div>
    <footer class="stepper__footer">
      <PortalTarget :name="PortalName.STEPPER_FOOTER" />
    </footer>
  </div>
</template>

<script>
import WizardBreadcrumb from '@/components/wizard/WizardBreadcrumb';
import PortalName from '@/utils/PortalName';

export default {
  name: 'WizardStepper',
  components: { WizardBreadcrumb },
  props: {
    backTo: { type: [String, Object, undefined], default: undefined },
    steps: { type: Number, required: true },
    leftImage: { type: [String, undefined], default: undefined },
    rightImage: { type: [String, undefined], default: undefined },
    start: { type: Number, default: 1 },
  },
  data() {
    return {
      PortalName,
      currentStep: this.start,
    };
  },
  methods: {
    restart() {
      this.setStep(this.start);
    },
    prev() {
      this.setStep(this.currentStep - 1);
    },
    next() {
      this.setStep(this.currentStep + 1);
    },
    /**
     * @param {number} step
     */
    setStep(step) {
      this.currentStep = step;

      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  position: relative;
  margin-bottom: 5.3125em;

  &--enter-active,
  &--leave-active {
    transition-property: opacity, transform;
    transition-duration: $default-transition-duration;
  }

  &--enter,
  &--leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  &--enter-to,
  &--leave {
    opacity: 1;
    transform: translateX(0);
  }

  &__header {
    @extend %base-container;

    padding-bottom: 0.875em;

    @include breakpoint($breakpoint-tablet) {
      position: absolute;
      top: 1em;
      right: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;

    @include breakpoint($breakpoint-tablet) {
      margin-top: 2em;
    }
  }

  &__back-btn {
    @extend %back-btn;
  }

  &__body {
    position: relative;
    padding-top: 3.125em;
    padding-bottom: 2.5em;
    display: flex;

    @include breakpoint($breakpoint-tablet) {
      padding: 0;
    }
  }

  &__background {
    flex: 1;

    @include breakpoint($breakpoint-tablet) {
      height: $wizard-stepper-background-height;
      margin: $wizard-stepper-background-margin auto;
    }

    &--left {
      background-size: cover;
      background-position: left;
      display: none;

      @include breakpoint($breakpoint-tablet) {
        display: block;
      }
    }

    &--right {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: right;

      @include breakpoint($breakpoint-tablet) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
      }
    }
  }

  &__steps {
    position: relative;
    overflow: hidden;
    z-index: 1;
    max-width: 18.75em;

    width: calc(100% - 1.25em);
    background-color: $color-white;
    box-shadow: 0 0 0.3125em rgba($color-black, 0.29);
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include breakpoint($breakpoint-tablet) {
      max-width: 22em;
      min-height: 29.125em;
    }
  }

  &__step {
    flex: 1;
    display: flex;
    width: 100%;
  }
}
</style>
