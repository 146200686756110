<template>
  <PageLoader />
</template>

<script>
import PageLoader from '@/components/PageLoader';
import HydraClient from '@/api/HydraClient';

export default {
  name: 'AccountAskHydraLogin',
  components: { PageLoader },
  async mounted() {
    const { data } = await HydraClient.getAuthUrl();
    window.location = data.url;
  },
};
</script>

<style scoped></style>
