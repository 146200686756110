<template>
  <component :is="`h${level}`" :class="classes">
    <slot></slot>
  </component>
</template>

<script>
const TITLE_SELECTOR = 'base-title';

export default {
  name: 'BaseTitle',
  props: {
    level: { type: Number, default: undefined },
    white: { type: Boolean },
    center: Boolean,
  },
  computed: {
    classes() {
      return [
        TITLE_SELECTOR,
        `${TITLE_SELECTOR}--level-${this.level}`,
        {
          [`${TITLE_SELECTOR}--white`]: this.white,
          [`${TITLE_SELECTOR}--center`]: this.center,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.base-title {
  font-family: $font-prelo;

  &--level-1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    color: $color-default;
    font-weight: 200;
  }

  &--white {
    color: $color-white;
  }

  &--center {
    text-align: center;
  }
}
</style>
