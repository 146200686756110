import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/store/modules';
import { RootState } from '@/types/RootState';
import { DEFAULT_LANG } from '@/utils/Lang';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV === 'production',
  state() {
    return {
      lang: DEFAULT_LANG,
    };
  },
  modules,
});
