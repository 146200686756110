<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal
      is-active
      class="auth-modal"
      transition-name="auth-modal-"
      @close="close"
    >
      <template #container>
        <div class="auth-modal__body">
          <transition name="auth-modal__inner-">
            <div v-if="loading" class="auth-modal__loading-overlay">
              <Spinner />
            </div>
          </transition>

          <div class="auth-modal__header">
            <BaseBtn class="auth-modal__close" discrete @click="close" />
          </div>

          <div class="auth-modal__title">
            {{ $t('auth.title') }}
          </div>

          <div class="auth-modal__content">
            <EmailAuthForm
              @submit="loading = true"
              @error="loading = false"
              @close="close"
            />
          </div>

          <div class="auth-modal__footer">
            <div class="auth-modal__footer-title">
              {{ $t('auth.form.footer.title') }}
            </div>

            <BaseBtn
              block
              border
              class="auth-modal__footer-btn"
              @click="handleSignup"
            >
              {{ $t('auth.signUp') }}
            </BaseBtn>
          </div>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import PortalName from '@/utils/PortalName';
import BusName from '@/utils/BusName';
import EmailAuthForm from '@/components/form/EmailAuthForm';
import Spinner from '@/components/Spinner';
import BaseBtn from '@/components/BaseBtn';
import HydraClient from '@/api/HydraClient';

export default {
  name: 'AuthROTWModal',
  components: {
    Spinner,
    EmailAuthForm,
    BaseModal,
    BaseBtn,
  },
  data() {
    return {
      loading: false,
      PortalName,
      BusName,
      tab: this.$t('authTypeSwitcher.email'),
    };
  },
  async mounted() {
    const { data } = await HydraClient.getAuthUrl();
    window.location = data.url;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleSignup() {
      this.$emit('register');
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-modal {
  @extend %auth-modal-transition;

  &__body {
    padding: 2.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    @extend %auth-modal__title;
  }

  &__loading-overlay {
    @extend %loading-overlay;
  }

  &__footer {
    flex: 1;
    margin: 1.75em -2.5em -2.5em;
    padding: 1.25em 2.5em 2.5em;
    border-top: 1px solid $color-default;
    background-color: $color-wild-sand;

    &-title {
      font-family: $font-family-prelo-condensed;
      font-size: 1.125em;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1em;
    }

    &-btn {
      font-family: $font-family-prelo-condensed;
    }
  }

  &__inner {
    &--enter-active,
    &--leave-active {
      transition: opacity $default-transition-duration;
    }

    &--enter,
    &--leave-to {
      opacity: 0;
    }

    &--enter-to,
    &--leave {
      opacity: 1;
    }
  }

  &::v-deep {
    .base-modal {
      &__container {
        width: 100%;
        max-width: 25em;
        overflow: auto;
      }
    }
  }
}
</style>
