import { render, staticRenderFns } from "./PhoneAuthForm.vue?vue&type=template&id=e548f250&scoped=true&"
import script from "./PhoneAuthForm.vue?vue&type=script&lang=js&"
export * from "./PhoneAuthForm.vue?vue&type=script&lang=js&"
import style0 from "./PhoneAuthForm.vue?vue&type=style&index=0&id=e548f250&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e548f250",
  null
  
)

export default component.exports