<template>
  <div class="notification-request" v-if="isVisible">
    <BaseModal
      is-active
      @close="() => handleClose(false)"
      hide-close-button
      :close-on-backdrop-click="false"
    >
      <template #header-content>
        <div class="notification-request__header">
          <BaseLogo logo-only /> {{ $t('notificationRequest.title') }}
        </div>
      </template>
      <template>
        <p class="notification-request__content">
          {{ modalContent }}
        </p>
      </template>
      <VACButton
        v-if="!isInAuthorizationFlow"
        type="button"
        full-width
        :label="continueLabel"
        @click="requestPermission"
      />
      <VACButton
        class="notification-request__refusal"
        v-if="!isInAuthorizationFlow"
        type="button"
        appearance="secondary"
        full-width
        :label="$t('notificationRequest.refuse')"
        @click="registerRefusal"
      />
      <Spinner v-else />
    </BaseModal>
  </div>
</template>
<script>
import AuthorizationType from '@/utils/AuthorizationType';
import { VACButton } from '@webqam-vac-ui/lib';
import Spinner from './Spinner.vue';
import BaseLogo from './BaseLogo.vue';
import BaseModal from './BaseModal.vue';
export default {
  name: 'NotificationRequest',
  components: { BaseModal, VACButton, BaseLogo, Spinner },
  props: {
    content: {
      type: String,
      default: null,
    },
    authorizations: {
      type: Array,
      default: () => [],
    },
    contentType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInAuthorizationFlow: false,
      AuthorizationType,
    };
  },
  computed: {
    isVisible() {
      if (!this.authorizations || this.authorizations.length === 0) {
        return false;
      }
      if (this.$store.state.profile.notifications) {
        return this.authorizations.some(
          (item) => !this.$store.state.profile.notifications[item]
        );
      }
      return false;
    },
    continueLabel() {
      if (localStorage.getItem('isPushNotificationsEnabled') === 'true') {
        return this.$t('notificationRequest.sendMe');
      } else {
        return this.$t('notificationRequest.continue');
      }
    },
    modalContent() {
      if (localStorage.getItem('isPushNotificationsEnabled') === 'true') {
        return this.$t(`notificationRequest.${this.contentType}Accepted`);
      } else {
        return this.$t(`notificationRequest.${this.contentType}`);
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async requestPermission() {
      this.isInAuthorizationFlow = true;
      await Notification.requestPermission();
      if (this.$OneSignal.isPushNotificationsEnabled()) {
        this.$OneSignal.setExternalUserId(this.$store.state.auth.userInfos.id);
        await this.$store.dispatch('profile/saveProfile', {
          notifications: {
            ...this.$store.state.profile.notifications,
            ...this.authorizations.reduce(
              (acc, item) => ({ ...acc, [item]: true }),
              {}
            ),
          },
        });
      }
      this.handleClose();
    },
    registerRefusal() {
      localStorage.setItem(`${this.authorizations[0]}_refused`, true);
      this.handleClose();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.isVisible) {
        this.handleClose();
      }
    });
  },
};
</script>
<style scoped lang="scss">
.notification-request {
  :deep {
    .base-modal__header {
      font-family: $font-family-prelo-condensed;
      margin-left: 1rem;
    }
    .base-modal__container {
      max-width: 32rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__refusal {
    margin-top: 1rem;
  }
}
</style>
