<template>
  <transition name="slide-down">
    <div v-if="isVisible && !isDenied" class="onboarding-sticky-header">
      <div class="onboarding-sticky-header__container">
        <div class="onboarding-sticky-header__intro">
          {{ $t('onboarding.header.intro') }}
        </div>
        <div class="onboarding-sticky-header__actions">
          <VACButton
            :label="$t('onboarding.header.start')"
            center
            condensed
            full-width
            appearance="primary"
            @click="$emit('onboard')"
          ></VACButton>
          <VACButton
            class="onboarding-sticky-header__dismiss"
            :label="$t('onboarding.header.dismiss')"
            appearance="link"
            fit-content
            @click="denyOnboarding"
          ></VACButton>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { VACButton } from '@webqam-vac-ui/lib';

export default {
  name: 'OnboardingStickyHeader',
  components: { VACButton },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDenied: localStorage.getItem('deny_onboarding') === 'true',
    };
  },
  methods: {
    denyOnboarding() {
      localStorage.setItem('deny_onboarding', true);
      this.isDenied = true;
    },
  },
};
</script>
<style lang="scss">
.onboarding-sticky-header {
  display: none;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  color: $color-black;
  background-color: $color-alto;
  background-image: url('../../assets/onboarding-sticky-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include breakpoint($breakpoint-desktop) {
    display: block;
  }

  &__intro {
    max-width: 37.5rem;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 67.5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__actions {
    display: flex;
    gap: 1.9375rem;
    align-items: center;
  }

  &__dismiss {
    flex-shrink: 0;
  }
}
</style>
