<template>
  <BaseModal class="onboarding-modal" is-active @close="$emit('close')">
    <div class="onboarding-modal__content">
      <OnboardingIntro
        v-if="modalState === ONBOARDING_STATE.START"
        @start="startQuestionnaire"
      />
      <OnboardingQuestion
        v-if="modalState === ONBOARDING_STATE.QUESTIONNAIRE"
        :key="onboardingCurrentQuestionIndex"
        :all-responses="answers"
        v-model="answers"
        @goNext="goNext"
        @goBack="goBack"
        @goIntro="goToIntro"
      />
      <OnboardingLoading v-if="modalState === ONBOARDING_STATE.LOADING" />
      <OnboardingEnd
        v-if="modalState === ONBOARDING_STATE.END"
        @close="$emit('close')"
      />
    </div>
    <template #actions>
      <PortalTarget :name="PortalName.MODAL_ACTIONS" />
    </template>
  </BaseModal>
</template>
<script>
import Onboarding from '@/mixins/Onboarding';
import BaseModal from '../BaseModal';
import OnboardingIntro from './OnboardingIntro.vue';
import OnboardingLoading from './OnboardingLoading.vue';
import OnboardingQuestion from './OnboardingQuestion.vue';
import OnboardingEnd from './OnboardingEnd.vue';
import ContentAPI from '@/api/ContentAPI';
import PortalName from '@/utils/PortalName';

const ONBOARDING_STATE = Object.freeze({
  START: 'start',
  QUESTIONNAIRE: 'questionnaire',
  LOADING: 'loading',
  END: 'end',
});

export default {
  name: 'OnboardingModal',
  components: {
    BaseModal,
    OnboardingIntro,
    OnboardingLoading,
    OnboardingQuestion,
    OnboardingEnd,
  },
  mixins: [Onboarding],
  data() {
    return {
      PortalName,
      ONBOARDING_STATE,
      modalState: ONBOARDING_STATE.START,
      answers: {},
    };
  },
  methods: {
    startQuestionnaire() {
      this.modalState = ONBOARDING_STATE.QUESTIONNAIRE;
    },
    goNext() {
      if (
        this.onboardingCurrentQuestionIndex ===
        this.onboardingQuestionCount - 1
      ) {
        this.modalState = ONBOARDING_STATE.LOADING;
        this.savePreferences();
      } else {
        if (
          this.onboardingCurrentStep === 'theme_step' &&
          this.answers.theme &&
          this.answers.theme.length === 0
        ) {
          this.$store.dispatch('onboarding/setOnboardingStep', {
            payloadIndex: 1,
          });
        }
        this.onboardingNextStep().then(() => {
          if (this.onboardingCurrentQuestion['#stats_input_origin']) {
            if (
              !this.answers[
                this.onboardingCurrentQuestion['#stats_input_origin']
              ]
            ) {
              this.goNext();
            }
          }
        });
        this.sendQuizResponse(false);
      }
    },
    goToIntro() {
      this.modalState = ONBOARDING_STATE.START;
    },
    goBack() {
      this.onboardingPreviousStep().then(() => {
        if (this.onboardingCurrentQuestion['#stats_input_origin']) {
          this.goBack();
        }
      });
    },
    async sendQuizResponse(isCompleted) {
      await ContentAPI.saveOnboarding(
        this.answers,
        this.onboardingCurrentStep,
        isCompleted
      );
      if (isCompleted) {
        await this.$store.dispatch('onboarding/fetchData', true);
      }
    },
    async savePreferences() {
      await this.sendQuizResponse(true);
      this.modalState = ONBOARDING_STATE.END;
    },
  },
  async mounted() {
    await this.fetchOnboardingData();
    if (this.onboardingCurrentStep === 'survey_step') {
      this.goNext();
    }
    this.answers = { ...this.draft };
  },
};
</script>
<style scoped lang="scss">
.onboarding-modal {
  &__content {
    height: 100%;
    overflow: auto;
    @include breakpoint($breakpoint-desktop) {
      overflow: visible;
    }
  }

  &:deep(.base-modal__body) {
    flex-grow: 1;
  }

  &:deep(.base-modal__body-container) {
    height: calc(100% - 2em);

    @include breakpoint($breakpoint-desktop) {
      height: 82%;
    }
  }

  &:deep(.base-modal__container) {
    width: 100%;
    max-width: 1200px;
    height: 100%;

    @include breakpoint($breakpoint-desktop) {
      max-height: 700px;
    }
  }
}
</style>
