<template>
  <div class="start-conversation">
    <SetPseudonymModal
      v-if="showPseudonymDialog"
      @close="showPseudonymDialog = false"
    />
    <VForm
      ref="form"
      class="start-conversation__container"
      @submit.prevent="emitEvent"
    >
      <BaseTitle class="start-conversation__title" :level="2">
        {{ $t('conversation.start') }}
      </BaseTitle>
      <VACTextarea
        v-if="userHasSetPseudonym"
        name="message"
        ref="message"
        v-model="content"
        :label="$t('conversation.placeholder')"
        class="start-conversation__field"
        :rules="messageRules"
        :disabled="isSubmitting"
        :max-length="500"
        :current-length="content.length"
        @overflowing="isOverflowing = true"
        required
      >
        <template #append>
          <EmojiPicker @insert="insertEmoji($event)" />
        </template>
      </VACTextarea>
      <p v-else class="start-conversation__field">
        {{ $t('conversation.setPseudonymText') }}
      </p>
      <Spinner v-if="isSubmitting" small class="start-conversation__spinner" />
      <VACButton
        v-else-if="userHasSetPseudonym"
        appearance="primary"
        :disabled="isSubmitting"
        type="submit"
        class="start-conversation__submit"
      >
        {{ $t('conversation.create') }}
      </VACButton>
      <VACButton
        v-else
        appearance="primary"
        :disabled="isOverflowing || content.length === 0"
        class="start-conversation__submit"
        @click="togglePseudonymDialog"
      >
        {{ $t('conversation.setPseudonymButton') }}
      </VACButton>
    </VForm>
  </div>
</template>

<script>
import { VACButton, VACTextarea } from '@webqam-vac-ui/lib';
import BaseTitle from '@/components/BaseTitle';
import EmojiPicker from '@/components/form/EmojiPicker';
import Auth from '@/mixins/Auth';
import SetPseudonymModal from '@/components/conversation/SetPseudonymModal';
import Spinner from '@/components/Spinner';

export default {
  name: 'StartConversation',
  components: {
    Spinner,
    SetPseudonymModal,
    BaseTitle,
    EmojiPicker,
    VACButton,
    VACTextarea,
  },
  mixins: [Auth],
  props: {
    isSubmitting: Boolean,
  },
  data() {
    return {
      content: '',
      isOverflowing: false,
      showPseudonymDialog: false,
      messageRules: [
        (v) => !!v || this.$t('conversation.message.required'),
        (v) =>
          (v && v.length <= 300) || this.$t('conversation.message.maxLength'),
      ],
    };
  },
  methods: {
    insertEmoji(emoji) {
      const position =
        this.$refs.message.$el.querySelector('textarea').selectionStart;
      this.content =
        this.content.slice(0, position) +
        emoji.native +
        this.content.slice(position);
    },
    emitEvent() {
      if (this.$refs.form.validate()) {
        this.$emit('message', this.content);
      }
    },
    togglePseudonymDialog() {
      this.showPseudonymDialog = !this.showPseudonymDialog;
    },
    clearText() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.start-conversation {
  &__container {
    @extend %base-container;
    padding-top: 1.875em;
    padding-bottom: 1.875em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 2px solid $color-wild-sand;
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.25em;
    margin-bottom: 0;
    text-align: center;
  }

  &__field {
    width: 100%;
    max-width: 38.125rem;
    margin: 1.25em auto;
    padding-top: 0;
  }

  &__spinner {
    height: 1em;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ::v-deep {
      .spinner__icon {
        font-size: 1.5em;
      }
    }
  }
}
</style>
