<template>
  <div class="help__wrapper">
    <transition name="fade">
      <div v-if="overlay" class="help__overlay"></div>
    </transition>
    <div ref="help" :class="classList">
      <header class="help__header" @click="startOpening">
        <div class="help__title">
          <slot name="title"></slot>
        </div>
        <button
          v-if="fixed"
          class="help__close"
          type="button"
          @click.stop="close"
        ></button>
        <div v-else class="help__icon"></div>
      </header>
      <transition name="help-">
        <div v-if="body" class="help__body">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'help';
const TRANSITION_DURATION = 150;

export default {
  name: 'HelpBox',
  data() {
    return {
      open: false,
      fixed: false,
      body: false,
      overlay: false,
      TRANSITION_DURATION,
    };
  },
  computed: {
    classList() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--fixed`]: this.fixed,
          [`${BLOCK_SELECTOR}--open`]: this.open,
          [`${BLOCK_SELECTOR}--body`]: this.body,
        },
      ];
    },
  },
  methods: {
    startOpening() {
      if (!this.fixed) {
        this.setToFixed();

        setTimeout(() => {
          this.overlay = true;
          this.open = true;
          this.body = true;
        }, TRANSITION_DURATION);
      }
    },
    setToFixed() {
      const el = this.$refs.help;
      const rect = el.getBoundingClientRect();

      this.fixed = true;
      el.style.bottom = `${window.innerHeight - rect.bottom}px`;
    },
    close() {
      this.body = false;

      setTimeout(() => {
        this.open = false;

        setTimeout(() => {
          this.overlay = false;

          setTimeout(() => {
            this.setToRelative();
          }, TRANSITION_DURATION);
        }, TRANSITION_DURATION * 2);
      }, TRANSITION_DURATION * 2);
    },
    setToRelative() {
      this.fixed = false;
      this.$refs.help.style.bottom = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  $block-selector: &;
  $collapsed-width-s: 90vw;
  $collapsed-width-l: 22em;
  $expanded-width-s: 100vw;
  $expanded-width-l: 29.75em;

  @extend %base-card;
  z-index: 11;
  top: auto;
  right: 0;
  left: 0;
  min-height: 3.125em;
  max-width: $expanded-width-s;
  width: $collapsed-width-s;
  margin: 0 auto;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
  transition-property: top, bottom, min-height, max-width, width, transform;
  transition-duration: $default-transition-duration;

  @include breakpoint($breakpoint-s) {
    max-width: $expanded-width-l;
    width: $collapsed-width-l;
  }

  &--fixed {
    position: fixed;
    width: $expanded-width-l;
  }

  &--open {
    top: 50%;
    bottom: auto !important;
    transform: translate3d(0, -50%, 0);

    #{$block-selector} {
      &__header {
        cursor: default;
      }
    }
  }

  &__wrapper {
    height: 3.125em;
    max-width: $expanded-width-l;
    width: $expanded-width-s;

    @include breakpoint($breakpoint-s) {
      width: $expanded-width-l;
    }
  }

  &__overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-black, 0.5);
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.125em;
    padding: 0.625em;
    font-family: $font-family-prelo-condensed;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $color-primary;
      transition: width $default-transition-duration;
    }

    #{$block-selector}--body &::after {
      width: 3.125em;
    }
  }

  &__icon,
  &__close {
    &::before {
      @extend %icon;
    }
  }

  &__icon {
    display: flex;
    font-size: 1.5625em;

    &::before {
      @extend %icon--help;
    }
  }

  &__close {
    $size: 2.5em;

    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    width: $size;

    &:hover {
      color: $color-primary;
    }

    &::before {
      @extend %icon--close;
    }
  }

  &__body {
    padding: 1.25em;
    transition-property: max-height, padding-top, padding-bottom, opacity;
    transition-duration: $default-transition-duration * 4;
  }

  &--enter,
  &--leave-to {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }

  &--enter-to,
  &--leave {
    max-height: 100vh;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    opacity: 1;
  }
}
</style>
