<template>
  <div class="account-content-block">
    <div class="account-content-block__header">
      <div class="account-content-block__item">
        <slot name="header-left"> </slot>
      </div>
      <div class="account-content-block__item">
        <slot name="header-right"> </slot>
      </div>
    </div>
    <div class="account-content-block__body">
      <slot name="default"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountContentBlock',
};
</script>

<style scoped lang="scss">
.account-content-block {
  @extend %base-container;
  margin-bottom: 2em;
  position: relative;
  padding: 0;

  &__header {
    margin-bottom: 1em;

    @include breakpoint($breakpoint-desktop) {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
