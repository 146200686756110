<template>
  <div v-if="events" class="home-upcoming-events">
    <div class="home-upcoming-events__background"></div>
    <div class="home-upcoming-events__container">
      <h3 id="upcoming" class="home-upcoming-events__title">
        {{ $t('home.events.upcoming') }}
      </h3>
      <swiper class="home-upcoming-events__events" :options="swiperOptions">
        <swiper-slide
          v-for="(event, index) in filteredEvents"
          :key="`home-event-${index}`"
        >
          <CardPlaceholder height="15em">
            <template #rows>
              <CardPlaceholderRow height="0.75em" width="40%" />
              <CardPlaceholderRow height="1.125em" />
              <CardPlaceholderRow height="1.125em" />
              <CardPlaceholderRow height="0.75em" width="30%" />
            </template>

            <CardEvent
              v-if="event.ID"
              small
              :event="event"
              :no-infos="!isLoggedIn"
              :no-interaction="!isLoggedIn"
            />
          </CardPlaceholder>
        </swiper-slide>
        <swiper-slide>
          <CardMoreEvents :no-interaction="!isLoggedIn" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import CardEvent from '@/components/card/CardEvent';
import CardMoreEvents from '@/components/card/CardMoreEvents';
import CardPlaceholder from '@/components/card/CardPlaceholder';
import CardPlaceholderRow from '@/components/card/CardPlaceholderRow';

export default {
  name: 'HomeUpcomingEvents',
  components: {
    CardMoreEvents,
    CardEvent,
    CardPlaceholder,
    CardPlaceholderRow,
  },
  props: {
    events: { type: Array, default: undefined },
    isLoggedIn: Boolean,
  },
  computed: {
    swiperOptions() {
      return {
        breakpoints: {
          320: {
            spaceBetween: 20,
            slidesPerView: 1.2,
          },
          768: {
            spaceBetween: 48,
            slidesPerView: 2.5,
          },
          1200: {
            spaceBetween: 48,
            slidesPerView: 4,
          },
        },
      };
    },
    filteredEvents() {
      return this.events.slice(0, 3);
    },
  },
};
</script>

<style scoped lang="scss">
.home-upcoming-events {
  position: relative;
  margin-bottom: 0.5em;

  @include breakpoint($breakpoint-tablet) {
    margin-bottom: 2.75em;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-gallery;
    width: 100%;
    height: 16.5em;
    z-index: 1;
  }

  &__container {
    @extend %base-container;

    position: relative;
    z-index: 2;
    padding-top: 1.25em;
    padding-right: 0;
    padding-left: 0;

    @include breakpoint($breakpoint-desktop) {
      max-width: 67.5rem + 2 * $home-mobile-padding;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__title,
  &__events {
    padding: 0 $home-mobile-padding;

    @include breakpoint($breakpoint-tablet) {
      padding: 0 $home-tablet-padding;
    }

    @include breakpoint($breakpoint-desktop) {
      padding: 0 $home-mobile-padding;
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.25em;
    text-transform: uppercase;
  }

  &__events {
    padding-bottom: 1em;
    margin-top: 1.25em;
  }

  .swipper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: auto;
  }

  .card-placeholder {
    box-shadow: 0 0 6px #00000029;

    .card-event {
      height: 100%;
      box-shadow: none;
    }
  }

  &::v-deep {
    .card-event {
      &__info-block {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      &__info--name {
        @include line-clamp(2);

        flex: 1;
      }
    }
  }
}
</style>
