<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal is-active left-title big-title @close="handleClose">
      <template #header-title>{{
        $t('goldenBook.record-modal.title')
      }}</template>
      <div class="golden-book-record-modal__body">
        <p class="golden-book-record-modal__description">
          {{ $t('goldenBook.record-modal.description-1') }}
        </p>
        <p class="golden-book-record-modal__description">
          {{ $t('goldenBook.record-modal.description-2') }}
        </p>
        <VACCheckbox
          v-model="doNotShowAgain"
          class="golden-book-record-modal__checkbox"
          :label="$t('goldenBook.record-modal.checkbox-label')"
        />
        <VACButton
          :label="$t('goldenBook.continue')"
          @click="handleContinue"
          class="golden-book-record-modal__continue-btn"
        >
        </VACButton>
      </div>
    </BaseModal>
  </Portal>
</template>
<script>
import BaseModal from '@/components/BaseModal';
import PortalName from '@/utils/PortalName';
import { VACButton, VACCheckbox } from '@webqam-vac-ui/lib';

export default {
  name: 'GoldenBookRecordModal',
  components: {
    BaseModal,
    VACButton,
    VACCheckbox,
  },
  data() {
    return {
      PortalName,
      doNotShowAgain: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit('close', { goBack: true });
    },
    handleContinue() {
      if (this.doNotShowAgain) {
        localStorage.setItem('hideStoryInfoModal', 'true');
      }
      this.$emit('close', { goBack: false });
    },
  },
};
</script>
<style lang="scss">
.golden-book-record-modal {
  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__continue-btn {
    width: 100%;
    margin-top: 1rem;
  }
}
</style>
