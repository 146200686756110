<template>
  <div class="account-personal-informations">
    <AccountNotifications />
    <PersonalAccount class="account__item" />
    <VACExpandableSection is-open large class="account__section">
      <template #title>
        {{ $t('account.passwordInformations.title') }}
      </template>
      <template #body>
        <PasswordAccount class="account__item" />
      </template>
    </VACExpandableSection>
  </div>
</template>

<script>
import { VACExpandableSection } from '@webqam-vac-ui/lib';
import PersonalAccount from '@/components/account/PersonalAccount';
import PasswordAccount from '@/components/account/PasswordAccount';
import AccountNotifications from '@/components/account/AccountNotifications';
export default {
  name: 'AccountPersonalInformations',
  components: {
    PersonalAccount,
    VACExpandableSection,
    PasswordAccount,
    AccountNotifications,
  },
};
</script>

<style scoped lang="scss">
.account-personal-informations {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @include breakpoint($breakpoint-tablet) {
    gap: 3.125rem;
  }
}
</style>
