<template>
  <CollectionWidget />
</template>

<script>
import CollectionWidget from '@/components/collection/CollectionWidget';
import Tracking from '@/mixins/Tracking';
export default {
  name: 'AccountCollection',
  components: { CollectionWidget },
  mixins: [Tracking],
  mounted() {
    this.trackPageView();
  },
};
</script>

<style scoped></style>
