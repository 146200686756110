import { createNamespacedHelpers, Module } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';
import { RootState } from '@/types/RootState';
import { CaptchaState } from '@/types/CaptchaState';

export const NAMESPACE = 'captcha';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum CaptchaMutations {
  setIsHCaptcha = 'setIsHCaptcha',
}

export enum CaptchaGetters {
  isHCaptcha = 'isHCaptcha',
}

const module: Module<CaptchaState, RootState> = {
  namespaced: true,
  state() {
    return {
      isHCaptcha: false,
    };
  },
  mutations: {
    [CaptchaMutations.setIsHCaptcha](state, value) {
      state.isHCaptcha = value;
    },
  },
};

export default module;
