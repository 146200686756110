<template>
  <div class="account-public-profile">
    <PublicProfileAccount />
  </div>
</template>

<script>
import PublicProfileAccount from '@/components/account/PublicProfileAccount';

export default {
  name: 'AccountPublicProfile',
  components: {
    PublicProfileAccount,
  },
};
</script>

<style scoped lang="scss"></style>
