<template>
  <div class="event-grid">
    <GridViewTemplate :title="$t('events.title')">
      <template #filters>
        <div class="event-grid__filters">
          <div class="event-grid__filters-container">
            <VACButton
              v-for="filter in filters"
              :key="`event-filter-${filter.value}`"
              :class="filterClass(filter)"
              :to="{
                name: RouteName.EVENTS,
                params: { preFilter: filter.value },
              }"
              appearance="discrete"
              icon=""
              :label="filter.label"
            />
          </div>
        </div>
        <EventSearch
          v-if="preFilter === EventsCategories.search"
          class="event-grid__search"
          :countries="countries"
          @filter="updateSearchCriterias"
        />
        <div
          v-if="preFilter === EventsCategories.search && currentTotal > 0"
          class="event-grid__results-count"
        >
          {{ $t('events.results_count', { count: currentTotal }) }}
        </div>
        <p
          v-if="preFilter === EventsCategories.myEvents"
          class="event-grid__upcoming-events-title"
        >
          {{ $t('events.upcoming') }}
        </p>
      </template>
      <template #default>
        <BaseGridItem
          v-for="(event, index) in events"
          :key="`event-grid-${index}`"
          :size="$matchMedia.xl ? 33.33 : 50"
        >
          <EventCard :event="event" :is-booked="isEventBooked(event.ID)" />
        </BaseGridItem>
        <div
          v-if="!events || events.length === 0"
          class="event-grid__no-events"
        >
          {{ $t('events.no_results') }}
        </div>
      </template>
    </GridViewTemplate>
    <GridViewTemplate v-if="passedEvents" class="event-grid__passed-events">
      <template #filters>
        <hr />
        <p class="event-grid__passed-events-title">
          {{ $t('events.passed') }}
        </p>
      </template>
      <template #default>
        <BaseGridItem
          v-for="(event, index) in passedEvents"
          :key="`event-passed-grid-${index}`"
          :size="$matchMedia.xl ? 33.33 : 50"
        >
          <EventCard :event="event" />
        </BaseGridItem>
        <div
          v-if="!passedEvents || passedEvents.length === 0"
          class="event-grid__no-events"
        >
          {{ $t('events.no_results') }}
        </div>
      </template>
    </GridViewTemplate>
    <template v-if="hasMoreEvents">
      <Spinner v-if="isItemLoading" />
      <Spy @reached="nextPage" />
    </template>
    <BackToTop />
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import GridViewTemplate from '@/components/grid/GridViewTemplate';
import BaseGridItem from '@/components/grid/BaseGridItem';
import EventSearch from '@/components/event/EventSearch';
import EventCard from '@/components/event/EventCard';
import BackToTop from '@/components/BackToTop';
import Spy from '@/components/Spy';
import Spinner from '@/components/Spinner';
import {
  EventsActions,
  EventsGetters,
  EventsCategories,
  prefixType,
} from '@/store/modules/events';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

const BLOCK_SELECTOR = 'event-grid';

const fakeEvents = new Array(6).fill([{}]).flat();

export default {
  name: 'EventGrid',
  components: {
    VACButton,
    BackToTop,
    EventSearch,
    BaseGridItem,
    GridViewTemplate,
    EventCard,
    Spy,
    Spinner,
  },
  mixins: [Tracking],
  props: {
    preFilter: { type: String, default: 'all' },
  },
  data() {
    return {
      RouteName,
      EventsCategories,
      searchCriterias: undefined,
      isItemLoading: false,
      isSearching: false,
      filters: [
        {
          label: this.$t('events.options.all'),
          value: 'all',
        },
        {
          label: this.$t('events.options.my_events'),
          value: 'my_events',
        },
        {
          label: this.$t('events.options.search'),
          value: 'search',
        },
      ],
    };
  },
  computed: {
    currentCategory() {
      return this.$store.getters[prefixType(EventsGetters.getEvents)](
        this.preFilter
      );
    },
    events() {
      return (
        (!this.isSearching &&
          this.currentCategory &&
          this.currentCategory.events) ||
        fakeEvents
      );
    },
    myEventsCategory() {
      return this.$store.getters[prefixType(EventsGetters.getEvents)](
        EventsCategories.myEvents
      );
    },
    allEventsCategory() {
      return this.$store.getters[prefixType(EventsGetters.getEvents)](
        EventsCategories.all
      );
    },
    countries() {
      return this.allEventsCategory && this.allEventsCategory.countries;
    },
    myActiveEvents() {
      if (this.myEventsCategory) {
        return this.myEventsCategory.events;
      }
      return [];
    },
    passedEvents() {
      return (
        this.preFilter === EventsCategories.myEvents &&
        this.myEventsCategory &&
        this.myEventsCategory.passedEvents
      );
    },
    currentPageNum() {
      return this.currentCategory && this.currentCategory.currentPage;
    },
    currentTotal() {
      return this.currentCategory && this.currentCategory.total;
    },
    hasMoreEvents() {
      return this.events && this.events.length < this.currentTotal;
    },
    apiSearchParameters() {
      const settings = {};
      if (this.preFilter === EventsCategories.search) {
        settings.all = true;
        if (this.searchCriterias) {
          if (this.searchCriterias.dates) {
            if (this.searchCriterias.dates[0]) {
              settings.dateStart = this.searchCriterias.dates[0];
            }
            if (this.searchCriterias.dates[1]) {
              settings.dateEnd = this.searchCriterias.dates[1];
            }
          }
          if (this.searchCriterias.countryCode) {
            settings.countryCodes = this.searchCriterias.countryCode;
          }
          if (this.searchCriterias.onlyUpcomingEvents) {
            settings.all = false;
          }
        }
      }
      return settings;
    },
  },
  watch: {
    $route() {
      this.fetchEvents(1, this.preFilter, false, this.apiSearchParameters);
    },
  },
  created() {
    this.trackPageView(this.$t('title.events'));
    this.fetchEvents(1, EventsCategories.all, false, this.apiSearchParameters);
    this.fetchEvents(1, EventsCategories.myEvents);
    if (this.preFilter === EventsCategories.search) {
      this.fetchEvents(
        1,
        EventsCategories.search,
        false,
        this.apiSearchParameters
      );
    }
  },
  methods: {
    filterClass({ value }) {
      return [
        `${BLOCK_SELECTOR}__filter`,
        {
          [`${BLOCK_SELECTOR}__filter--active`]: value === this.preFilter,
        },
      ];
    },
    fetchEvents(
      page = 1,
      category = this.preFilter,
      forceRefresh = false,
      filters
    ) {
      this.$store
        .dispatch(prefixType(EventsActions.fetchEvents), {
          category,
          page,
          forceRefresh,
          ...filters,
        })
        .then(() => {
          this.isItemLoading = false;
          this.isSearching = false;
        });
    },
    isEventBooked(eventID) {
      if (this.preFilter === EventsCategories.myEvents) {
        return true;
      }
      return (
        this.myActiveEvents &&
        typeof this.myActiveEvents.find((e) => e.ID === eventID) !== 'undefined'
      );
    },
    updateSearchCriterias($event) {
      this.isSearching = true;
      this.searchCriterias = $event;
      this.fetchEvents(
        1,
        EventsCategories.search,
        true,
        this.apiSearchParameters
      );
    },
    nextPage() {
      if (
        !this.isItemLoading &&
        this.currentCategory.maxPage > this.currentPageNum
      ) {
        this.isItemLoading = true;
        this.fetchEvents(
          this.currentPageNum + 1,
          this.currentCategory.category,
          false,
          this.apiSearchParameters
        );
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.events')}`,
    };
  },
};
</script>

<style scoped lang="scss">
.event-grid {
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__filters {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include breakpoint($breakpoint-m) {
      overflow: unset;
    }
  }

  &__filters-container {
    display: flex;
    width: fit-content;
    @include breakpoint($breakpoint-m) {
      justify-content: center;
      width: 100%;
    }
  }
  &__filter {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-transform: initial;
    font-size: 1rem;
    white-space: nowrap;

    @include breakpoint($breakpoint-xxs) {
      padding-left: $base-btn-horizontal-padding;
      padding-right: $base-btn-horizontal-padding;
    }

    &--active {
      font-weight: bold;
    }
  }

  &__search {
    margin-top: 2em;
    margin-bottom: 3em;
  }

  &__spinner {
    margin-top: 3em;
    width: 100%;
  }

  &__passed-events {
    margin-top: 2em;
    margin-bottom: 4em;
  }

  &__passed-events-title,
  &__upcoming-events-title {
    margin-top: 0.75em;
    text-align: left;
    font-family: $font-family-prelo-condensed;
    font-weight: 700;
    font-size: 1.25em;
  }

  &__upcoming-events-title {
    display: block;
  }

  &__no-events {
    width: 100%;
    margin-top: 1em;
    text-align: center;
  }
}
</style>
