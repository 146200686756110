enum RouteName {
  PREHOME = 'prehome',
  HOME = 'home',
  CONTACT = 'contact',
  CONTACT_CONFIRMATION = 'contact_confirmation',
  LEGAL = 'legal',
  TERMS_OF_USE = 'terms-of-use',
  FAQ = 'faq',
  MAGAZINE = 'magazine',
  SINGLE_ARTICLE = 'single_article',
  ARTICLE_CONVERSATION = 'article_conversation',
  EVENTS = 'events',
  SINGLE_EVENT = 'single_event',
  REGISTER_EVENT = 'register_event',
  EVENT_REGISTRATION_CONFIRMATION = 'register_confirmation',
  CMS = 'cms',
  PAGE_NOT_FOUND = 'page_not_found',
  ACCOUNT_DETAILS = 'account-details',
  ACCOUNT_CONFIRMATION = 'account-confirmation',
  ACCOUNT_POSTS = 'account_posts',
  ACCOUNT_STORIES = 'account_stories',
  ACCOUNT_CONVERSATIONS = 'account_conversations',
  ACCOUNT_LOGIN_CALLBACK = 'account_login_callback',
  ACCOUNT_WECHAT_ASSOCIATION = 'account_wechat_association',
  ACCOUNT_PROCESS_LOGIN = 'account_process_login',
  ACCOUNT_ASK_LOGIN = 'account_ask_login',
  USER_PROFILE = 'user_profile',
  USER_STORIES = 'user_stories',
  USER_CONVERSATIONS = 'user_conversations',
  RESET_PASSWORD = 'reset_password',
  PASSWORD_FORGOT = 'password_forgot',
  PASSWORD_FORGOT_CN = 'password_forgot_cn',
  COLLECTION = 'collection',
  ALREADY_EMAIL_ACCOUNT = 'already_email_account',
  ALREADY_EMAIL_ACCOUNT_PASSWORD = 'already_email_account_password',
  GOLDEN_BOOK = 'golden_book',
  GOLDEN_BOOK_SHARE_STORY = 'golden_book_share_story',
  GOLDEN_BOOK_VAG_MEDIA = 'golden_book_vag_media',
  GOLDEN_BOOK_EDIT_STORY = 'golden_book_edit_story',
  GOLDEN_BOOK_SINGLE_STORY = 'golden_book_single_story',
  GOLDEN_BOOK_CONVERSATION = 'golden_book_conversation',
}

export default RouteName;
