<template>
  <div v-if="event" class="event-register-form">
    <BaseDialog :show="showError" :title="$t('error.title')">
      <!--eslint-disable-next-line vue/no-v-html-->
      <div v-if="showErrorMessage" v-html="showErrorMessage"></div>
      <div v-else>{{ $t('error.register_event') }}</div>
    </BaseDialog>
    <form class="event-register-form__form" @submit.prevent="handleSubscribe()">
      <div class="event-register-form__row">
        <VACInput
          disabled
          readonly
          name="lastname"
          :label="$t('events.register.last_name')"
          v-model="formData.firstName"
          class="event-register-form__input"
        />
        <VACInput
          disabled
          readonly
          name="first_name"
          :label="$t('events.register.first_name')"
          v-model="formData.lastName"
          class="event-register-form__input"
        />
      </div>
      <div class="event-register-form__row">
        <VACInput
          v-if="!formData.phone.prefix"
          disabled
          readonly
          type="mail"
          v-model="formData.email"
          name="email"
          :label="$t('events.register.mail')"
          :rules="rules.email"
          class="event-register-form__input"
        />
        <VACInput
          v-else
          v-model="formData.phone"
          :label="$t('events.register.phone')"
          disabled
          readonly
          type="phone"
          name="phone"
          class="event-register-form__input"
        />
      </div>
      <div
        v-if="availableSlots && availableSlots.length > 0"
        class="event-register-form__grey"
      >
        <div class="event-register-form__row">
          <VACSelect
            v-model="formData.guestsNumber"
            name="guest_count"
            :label="$t('events.register.guests')"
            :disabled="isDisabled || fieldDisabled"
            :options="availableSlots"
            class="event-register-form__guests"
          />
        </div>
      </div>
      <div class="event-register-form__row">
        <VACSelect
          v-if="timeSlots"
          v-model="formData.timeslot"
          name="guest_count"
          :label="$t('events.register.time')"
          :disabled="isDisabled || fieldDisabled"
          :options="timeSlots"
          class="event-register-form__field"
          required
        />
        <VACInput
          name="message"
          class="event-register-form__field"
          :label="$t('events.register.message')"
          :disabled="isDisabled || fieldDisabled"
          v-model="formData.message"
        />
      </div>
      <template v-if="!isDisabled && fieldDisabled">
        <VACButton
          full-width
          center
          appearance="primary"
          @click.prevent="fieldDisabled = false"
        >
          {{ $t('events.register.manage') }}
        </VACButton>
        <EventUnsubscribe :event="event" :registration="registerInfos" />
      </template>
      <template v-else-if="!isDisabled">
        <VACButton
          appearance="primary"
          full-width
          center
          type="submit"
          :label="
            $t(
              alreadyRegisteredEvent
                ? 'events.register.confirm_submit'
                : 'events.register.submit_button'
            )
          "
        >
        </VACButton>
        <VACButton
          v-if="registerInfos"
          appearance="link"
          class="event-register-form__cancel"
          @click="fieldDisabled = true"
        >
          {{ $t('events.register.cancel_edit') }}
        </VACButton>
      </template>
      <Spinner v-else small />
    </form>
    <NotificationRequest
      v-if="AIShowModal"
      :authorizations="[AuthorizationType.EVENT]"
      content-type="event"
      @close="aiModalClosed"
    />
  </div>
</template>

<script>
import AuthorizationType from '@/utils/AuthorizationType';
import ContentAPI from '@/api/ContentAPI';
import { VACInput, VACSelect, VACButton } from '@webqam-vac-ui/lib';
import { formatDateAndTime } from '@/utils/DateUtils';
import RouteName from '@/utils/RouteName';
import Spinner from '@/components/Spinner';
import BaseDialog from '@/components/BaseDialog';
import EventUnsubscribe from '@/components/event/EventUnsubscribe';
import { prefixType, EventsActions } from '@/store/modules/events';
import UserData from '@/mixins/UserData';
import { pushToDataLayer } from '@/utils/GTMMaker';
import AuthorizationInterceptor from '@/mixins/AuthorizationInterceptor';
import NotificationRequest from '../NotificationRequest.vue';

export default {
  name: 'EventRegisterForm',
  components: {
    EventUnsubscribe,
    BaseDialog,
    Spinner,
    VACInput,
    VACSelect,
    VACButton,
    NotificationRequest,
  },
  mixins: [UserData, AuthorizationInterceptor],
  props: {
    event: { type: Object, default: undefined },
    registerInfos: { type: Object, default: undefined },
  },
  data() {
    return {
      AuthorizationType,
      showError: false,
      showErrorMessage: null,
      isFormValid: '',
      isFormLazy: true,
      isDisabled: false,
      fieldDisabled: !!this.registerInfos,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: {},
        guestsNumber: 0,
        guestName: [],
        timeslot: '',
        message: '',
      },
      rules: {
        email: [
          (v) => !!v || this.$t('events.register.error.email.required'),
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('events.register.error.email.invalid'),
        ],
        registrationSlot: [
          (v) => !!v || this.$t('events.register.error.timeslot.required'),
        ],
        guestName: [
          (v) => !!v || this.$t('events.register.error.guests.required'),
        ],
      },
    };
  },
  computed: {
    availableSlots() {
      if (!this.event.allowed_guests || this.event.allowed_guests === '0') {
        return undefined;
      }
      const items = [];
      for (let i = 0; i <= parseInt(this.event.allowed_guests); i++) {
        items.push({
          label: i,
          value: i,
        });
      }
      return items;
    },
    timeSlots() {
      const timeSlots = [];
      for (const slot of this.event.registration_slot) {
        timeSlots.push({
          label: formatDateAndTime(slot.value),
          value: slot.value,
        });
      }
      return timeSlots.length > 0 ? timeSlots : false;
    },
    alreadyRegisteredEvent() {
      return !!this.registerInfos;
    },
    user() {
      return this.$store.state.auth.userInfos.user;
    },
  },
  mounted() {
    this.formData.firstName = this.user.firstName1;
    this.formData.lastName = this.user.lastName1;
    this.formData.email = this.user.emailAddress;

    if (this.user.phone) {
      this.formData.phone = {
        prefix: this.user.phone.slice(0, 3),
        number: this.user.phone.slice(3),
      };
    }

    if (this.registerInfos) {
      this.formData.guestsNumber = parseInt(this.registerInfos.guest);
      this.formData.timeslot = this.registerInfos.registration_slots;
      this.formData.message = this.registerInfos.message;
    }
  },
  methods: {
    handleSubscribe() {
      this.AICallback = this.sendSubscription();
      if (
        !localStorage.getItem(`${AuthorizationType.EVENT}_refused`) &&
        !this.$store.state.profile.notifications[AuthorizationType.EVENT]
      ) {
        this.AIShowModal = true;
      } else {
        this.sendSbscription();
      }
    },
    async sendSubscription() {
      this.isDisabled = true;
      try {
        const result = await ContentAPI.registerToEvent({
          event: this.event.ID,
          firstName: this.userFirstName,
          lastName: this.userLastName,
          email: this.formData.email,
          phone: this.formData.phone.number,
          prefixPhone: this.formData.phone.prefix,
          registrationSlots: this.formData.timeslot,
          guest: this.formData.guestsNumber,
          message: this.formData.message,
          guestName: this.formData.guestName,
        });
        if (result) {
          pushToDataLayer({
            event: 'eventSubscription',
            EventName: this.event.title,
          });
          await this.$store.dispatch(prefixType(EventsActions.destroyCache));
          this.redirectToConfirmation();
        } else {
          this.showError = true;
        }
      } catch (err) {
        this.showError = true;
        this.showErrorMessage =
          (err &&
            err.response &&
            err.response.data &&
            err.response.data.message) ||
          err.message;
      }
    },
    redirectToConfirmation() {
      this.$router.push({
        name: RouteName.EVENT_REGISTRATION_CONFIRMATION,
        params: { eventId: this.event.ID },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.event-register-form {
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__grey {
    @include breakpoint($breakpoint-tablet) {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -3em;
        bottom: 0;
        width: 100vw;
        background-color: $color-wild-sand;
      }
    }
  }

  &__guests {
    z-index: 2;
    @include breakpoint($breakpoint-tablet) {
      max-width: 48%;
      flex-basis: 48%;
    }
  }

  &__field {
    flex-basis: 100%;

    @include breakpoint($breakpoint-tablet) {
      max-width: 48%;
      flex-basis: 48%;
    }
  }

  ::v-deep {
    .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
      background: transparent;
      padding-left: 0;
      padding-right: 0;

      &:hover {
        background: transparent;
      }
    }
  }

  &__cancel {
    margin-top: 1em;
  }

  &__input {
    width: calc(50% - 0.5rem);
  }
}
</style>
