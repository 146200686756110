<template>
  <div class="inline-menu">
    <VACButton
      v-for="(item, index) in menuItems"
      :key="index"
      :class="menuItemClasses(item)"
      :href="item.link"
      appearance="discrete"
      :label="item.title"
      icon=""
      @click.prevent="goTo(item.link)"
    />
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';

const MENU_ITEM_SELECTOR = 'inline-menu__item';

export default {
  name: 'InlineMenu',
  components: { VACButton },
  props: {
    menuItems: { type: Array, default: undefined },
  },
  methods: {
    menuItemClasses(item) {
      return [
        MENU_ITEM_SELECTOR,
        {
          [`${MENU_ITEM_SELECTOR}--active`]: item.active,
        },
      ];
    },
    goTo(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style scoped lang="scss">
.inline-menu {
  display: none;

  @include breakpoint($breakpoint-desktop) {
    display: flex;
    flex-grow: 1;
    margin-right: 1em;
    gap: 1.875rem;
  }

  &__item {
    font-weight: bold;
    font-size: 0.75rem;
    transition: color 0.3s;
    &:hover {
      color: $color-mongoose;
    }
    &--active {
      color: $color-mongoose;
      ::v-deep .vac-button {
        &,
        &::after {
          color: $color-primary;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
