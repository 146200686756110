<template>
  <PageLoader v-if="!userProfile || isFetching" />
  <div v-else class="account-conversations">
    <BaseBtn
      discrete
      class="account-conversations__back-btn"
      @click="$router.go(-1)"
    >
      {{ backText }}
    </BaseBtn>
    <BaseTitle :level="1" center class="account-conversations__title">
      {{ pageTitle }}
    </BaseTitle>
    <div
      v-masonry="gridId"
      horizontal-order="true"
      transition-duration="0.3s"
      item-selector=".account-conversations__conversation"
      column-width=".account-conversations__conversation"
      percent-position
      :gutter="32"
    >
      <ConversationSummaryCard
        v-for="conversation in conversations"
        :key="conversation.parent_id"
        v-masonry-tile
        class="account-conversations__conversation"
        :node-id="conversation.parent_id"
        :node-type="conversation.parent_type"
        :name="conversation.parent_title"
        :image="conversation.image[0].x1"
        :content="conversation.interactions[0].texte"
        :reply-count="conversation.interactions[0].number_reply"
        :author="conversation.interactions[0].author.pseudo"
        :date="conversation.interactions[0].date"
        :interactions="conversation.interactions"
      />
    </div>
    <Spinner v-if="currentPage < maxPage" />
    <Spy v-if="currentPage < maxPage" @reached="fetchConversations" />
  </div>
</template>

<script>
import PageLoader from '@/components/PageLoader';
import BaseTitle from '@/components/BaseTitle';
import ConversationSummaryCard from '@/components/conversation/ConversationSummaryCard';
import Spinner from '@/components/Spinner';
import Spy from '@/components/Spy';

import ContentAPI from '@/api/ContentAPI';
import RouteName from '@/utils/RouteName';
import BaseBtn from '@/components/BaseBtn';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountConversations',
  components: {
    BaseBtn,
    Spinner,
    PageLoader,
    BaseTitle,
    ConversationSummaryCard,
    Spy,
  },
  mixins: [Tracking],
  props: {
    profileId: { type: String, default: undefined },
  },
  data() {
    return {
      currentPage: 0,
      maxPage: undefined,
      isFetching: false,
      userProfile: undefined,
      conversations: [],
      gridId: 'allConv',
      RouteName,
    };
  },
  computed: {
    isProfilePreview() {
      return this.$route.name === RouteName.ACCOUNT_CONVERSATIONS;
    },
    backText() {
      if (this.isProfilePreview) {
        return this.$t('accountPosts.backToPosts');
      }
      return this.$t('accountPosts.back');
    },
    pageTitle() {
      if (this.isProfilePreview) {
        return this.$t('accountPosts.myConversations');
      }
      return this.$t('accountPosts.conversationsOf', {
        name: this.userProfile.pseudo,
      });
    },
    userProfileId() {
      return this.isUserProfile
        ? this.$store.state.auth.userProfile.ID
        : this.profileId;
    },
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async initComponent() {
      this.isFetching = true;
      if (!this.isProfilePreview) {
        if (this.profileId) {
          const { data } = await ContentAPI.getProfile(this.profileId);
          this.userProfile = data;
        } else {
          this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
        }
      } else {
        this.userProfile = this.$store.state.auth.userInfos;
      }
      this.trackPageView(this.userProfile ? this.pageTitle : '');
      await this.fetchConversations();
      this.isFetching = false;
    },
    async fetchConversations() {
      this.currentPage++;
      const { data } = await ContentAPI.getUserAllConversations({
        page: this.currentPage,
        user: this.userProfileId,
      });
      this.conversations = [
        ...this.conversations,
        ...data.paragraphs.filter((item) => item.deleted !== '1'),
      ];
      this.maxPage = data.nbPage;
      this.requestRedraw();
    },
    requestRedraw() {
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          this.$redrawVueMasonry(this.gridId);
        });
      });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.userProfile ? this.pageTitle : ''}`,
    };
  },
};
</script>

<style scoped lang="scss">
.account-conversations {
  @extend %base-container;
  position: relative;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.875em;
    margin-bottom: 2.233em;
  }

  &__back-btn {
    @extend %back-btn;

    display: none;
    position: absolute;
    top: 0.25em;
    left: 0;

    @include breakpoint($breakpoint-tablet) {
      left: 3.438em;
      display: flex;
    }

    @include breakpoint($breakpoint-desktop) {
      left: 0;
    }
  }

  &__conversation {
    width: 100%;
    margin-bottom: 2em;

    @include breakpoint($breakpoint-tablet) {
      width: calc(50% - 1em);
    }
  }
}
</style>
