<template>
  <div class="card-placeholder-row" :style="styles"></div>
</template>

<script>
export default {
  name: 'CardPlaceholderRow',
  props: {
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
  computed: {
    /**
     * @returns {Object}
     */
    styles() {
      const styles = {};

      if (this.height) {
        styles.height = this.height;
      }

      if (this.width) {
        styles.width = this.width;
      }

      return styles;
    },
  },
};
</script>

<style scoped lang="scss">
.card-placeholder-row {
  height: 1em;
  width: 100%;
  background-color: $color-wild-sand;
}
</style>
