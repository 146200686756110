import ContentAPI from '@/api/ContentAPI';
import { ArticleNode } from '@/types/ArticleListResponse';
import OnboardingData, { OnboardingPage } from '@/types/OnboardingData';
import { Module, createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'onboarding';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

interface OnboardingCustomContent extends ArticleNode {
  bundle: string;
}

interface moduleState {
  data: OnboardingData | undefined;
  currentStep: string | undefined;
  draft: any;
  content: Array<OnboardingCustomContent>;
}

const module: Module<moduleState, any> = {
  namespaced: true,
  state: {
    data: undefined,
    currentStep: undefined,
    draft: undefined,
    content: [],
  },
  mutations: {
    setOnboardingData: (state: moduleState, payload: OnboardingData): void => {
      state.data = payload;
    },
    setOnboardingStep: (state: moduleState, payload: string): void => {
      state.currentStep = payload;
    },
    setDraftAnswers: (state: moduleState, payload: any): void => {
      state.draft = payload;
    },
    setCustomContent: (state: moduleState, payload: any): void => {
      state.content = payload;
    },
  },
  getters: {
    isOnboardingCompleted: (state): boolean => {
      return state.data ? state.data.completed : false;
    },
    isOnboardingNecessary: (state): boolean => {
      return state.data ? !state.data.completed : false;
    },
    onboardingQuestionCount: (state): number => {
      return state.data
        ? state.data.form.pages.filter((page) => page['#is_question'] !== false)
            .length
        : 0;
    },
    onboardingTime: (state): number => {
      if (!state.data) {
        return 0;
      }
      return Math.round(
        ((state.data &&
          state.data.form.pages.filter((page) => page['#is_question'] !== false)
            .length) *
          30) /
          60
      );
    },
    onboardingCurrentQuestion: (state): OnboardingPage | undefined => {
      if (state.data) {
        return state.data.form.pages.find(
          (item) => item['#page_name'] === state.currentStep
        );
      }
      return undefined;
    },
    onboardingCurrentQuestionIndex: (state): number | undefined => {
      if (state.data) {
        return state.data.form.pages.findIndex(
          (item) => item['#page_name'] === state.currentStep
        );
      }
      return undefined;
    },
    thematicsStats: (state) => {
      if (state.data) {
        const formPage = state.data.form.pages.find(
          (page) => page['#page_name'] === 'theme_step'
        );
        if (formPage) {
          const formElements = formPage.elements.find(
            (elem) => elem['#name'] === 'theme[checkboxes]'
          );
          if (formElements) {
            return formElements['#options'];
          }
        }
      }
      return undefined;
    },
    magazineContent: (state) => {
      if (state.content) {
        return state.content.filter(
          (content) => content.bundle === 'magazine_article'
        );
      }
      return [];
    },
  },
  actions: {
    fetchData: async ({ commit, state }, force): Promise<void> => {
      if (!state.data || force) {
        const { data } = await ContentAPI.getOnboarding();
        commit('setOnboardingData', data);
        commit('setDraftAnswers', data.form.draft.data);
        if (data.form.current_page && !data.completed) {
          commit('setOnboardingStep', data.form.current_page);
        } else {
          commit('setOnboardingStep', data.form.pages[0]['#page_name']);
        }
        if (data.result) {
          commit('setCustomContent', data.result);
        }
      }
    },
    nextStep: ({ state, commit, getters }): void => {
      commit(
        'setOnboardingStep',
        state.data?.form.pages[getters.onboardingCurrentQuestionIndex + 1][
          '#page_name'
        ]
      );
    },
    setOnboardingStep: ({ state, commit }, { payloadIndex }): void => {
      commit(
        'setOnboardingStep',
        state.data?.form.pages[payloadIndex]['#page_name']
      );
    },
    previousStep: ({ state, commit, getters }): void => {
      commit(
        'setOnboardingStep',
        state.data?.form.pages[getters.onboardingCurrentQuestionIndex - 1][
          '#page_name'
        ]
      );
    },
  },
};

export default module;
