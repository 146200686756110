<template>
  <!-- eslint-disable -->
  <div class="collection-widget" ref="container">
    <div class="collection-widget__container">
      <BaseBtn class="collection-widget__action" primary :href="ccpLink">
        {{ $t('timepiece.register.title') }}
      </BaseBtn>
    </div>
    <iframe
      id="ccp-iframe"
      :src="hydraFrameLink"
      style="display: none;"
    ></iframe>
  </div>
  <!-- eslint-enable -->
</template>

<script>
import BaseBtn from '@/components/BaseBtn';
import Internationalization from '@/utils/Internationalization';
import { generateLocalizedCCPLink, getLocaleForLink } from '@/utils/CCP';

export default {
  name: 'CollectionWidget',
  components: { BaseBtn },
  computed: {
    hydraFrameLink() {
      return process.env.VUE_APP_HYDRA_FRAME.replace(
        '%LANG%',
        getLocaleForLink()
      );
    },
    ccpLink() {
      return generateLocalizedCCPLink('/portfolio');
    },
    widgetCountry() {
      return Internationalization.isCnCountry() ? 'CN' : 'WW';
    },
  },
  mounted() {
    this.$nextTick(() => {
      const script = document.createElement('script');
      script.src = process.env.VUE_APP_CCP_WIDGET.replace(
        '%LANG%',
        getLocaleForLink()
      );
      document.head.appendChild(script);
      const widget = document.createElement('ccp-portfolio');
      widget.setAttribute('language', getLocaleForLink());
      widget.setAttribute('country', this.widgetCountry);
      widget.setAttribute('hydrasso', 'true');
      this.$refs.container.appendChild(widget);
    });
  },
};
</script>

<style scoped lang="scss">
.collection-widget {
  &__container {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 2em;
    margin-top: 2em;
    width: fit-content;
  }

  &::v-deep {
    ccp-card .ccp-card-wrapper .ccp-card-panel {
      z-index: 1;
    }
  }
}
</style>
