import storage from '@/utils/storage';
import authentication, {
  AUTH_SESSION_COUNTRY,
  AUTH_SESSION_SESSION_TOKEN_ID,
} from '@/utils/authentication';
import AuthenticationRealms from '@/enums/AuthenticationRealms';
import HydraClient from '@/api/HydraClient';
import WeChatClient from '@/api/WeChatClient';
import UpdateAccountParam from '@/types/UpdateAccountParam';
import { AxiosResponse } from 'axios';
import store from '@/store';

class AccountAPIWrapper {
  get currentRealm() {
    return storage.get(AUTH_SESSION_COUNTRY);
  }

  async updatePassword(
    newPassword: string,
    oldPassword: string
  ): Promise<void> {
    if (this.currentRealm === AuthenticationRealms.WW) {
      return await HydraClient.updatePassword(oldPassword, newPassword);
    } else {
      return await WeChatClient.updatePassword(oldPassword, newPassword);
    }
  }

  async updateAccount(
    params: UpdateAccountParam
  ): Promise<void | AxiosResponse> {
    if (this.currentRealm === AuthenticationRealms.WW) {
      return await HydraClient.updateAccount(params);
    } else {
      return await WeChatClient.updateAccount(params);
    }
  }

  async logout() {
    store.dispatch('loader/enableLoader');
    if (this.currentRealm === AuthenticationRealms.WW) {
      const { data } = await HydraClient.revokeToken(
        storage.get(AUTH_SESSION_SESSION_TOKEN_ID)
      );
      window.location.href = data.url;
    } else {
      await WeChatClient.logout();
    }
    authentication.logout();
    store.dispatch('loader/disableLoader');
  }
}

export default new AccountAPIWrapper();
