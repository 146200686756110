<template>
  <div class="account-c">
    <Spinner v-if="loading" />
    <template v-else>
      <p class="account-c__description">
        {{ $t('reset-password.expired.description') }}
      </p>

      <div class="account-c__captcha">
        <component
          :is="captchaComponentName"
          ref="captcha"
          :sitekey="siteKey"
          @verify="onVerify"
        />
      </div>
      <BaseBtn primary @click="sendConfirmationEmail">
        {{ $t('reset-password.expired.button') }}
      </BaseBtn>
    </template>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner';
import RouteName from '@/utils/RouteName';
import ContentAPI from '@/api/ContentAPI';
import BaseBtn from '@/components/BaseBtn';
import Captcha from '@/mixins/Captcha';
import VueRecaptcha from 'vue-recaptcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountConfirmation',
  components: { BaseBtn, Spinner, VueHcaptcha, VueRecaptcha },
  mixins: [Captcha, Tracking],
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.trackPageView();
    const { token, email } = this.$route.query;

    if (token) {
      ContentAPI.registrationConfirmationToken(token)
        .then(() => {
          this.$router.push({ name: RouteName.PREHOME }).then(() => {
            this.flash(this.$t('confirm-account.success'), 'success');
          });
        })
        .catch(() => {
          if (email) {
            this.loading = false;
          } else {
            this.fail();
          }
        });
    } else if (email) {
      this.loading = false;
    } else {
      this.fail();
    }
  },
  methods: {
    sendConfirmationEmail() {
      if (this.hasCaptcha) {
        ContentAPI.sendConfirmationEmail(
          this.$route.query.email.replace(' ', '+'),
          this.captchaHeader
        )
          .then(() => {
            this.$router.push({ name: RouteName.PREHOME }).then(() => {
              this.flash(this.$t('confirm-account.send'), 'success');
            });
          })
          .catch(() => {
            this.flash(this.$t('confirm-account.error.generic'), 'error');
          });
      } else {
        this.flash(this.$t('registration-form.error.recaptcha'), 'error');
      }
    },
    fail() {
      this.$router.push({ name: RouteName.PREHOME }).then(() => {
        this.flash(this.$t('confirm-account.error.invalid'), 'error');
      });
    },
  },
  /**
   * @param {Object} to
   * @param {Object} from
   * @param {function} next
   */
  beforeRouteEnter(to, from, next) {
    const { email, token } = to.query;

    if (email && token) {
      next();
    } else {
      next({ path: RouteName.PREHOME });
    }
  },
};
</script>

<style lang="scss" scoped>
.account-c {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &__captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }
}
</style>
