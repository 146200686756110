enum AutoLoginStorage {
  REDIRECT_AFTER_LOGIN = 'AL_RAL',
  FORCE_LOGIN = 'AL_FL',
  ENABLED = 'AL_ON',
  REALM = 'AL_REALM',
  RWF_TOKEN = 'RWF_ENC',
  EXPIRATION = 'AL_EXPIRATION',
}

export default AutoLoginStorage;
