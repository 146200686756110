export enum ConversationFilters {
  ALL = 'all',
  ONLY_ME = 'me',
}

export enum ConversationSort {
  DATE = 'date',
  POPULARITY = 'popularity',
}

export enum ConversationNodeType {
  STORY = 'Story',
  ARTICLE = 'Magazine article',
}
