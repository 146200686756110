<template>
  <div class="field-file">
    <div class="field-file__input-container">
      <label ref="label" :for="inputId" class="field-file__input-button">
        <VACButton
          type="button"
          class="field-file__inner-button"
          :appearance="isGallery ? 'discrete' : 'primary'"
          :icon="isGallery ? '' : 'arrow-right'"
          @click="propagateClick"
        >
          <slot name="buttonLabel">{{ defaultButtonLabel }}</slot>
        </VACButton>
      </label>

      <input
        :id="inputId"
        ref="input"
        :accept="acceptStr"
        :name="inputId"
        class="field-file__input"
        type="file"
        @change="selectFiles"
      />
    </div>

    <template v-if="showDropZone">
      <span class="field-file__separator">{{ separatorLabel }}</span>

      <div
        :class="{ 'field-file__drop-container--dragging': isDragging }"
        class="field-file__drop-container"
        @drop.prevent="dropFiles"
        @dragover.prevent
        @dragenter.prevent="dragEnter"
        @dragleave.prevent="dragLeave"
      >
        <slot name="dropLabel">{{ defaultDropLabel }}</slot>
      </div>
    </template>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';

export default {
  name: 'FieldFile',
  components: { VACButton },
  props: {
    showDropZone: Boolean,
    accept: {
      type: Array,
      required: true,
    },
    isGallery: Boolean,
  },
  data() {
    return {
      defaultButtonLabel: 'Select a file',
      defaultSeparatorLabel: 'or',
      defaultDropLabel: 'Drop a file',
      dragCounter: 0,
    };
  },
  computed: {
    /**
     * @return {string}
     */
    inputId() {
      return `field-file-input-${Math.random().toString(36).substr(2, 9)}`;
    },
    /**
     * @return {string}
     */
    acceptStr() {
      return this.accept.join(',');
    },
    /**
     * @return {string}
     */
    separatorLabel() {
      return this.$slots.separatorLabel[0].text || this.defaultSeparatorLabel;
    },
    /**
     * @return {boolean}
     */
    isDragging() {
      return this.dragCounter > 0;
    },
  },
  methods: {
    dropFiles({ dataTransfer }) {
      const files = dataTransfer.files;

      if (this.isDropValid(files)) {
        this.change(files);
      } else {
        this.$emit('error');
      }

      this.dragCounter = 0;
    },
    /**
     * @param {FileList} files
     * @return {boolean}
     */
    isDropValid(files) {
      return (
        files.length === 1 &&
        files.length ===
          Array.from(files).filter((file) => {
            return -1 !== this.accept.indexOf(file.type);
          }).length
      );
    },
    dragEnter() {
      ++this.dragCounter;
    },
    dragLeave() {
      --this.dragCounter;
    },
    selectFiles({ target }) {
      const files = target.files;

      if (this.isDropValid(files)) {
        this.change(files);
      } else {
        this.$emit('error');
      }
    },
    /**
     * @param {FileList} files
     */
    change(files) {
      this.$emit('change', files);
    },
    reset() {
      this.$refs.input.value = '';
    },
    propagateClick() {
      this.$refs.label.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.field-file {
  display: flex;
  align-items: center;
  @include breakpoint($breakpoint-l) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: stretch;
  }

  &__input-container {
    position: relative;
    width: 100%;

    @include breakpoint($breakpoint-l) {
      width: auto;
    }

    ::v-deep .vac-button {
      width: 100%;
    }
  }

  &__input {
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  &__input-button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__separator,
  &__drop-container {
    display: none;
  }

  &__separator {
    margin: 0 0.625em;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint($breakpoint-l) {
      display: inline-block;
    }
  }

  &__drop-container {
    flex: 1;
    align-self: stretch;
    align-items: center;
    padding: 0.5rem 1em;
    border: 1px dashed $color-black;
    line-height: 1.2;
    transition-duration: $default-transition-duration;
    transition-property: background-color, border-color;

    @include breakpoint($breakpoint-l) {
      display: flex;
    }

    &--dragging {
      border-color: $color-black;
      background-color: rgba($color-black, 10%);
    }

    &--invalid {
      border-color: $color-danger;
      background-color: rgba($color-danger, 10%);
      cursor: not-allowed;
    }
  }
}
</style>
