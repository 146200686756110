<template>
  <div class="the-header-member-sentinel"></div>
</template>

<script>
export default {
  name: 'TheHeaderMemberSentinel',
};
</script>

<style lang="scss" scoped>
.the-header-member-sentinel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  //background: rgba(red, 0.25);
  //z-index: 1000;

  min-height: $header-member-min-height;
}
</style>
