<template>
  <transition name="fade" mode="out-in">
    <Spinner v-if="loading" class="step__loader" />
    <div v-else class="step">
      <PageLoader v-if="transparentLoading" translucide relative />
      <header :class="headerClasses">
        <strong v-if="!noStep" class="step__title">
          {{
            $t('wizard.header.title', {
              current: currentStep,
              total: totalSteps,
            })
          }}
        </strong>
        <!--eslint-disable vue-i18n/no-raw-text-->
        <template v-if="title">{{ noStep ? title : `: ${title}` }}</template>
        <!--eslint-enable vue-i18n/no-raw-text-->
      </header>
      <div class="step__body">
        <slot></slot>
      </div>
      <footer class="step__footer">
        <slot name="footer"></slot>
      </footer>
      <Portal v-if="$slots.help" :to="PortalName.STEPPER_FOOTER">
        <HelpBox>
          <template #title>
            <slot name="help-title"></slot>
          </template>
          <template #default>
            <slot name="help"></slot>
          </template>
        </HelpBox>
      </Portal>
    </div>
  </transition>
</template>

<script>
import Spinner from '@/components/Spinner';
import HelpBox from '@/components/HelpBox';
import PortalName from '@/utils/PortalName';
import PageLoader from '@/components/PageLoader';

const BLOCK_SELECTOR = 'step';
export default {
  name: 'WizardStep',
  components: { PageLoader, HelpBox, Spinner },
  props: {
    title: { type: [String, undefined], default: undefined },
    currentStep: { type: Number, default: 1 },
    totalSteps: { type: Number, default: 1 },
    prev: { type: [Function, undefined], default: undefined },
    next: { type: [Function, undefined], default: undefined },
    loading: Boolean,
    transparentLoading: Boolean,
    noStep: Boolean,
    boldTitle: Boolean,
  },
  data() {
    return {
      PortalName,
    };
  },
  computed: {
    headerClasses() {
      return [
        `${BLOCK_SELECTOR}__title`,
        {
          [`${BLOCK_SELECTOR}__title--bold`]: this.boldTitle,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 2.5em 1.25em 1.25em;

  &__header {
    text-align: center;
    color: $color-primary;
  }

  &__title {
    font-weight: bold;

    &--bold {
      @extend %auth-modal__title;
    }
  }

  &__loader {
    width: 100%;
    align-items: center;
  }
}
</style>
