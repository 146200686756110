<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal is-active class="set-pseudonym-modal" @close="close">
      <template #container>
        <div class="set-pseudonym-modal__header">
          <BaseBtn class="set-pseudonym-modal__close" discrete @click="close" />
          <div class="set-pseudonym-modal__title">
            {{ $t('conversation.pseudonymModal.title') }}
          </div>
        </div>
        <ProfileForm class="set-pseudonym-modal__body" @submited="close" />
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import BaseBtn from '@/components/BaseBtn';
import ProfileForm from '@/components/account/ProfileForm';

import RouteName from '@/utils/RouteName';
import PortalName from '@/utils/PortalName';

export default {
  name: 'SetPseudonymModal',
  components: { ProfileForm, BaseBtn, BaseModal },
  data() {
    return {
      PortalName,
      RouteName,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.set-pseudonym-modal {
  &__header {
    @extend %base-modal__header;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    font-family: $font-prelo-condensed;
  }

  &__body {
    padding: 1em;
    width: 100%;
    overflow: auto;

    @include breakpoint($breakpoint-desktop) {
      width: 47.5em;
    }
  }
}
</style>
