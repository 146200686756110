/**
 * PLEASE READ
 * -----------------------------------------------------------------------
 * This file is a mixin to handle autologin flow
 * It has been set in a Mixin for better readability
 * It is used on App.vue file and only initAutoLogin method should be use
 * -----------------------------------------------------------------------
 * It hasn't been handled in a middleware for better User Experience
 * and to avoid white pages while handling redirection.
 * The cleanest thing we can do for UX and Mechanics is to handle it on
 * server side, but we're in a Static SPA, so no SSR.
 * -----------------------------------------------------------------------
 * All the actions made here are processed before main component routing.
 * Please handle redirections here and not in dedicated workflow view.
 * -----------------------------------------------------------------------
 */

import storage from '../utils/storage';
import authentication from '../utils/authentication';
import Internationalization from '../utils/Internationalization';
import IsInWeChat from '../utils/IsInWeChat';
import WeChatClient from '../api/WeChatClient';
import RouteName from '../utils/RouteName';
import AuthenticationRealms from '../enums/AuthenticationRealms';
import AutoLoginStorage from '../enums/AutoLoginStorage';

const DISABLED_FOR_ROUTES = [
  RouteName.ACCOUNT_WECHAT_ASSOCIATION,
  RouteName.ACCOUNT_LOGIN_CALLBACK,
  RouteName.RESET_PASSWORD,
  RouteName.ACCOUNT_ASK_LOGIN,
  RouteName.ACCOUNT_CONFIRMATION,
];

const MINUTES_EXPIRATION = 5;

export default {
  computed: {
    shouldRedirectToLogin() {
      return storage.get(AutoLoginStorage.FORCE_LOGIN);
    },
    isInAutoLoginMode() {
      return storage.get(AutoLoginStorage.ENABLED);
    },
    isAuthenticated() {
      return authentication.retrieve.sessionToken;
    },
    shouldEngageAutoLogin() {
      return (
        // User is not connected
        !this.isAuthenticated &&
        // Autologin flow isn't started
        !this.isInAutoLoginMode &&
        // Is not after logout (/ route + state param from hydra)
        !this.$route.query.state &&
        // Handle Specific routes
        !DISABLED_FOR_ROUTES.includes(this.$route.name) &&
        // Handle wechat callback
        !this.$route.query.encryptMsg &&
        !this.$route.query.error
      );
    },
    autoLoginAuthRealm() {
      return storage.get(AutoLoginStorage.REALM);
    },
  },
  methods: {
    /**
     * Init autologin flow
     * @returns {Promise<void>}
     */
    async initAutoLogin() {
      this.checkAutologinExpiration();
      // If we're in autologin mode we're proceed to controls
      if (this.isInAutoLoginMode) {
        this.handleAutoLoginCallback();
      } else {
        // We engage a new auto login flow
        if (this.shouldEngageAutoLogin) {
          // We check if we have china token
          if (storage.get(AutoLoginStorage.RWF_TOKEN)) {
            this.keepLoader = true;
            await this.loginForCN();
          } else {
            await this.redirectToLoginAutologinRoute();
          }
        }
      }
    },
    checkAutologinExpiration() {
      const autologinTime = storage.get(AutoLoginStorage.EXPIRATION);
      if (autologinTime === null) {
        this.disableAutoLoginMode();
        return;
      }
      const autologinTimeTime =
        parseInt(autologinTime) + MINUTES_EXPIRATION * 60000;
      const expirationTime = new Date().getTime();
      if (expirationTime > autologinTimeTime) {
        this.disableAutoLoginMode();
      }
    },
    /**
     * Enable autologin mode and store realm
     * @param realm
     */
    enableAutoLoginMode(realm) {
      storage.set(AutoLoginStorage.ENABLED, true);
      storage.set(AutoLoginStorage.REALM, realm);
      storage.set(AutoLoginStorage.EXPIRATION, new Date().getTime());
    },
    /**
     * Disable autologin mode
     */
    disableAutoLoginMode() {
      storage.remove(AutoLoginStorage.ENABLED);
      storage.remove(AutoLoginStorage.REALM);
      storage.remove(AutoLoginStorage.EXPIRATION);
    },
    /**
     * Handle autologin init flow to enage login
     * @returns {Promise<void>}
     */
    async redirectToLoginAutologinRoute() {
      if (Internationalization.getCountry() === 'cn' && IsInWeChat()) {
        this.keepLoader = true;
        this.enableAutoLoginMode(AuthenticationRealms.CN);
        const { data } = await WeChatClient.getLogin();
        window.location = data.data;
      }
      if (Internationalization.getCountry() !== 'cn') {
        this.keepLoader = true;
        this.enableAutoLoginMode(AuthenticationRealms.WW);
        this.$router.push({ name: RouteName.ACCOUNT_ASK_LOGIN });
      }
    },
    /**
     * Handle post autologin action
     */
    handleAutoLoginCallback() {
      // Autologin for WW
      if (this.autoLoginAuthRealm === AuthenticationRealms.WW) {
        // Autologin has failed, we redirect to prehome page
        if (this.$route.name === RouteName.ACCOUNT_LOGIN_CALLBACK) {
          this.disableAutoLoginMode();
          if (!this.shouldRedirectToLogin) {
            this.$router.push({
              name: RouteName.PREHOME,
              query: { fc: true },
            });
          }
        }
        // Autologin has succeeded, we disable autologin flag
        if (this.$route.name === RouteName.ACCOUNT_PROCESS_LOGIN) {
          this.disableAutoLoginMode();
        }
      }
      /**
       * We do nothing for china callback because everything is handled in
       * RWF API call for user binding (see processWechatBinding method
       * in App.vue for details)
       */
    },
    redirectAfterAutoLogin() {
      if (storage.get(AutoLoginStorage.REDIRECT_AFTER_LOGIN)) {
        this.$router
          .push(storage.get(AutoLoginStorage.REDIRECT_AFTER_LOGIN))
          .then(() => {
            storage.remove(AutoLoginStorage.REDIRECT_AFTER_LOGIN);
            storage.remove(AutoLoginStorage.FORCE_LOGIN);
            this.keepLoader = false;
            this.$store.dispatch('loader/disableLoader');
          });
      } else {
        this.$router
          .push({
            name: RouteName.HOME,
          })
          .then(() => {
            this.keepLoader = false;
            this.$store.dispatch('loader/disableLoader');
          });
      }
    },
    async loginForCN() {
      try {
        const rez = await WeChatClient.autoLogin(
          storage.get(AutoLoginStorage.RWF_TOKEN)
        );
        await authentication.persist({
          country: AuthenticationRealms.CN,
          hasTimepieces: false,
          sessionToken: rez.data.sessionToken,
          tokenId: null,
        });
        await this.$store.dispatch('auth/fetchCurrentUser');
        this.disableAutoLoginMode();
        this.redirectAfterAutoLogin();
      } catch (e) {
        this.$store.dispatch('loader/disableLoader');
        this.$router.push({
          name: RouteName.PREHOME,
        });
        this.keepLoader = false;
        this.disableAutoLoginMode();
      }
    },
  },
};
