<script>
const BLOCK_SELECTOR = 'btn-toolbar';

const concat = (...classList) => classList.filter(Boolean).join(' ');

export default {
  name: 'BtnToolbar',
  props: {
    noWrap: Boolean,
    fullWidth: Boolean,
    fullWidthEqual: Boolean,
    centered: Boolean,
    // centeredUntilXxxs: Boolean,
    // centeredUntilXxs: Boolean,
    // centeredUntilXs: Boolean,
    // centeredUntilS: Boolean,
    // centeredUntilM: Boolean,
    // centeredUntilL: Boolean,
    // centeredUntilXl: Boolean,
    // centeredUntilXxl: Boolean,
    // centeredUntilXxxl: Boolean,
    vertical: Boolean,
    // verticalUntilXxxs: Boolean,
    // verticalUntilXxs: Boolean,
    // verticalUntilXs: Boolean,
    // verticalUntilS: Boolean,
    // verticalUntilM: Boolean,
    // verticalUntilL: Boolean,
    // verticalUntilXl: Boolean,
    // verticalUntilXxl: Boolean,
    // verticalUntilXxxl: Boolean,
  },
  render(h) {
    // Append 'btn-toolbar__item' class
    this.$slots.default.forEach((vnode) => {
      vnode.data = {
        ...vnode.data,
        staticClass: concat(
          vnode.data && vnode.data.staticClass,
          `${BLOCK_SELECTOR}__item`
        ),
      };
    });

    return h(
      'div',
      {
        class: [
          BLOCK_SELECTOR,
          {
            [`${BLOCK_SELECTOR}--no-wrap`]: this.noWrap,
            [`${BLOCK_SELECTOR}--full-width`]: this.fullWidth,
            [`${BLOCK_SELECTOR}--full-width-equal`]: this.fullWidthEqual,
            // Centered
            [`${BLOCK_SELECTOR}--centered`]: this.centered,
            // [`${BLOCK_SELECTOR}--centered-until-xxxs`]: this.centeredUntilXxxs,
            // [`${BLOCK_SELECTOR}--centered-until-xxs`]: this.centeredUntilXxs,
            // [`${BLOCK_SELECTOR}--centered-until-xs`]: this.centeredUntilXs,
            // [`${BLOCK_SELECTOR}--centered-until-s`]: this.centeredUntilS,
            // [`${BLOCK_SELECTOR}--centered-until-m`]: this.centeredUntilM,
            // [`${BLOCK_SELECTOR}--centered-until-l`]: this.centeredUntilL,
            // [`${BLOCK_SELECTOR}--centered-until-xl`]: this.centeredUntilXl,
            // [`${BLOCK_SELECTOR}--centered-until-xxl`]: this.centeredUntilXxl,
            // [`${BLOCK_SELECTOR}--centered-until-xxxl`]: this.centeredUntilXxxl,
            // Vertical
            [`${BLOCK_SELECTOR}--vertical`]: this.vertical,
            // [`${BLOCK_SELECTOR}--vertical-until-xxxs`]: this.verticalUntilXxxs,
            // [`${BLOCK_SELECTOR}--vertical-until-xxs`]: this.verticalUntilXxs,
            // [`${BLOCK_SELECTOR}--vertical-until-xs`]: this.verticalUntilXs,
            // [`${BLOCK_SELECTOR}--vertical-until-s`]: this.verticalUntilS,
            // [`${BLOCK_SELECTOR}--vertical-until-m`]: this.verticalUntilM,
            // [`${BLOCK_SELECTOR}--vertical-until-l`]: this.verticalUntilL,
            // [`${BLOCK_SELECTOR}--vertical-until-xl`]: this.verticalUntilXl,
            // [`${BLOCK_SELECTOR}--vertical-until-xxl`]: this.verticalUntilXxl,
            // [`${BLOCK_SELECTOR}--vertical-until-xxxl`]: this.verticalUntilXxxl,
          },
        ],
      },
      this.$slots.default
    );
  },
};
</script>

<style lang="scss" scoped>
.btn-toolbar {
  $block-selector: &;
  $btn-toolbar-gutter: 1em;
  // Enable breakpoints when you need them
  // (they are commented out to avoid dead code in the bundle)
  $btn-toolbar-vertical-breakpoints: (
    // xxxs: $breakpoint-xxxs,
    // xxs: $breakpoint-xxs,
    //xs: $breakpoint-xs,
    // s: $breakpoint-s,
    //  m: $breakpoint-m,
    // l: $breakpoint-l,
    // xl: $breakpoint-xl,
    // xxl: $breakpoint-xxl,
    // xxxl: $breakpoint-xxxl,
  );
  $btn-toolbar-centered-breakpoints: $btn-toolbar-vertical-breakpoints;

  @function getNotSelector($block, $modifier) {
    $output: $block + ':not(' + $block + '--' + $modifier + ')';

    @each $size, $bp in $btn-toolbar-vertical-breakpoints {
      $output: $output +
        ':not(' +
        $block +
        '--' +
        $modifier +
        '-until-' +
        $size +
        ')';
    }

    @return $output;
  }

  margin-top: -$btn-toolbar-gutter;
  margin-left: -$btn-toolbar-gutter;
  margin-bottom: 1em;
  display: flex;
  flex-flow: column wrap;

  &--centered {
    justify-content: center;
  }

  // Generate selectors for each breakpoint
  @each $size, $bp in $btn-toolbar-centered-breakpoints {
    &--centered-until-#{$size} {
      justify-content: center;

      @include breakpoint($bp) {
        justify-content: unset;
      }
    }
  }

  &--vertical {
    flex-direction: column;

    @at-root #{selector-unify(&, $block-selector + '--centered')} {
      align-items: center;
    }
  }

  @at-root #{getNotSelector($block-selector, 'vertical')} {
    flex-direction: unset;
  }

  // Generate selectors for each breakpoint
  @each $size, $bp in $btn-toolbar-vertical-breakpoints {
    @include breakpoint($bp) {
      &--vertical-until-#{$size} {
        flex-direction: unset;
      }
    }
  }

  &--no-wrap {
    flex-wrap: unset;
  }

  &--full-width,
  &--full-width-equal {
    // Nothing to do here, just leaving empty selectors so class are available
    // in autocomplete suggestions
  }

  &__item {
    margin-top: $btn-toolbar-gutter;
    margin-left: $btn-toolbar-gutter;

    #{$block-selector}--full-width & {
      flex: auto;
    }

    #{$block-selector}--full-width-equal & {
      flex: 1;
      min-width: 0;
    }
  }
}
</style>
