import { render, staticRenderFns } from "./OnboardingQuestion.vue?vue&type=template&id=726532a3&scoped=true&"
import script from "./OnboardingQuestion.vue?vue&type=script&lang=js&"
export * from "./OnboardingQuestion.vue?vue&type=script&lang=js&"
import style0 from "./OnboardingQuestion.vue?vue&type=style&index=0&id=726532a3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "726532a3",
  null
  
)

export default component.exports