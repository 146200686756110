import RouteName from '@/utils/RouteName';
import BusName from '@/utils/BusName';
import { pushToDataLayer } from '@/utils/GTMMaker';

export default {
  data() {
    return {
      passwordForgotRouteName: null,
    };
  },
  methods: {
    login() {
      this.errorMessage = null;

      if (this.$refs.form.validate()) {
        if (this.hasCaptcha) {
          this.$emit('submit');

          this.callApi()
            .then(() => {
              pushToDataLayer({
                event: 'login',
              });
              this.redirectAfterLogin();
              this.close();
            })
            .catch(() => {
              this.$emit('error');
              this.errorMessage = this.$t('auth.error');
              this.resetCaptcha();
            });
        } else {
          this.errorMessage = this.$t('cms.contact.form.error.recaptcha');
        }
      }
    },
    /**
     * @return {Promise<void>}
     */
    callApi() {
      throw new Error('Method `callApi` must be overridden by component');
    },
    /**
     * @return {Promise<Route>}
     */
    redirectAfterLogin() {
      const redirect = new URL(location).searchParams.get('redirect');

      if (null !== redirect) {
        return this.$router.push(redirect);
      }

      return this.$router.push({
        name: RouteName.HOME,
      });
    },
    close() {
      this.$emit('close');
    },
    forgotPassword() {
      const name = this.passwordForgotRouteName;

      if (name === this.$router.currentRoute.name) {
        this.$root.$emit(BusName.RESET_FORGOTTEN_PASSWORD_STEPPER);
      } else {
        this.$router.push({ name });
      }

      this.close();
    },
  },
};
