<template>
  <component :is="tag" class="page-title">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    tag: {
      type: String,
      default: 'h1',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  font-family: $font-prelo-condensed;
  font-size: 1.875rem;
  margin-bottom: 0.125rem;

  @include breakpoint($breakpoint-tablet) {
    font-size: 2.5rem;
  }
}
</style>
