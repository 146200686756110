<template>
  <Portal :to="PortalName.MAIN_TITLE">
    <PageTitle>
      <slot></slot>
    </PageTitle>
  </Portal>
</template>

<script>
import PageTitle from '@/components/page/PageTitle';
import PortalName from '@/utils/PortalName';

export default {
  name: 'MainTitle',
  components: { PageTitle },
  data() {
    return {
      PortalName,
    };
  },
};
</script>

<style lang="scss" scoped></style>
