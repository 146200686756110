import axios from 'axios';
import Internationalization from '@/utils/Internationalization';

export async function checkForWatchInCCP(customerNumber: string) {
  const domain = Internationalization.isCnCountry()
    ? process.env.VUE_APP_API_CCP_CONTROL_CN_URL
    : process.env.VUE_APP_API_CCP_CONTROL_URL;
  if (domain) {
    let hasWatch: boolean;
    try {
      await axios.get(
        `${domain}api/customer/customer/${customerNumber}/portfolio`
      );
      hasWatch = true;
    } catch (e) {
      hasWatch = false;
    }
    return hasWatch;
  }
}
