import { render, staticRenderFns } from "./GalleryThumbs.vue?vue&type=template&id=0dabe8ce&scoped=true&"
import script from "./GalleryThumbs.vue?vue&type=script&lang=js&"
export * from "./GalleryThumbs.vue?vue&type=script&lang=js&"
import style0 from "./GalleryThumbs.vue?vue&type=style&index=0&id=0dabe8ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dabe8ce",
  null
  
)

export default component.exports