var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"myDiv",class:_vm.blockClasses,on:{"mouseenter":_vm.startVideoPlay,"mouseleave":_vm.stopVideoPlay}},[(_vm.showAuthoringOptions)?_c('div',{staticClass:"golden-book-card__menu"},[_c('VMenu',{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('BaseBtn',_vm._g(_vm._b({staticClass:"golden-book-card__dropdown-button",attrs:{"discrete":""}},'BaseBtn',attrs,false),on))]}}],null,false,3613368854)},[_c('VList',[_c('VListItem',[_c('VListItemTitle',[_c('RouterLink',{attrs:{"to":{
                name: _vm.RouteName.GOLDEN_BOOK_EDIT_STORY,
                params: { storyId: _vm.id },
              }}},[_c('BaseBtn',{staticClass:"golden-book-card__dropdown-item",attrs:{"discrete":""}},[_vm._v(" "+_vm._s(_vm.$t('accountPosts.edit'))+" ")])],1)],1)],1),_c('VListItem',[_c('VListItemTitle',[_c('BaseBtn',{staticClass:"golden-book-card__dropdown-item golden-book-card__dropdown-item--danger",attrs:{"discrete":""},on:{"click":function($event){_vm.showDeleteModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('accountPosts.delete'))+" ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.showAuthoringOptions && _vm.showDeleteModal)?_c('DeleteGoldenBookPostModal',{on:{"cancel":function($event){_vm.showDeleteModal = false},"delete":_vm.deleteStory}}):_vm._e(),(_vm.id)?_c('RouterLink',{staticClass:"golden-book-card__link",attrs:{"to":{
      name: _vm.RouteName.GOLDEN_BOOK_SINGLE_STORY,
      params: { storyId: _vm.id },
    }}}):_vm._e(),_c('div',{class:_vm.backgroundClasses},[(!_vm.withVideo)?_c('img',{ref:"background",staticClass:"golden-book-card__image",attrs:{"src":_vm.definedBackgroundImage,"alt":"card image"}}):_c('video',{ref:"videoPlayer",staticClass:"golden-book-card__image golden-book-card__image--video",attrs:{"src":_vm.video,"poster":_vm.definedBackgroundImage,"muted":"","loop":""},domProps:{"muted":true}})]),_c('div',{staticClass:"golden-book-card__overlay"}),_c('div',{staticClass:"golden-book-card__tags"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.withStory),expression:"withStory"}],staticClass:"golden-book-card__story"},[_vm._v(" "+_vm._s(_vm.$t('goldenBook.withStory'))+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.withVideo),expression:"withVideo"}],staticClass:"golden-book-card__story"},[_vm._v(" "+_vm._s(_vm.$t('goldenBook.withVideo'))+" ")])]),_c('p',{staticClass:"golden-book-card__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"golden-book-card__footer"},[(_vm.authorId)?_c('RouterLink',{staticClass:"golden-book-card__author",attrs:{"to":{
        name: _vm.RouteName.USER_PROFILE,
        params: {
          profileId: _vm.authorId,
        },
      }},nativeOn:{"click":function($event){return _vm.trackUserProfilClick(_vm.TrackingAreas.CONTENT)}}},[_vm._v(" "+_vm._s(_vm.author)+" ")]):_c('p',{staticClass:"golden-book-card__author"},[_vm._v(" "+_vm._s(_vm.author)+" ")]),(_vm.withCounters)?_c('GoldenBookStorySocial',{attrs:{"comment-counter":_vm.commentCounter,"light":_vm.lightSocial,"like-counter":_vm.likeCount,"i-like":_vm.iLikeStatus},on:{"like":_vm.likeStory,"comment":_vm.redirectToComment}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }