import store from '../store';
import { FlashActions, prefixType } from '@/store/modules/flash';

export function flash(message, type = 'info') {
  store.dispatch(prefixType(FlashActions.addFlash), {
    type,
    message,
  });
}

export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {
    Vue.mixin({
      name: 'FlashPlugin',
      methods: {
        flash(message, type = 'info') {
          flash(message, type);
        },
      },
    });
  },
};
