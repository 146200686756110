import Closable from './Closable';
import ModalState from './ModalState';

export default {
  mixins: [Closable, ModalState],
  props: {
    closeLabel: {
      type: String,
      default: 'close',
    },
    title: {
      type: String,
      default: null,
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
  },
};
