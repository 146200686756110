<template>
  <div class="field-phone-number">
    <VAutocomplete
      ref="prefix"
      v-model="selectedPrefix"
      :disabled="disabled || prefixDisabled"
      :items="countries"
      :label="phoneLabel"
      :required="required"
      autocomplete="tel-country-code"
      class="field-phone-number__prefix"
      item-text="phone"
      item-value="phone"
      @input="updateData"
    />
    <div :class="separatorClass"></div>
    <VTextField
      ref="number"
      v-model="number"
      :disabled="disabled"
      :required="required"
      :rules="rules"
      autocomplete="tel-national"
      class="field-phone-number__number"
      type="tel"
      @input="updateData"
    />
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'field-phone-number';

export default {
  name: 'FieldPhoneNumber',
  props: {
    value: { type: Object, default: undefined },
    disabled: Boolean,
    label: { type: String, default: undefined },
    prefixDisabled: Boolean,
    required: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countries: [],
      selectedPrefix: '',
      number: '',
    };
  },
  computed: {
    phoneLabel() {
      return this.label == null || this.label === ''
        ? this.$t('events.register.phone')
        : this.label;
    },
    formattedPhoneNumber() {
      let phone = this.number;

      if (phone[0] === '0') {
        phone = phone.slice(1);
      }

      return phone;
    },
    hasPrefix() {
      return this.value && this.value[0] === '+';
    },
    separatorClass() {
      return [
        `${BLOCK_SELECTOR}__separator`,
        {
          [`${BLOCK_SELECTOR}__separator--disabled`]: this.disabled,
        },
      ];
    },
  },
  watch: {
    value() {
      this.$nextTick(() => {
        if (this.value.prefix != null) {
          this.selectedPrefix = this.value.prefix;
        }
        if (this.value.number != null) {
          this.number = this.value.number;
        }
      });
    },
  },
  mounted() {
    this.countries = this.$store.state['countries'].countryList.map(
      (country) => this.$store.state['countries'].countryMap[country].phone
    );

    this.$nextTick(() => {
      if (this.value) {
        if (this.value.prefix !== null) {
          this.selectedPrefix = this.value.prefix;
        }
        if (this.value.number !== null) {
          this.number = this.value.number;
        }
      }
    });
  },
  methods: {
    updateData() {
      this.$emit('input', {
        prefix: this.selectedPrefix,
        number: this.formattedPhoneNumber,
      });
    },
    formatNumber(number) {
      return number ? (number[0] === '0' ? number : `0${number}`) : '';
    },
  },
};
</script>

<style lang="scss" scoped>
$phone-field-separator-width: 1px;
.field-phone-number {
  display: flex;
  flex-direction: row;

  &__prefix {
    max-width: 6.25em;

    ::v-deep &.v-autocomplete.v-select.v-input--is-focused input {
      min-width: 0;
    }
  }

  &__separator {
    align-self: flex-end;
    height: 1.6em;
    width: $phone-field-separator-width;
    margin-top: 1.125em;
    margin-bottom: auto;
    background: $color-shuttle-gray;

    &--disabled {
      background: rgba(0, 0, 0, 0.38);
    }
  }

  &__number {
    margin-left: -$phone-field-separator-width;

    ::v-deep .v-input__slot {
      padding: 0 0.5em;
    }
  }
}
</style>
