<template>
  <div class="conversation-card">
    <SetPseudonymModal v-if="showPseudonymDialog" @close="pseudonymCallback" />
    <div class="conversation-card__body">
      <div
        class="conversation-card__container conversation-card__container--header"
      >
        <p class="conversation-card__infos">
          <RouterLink
            :to="{
              name: RouteName.USER_PROFILE,
              params: { profileId: conversation.author.ID },
            }"
            class="conversation-card__infos"
            @click.native="trackUserProfilClick(TrackingAreas.COMMENT)"
          >
            {{ authorName }}
          </RouterLink>
          <span class="conversation-card__date">
            {{ conversationDate }}
          </span>
        </p>
        <div v-if="canDoAction" class="conversation-card__actions">
          <VACButton
            appearance="discrete"
            icon=""
            class="conversation-card__action conversation-card__action--edit"
            @click="enableEditMode"
          />
          <VACButton
            appearance="discrete"
            icon=""
            class="conversation-card__action conversation-card__action--delete"
            @click="showDeleteModal = true"
          />
        </div>
      </div>
      <p
        v-if="!editMode"
        class="conversation-card__container conversation-card__container--text"
      >
        {{ conversation.texte }}
      </p>
      <VForm
        v-else
        ref="editForm"
        class="conversation-card__bottom-container conversation-card__bottom-container--edit"
        @submit.prevent="editComment"
      >
        <FieldTextWithEmoji
          v-model="conversationText"
          :disabled="isSubmitting"
          text-area
          name="comment"
          :rules="messageRules"
          :counter="300"
          @extended="$emit('extended')"
        />
        <Spinner v-if="isSubmitting" small class="conversation-card__spinner" />
        <VACButton
          v-else
          type="submit"
          :disabled="isSubmitting"
          class="conversation-card__edit-btn"
          appearance="primary"
          icon=""
          :label="$t('conversation.saveEdits')"
        />
        <VACButton
          :disabled="isSubmitting"
          discrete
          block
          class="conversation-card__edit-btn conversation-card__edit-btn--discard"
          @click="disableEditMode"
        >
          {{ $t('conversation.discardChanges') }}
        </VACButton>
      </VForm>
    </div>
    <PerfectScrollbar v-if="replies.length > 0" :class="repliesClasses">
      <ConversationReply
        v-for="reply in replies"
        :key="reply.ID"
        :reply="reply"
        :parent-id="conversation.ID"
        class="conversation-card__reply"
        @delete="handleDeleteReply"
        @edited="handleEditReply"
        @extended="$emit('extended')"
      />
    </PerfectScrollbar>
    <VACButton
      v-if="repliesShouldBeLimited"
      class="conversation-card__more"
      appearance="link"
      icon=""
      @click="showAllReplies"
    >
      {{ $tc('conversation.showReplies', moreReplyCount) }}
    </VACButton>
    <div class="conversation-card__bottom-container">
      <VACButton
        v-if="!replyMode"
        appearance="link"
        icon=""
        class="conversation-card__reply-btn"
        @click="enableReplyMode"
      >
        {{ $t('conversation.reply') }}
      </VACButton>
      <template v-else>
        <VForm
          ref="replyForm"
          class="conversation-card__reply-container"
          @submit.prevent="sendReply"
        >
          <FieldTextWithEmoji
            v-model="newReply"
            text-area
            name="comment"
            :counter="300"
            :rules="messageRules"
            :disabled="isSubmitting"
            class="conversation-card__reply-field"
            @extended="$emit('extended')"
          />
          <Spinner
            v-if="isSubmitting"
            small
            class="conversation-card__spinner"
          />
          <VACButton
            v-else
            type="submit"
            :disabled="isSubmitting"
            class="conversation-card__edit-btn"
            appearance="secondary"
            :label="$t('conversation.send')"
          />
        </VForm>
      </template>
    </div>
    <DeleteConversationModal
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="deleteConversation"
    />
  </div>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';

import { VACButton } from '@webqam-vac-ui/lib';
import Spinner from '@/components/Spinner';
import FieldTextWithEmoji from '@/components/form/FieldTextWithEmoji';

import { timestampToRelativeDate } from '@/utils/DateUtils';
import ConversationReply from '@/components/conversation/ConversationReply';
import MasonryConversationGrid from '@/mixins/MasonryConversationGrid';
import DeleteConversationModal from '@/components/conversation/DeleteConversationModal';
import SetPseudonymModal from '@/components/conversation/SetPseudonymModal';
import Auth from '@/mixins/Auth';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

const BLOCK_SELECTOR = 'conversation-card';
const REPLY_LIMIT = 2;

const ACTION_REPLY = 'reply';

export default {
  name: 'ConversationsCard',
  components: {
    DeleteConversationModal,
    ConversationReply,
    FieldTextWithEmoji,
    Spinner,
    SetPseudonymModal,
    VACButton,
  },
  mixins: [MasonryConversationGrid, Auth, Tracking],
  props: {
    conversation: { type: Object, required: true },
  },
  data() {
    return {
      RouteName,
      editMode: false,
      replyMode: false,
      conversationText: this.conversation.texte,
      newReply: '',
      messageRules: [
        (v) => !!v || this.$t('conversation.message.required'),
        (v) =>
          (v && v.length <= 300) || this.$t('conversation.message.maxLength'),
      ],
      isSubmitting: false,
      limitReplies: true,
      showDeleteModal: false,
      showPseudonymDialog: false,
      requiredAction: undefined,
    };
  },
  computed: {
    moreReplyCount() {
      if (this.conversation.replies.length > REPLY_LIMIT) {
        return this.conversation.replies.length - REPLY_LIMIT;
      }
      return 0;
    },
    repliesShouldBeLimited() {
      return (
        this.limitReplies && this.conversation.replies.length > REPLY_LIMIT
      );
    },
    replies() {
      if (this.repliesShouldBeLimited) {
        return this.conversation.replies.slice(0, REPLY_LIMIT);
      }
      return this.conversation.replies;
    },
    conversationDate() {
      return timestampToRelativeDate(this.conversation.date);
    },
    canDoAction() {
      return (
        this.conversation.author.ID === this.$store.state.auth.userInfos.userId
      );
    },
    repliesClasses() {
      return [
        `${BLOCK_SELECTOR}__replies`,
        {
          [`${BLOCK_SELECTOR}__replies--unlimited`]: !this.limitReplies,
        },
      ];
    },
    authorName() {
      if (this.conversation.author.pseudo === 'Me') {
        return this.$t('conversation.me');
      }
      return this.conversation.author.pseudo;
    },
  },
  methods: {
    convertReplyDate(date) {
      return timestampToRelativeDate(date);
    },
    async deleteConversation() {
      this.showDeleteModal = false;
      this.$emit('deleted', this.conversation.ID);
      await ContentAPI.deleteConversation(this.conversation.ID);
    },
    enableReplyMode() {
      if (this.userHasSetPseudonym) {
        this.replyMode = true;
        this.requestMasonryRedraw();
      } else {
        this.requiredAction = ACTION_REPLY;
        this.showPseudonymDialog = true;
      }
    },
    enableEditMode() {
      this.conversationText = this.conversation.texte;
      this.editMode = true;
      this.requestMasonryRedraw();
    },
    disableEditMode() {
      this.editMode = false;
      this.requestMasonryRedraw();
    },
    async sendReply() {
      if (this.$refs.replyForm.validate() && this.userHasSetPseudonym) {
        this.isSubmitting = true;
        try {
          const result = await ContentAPI.replyToConversation(
            this.conversation.ID,
            this.newReply
          );
          // eslint-disable-next-line vue/no-mutating-props
          this.conversation.replies.push(result.data);
          if (this.conversation.replies.length > REPLY_LIMIT) {
            this.limitReplies = false;
          }
          this.$emit('newReply');
        } catch (e) {
          throw new Error('Error while sending message');
        }
        this.$refs.replyForm.reset();
        this.replyMode = false;
        this.isSubmitting = false;
      }
    },
    async editComment() {
      if (this.$refs.editForm.validate()) {
        this.isSubmitting = true;
        await ContentAPI.editConversation(
          this.conversation.ID,
          this.conversationText
        );
        this.isSubmitting = false;
        this.editMode = false;
        this.$emit('edited', {
          id: this.conversation.ID,
          text: this.conversationText,
        });
        this.$refs.editForm.reset();
      }
    },
    handleDeleteReply(id) {
      this.$emit('deleteReply', {
        conversationId: this.conversation.ID,
        replyId: id,
      });
    },
    handleEditReply(payload) {
      this.$emit('editReply', {
        conversationId: this.conversation.ID,
        text: payload.text,
        replyId: payload.id,
      });
    },
    showAllReplies() {
      this.limitReplies = false;
      this.requestMasonryRedraw();
    },
    pseudonymCallback() {
      this.showPseudonymDialog = false;
      if (this.requiredAction === ACTION_REPLY && this.userHasSetPseudonym) {
        this.enableReplyMode();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.conversation-card {
  $block-selector: &;
  background-color: $color-wild-sand;

  &__body {
    &:hover {
      #{$block-selector}__actions {
        display: block;
      }
    }
  }

  &__container {
    padding: 1.875em 1.875em 0;
    position: relative;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--text {
      display: block;
      padding-top: 0;
    }

    &--replies {
      padding-top: 0;
      padding-bottom: 0;
      /*max-height: 600px;
      overflow-y: auto;*/
    }
  }

  &__actions {
    display: none;
  }

  &__infos {
    @extend %conversation-card__infos;
  }

  &__date {
    @extend %conversation-card__date;
  }

  &__bottom-container {
    width: 100%;
    min-height: 4.188em;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-wild-sand;
    border-top: 1px solid $color-alto;

    &--edit {
      padding: 0.5em 1.875em;
      margin-top: 1em;
      margin-bottom: 1em;
      display: block;
    }
  }

  &__reply-container {
    width: 100%;
    padding: 1.875em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__action {
    &::before {
      @extend %icon;
    }

    &--edit {
      margin-right: 0.5em;

      &::before {
        @extend %icon--pen;
      }
    }

    &--delete::before {
      @extend %icon--bin;
    }
  }

  &__edit-btn {
    margin-top: 1em;

    @include breakpoint($breakpoint-tablet) {
      width: 14.375em;
      margin-left: auto;
      margin-right: auto;
    }

    &--discard {
      color: $color-crown-of-thorns;
    }
  }

  &__spinner {
    height: 1em;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ::v-deep {
      .spinner__icon {
        font-size: 1.5em;
      }
    }
  }

  &__more {
    margin-left: 1.875em;
    border-left: 1px solid $color-beaver;
    padding-left: 1em;
    margin-bottom: 1em;
    font-weight: bold;
  }

  &__replies {
    &--unlimited {
      max-height: 31.25em;
    }

    ::v-deep {
      .ps__thumb-y {
        background-color: $color-silver;
        border-radius: 0;
        width: 2px;
        right: 0;
      }
    }
  }

  &__reply {
    margin-left: 1.875em;
    margin-right: 1.875em;
  }

  &__reply-field {
    width: 100%;
  }
}
</style>
