import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';
import WeChatClient from '@/api/WeChatClient';
import {
  morphHydraDataToLegacyFormat,
  morphWeChatDataToLegacyFormat,
} from '@/utils/SSOHelpers';
import { checkForWatchInCCP } from '@/api/CCPApi';
import storage from '@/utils/storage';
import { AUTH_SESSION_COUNTRY } from '@/utils/authentication';
import AuthenticationRealms from '@/enums/AuthenticationRealms';
import HydraClient from '@/api/HydraClient';

export const NAMESPACE = 'auth';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum AuthMutation {
  setCurrentSession = 'setCurrentSession',
  setUserInfos = 'setUserInfos',
}

export enum AuthActions {
  fetchCurrentUser = 'fetchCurrentUser',
  cleanSession = 'cleanSession',
}

const module = {
  namespaced: true,
  state() {
    return {
      currentSession: null,
      userInfos: null,
    };
  },
  mutations: {
    [AuthMutation.setCurrentSession](state, value) {
      state.currentSession = value;
    },
    [AuthMutation.setUserInfos](state, value) {
      state.userInfos = value;
    },
  },
  actions: {
    async [AuthActions.fetchCurrentUser]({ commit }) {
      if (storage.get(AUTH_SESSION_COUNTRY) === AuthenticationRealms.CN) {
        const { data } = await WeChatClient.getCurrentUserInfos();
        const formattedData = morphWeChatDataToLegacyFormat(
          {
            ...data,
            hasWatch: await checkForWatchInCCP(data.uuid),
          },
          ''
        );
        commit(AuthMutation.setUserInfos, formattedData);
      } else {
        const response = await HydraClient.getCurrentUserInfos();
        const formattedData = morphHydraDataToLegacyFormat(
          {
            ...response.data,
            hasWatch: await checkForWatchInCCP(response.data.id),
          },
          ''
        );
        commit(AuthMutation.setUserInfos, formattedData);
      }
    },
    [AuthActions.cleanSession]({ commit }) {
      commit(AuthMutation.setUserInfos, null);
      commit(AuthMutation.setCurrentSession, null);
    },
  },
};

export default module;
