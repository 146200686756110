<template>
  <VACExpandableSection
    :class="blockClasses"
    is-outlined
    toggler-class="rcms_dropdownFAQ"
    :toggler-attr="{
      'data-tracking-questions': item.category,
    }"
  >
    <template #title>
      <div class="faq-item__title">{{ item.category }}</div>
    </template>
    <template #body>
      <ul class="faq-item__list">
        <li
          v-for="(question, index) in questions"
          :key="`faq-question-${index}`"
          class="faq-item__item"
        >
          <FaqQuestion :question="question" />
        </li>
      </ul>
    </template>
  </VACExpandableSection>
</template>

<script>
import { VACExpandableSection } from '@webqam-vac-ui/lib';
import FaqQuestion from '@/components/faq/FaqQuestion';
const BASE_CLASS = 'faq-item';
export default {
  name: 'FaqItem',
  components: { FaqQuestion, VACExpandableSection },
  props: {
    item: { type: Object, required: true },
    isFirst: { type: Boolean },
  },
  computed: {
    questions() {
      return this.item && this.item.questions && this.item.questions.nodes;
    },
    blockClasses() {
      return [
        BASE_CLASS,
        {
          [`${BASE_CLASS}--first`]: this.isFirst,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-item {
  margin-bottom: 1px;

  &--first {
    border-top: 1px solid $color-alto;
  }

  &__list {
    padding: 0;
  }

  &__item {
    display: block;
    border-bottom: 1px solid $color-shuttle-gray;
    padding: 1.25em 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
</style>
