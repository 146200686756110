<template>
  <div class="grid-view-template">
    <h3 class="grid-view-template__title">
      {{ title }}
    </h3>
    <div class="grid-view-template__filter">
      <slot name="filters"> </slot>
    </div>
    <BaseGrid class="grid-view-template__content">
      <slot></slot>
    </BaseGrid>
  </div>
</template>

<script>
import BaseGrid from '@/components/grid/BaseGrid';
export default {
  name: 'GridViewTemplate',
  components: { BaseGrid },
  props: {
    title: { type: String, default: undefined },
  },
};
</script>

<style scoped lang="scss">
.grid-view-template {
  @extend %base-container;
  text-align: center;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 2.5rem;
    font-weight: normal;
  }

  &__content {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
</style>
