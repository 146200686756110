<template>
  <portal :to="PortalName.APP_FOOTER" :disabled="!isFullscreen">
    <BaseBtn
      v-if="isFullscreen"
      discrete
      class="block-gallery__exit"
      @click="closeFullscreen"
    />
    <div :class="blockClasses">
      <Swiper
        ref="swiperGallery"
        :options="swiperOptionsGallery"
        :class="galleryClasses"
        @imagesReady="emitReadyEvent"
        @slideChange="updateCurrentIndex"
        @click-slide="emitFullscreenRequest"
      >
        <template #default>
          <SwiperSlide v-if="video && !isFullscreen">
            <div @click.stop="playVideo">
              <VideoIcon />
              <img
                class="block-gallery__gallery-image"
                :src="images[0].x1"
                alt=""
              />
              <Portal to="portal-container--modal">
                <transition name="fade">
                  <VideoPlayer
                    v-if="isVideoPlaying"
                    :video="video"
                    @close="closeVideo"
                  />
                </transition>
              </Portal>

              <ArticleBadge
                v-if="!isFullscreen && category"
                class="block-gallery__badge"
                :type="category"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide
            v-for="(image, index) in images"
            :key="`magazine-gallery-image-${index}`"
          >
            <img class="block-gallery__gallery-image" :src="image.x1" alt="" />
            <ArticleBadge
              v-if="!isFullscreen && category"
              class="block-gallery__badge"
              :type="category"
            />
          </SwiperSlide>
        </template>
        <template #pagination>
          <div class="block-gallery__pagination"></div>
        </template>
        <template v-if="arrows" #button-prev>
          <button
            :class="[
              'block-gallery__arrow',
              'block-gallery__arrow--prev',
              { [`block-gallery__arrow--out`]: outWidth },
            ]"
          ></button>
        </template>
        <template v-if="arrows" #button-next>
          <button
            :class="[
              'block-gallery__arrow',
              'block-gallery__arrow--next',
              { [`block-gallery__arrow--out`]: outWidth },
            ]"
          ></button>
        </template>
      </Swiper>
      <GalleryThumbs
        v-show="isFullscreen"
        :images="images"
        :active-item="currentIndex"
        :video="video"
        @click="handleThumbsClick"
      />
    </div>
  </portal>
</template>

<script>
import PortalName from '@/utils/PortalName';
import BaseBtn from '@/components/BaseBtn';
import GalleryThumbs from '@/components/gallery/GalleryThumbs';
import ArticleBadge from '@/components/badge/ArticleBadge';
import VideoIcon from '@/components/VideoIcon';
import VideoPlayer from '@/components/VideoPlayer';

const BLOCK_SELECTOR = 'block-gallery';

export default {
  name: 'BlockGallery',
  components: { VideoIcon, ArticleBadge, GalleryThumbs, BaseBtn, VideoPlayer },
  props: {
    images: { type: Array, default: undefined },
    isFullscreen: Boolean,
    category: { type: String, default: undefined },
    video: { type: String, default: undefined },
    outWidth: Boolean,
    loop: Boolean,
    primary: Boolean,
    arrows: Boolean,
    imagePerSlide: { type: [Number, String], default: 1 },
  },
  data() {
    return {
      currentIndex: 0,
      PortalName,
      isVideoPlaying: false,
    };
  },
  computed: {
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--fullscreen`]: this.isFullscreen,
          [`${BLOCK_SELECTOR}--primary`]: this.primary,
        },
      ];
    },
    galleryClasses() {
      return [`${BLOCK_SELECTOR}__gallery`];
    },
    swiperOptionsGallery() {
      const swiperOptions = {};
      swiperOptions.slidesPerView = this.imagePerSlide;
      swiperOptions.spaceBetween =
        this.imagePerSlide === 1 ? (this.outWidth ? 0 : 500) : 20;
      swiperOptions.loop = this.loop;
      if (!this.outWidth) {
        swiperOptions.autoplay = {
          delay: 3000,
        };
      }

      if (this.arrows) {
        swiperOptions.navigation = {
          prevEl: `.${BLOCK_SELECTOR}__arrow--prev`,
          nextEl: `.${BLOCK_SELECTOR}__arrow--next`,
        };
      }

      if (!this.isFullscreen) {
        swiperOptions.pagination = {
          el: `.${BLOCK_SELECTOR}__pagination`,
          modifierClass: `${BLOCK_SELECTOR}__pagination-`,
          bulletClass: `${BLOCK_SELECTOR}__pagination-bullet`,
          bulletActiveClass: `${BLOCK_SELECTOR}__pagination-bullet--active`,
          bulletElement: 'button',
          clickable: true,
        };
      }

      return swiperOptions;
    },
  },
  watch: {
    images() {
      this.$refs.swiperGallery.$swiper.update();
    },
  },
  methods: {
    emitFullscreenRequest() {
      this.$emit('updateFullscreen', true);
      this.resetSlide();
    },
    closeFullscreen() {
      this.$emit('updateFullscreen', false);
      this.resetSlide();
    },
    updateCurrentIndex() {
      this.setCurrentIndex(
        this.$refs.swiperGallery.$swiper.activeIndex - (this.loop ? 1 : 0)
      );
    },
    /**
     * @param {number} index
     * @return {number}
     */
    setCurrentIndex(index) {
      const slidesCount = this.images.length;
      this.currentIndex = (index + slidesCount) % slidesCount;

      return this.currentIndex;
    },
    /**
     * @param {number} index
     */
    handleThumbsClick(index) {
      const currentIndex = this.setCurrentIndex(index);

      this.slideTo(currentIndex);
    },
    /**
     * @param {number} index
     */
    slideTo(index) {
      this.$refs.swiperGallery.$swiper.slideTo(index + (this.loop ? 1 : 0));
    },
    resetSlide() {
      this.setCurrentIndex(0);
    },
    emitReadyEvent() {
      this.$emit('loaded');
    },
    playVideo() {
      this.isVideoPlaying = true;
    },
    closeVideo() {
      this.isVideoPlaying = false;
    },
  },
};
</script>

<style lang="scss">
.block-gallery {
  $block-selector: &;

  &__pagination {
    position: absolute;
    display: flex;
    justify-content: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__pagination-bullet {
    display: flex;
    align-items: center;
    width: 1em;
    height: 1.5em;
    margin-left: 0.2em;
    margin-right: 0.2em;
    opacity: 0.4;

    &::before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background-color: $color-white;
    }

    #{$block-selector}--primary & {
      &::before {
        background-color: $color-mine-shaft;
        opacity: 0.16;
      }
    }

    &--active {
      opacity: 1;

      #{$block-selector}--primary & {
        &::before {
          background-color: $color-default;
          opacity: 1;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.block-gallery {
  $block-selector: &;
  cursor: pointer;

  &__slide {
    position: relative;
  }

  &__badge {
    position: absolute;
    bottom: 1em;
    left: 0;
  }

  &__gallery {
    .swiper-slide {
      text-align: center;
    }
  }

  ::v-deep .swiper-wrapper {
    align-items: center;
  }

  &--fullscreen {
    cursor: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-bombay;
    background-image: url('../../assets/gallery-background.svg');
    background-position: top right;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 10;

    #{$block-selector}__gallery {
      @extend %base-container;
    }
  }

  &__arrow {
    $size: 2.5em;

    position: absolute;
    z-index: 2;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $size;
    width: $size;
    transform: translateY(-50%);
    transition: opacity $default-transition-duration;

    &::before {
      @extend %icon;
    }

    &--prev {
      left: 0;

      &::before {
        @extend %icon--left;
      }
    }

    &--next {
      right: 0;

      &::before {
        @extend %icon--right;
      }
    }

    &--out {
      color: white;
      background-color: black;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &__exit {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 11;
    overflow: visible;

    &::before {
      color: $color-black;
      font-size: 2.75em;
      @extend %icon;
      @extend %icon--arrow-left;
      transition: transform $default-transition-duration;
    }

    &:hover {
      &::before {
        transform: translateX(-0.2em);
      }
    }
  }
}
</style>
