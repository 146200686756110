<template>
  <div :class="blockClasses">
    <router-link
      v-if="!noInteraction && !blur"
      :to="{
        name: RouteName.SINGLE_ARTICLE,
        params: { articleId: article.ID },
      }"
      class="card-article-full__link"
    />
    <div v-if="getImage" class="card-article-full__image-container">
      <img
        class="card-article-full__image"
        :src="getImage"
        :alt="$t('magazine.image.alt')"
      />
    </div>
    <div class="card-article-full__overlay">
      <div class="card-article-full__overlay-bg"></div>
      <VideoIcon v-if="article.video" class="card-article-full__play" />
      <div v-if="!blur" :class="titleClasses">
        {{ article.title }}
      </div>
      <!--eslint-disable vue/no-v-html-->
      <p
        v-if="getFirstParagraph && !blur && !hideParagraph"
        class="card-article-full__description"
        v-text="getFirstParagraph"
      ></p>
      <!--eslint-enable vue/no-v-html-->
    </div>
    <div v-if="blur" class="card-article-full__blur"></div>
  </div>
</template>

<script>
import VideoIcon from '@/components/VideoIcon';
import RouteName from '@/utils/RouteName';

const BLOCK_SELECTOR = 'card-article-full';
export default {
  name: 'CardArticleFull',
  components: { VideoIcon },
  props: {
    article: { type: Object, default: undefined },
    hiddenTitle: { type: Boolean },
    blur: { type: Boolean },
    noInteraction: { type: Boolean },
    size: { type: Number, default: undefined },
    forceShowTitle: Boolean,
    hideParagraph: Boolean,
    noAnimation: Boolean,
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--with-interaction`]:
            !this.noInteraction && !this.blur,
          [`${BLOCK_SELECTOR}--no-animation`]: this.noAnimation,
        },
      ];
    },
    titleClasses() {
      return [
        `${BLOCK_SELECTOR}__title`,
        {
          [`${BLOCK_SELECTOR}__title--force-show`]: this.forceShowTitle,
        },
      ];
    },
    getFirstParagraph() {
      if (this.article.description) {
        const tmp = document.createElement('div');
        tmp.innerHTML = this.article.description;
        return tmp.textContent || tmp.innerText || undefined;
      }
      return undefined;
    },
    getImage() {
      switch (this.size) {
        case 3:
          return (
            this.article['images-landscape-large'][0] &&
            this.article['images-landscape-large'][0].x1
          );
        case 1:
          return (
            this.article['images-portrait-card-maxi'][0] &&
            this.article['images-portrait-card-maxi'][0].x1
          );
        case 2:
          return this.$matchMedia.xl
            ? this.article['images-landscape-medium'][0] &&
                this.article['images-landscape-medium'][0].x1
            : this.article['images-portrait-card-maxi'][0] &&
                this.article['images-portrait-card-maxi'][0].x1;
        default:
          return (
            this.article['images-portrait-card-maxi'][0] &&
            this.article['images-portrait-card-maxi'][0].x1
          );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-article-full {
  $block-selector: &;
  position: relative;
  height: 100%;
  overflow: hidden;

  &--with-interaction {
    cursor: pointer;
    &:hover {
      #{$block-selector}__image {
        transform: scale(1.1);
      }

      #{$block-selector}__overlay-bg {
        transform: translateY(0);
      }

      #{$block-selector}__title,
      #{$block-selector}__description {
        opacity: 1;
      }
    }
  }

  &--no-animation {
    &:hover {
      #{$block-selector}__image {
        transform: scale(1.1);
      }
    }

    #{$block-selector}__overlay-bg {
      transform: translateY(0);
    }

    #{$block-selector}__title,
    #{$block-selector}__description {
      opacity: 1;
    }
  }

  &__image {
    transition: transform $default-transition-duration;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: 100%;
  }

  &__overlay {
    @extend %block-abs-tlbr-zero;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1em;
    overflow: hidden;
  }

  &__overlay-bg {
    @extend %block-abs-tlbr-zero;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 25%,
      hsla(0, 0%, 100%, 0) 50%,
      rgba(0, 0, 0, 0.35) 75%,
      rgba(0, 0, 0, 0.35)
    );
    transition: transform $default-transition-duration;
    z-index: -1;

    @include breakpoint($breakpoint-desktop) {
      transform: translateY(40%);
    }
  }

  &__title {
    color: $color-white;
    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    font-size: 1.125em;
    text-transform: uppercase;
    text-align: left;
    transition: opacity $default-transition-duration;

    @include breakpoint($breakpoint-desktop) {
      opacity: 0;
    }

    &--force-show {
      opacity: 1;
    }
  }

  &__description {
    @include line-clamp(2);

    color: $color-white;
    text-align: left;
    margin: 0;
    transition: opacity $default-transition-duration;

    @include breakpoint($breakpoint-desktop) {
      opacity: 0;
    }
  }

  &__blur {
    @extend %block-abs-tlbr-zero;
    backdrop-filter: blur(4px);
    z-index: 2;
    pointer-events: none;
  }

  &__link {
    @extend %block-abs-tlbr-zero;
    z-index: 3;
  }
}
</style>
