<template>
  <div ref="container" class="vag-camera">
    <video
      ref="preview"
      class="vag-camera__preview"
      autoplay="true"
      playsinline
    ></video>
    <div :class="controlClasses">
      <div ref="progress" class="vag-camera__progress-circle">
        <button class="vag-camera__record" @click="handleClick"></button>
      </div>
    </div>
    <div class="vag-camera__switch">
      <VACRadioGroup
        :options="options"
        appearance="video"
        name="switch-video"
        v-model="recordingType"
        @input="handleSwitchType($event)"
      />
    </div>
    <video
      v-if="videoLink"
      :src="videoLink"
      ref="preview"
      class="vag-camera__preview vag-camera__preview--recorded"
      autoplay="true"
      loop="true"
      playsinline
    ></video>
  </div>
</template>
<script>
import { VACRadioGroup } from '@webqam-vac-ui/lib';
const MAX_VIDEO_DURATION = 10000;
const TYPE_VIDEO = 'video';
const TYPE_PHOTO = 'photo';
const BLOCK_CLASS = 'vag-camera';
const CONTROL_CLASS = `${BLOCK_CLASS}__control`;
export default {
  name: 'VAGCamera',
  components: {
    VACRadioGroup,
  },
  data() {
    return {
      videoRecordTimerInterval: undefined,
      videoRecordTime: 0,
      mediaRecorder: undefined,
      videoData: undefined,
      videoTimer: undefined,
      isVideo: false,
      tsStartTouch: undefined,
      tsEndTouch: undefined,
      thumbnail: undefined,
      options: [
        {
          label: 'Photo',
          inputValue: 'photo',
        },
        {
          label: 'Video',
          inputValue: 'video',
        },
      ],
      recordingType: TYPE_PHOTO,
      recording: false,
    };
  },
  computed: {
    videoLink() {
      if (this.videoData && this.videoData.length === 1) {
        return URL.createObjectURL(this.videoData[0]);
      }
      return undefined;
    },
    controlClasses() {
      return [
        CONTROL_CLASS,
        `${CONTROL_CLASS}--${this.recordingType}`,
        {
          [`${CONTROL_CLASS}--recording`]: this.recording,
        },
      ];
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: { facingMode: { exact: 'environment' } },
            audio: false,
          })
          .then((stream) => {
            if (!this.$refs.preview.srcObject) {
              this.$refs.preview.srcObject = stream;
            }
          });
      }
    });
  },
  beforeDestroy() {
    this.$refs.preview.srcObject.getTracks().forEach((track) => track.stop());
  },
  methods: {
    handleSwitchType(inputValue) {
      this.recordingType = inputValue;
    },
    handleClick() {
      if (this.recordingType === TYPE_PHOTO) {
        this.photoShoot();
      }
      if (this.recordingType === TYPE_VIDEO) {
        this.photoShoot();
        this.mediaRecorder ? this.stopVideoRecord() : this.startVideoRecord();
      }
    },
    photoShoot() {
      const canvas = document.createElement('canvas');
      const { width, height } = this.$refs.container.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(this.$refs.preview, 0, 0);
      if (this.recordingType === TYPE_PHOTO) {
        this.$emit('photo-taken', canvas.toDataURL('image/jpeg'));
      } else {
        this.$emit('thumbnail-taken', canvas.toDataURL('image/jpeg'));
      }
    },
    startVideoRecord() {
      this.recording = true;
      const interval = 10;
      this.videoRecordTime = 0;
      this.videoData = [];
      this.mediaRecorder = new MediaRecorder(this.$refs.preview.srcObject);
      this.mediaRecorder.ondataavailable = (event) => {
        this.$emit('video-recorded', {
          video: event.data,
          thumbnail: this.thumbnail,
        });
      };
      this.mediaRecorder.start();
      this.videoRecordTimerInterval = setInterval(() => {
        this.videoRecordTime += interval;

        this.progressDeg = Math.floor(
          ((10000 - this.videoRecordTime) * 360) / MAX_VIDEO_DURATION
        );
        this.$refs.progress.style.setProperty(
          '--current-progress',
          `${this.progressDeg}deg`
        );
        if (this.videoRecordTime >= MAX_VIDEO_DURATION) {
          this.stopVideoRecord();
        }
      }, interval);
    },
    stopVideoRecord() {
      this.recording = false;
      this.mediaRecorder.stop();
      clearInterval(this.videoRecordTimerInterval);
    },
  },
};
</script>
<style lang="scss">
.vag-camera {
  $block-selector: &;
  width: 100vw;
  height: 100vh;

  &__preview {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &--recorded {
      z-index: 2;
    }
  }

  &__control {
    position: absolute;
    bottom: 4.5rem;
    left: calc(50% - 3em);
    width: 6.5rem;
    height: 6.5rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__record {
    border-radius: 50%;
    background-color: white;
    width: 100%;
    height: 100%;
    border: none;
  }

  &__progress-circle {
    --current-progress: 360deg;
    background: conic-gradient(
      $color-tan var(--current-progress),
      transparent 0deg
    );
    padding: 0.35rem;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    #{$block-selector}__control--photo & {
      background: transparent;
    }
  }

  &__switch {
    position: absolute;
    bottom: 1.45rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
