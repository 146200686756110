<template>
  <component :is="headerComponent" />
</template>

<script>
import Auth from '@/utils/authentication';
import HeaderMember from './HeaderMember';
import HeaderPrehome from './HeaderPrehome';

export default {
  name: 'BaseHeader',
  data() {
    return {
      user: null,
    };
  },
  computed: {
    headerComponent() {
      return this.user ? HeaderMember : HeaderPrehome;
    },
  },
  created() {
    Auth.retrievePersistentUser().then((user) => {
      this.user = user;
    });
  },
};
</script>
