// TODO Rewrite this file in TypeScript

/**
 *
 * @param {object} state
 * @param {Array} payload
 * @param {string} keyProp
 * @param {Function|null} keyFn
 * @param {string|null} mapProp
 * @param {string|null} listProp
 * @param {string|null} prop
 * @param {boolean} freeze Freeze map and list for readonly entities (much faster for large datasets)
 */
export function set({
  state,
  payload,
  keyProp = 'id',
  keyFn,
  mapProp,
  listProp,
  prop,
  freeze = false,
}) {
  if (!(payload instanceof Array)) {
    throw new TypeError(`The expected payload must be an Array.`);
  }

  // Use temporary variables to trigger control hooks (when strict mode is enabled) only once
  const map = {};
  const list = [];

  payload.forEach((item) => {
    const key = (keyFn && keyFn(item[keyProp])) || item[keyProp];
    map[key] = item;
    list.push(key);
  });

  if (!(prop || (mapProp && listProp))) {
    throw new Error('Invalid prop names.');
  }

  state[mapProp || `${prop}Map`] = freeze ? Object.freeze(map) : map;
  state[listProp || `${prop}List`] = freeze ? Object.freeze(list) : list;
}

/**
 *
 * @param {(string|{map: string, list: string})[]} props
 * @return {Object}
 */
export function mapStoreState(props) {
  return props.reduce((acc, prop) => {
    if (typeof prop === 'string') {
      acc[`${prop}Map`] = {};
      acc[`${prop}List`] = [];
    } else if (typeof prop === 'object' && 'map' in prop && 'list' in prop) {
      acc[prop.map] = {};
      acc[prop.list] = [];
    } else {
      throw new Error('Unable to map prop state. Prop name is invalid.');
    }

    return acc;
  }, {});
}

/**
 *
 * @param {Object} map
 * @param {boolean} freeze
 * @returns {{}}
 */
export function mapStoreMutations(map, freeze = false) {
  return Object.entries(map).reduce(
    (
      acc,
      [
        mutationName,
        { keyProp, keyFn = null, mapProp = null, listProp = null, prop = null },
      ]
    ) => {
      acc[mutationName] = (state, payload) => {
        set({
          state,
          payload,
          keyProp,
          keyFn,
          mapProp,
          listProp,
          prop,
          freeze,
        });
      };

      return acc;
    },
    {}
  );
}

/**
 *
 * @param {string} namespace
 * @param {string} type
 * @returns {string}
 */
export function toPrefixed(namespace, type) {
  return `${namespace}/${type}`;
}

/**
 *
 * @param {string} namespace
 * @return {function(string): string}
 */
export function prefixTypeFactory(namespace) {
  return (type) => toPrefixed(namespace, type);
}

/**
 *
 * @param map
 * @param search
 * @returns {*|null}
 */
export function findEntity(map, search) {
  const _key =
    ('object' === typeof search && search._key) ||
    ((typeof search === 'string' || typeof search === 'number') && search);

  if (_key) {
    return map[_key] || null;
  } else {
    const paramNames = Object.keys(search);

    return Object.values(map).find((e) =>
      paramNames.every((p) => e[p] === search[p])
    );
  }
}

export class EntityNotFoundError extends Error {}

/**
 *
 * @param {Object} map
 * @param {string|Object} search
 * @param {string} entityName
 * @returns {*}
 */
export function findEntityOrFail(map, search, entityName = 'Entity') {
  const entity = findEntity(map, search);

  if (!entity) {
    throw new EntityNotFoundError(`${entityName} not found`);
  }

  return entity;
}

/**
 *
 * @param {Object} map
 * @param {Array} list
 * @returns {Array}
 */
export function mapEntity(map, list) {
  return list.map((k) => map[k]);
}

/**
 *
 * @param {object} state
 * @param {string|null} mapProp
 * @param {string|null} listProp
 * @param {string|null} prop
 * @returns {*}
 */
export function get({ state, mapProp = null, listProp = null, prop = null }) {
  if (!(prop || (mapProp && listProp))) {
    throw new Error('Invalid prop names.');
  }

  const _mapProp = mapProp || `${prop}Map`;
  const _listProp = listProp || `${prop}List`;

  return mapEntity(state[_mapProp], state[_listProp]);
}

/**
 *
 * @param {object} map
 * @returns {{}}
 */
export function mapStoreGetters(map) {
  return Object.entries(map).reduce((acc, [getterName, prop]) => {
    acc[getterName] = (state) => get({ state, prop });

    return acc;
  }, {});
}

/**
 *
 * @param {Object<string,string>} map
 * @return Object<string,function(*):*>
 */
export function mapFindByKeyGetters(map) {
  return Object.entries(map).reduce((acc, [getterName, statePropKey]) => {
    acc[getterName] = function (state) {
      return (id) => findEntity(state[statePropKey], { _key: id });
    };

    return acc;
  }, {});
}
