import { GetEventsConfig } from '@/types/GetEventsConfig';
import { EventListResponse, EventNode } from '@/types/EventListResponse';
import { FaqResponse } from '@/types/FaqResponse';
import { GetEventConfig } from '@/types/GetEventConfig';
import { GetCountriesConfig } from '@/types/GetCountriesConfig';
import CountryList from '@/types/CountryList';
import { GetEventsRequestParams } from '@/types/GetEventsRequestParams';
import { GetFaqConfig } from '@/types/GetFaqConfig';
import { GetArticlesConfig } from '@/types/GetArticlesConfig';
import { GetArticlesRequestParams } from '@/types/GetArticlesRequestParams';
import {
  ArticleListResponse,
  ArticleNode,
  ArticleNodesResponse,
} from '@/types/ArticleListResponse';
import { GetMyEventsConfig } from '@/types/GetMyEventsConfig';
import { MyEvents } from '@/types/MyEvents';
import { GetHomepageContentConfig } from '@/types/GetHomepageContentConfig';
import { GetHomepageContentResponse } from '@/types/GetHomepageContentResponse';
import { PostRegisterEventConfig } from '@/types/PostRegisterEventConfig';
import { CustomerContactConfig } from '@/types/CustomerContactConfig';
import { GetPageConfig } from '@/types/GetPageConfig';
import { PageResponse } from '@/types/PageResponse';
import Internationalization from '@/utils/Internationalization';
import store from '../store';
import { CountriesMutation, prefixType } from '@/store/modules/countries';
import {
  ConversationFilters,
  ConversationSort,
} from '@/utils/ConversationConstants';

import {
  ApiClientAuthError,
  ApiClientError,
  ArticleNotFoundError,
  EventNotFoundError,
  FaqNotFoundError,
  PageNotFoundError,
} from '@/api/abstract/AbstractApiClient';
import HttpMethods from '@/enums/HttpMethods';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import Auth from '@/utils/authentication';
import chunkVideo from '@/utils/VideoChunker';
import { randomStr } from '@/utils/String';

class ContentAPI {
  protected readonly BASE_URL: string;
  protected readonly username: string;
  protected readonly password: string;
  protected debug: boolean;

  constructor(baseUrl: string, username: string, password = '', debug = false) {
    this.BASE_URL = baseUrl;
    this.username = username;
    this.password = password;
    this.debug = debug;
  }

  protected async query({
    url,
    method = HttpMethods.GET,
    params,
    authenticate = true,
    data,
    headers = {},
  }: {
    url: string;
    method?: Method;
    params?: object;
    authenticate?: boolean;
    data?: object;
    headers?: object;
  }): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.BASE_URL,
      url,
      params,
      data,
      headers,
    };

    const country = Auth.retrievePersistentSession.country;
    const sessionToken = Auth.retrievePersistentSession.sessionToken;

    if (country) {
      config.params = {
        ...params,
        country,
      };
    }

    if (sessionToken) {
      config.headers = {
        ...headers,
        sessionToken,
      };
    }

    if (authenticate) {
      config.auth = {
        username: this.username,
        password: this.password,
      };
    }

    return axios(config);
  }

  async getInitData() {
    return await this.query({
      url: `/api/init`,
    });
  }

  async getEvents({
    lang = Internationalization.getCurrentLocale(),
    page = 1,
    itemPerPage = 6,
    all,
    dateStart,
    dateEnd,
    countryCodes,
  }: GetEventsConfig = {}): Promise<EventListResponse> {
    const params: GetEventsRequestParams = {};

    if (all !== undefined) {
      params.all = all;
    }

    params.page = page;
    params.itemPerPage = itemPerPage;

    if (dateStart !== undefined) {
      params.dateStart = dateStart;

      if (dateEnd !== undefined) {
        params.dateEnd = dateEnd;
      }
    }

    if (countryCodes) {
      params.countryCode = countryCodes;
    }

    const { data }: { data: EventListResponse } = await this.query({
      url: `${lang}/api/events`,
      params,
    });

    return data;
  }

  async getMyEvents({
    lang = Internationalization.getCurrentLocale(),
    page = 1,
    itemPerPage = 6,
  }: GetMyEventsConfig): Promise<MyEvents> {
    const { data }: { data: MyEvents } = await this.query({
      url: `${lang}/api/my-events`,
      params: { page, itemPerPage },
    });
    return data;
  }

  async getEvent({
    lang = Internationalization.getCurrentLocale(),
    id,
    userId,
  }: GetEventConfig): Promise<EventNode> {
    const { data }: { data: EventListResponse } = await this.query({
      url: `${lang}/api/events`,
      params: { id, userId },
    });

    if (data.nodes.length) {
      return data.nodes[0];
    }

    throw new EventNotFoundError('Event not found.');
  }

  async registerToEvent({
    lang = Internationalization.getCurrentLocale(),
    event,
    user,
    firstName,
    lastName,
    email,
    phone,
    prefixPhone,
    registrationSlots,
    guest,
    message,
    guestName,
  }: PostRegisterEventConfig): Promise<boolean> {
    const data = await this.query({
      url: `${lang}/api/events/register`,
      method: HttpMethods.POST,
      data: {
        event,
        user,
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phone,
        prefixPhone: prefixPhone,
        registration_slots: registrationSlots,
        guest,
        message,
        guest_name: guestName,
      },
    });
    return data.status === 200;
  }

  async unsubscribeEvent(eventId: number): Promise<boolean> {
    const data = await this.query({
      method: HttpMethods.DELETE,
      url: '/api/event-unregister',
      params: {
        event: eventId,
      },
    });
    return data.status === 200;
  }

  async getArticles({
    lang = Internationalization.getCurrentLocale(),
    page = 1,
    itemPerPage = 5,
    category = undefined,
  }: GetArticlesConfig = {}): Promise<ArticleNodesResponse> {
    const params: GetArticlesRequestParams = {};
    params.page = page;
    params.itemPerPage = itemPerPage;
    if (category) {
      params.category = category;
    }

    const { data }: { data: ArticleListResponse } = await this.query({
      url: `${lang}/api/articles`,
      params,
    });

    return {
      articles: data.nodes,
      count: parseInt(data.count),
    };
  }

  async getArticle({
    lang = Internationalization.getCurrentLocale(),
    id,
  }: GetArticlesConfig = {}): Promise<ArticleNode> {
    const { data }: { data: ArticleListResponse } = await this.query({
      url: `${lang}/api/articles`,
      params: { id },
    });

    if (data.nodes.length) {
      return data.nodes[0];
    }

    throw new ArticleNotFoundError('Article not found.');
  }

  async getHomepageContent({
    lang = Internationalization.getCurrentLocale(),
  }: GetHomepageContentConfig = {}): Promise<GetHomepageContentResponse> {
    const { data }: { data: GetHomepageContentResponse } = await this.query({
      url: `${lang}/api/homepage`,
    });

    if (data) {
      return data;
    }

    throw new FaqNotFoundError('Faq not found.');
  }

  async getFaq({
    lang = Internationalization.getCurrentLocale(),
  }: GetFaqConfig): Promise<FaqResponse> {
    const { data }: { data: FaqResponse } = await this.query({
      url: `${lang}/api/faq`,
    });

    if (data) {
      return data;
    }

    throw new FaqNotFoundError('Faq not found.');
  }

  async getPage({
    lang = Internationalization.getCurrentLocale(),
    slug,
  }: GetPageConfig): Promise<PageResponse> {
    const { data }: { data: PageResponse } = await this.query({
      url: `${lang}/api/page?slug=${slug}`,
    });

    if (data.count > 0) {
      return data;
    }

    throw new PageNotFoundError(`Page "${slug}" not found.`);
  }

  async getCountries({
    lang = Internationalization.getCurrentLocale(),
  }: GetCountriesConfig = {}): Promise<CountryList> {
    const {
      data: { countries, ip_lookup_country },
    }: {
      data: {
        countries: CountryList;
        splashscreen: string;
        ip_lookup_country: string;
      };
    } = await this.query({
      url: `${lang}/api/init`,
    });
    if (!Internationalization.getCountry()) {
      Internationalization.setCountry(ip_lookup_country);
    }

    store.commit(
      prefixType(CountriesMutation.setLookupCountry),
      ip_lookup_country
    );

    return countries;
  }

  async postCustomerContact({
    lang = Internationalization.getCurrentLocale(),
    name,
    email,
    phone,
    subject,
    message,
    headers = {},
  }: CustomerContactConfig): Promise<boolean> {
    const data = await this.query({
      url: `${lang}/api/customer/contact`,
      method: HttpMethods.POST,
      data: { name, email, phone, subject, message },
      headers,
    });
    return data.status === 200;
  }

  async sendConfirmationEmail(email: string, headers = {}) {
    return await this.query({
      url: '/api/token/email',
      method: HttpMethods.POST,
      data: {
        email,
        type: 'VERIFY',
      },
      headers,
    });
  }

  async registrationConfirmationToken(emailToken: string) {
    return await this.query({
      url: '/api/token/validate',
      method: HttpMethods.POST,
      data: {
        emailToken,
      },
    });
  }

  async getCollection({
    lang = Internationalization.getCurrentLocale(),
    user = undefined,
  }) {
    return await this.query({
      url: `/${lang}/api/customer/wallet`,
      params: {
        user,
      },
    });
  }

  async removeTimepiece(serialnumber) {
    return await this.query({
      url: '/api/customer/wallet',
      method: HttpMethods.DELETE,
      data: { serialnumber },
    });
  }

  async timepieceExist(serialnumber) {
    return await this.query({
      url: '/api/serial_number/exist',
      params: { serialnumber },
    });
  }

  async addTimepiece(serialnumber) {
    return await this.query({
      url: '/api/customer/wallet',
      method: HttpMethods.PUT,
      data: { serialnumber },
    });
  }

  async register({ data, headers = {} }) {
    return await this.query({
      url: '/api/customer',
      method: HttpMethods.POST,
      data,
      headers,
    });
  }

  async checkEmail(email, headers = {}) {
    return await this.query({
      url: '/api/customer/status/by_email',
      params: { email },
      headers,
    });
  }

  async checkPhone(mobilePhone, mobilePhoneCountry) {
    return await this.query({
      url: '/api/customer/status/by_phone',
      params: { mobilePhone, mobilePhoneCountry },
    });
  }

  async getUsefulLinks() {
    return await this.query({
      url: '/api/useful-links',
      params: {
        _format: 'json',
      },
    });
  }

  async getStories({
    page = 1,
    itemPerPage = 9,
    id = undefined,
    themeArray = [],
    collectionArray = [],
    sort = undefined,
    user = undefined,
  }) {
    const collection =
      collectionArray.length > 0 ? collectionArray.join() : undefined;
    const theme = themeArray.length > 0 ? themeArray.join() : undefined;
    return await this.query({
      url: '/api/stories',
      params: {
        page,
        itemPerPage,
        id,
        theme,
        collection,
        sort,
        user,
      },
    });
  }

  async getStoriesFilters() {
    return await this.query({
      url: `${Internationalization.getCurrentLocale()}/api/storiesFilters`,
    });
  }

  async postStory({
    ID,
    title,
    description,
    image,
    hasVideo,
    theme,
    collection,
    iLike,
  }) {
    return await this.query({
      url: '/api/story/post',
      method: HttpMethods.POST,
      data: {
        ID,
        title,
        description,
        image,
        hasVideo,
        theme,
        collection,
        'i-like': iLike,
      },
    });
  }

  async updateStory({
    id,
    title,
    description,
    image,
    theme,
    collection,
    iLike,
  }) {
    return await this.query({
      url: '/api/story',
      method: HttpMethods.PUT,
      params: {
        id,
      },
      data: {
        title,
        description,
        image,
        theme,
        collection,
        'i-like': iLike,
      },
    });
  }

  /**
   * Uploads a video to the previously created story
   * @param {String} storyId - ID of the created story to upload the video to
   * @param {Blob} blob - Complete video blob
   */
  async uploadStoryVideo(storyId, blob) {
    const chunks = chunkVideo(blob);
    const Uuid = randomStr();
    for (let i = 0; i < chunks.length; i++) {
      const queryData = new FormData();
      queryData.append('id', storyId);
      queryData.append('uploadUuid', Uuid);
      queryData.append('isLastChunk', `${i === chunks.length - 1}`);
      queryData.append('file', chunks[i], `video-${i}.webm`);

      await this.query({
        url: `/api/story/${storyId}/video`,
        method: HttpMethods.POST,
        data: queryData,
        params: {
          _format: 'json',
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  }

  async pollingVideo(storyId) {
    return await this.query({
      url: `api/node/status/${storyId}`,
    });
  }

  async getConversations({
    parentId,
    page = 1,
    itemPerPage = 16,
    filterAuthor = ConversationFilters.ALL,
    sort = ConversationSort.DATE,
  }) {
    return await this.query({
      url: '/api/conversations',
      params: {
        page,
        itemPerPage,
        sort,
        filterAuthor,
        parent_id: parentId,
      },
    });
  }

  async postConversation(parentId, message, filterAuthor = 'all') {
    return await this.query({
      url: '/api/conversation/post',
      method: HttpMethods.POST,
      data: {
        parent_id: parentId,
        message,
        filterAuthor,
      },
    });
  }

  async postReply(parentId, message) {
    return await this.query({
      url: '/api/reply/post',
      method: HttpMethods.POST,
      data: {
        parent_id: parentId,
        message,
      },
    });
  }

  async setLikeStatus(id, like) {
    return await this.query({
      url: '/api/like',
      method: HttpMethods.POST,
      data: {
        id,
        i_like: like,
      },
    });
  }

  async deleteConversation(id) {
    return await this.query({
      url: `/api/conversation`,
      method: HttpMethods.DELETE,
      params: {
        id,
      },
    });
  }

  async replyToConversation(parentId, message) {
    return await this.query({
      url: `/api/reply/post`,
      method: HttpMethods.POST,
      data: {
        parent_id: parentId,
        message,
      },
    });
  }

  async editConversation(id, message) {
    return await this.query({
      url: `/api/conversation`,
      method: HttpMethods.PUT,
      params: {
        id,
      },
      data: {
        message,
      },
    });
  }

  async deleteReply(id) {
    return await this.query({
      url: `/api/reply`,
      method: HttpMethods.DELETE,
      params: {
        id,
      },
    });
  }

  async editReply(id, message) {
    return await this.query({
      url: `/api/reply`,
      method: HttpMethods.PUT,
      params: {
        id,
      },
      data: {
        message,
      },
    });
  }

  async getProfile(id) {
    return await this.query({
      url: `/api/profile`,
      params: {
        user: id,
      },
    });
  }

  async getPreferredStore(id) {
    return await this.query({
      url: `/api/user/preferred-store`,
      params: {
        user: id,
      },
    });
  }

  async deleteStory(id) {
    return await this.query({
      url: `/api/story`,
      method: HttpMethods.DELETE,
      params: {
        id,
      },
    });
  }

  async getUserConversations(user, limit = 2) {
    return await this.query({
      url: `/api/profile/conversations`,
      params: {
        user,
        limit,
      },
    });
  }

  async getUserAllConversations({
    user = undefined,
    page = 1,
    itemPerPage = 9,
  }) {
    return await this.query({
      url: `/api/profile/conversations/all`,
      params: {
        user,
        page,
        itemPerPage,
      },
    });
  }

  async voteToPoll(id, answer) {
    return await this.query({
      url: `/api/poll`,
      method: HttpMethods.POST,
      data: {
        id,
        answer,
      },
    });
  }

  async getOnboarding() {
    const lang = Internationalization.getCurrentLocale();
    return await this.query({ url: `${lang}/api/quiz` });
  }

  async saveOnboarding(data, currentPage: string, isCompleted = false) {
    return await this.query({
      url: '/api/quiz',
      method: 'PUT',
      data: {
        data: { ...data },
        current_page: currentPage,
        set_completed: isCompleted,
      },
    });
  }

  async updateOnboarding(data) {
    return await this.query({ url: '/api/quiz', method: 'POST', data });
  }
}

if (!process.env.VUE_APP_API_URL) {
  throw new ApiClientError('API URL is undefined');
}

if (!process.env.VUE_APP_API_AUTH_BASIC_USER) {
  throw new ApiClientAuthError('API auth user is undefined');
}

export default new ContentAPI(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_API_AUTH_BASIC_USER,
  process.env.VUE_APP_API_AUTH_BASIC_PASSWORD,
  !!(process.env.VUE_APP_API_DEBUG && JSON.parse(process.env.VUE_APP_API_DEBUG))
);
