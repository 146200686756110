<template>
  <div class="prehome">
    <PrehomeIntroduction />
    <PrehomeDevice />
    <PrehomeWelcome />
    <PrehomeContent />
    <PrehomeEvent />
    <PrehomeWatch />
  </div>
</template>

<script>
import PrehomeIntroduction from '@/components/prehome/PrehomeIntroduction';
import PrehomeDevice from '@/components/prehome/PrehomeDevice';
import PrehomeWelcome from '@/components/prehome/PrehomeWelcome';
import PrehomeContent from '@/components/prehome/PrehomeContent';
import PrehomeEvent from '@/components/prehome/PrehomeEvent';
import PrehomeWatch from '@/components/prehome/PrehomeWatch';
import authentication from '@/utils/authentication';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'Prehome',
  components: {
    PrehomeWatch,
    PrehomeEvent,
    PrehomeContent,
    PrehomeWelcome,
    PrehomeDevice,
    PrehomeIntroduction,
  },
  mixins: [Tracking],
  created() {
    if (authentication.retrieve.sessionToken) {
      this.$router.replace({ name: RouteName.HOME });
    }
  },
  mounted() {
    if (
      !this.$route.query.fc &&
      !this.$route.query.state &&
      !this.$route.query.redirect
    ) {
      this.trackPageView(this.$t('the_hour_club'));
    }
  },
};
</script>
<style scoped lang="scss">
.prehome {
  overflow-x: hidden;
}
</style>
