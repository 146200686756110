<template>
  <VACButton
    v-if="eventBooked && !eventClosed"
    appearance="link"
    class="event-unsubscribe"
    @click="unsubscribe"
  >
    {{ $t('events.register.cancel') }}
  </VACButton>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';
import { VACButton } from '@webqam-vac-ui/lib';
import { EventStatus } from '@/types/EventListResponse';
import RouteName from '@/utils/RouteName';
import { EventsActions, prefixType } from '@/store/modules/events';

export default {
  name: 'EventUnsubscribe',
  components: { VACButton },
  props: {
    event: { type: Object, required: true },
    registration: { type: Object, default: null },
  },
  computed: {
    eventClosed() {
      return this.event.status === EventStatus.CLOSED;
    },
    eventBooked() {
      return !!this.registration;
    },
  },
  methods: {
    unsubscribe() {
      if (confirm(this.$t('events.unsubscribe.confirm'))) {
        ContentAPI.unsubscribeEvent(parseInt(this.event.ID)).then(() => {
          this.$store.dispatch(prefixType(EventsActions.destroyCache));
          this.$router.push({ name: RouteName.EVENTS });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.event-unsubscribe {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 2em;
}
</style>
