var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":_vm.PortalName.APP_FOOTER,"disabled":!_vm.isFullscreen}},[(_vm.isFullscreen)?_c('BaseBtn',{staticClass:"block-gallery__exit",attrs:{"discrete":""},on:{"click":_vm.closeFullscreen}}):_vm._e(),_c('div',{class:_vm.blockClasses},[_c('Swiper',{ref:"swiperGallery",class:_vm.galleryClasses,attrs:{"options":_vm.swiperOptionsGallery},on:{"imagesReady":_vm.emitReadyEvent,"slideChange":_vm.updateCurrentIndex,"click-slide":_vm.emitFullscreenRequest},scopedSlots:_vm._u([{key:"default",fn:function(){return [(_vm.video && !_vm.isFullscreen)?_c('SwiperSlide',[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.playVideo.apply(null, arguments)}}},[_c('VideoIcon'),_c('img',{staticClass:"block-gallery__gallery-image",attrs:{"src":_vm.images[0].x1,"alt":""}}),_c('Portal',{attrs:{"to":"portal-container--modal"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isVideoPlaying)?_c('VideoPlayer',{attrs:{"video":_vm.video},on:{"close":_vm.closeVideo}}):_vm._e()],1)],1),(!_vm.isFullscreen && _vm.category)?_c('ArticleBadge',{staticClass:"block-gallery__badge",attrs:{"type":_vm.category}}):_vm._e()],1)]):_vm._e(),_vm._l((_vm.images),function(image,index){return _c('SwiperSlide',{key:`magazine-gallery-image-${index}`},[_c('img',{staticClass:"block-gallery__gallery-image",attrs:{"src":image.x1,"alt":""}}),(!_vm.isFullscreen && _vm.category)?_c('ArticleBadge',{staticClass:"block-gallery__badge",attrs:{"type":_vm.category}}):_vm._e()],1)})]},proxy:true},{key:"pagination",fn:function(){return [_c('div',{staticClass:"block-gallery__pagination"})]},proxy:true},(_vm.arrows)?{key:"button-prev",fn:function(){return [_c('button',{class:[
            'block-gallery__arrow',
            'block-gallery__arrow--prev',
            { [`block-gallery__arrow--out`]: _vm.outWidth },
          ]})]},proxy:true}:null,(_vm.arrows)?{key:"button-next",fn:function(){return [_c('button',{class:[
            'block-gallery__arrow',
            'block-gallery__arrow--next',
            { [`block-gallery__arrow--out`]: _vm.outWidth },
          ]})]},proxy:true}:null],null,true)}),_c('GalleryThumbs',{directives:[{name:"show",rawName:"v-show",value:(_vm.isFullscreen),expression:"isFullscreen"}],attrs:{"images":_vm.images,"active-item":_vm.currentIndex,"video":_vm.video},on:{"click":_vm.handleThumbsClick}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }