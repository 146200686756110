import {
  ApiClientAuthError,
  ApiClientError,
} from '@/api/abstract/AbstractApiClient';
import HttpMethods from '@/enums/HttpMethods';
import Auth from '@/utils/authentication';
import AccountAPIInterface from '@/api/abstract/AccountAPIInterface';
import UpdateAccountParam from '@/types/UpdateAccountParam';
import { LoggedUser } from '@/types/LoggedUser';
import Internationalization from '@/utils/Internationalization';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { checkForWatchInCCP } from '@/api/CCPApi';
import store from '@/store';
import storage from '@/utils/storage';
import AutoLoginStorage from '@/enums/AutoLoginStorage';

class WeChatClient implements AccountAPIInterface {
  protected readonly BASE_URL: string;
  protected readonly username: string;
  protected readonly password: string;
  protected debug: boolean;

  constructor(baseUrl: string, username: string, password = '', debug = false) {
    this.BASE_URL = baseUrl;
    this.username = username;
    this.password = password;
    this.debug = debug;
  }

  protected async query({
    url,
    method = HttpMethods.GET,
    params,
    authenticate = true,
    data,
    headers = {},
  }: {
    url: string;
    method?: Method;
    params?: object;
    authenticate?: boolean;
    data?: object;
    headers?: object;
  }): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.BASE_URL,
      url,
      params,
      data,
      headers,
    };

    const country = Auth.retrievePersistentSession.country;
    const sessionToken = Auth.retrievePersistentSession.sessionToken;

    if (country) {
      config.params = {
        ...params,
        country,
      };
    }

    if (sessionToken) {
      config.headers = {
        ...headers,
        sessionToken,
      };
    }

    if (authenticate) {
      config.auth = {
        username: this.username,
        password: this.password,
      };
    }

    return axios(config);
  }

  /**
   * Get oAuth login URL for WeChat
   */
  async getLogin() {
    return await this.query({
      url: `api/rwf/oauth-url`,
    });
  }

  async autoLogin(uid: string) {
    return await this.query({
      url: `api/wechat/auto-login`,
      method: HttpMethods.POST,
      data: {
        uid,
      },
    });
  }

  /**
   * Get current logged in user info
   */
  async getCurrentUserInfos() {
    return await this.query({
      url: 'api/customer/current',
      params: {
        country: Auth.retrievePersistentSession.country,
      },
      headers: {
        sessionToken: `${Auth.retrievePersistentSession.sessionToken}`,
      },
    });
  }

  async unbind() {
    return await this.query({
      url: `api/wechat/unbind`,
      method: HttpMethods.DELETE,
    });
  }

  /**
   * Ask pin code verification via SMS
   * @param number
   */
  async askPhoneConfirmation(number: string) {
    return await this.query({
      url: '/api/token/phone',
      method: HttpMethods.POST,
      data: {
        number,
        type: 'VERIFY',
      },
    });
  }

  async askPasswordForgotPinCode(number: string) {
    return await this.query({
      url: '/api/token/phone',
      method: HttpMethods.POST,
      data: {
        number,
        type: 'PASSWORD_RESET',
      },
    });
  }

  async resetPasswordWithPhone(config, headers = {}) {
    return await this.query({
      url: '/api/password/reset-phone',
      method: HttpMethods.POST,
      data: config,
      headers,
    });
  }

  async loginPhoneWechat(
    phone: string,
    password: string,
    uuid: string,
    headers: object
  ) {
    const { data }: { data: LoggedUser } = await this.query({
      url: `api/wechat/login-phone`,
      method: HttpMethods.POST,
      data: {
        phone,
        password,
        bind: {
          uid: uuid,
        },
      },
      headers,
    });
    if (data.user) {
      const hasTimepiece = checkForWatchInCCP(data.user.uuid);
      Auth.login({ ...data, hasTimepiece });
      store.commit('auth/setUserInfos', { ...data, hasTimepiece });
    } else {
      throw new Error('Internal error on login');
    }
  }

  async loginWithPhone(phone: string, password, headers = {}): Promise<void> {
    const { data }: { data: LoggedUser } = await this.query({
      url: '/api/login-phone',
      method: HttpMethods.POST,
      data: {
        phone,
        password,
      },
      headers,
    });
    if (data.user) {
      const hasTimepieces = await checkForWatchInCCP(data.user.uuid);
      Auth.login({ ...data, hasTimepieces });
      store.commit('auth/setUserInfos', { ...data, hasTimepieces });
    } else {
      throw new Error('Internal error on login');
    }
  }

  /**
   * Update password for current connected user
   * @param oldPassword
   * @param newPassword
   */
  async updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    await this.query({
      url: '/api/customer/current/password',
      method: HttpMethods.POST,
      data: {
        oldPassword,
        newPassword,
        country: Auth.retrievePersistentSession.country,
      },
      headers: {
        sessionToken: `${Auth.retrievePersistentSession.sessionToken}`,
      },
    });
  }

  async updateAccount(params: UpdateAccountParam): Promise<AxiosResponse> {
    if (params.language) {
      Internationalization.setLocale(params.language);
    }
    return await this.query({
      url: '/api/customer/current',
      method: HttpMethods.PUT,
      data: {
        firstName1: params.firstName,
        lastName1: params.lastName,
        gender: params.gender,
        title: params.title,
        addresses: [
          {
            mobile: `${params.phonePrefix}${params.phone}`,
            country: params.country,
            defaultAddress: true,
          },
        ],
        websiteCountry: params.country,
        emailAddress: params.email,
        contactByBrand: params.allowBrandContact,
      },
    });
  }

  async logout() {
    storage.remove(AutoLoginStorage.RWF_TOKEN);
    return await this.query({
      url: 'api/logout',
      method: 'POST',
      data: {
        country: 'CN',
      },
    });
  }

  /**
   *
   */
  async unbindWechat(uid: string) {
    await this.query({
      method: 'delete',
      url: 'api/wechat/unbind-platform',
      data: {
        uid,
      },
    });
  }
}

if (!process.env.VUE_APP_API_URL) {
  throw new ApiClientError('API URL is undefined');
}

if (!process.env.VUE_APP_API_AUTH_BASIC_USER) {
  throw new ApiClientAuthError('API auth user is undefined');
}

export default new WeChatClient(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_API_AUTH_BASIC_USER,
  process.env.VUE_APP_API_AUTH_BASIC_PASSWORD,
  !!(process.env.VUE_APP_API_DEBUG && JSON.parse(process.env.VUE_APP_API_DEBUG))
);
