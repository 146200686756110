import AuthenticationRealms from '@/enums/AuthenticationRealms';

export function morphHydraDataToLegacyFormat(hydraData, accessToken: string) {
  return {
    sessionToken: accessToken,
    customerUuid: hydraData.id,
    id: hydraData.userId,
    country: AuthenticationRealms.WW,
    hasTimepieces: hydraData.hasWatch,
    tokenId: hydraData.tokenId,
    user: {
      firstName1: hydraData.firstName,
      lastName1: hydraData.lastName,
      gender: hydraData.gender,
      title: hydraData.title,
      birthDate: hydraData.birthDate,
      newsletterAccount: hydraData.consent.clientNewsletter,
      emailAddress: hydraData.email,
    },
    pseudo: hydraData.pseudo,
    share_social: hydraData.share_social,
    share_collection: hydraData.share_collection,
  };
}

export function morphWeChatDataToLegacyFormat(weChatData, accessToken: string) {
  return {
    sessionToken: accessToken,
    customerUuid: weChatData.uuid,
    id: weChatData.userId,
    country: AuthenticationRealms.CN,
    hasTimepieces: weChatData.hasWatch,
    user: {
      firstName1: weChatData.firstName1,
      lastName1: weChatData.lastName1,
      gender: weChatData.gender,
      title: weChatData.title,
      birthDate: weChatData.birthDate,
      newsletterAccount: weChatData.newsletterAccount,
      emailAddress: weChatData.emailAddress,
      phone: {
        prefix: weChatData.mobilePhone.substr(0, 3),
        number: weChatData.mobilePhone.substr(3),
      },
    },
    pseudo: weChatData.pseudo,
    share_social: weChatData.share_social,
    share_collection: weChatData.share_collection,
  };
}
