<template>
  <div class="pin-input__wrapper">
    <div class="pin-input">
      <div class="pin-input__intro">
        {{ $t('pin-confirmation.intro') }}<b>{{ phoneNumber }}</b>
      </div>
      <VForm
        ref="digits"
        class="pin-input__pin-container"
        @submit.prevent="sendSubmit"
      >
        <FieldDigitCode v-model="pinCode" :number-of-digit="numberOfDigit" />
      </VForm>
      <div class="pin-input__instructions pin-input__instructions--first">
        {{ $t('pin-confirmation.instruction-p1') }}
      </div>
      <div
        v-if="seconds > 0"
        class="pin-input__instructions pin-input__instructions--second"
      >
        {{ $t('pin-confirmation.instruction-p2', { seconds }) }}
      </div>
      <div v-if="innerErrorMessage" class="pin-input__error">
        {{ innerErrorMessage }}
      </div>
      <div v-if="errorMessage" class="pin-input__error">
        <template v-if="typeof errorMessage === 'string'">
          {{ errorMessage }}
        </template>
        <ul v-else>
          <li v-for="msg in errorMessage" :key="msg">{{ msg }}</li>
        </ul>
      </div>
      <BaseBtn
        v-else
        discrete
        class="pin-input__instructions pin-input__instructions--link"
        @click="requestNewPin"
      >
        {{ $t('pin-confirmation.instruction-newPin') }}
      </BaseBtn>
      <BaseBtn :disabled="!pinCodeValid" block primary @click="sendSubmit">
        {{ $t('pin-confirmation.submit-button') }}
      </BaseBtn>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn';
import FieldDigitCode from '@/components/form/FieldDigitCode';
import WeChatClient from '@/api/WeChatClient';

export default {
  name: 'PinInput',
  components: { FieldDigitCode, BaseBtn },
  props: {
    phoneNumber: { type: String, required: true },
    askCode: Boolean,
    errorMessage: { type: [Array, String], default: null },
  },
  data() {
    return {
      innerErrorMessage: null,
      seconds: 45,
      numberOfDigit: 6,
      pinCode: '',
    };
  },
  computed: {
    pinCodeValid() {
      return this.pinCode.length === this.numberOfDigit;
    },
  },
  mounted() {
    this.start();
  },
  methods: {
    sendSubmit() {
      if (this.pinCodeValid) {
        this.$emit('submit', this.pinCode);
      }
    },
    requestNewPin() {
      this.loaderVisibility = null;
      this.$emit('sendNewCode');
      const askCode = this.askCode
        ? WeChatClient.askPhoneConfirmation(this.phoneNumber)
        : WeChatClient.askPasswordForgotPinCode(this.phoneNumber);

      askCode
        .then(() => {
          this.loaderVisibility = false;
          this.start();
          this.$emit('receiveNewCode');
        })
        .catch((error) => {
          this.loaderVisibility = false;
          this.innerErrorMessage = error.message;
          this.$emit('receiveNewCode');
        });
    },
    start() {
      this.seconds = 45;
      const interval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
        } else {
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.pin-input {
  $padding-value: 1.25em;
  padding: 0 1.25em;
  flex: 1;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__intro {
    padding: 0 1.5em;
    font-size: 0.875em;
    margin-bottom: 2em;
  }

  &__pin-container {
    width: 100%;
    margin-bottom: 2em;
  }

  &__pin-digit {
    padding: 0 0.5em;
    flex: 1 0 0;
  }

  &__instructions {
    width: 100%;
    text-align: center;
    font-size: 0.875em;

    &--first {
      margin-top: -2em;
    }

    &--second,
    &--link {
      margin-bottom: 2em;
    }

    &--link {
      text-decoration: underline;
    }
  }

  &__error {
    font-size: 0.75em;
    font-style: italic;
    color: $color-danger;
    display: block;
    margin-top: 0.5em;
    text-align: center;
  }
}
</style>
