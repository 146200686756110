<template>
  <div class="base-grid">
    <div class="base-grid__container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseGrid',
};
</script>

<style scoped lang="scss">
.base-grid {
  &__container {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($breakpoint-tablet) {
      margin: -1.656em -1.375em;
    }
  }
}
</style>
