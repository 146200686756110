<template>
  <div class="complexity-validator">
    <div class="complexity-validator__container">
      <div class="complexity-validator__intro">
        {{ $t('registration-form.password.intro') }}
      </div>
      <div class="complexity-validator__list">
        <PasswordValidatorItem
          v-if="minCharNumber > 0"
          :is-valid="minLengthValidity"
          :text="minLengthLabel"
          class="complexity-validator__item"
        />
        <PasswordValidatorItem
          v-if="atLeastOneDigit"
          :is-valid="digitValidity"
          :text="$t('registration-form.password.numeric')"
          class="complexity-validator__item"
        />
        <PasswordValidatorItem
          v-if="atLeastMixCase"
          :is-valid="caseValidity"
          :text="$t('registration-form.password.mix-case')"
          class="complexity-validator__item"
        />
        <PasswordValidatorItem
          v-if="atLeastOneSpecialChar"
          :is-valid="speCharValidity"
          :text="$t('registration-form.password.spe-char')"
          class="complexity-validator__item"
        />
        <PasswordValidatorItem
          v-if="noTwiceSameConsecutiveChar"
          :is-valid="consecutiveCharValidity"
          :text="
            $t('registration-form.password.consecutive-identical-characters')
          "
          class="complexity-validator__item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PasswordValidatorItem from '@/components/form/password/PasswordValidatorItem';
export default {
  name: 'PasswordComplexityValidator',
  components: { PasswordValidatorItem },
  props: {
    suggestion: { type: String, default: '' },
    minCharNumber: {
      type: Number,
      default: 0,
    },
    atLeastMixCase: Boolean,
    atLeastOneDigit: Boolean,
    atLeastOneSpecialChar: Boolean,
    noTwiceSameConsecutiveChar: Boolean,
  },
  computed: {
    minLengthValidity() {
      return (
        this.minCharNumber === 0 || this.suggestion.length >= this.minCharNumber
      );
    },
    caseValidity() {
      const upperCaseValidity =
        !this.atLeastMixCase || /.*[A-Z]/.test(this.suggestion);
      const lowerCaseValidity =
        !this.atLeastMixCase || /.*[a-z]/.test(this.suggestion);
      return upperCaseValidity && lowerCaseValidity;
    },
    digitValidity() {
      return !this.atLeastOneDigit || /.*[0-9]/.test(this.suggestion);
    },
    speCharValidity() {
      return (
        !this.atLeastOneSpecialChar || /.*[~!@#$%&_\-+=`]/.test(this.suggestion)
      );
    },
    consecutiveCharValidity() {
      return (
        !this.noTwiceSameConsecutiveChar ||
        !/([a-zA-Z0-9])\1\1/.test(this.suggestion)
      );
    },
    minLengthLabel() {
      return this.$t('registration-form.password.min-length', {
        0: this.minCharNumber,
      });
    },
  },
  watch: {
    suggestion() {
      const isValid =
        this.minLengthValidity &&
        this.caseValidity &&
        this.digitValidity &&
        this.speCharValidity &&
        this.consecutiveCharValidity;
      this.$emit('isValid', isValid);
    },
  },
};
</script>

<style lang="scss" scoped>
.complexity-validator {
  &__container {
    font-size: 0.875em;
    max-width: 40em;
    margin: 0 auto;
  }

  &__intro {
    font-weight: bold;
    margin-bottom: 1em;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 100%;
  }
}
</style>
