<i18n>
{
  "fr": {
    "close": "fermer"
  },
  "en": {
    "close": "Close"
  }
}
</i18n>

<template>
  <transition :name="transitionName" appear v-on="transitionHooks">
    <div v-if="isActive" :class="className" class="base-modal">
      <slot name="backdrop">
        <div class="base-modal__backdrop" @click="backdropClose"></div>
      </slot>

      <div class="base-modal__container-wrapper">
        <div class="base-modal__container">
          <slot name="container">
            <div v-if="shouldDisplayHeader" :class="headerClasses">
              <slot name="header">
                <slot name="header-content">
                  <div
                    v-if="title || $slots['header-title']"
                    :class="titleClasses"
                  >
                    <slot name="header-title">
                      {{ title }}
                    </slot>
                  </div>
                </slot>

                <template v-if="!hideCloseButton">
                  <slot name="header-close-btn">
                    <button
                      :title="computedCloseLabel"
                      class="base-modal__close-btn"
                      type="button"
                      @click="closeModal"
                    >
                      <slot name="header-close-btn-label">
                        <span
                          v-if="computedCloseLabel"
                          class="base-modal__close-btn-label"
                        >
                          {{ computedCloseLabel }}
                        </span>
                      </slot>
                    </button>
                  </slot>
                </template>
              </slot>
            </div>

            <div
              v-if="$slots['subheader'] || $slots['subheader-content']"
              class="base-modal__subheader"
            >
              <slot name="subheader">
                <div class="base-modal__subheader-container">
                  <slot name="subheader-content"></slot>
                </div>
              </slot>
            </div>

            <div class="base-modal__body">
              <slot name="body">
                <div class="base-modal__body-container">
                  <slot></slot>
                </div>
              </slot>
            </div>

            <div
              v-if="$slots['footer'] || $slots['footer-content']"
              class="base-modal__footer"
            >
              <slot name="footer">
                <div class="base-modal__footer-container">
                  <slot name="footer-content"></slot>
                </div>
              </slot>
            </div>

            <div
              v-if="
                $slots['actions'] ||
                $slots['action-container'] ||
                $slots['action-toolbar']
              "
              class="base-modal__actions"
            >
              <slot name="actions">
                <div class="base-modal__actions-container">
                  <slot name="actions-container">
                    <BtnToolbar v-bind="actionToolbarProps">
                      <slot name="action-toolbar"></slot>
                    </BtnToolbar>
                  </slot>
                </div>
              </slot>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Modal from '../mixins/Modal';
import BtnToolbar from './BtnToolbar';

const BLOCK_SELECTOR = 'base-modal';
const HEADER_SELECTOR = 'base-modal__header';
const TITLE_SELECTOR = 'base-modal__header-title';

export default {
  name: 'BaseModal',
  components: { BtnToolbar },
  mixins: [Modal],
  model: {
    prop: 'isActive',
    event: 'close',
  },
  props: {
    transitionName: {
      type: String,
      default: `${BLOCK_SELECTOR}-`,
    },
    fullScreen: Boolean,
    // fullScreenUntilXxxs: Boolean,
    // fullScreenUntilXxs: Boolean,
    fullScreenUntilXs: Boolean,
    // fullScreenUntilS: Boolean,
    // fullScreenUntilM: Boolean,
    // fullScreenUntilL: Boolean,
    // fullScreenUntilXl: Boolean,
    // fullScreenUntilXxl: Boolean,
    // fullScreenUntilXxxl: Boolean,
    actionToolbarProps: {
      type: Object,
      default: null,
    },
    afterEnterCb: {
      type: Function,
      default: null,
    },
    afterLeaveCb: {
      type: Function,
      default: null,
    },
    leftTitle: Boolean,
    bigTitle: Boolean,
    centeredTitle: Boolean,
  },
  data() {
    return {
      BLOCK_SELECTOR,
      HEADER_SELECTOR,
      TITLE_SELECTOR,
    };
  },
  computed: {
    /**
     *
     * @return {string}
     */
    computedCloseLabel() {
      return (
        (this.closeLabel && this.$t && this.$t(this.closeLabel)) ||
        this.closeLabel
      );
    },
    shouldDisplayHeader() {
      return (
        !this.hideCloseButton ||
        !!this.$slots['header'] ||
        !!this.$slots['header-content'] ||
        this.title ||
        !!this.$slots['header-title']
      );
    },
    className() {
      return {
        [`${BLOCK_SELECTOR}--full-screen`]: this.fullScreen,
        // [`${BLOCK_SELECTOR}--full-screen-until-xxxs`]: this.fullScreenUntilXxxs,
        // [`${BLOCK_SELECTOR}--full-screen-until-xxs`]: this.fullScreenUntilXxs,
        [`${BLOCK_SELECTOR}--full-screen-until-xs`]: this.fullScreenUntilXs,
        // [`${BLOCK_SELECTOR}--full-screen-until-s`]: this.fullScreenUntilS,
        // [`${BLOCK_SELECTOR}--full-screen-until-m`]: this.fullScreenUntilM,
        // [`${BLOCK_SELECTOR}--full-screen-until-l`]: this.fullScreenUntilL,
        // [`${BLOCK_SELECTOR}--full-screen-until-xl`]: this.fullScreenUntilXl,
        // [`${BLOCK_SELECTOR}--full-screen-until-xxl`]: this.fullScreenUntilXxl,
        // [`${BLOCK_SELECTOR}--full-screen-until-xxxl`]: this.fullScreenUntilXxxl,
      };
    },
    headerClasses() {
      return [
        HEADER_SELECTOR,
        {
          [`${HEADER_SELECTOR}--left`]: this.leftTitle,
          [`${HEADER_SELECTOR}--center`]: this.centeredTitle,
        },
      ];
    },
    titleClasses() {
      return [TITLE_SELECTOR, { [`${TITLE_SELECTOR}--big`]: this.bigTitle }];
    },
    transitionHooks() {
      const hooks = {};

      ['afterEnter', 'afterLeave'].forEach((k) => {
        if (this[`${k}Cb`]) {
          hooks[k] = this[`${k}Cb`];
        }
      });

      return hooks;
    },
  },
  methods: {
    backdropClose() {
      if (this.closeOnBackdropClick) {
        this.$emit('close');
      }
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-modal {
  $block-selector: &;
  // Default values
  $base-modal-z-index: 1000 !default;
  $base-modal-backdrop-background: rgba($color-shuttle-gray, 0.5) !default;
  $base-modal-background: $color-default-background !default;
  $base-modal-container-border-radius-map: (
    default: 0,
  ) !default;
  $base-modal-container-wrapper-margin-map: (
    default: 0.5em,
    $breakpoint-m: 2em,
  ) !default;
  $base-modal-section-container-margin-map: (
    default: 0.5em,
    $breakpoint-m: 2.5em,
  );
  $base-modal-full-screen-available-breakpoint-map: (
    //xxxs: $breakpoint-xxxs,
    //xxs: $breakpoint-xxs,
    xs: $breakpoint-xs,
    //s: $breakpoint-s,
    //m: $breakpoint-m,
    //l: $breakpoint-l,
    //xl: $breakpoint-xl,
    //xxl: $breakpoint-xxl,
    //xxxl: $breakpoint-xxxl,
  );
  $base-modal-allow-container-content-overflow: true !default;
  $base-modal-transition-duration: $default-transition-duration !default;
  $base-modal-transition-scale-delta: 0.05 !default;
  $base-modal-transition-full-screen-transform: translateY(1em);
  $base-modal-actions-margin: 1em !default;
  $base-modal-btn-close-padding: 1em !default;

  position: fixed;
  z-index: $base-modal-z-index;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  // Add transition on the root element so <transition> can detect the end of the transition
  transition: visibility 0s $base-modal-transition-duration;

  &--full-screen {
    // Nothing to do here, just leaving empty selectors so class is available
    // in autocomplete suggestions
  }

  &__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $base-modal-backdrop-background;

    #{$block-selector}--enter &,
    #{$block-selector}--leave-to & {
      opacity: 0;
    }

    #{$block-selector}--enter-active &,
    #{$block-selector}--leave-active & {
      transition: opacity $base-modal-transition-duration;
    }

    @at-root %hide-backdrop {
      display: none;
    }

    #{$block-selector}--full-screen & {
      @extend %hide-backdrop;
    }

    @each $size, $bp in $base-modal-full-screen-available-breakpoint-map {
      #{$block-selector}--full-screen-until-#{$size} & {
        @extend %hide-backdrop;

        @include breakpoint($bp) {
          display: block;
        }
      }
    }
  }

  &__container-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex: 1;
    display: flex;
    pointer-events: none;

    @include map-breakpoint($base-modal-container-wrapper-margin-map)
      using($breakpoint, $value) {
      margin: $value;
    }

    @at-root %container-wrapper-full-screen-margin {
      margin: 0;
    }

    #{$block-selector}--full-screen & {
      @extend %container-wrapper-full-screen-margin;
    }

    @each $size, $bp in $base-modal-full-screen-available-breakpoint-map {
      #{$block-selector}--full-screen-until-#{$size} & {
        @extend %container-wrapper-full-screen-margin;

        @include breakpoint($bp) {
          @include map-breakpoint($base-modal-container-wrapper-margin-map)
            using($breakpoint, $value) {
            margin: $value;
          }
        }
      }
    }

    #{$block-selector}--enter &,
    #{$block-selector}--leave-to & {
      opacity: 0;
    }

    @if ($base-modal-transition-scale-delta) {
      #{$block-selector}--enter & {
        transform: scale(1 + $base-modal-transition-scale-delta);
      }

      #{$block-selector}--leave-to & {
        transform: scale(1 - $base-modal-transition-scale-delta);
      }
    }

    @at-root %full-screen-transform {
      transform: $base-modal-transition-full-screen-transform;
    }

    #{$block-selector}--enter#{$block-selector}--full-screen &,
    #{$block-selector}--leave-to#{$block-selector}--full-screen & {
      @extend %full-screen-transform;
    }

    @each $size, $bp in $base-modal-full-screen-available-breakpoint-map {
      @if ($base-modal-transition-scale-delta) {
        #{$block-selector}--enter#{$block-selector}--full-screen-until-#{$size}
          & {
          @extend %full-screen-transform;

          @include breakpoint($bp) {
            transform: scale(1 + $base-modal-transition-scale-delta);
          }
        }

        #{$block-selector}--leave-to#{$block-selector}--full-screen-until-#{$size}
          & {
          @extend %full-screen-transform;

          @include breakpoint($bp) {
            transform: scale(1 - $base-modal-transition-scale-delta);
          }
        }
      }
    }

    #{$block-selector}--enter-active &,
    #{$block-selector}--leave-active & {
      transition-property: opacity, transform;
      transition-duration: $base-modal-transition-duration;
    }
  }

  &__container {
    background: $base-modal-background;
    position: relative; // Set container on top of backdrop (absolute positioning) by creating a stacking context
    display: flex;
    flex-direction: column;
    max-height: 100%;
    margin: auto;
    pointer-events: auto;
    overflow: hidden;
    @if not($base-modal-allow-container-content-overflow) {
      overflow: auto;
    }

    @include map-breakpoint($base-modal-container-border-radius-map)
      using($breakpoint, $value) {
      border-radius: $value;
    }

    @at-root %container-full-screen {
      border-radius: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }

    #{$block-selector}--full-screen & {
      @extend %container-full-screen;
    }

    @each $size, $bp in $base-modal-full-screen-available-breakpoint-map {
      #{$block-selector}--full-screen-until-#{$size} & {
        @extend %container-full-screen;

        @include breakpoint($bp) {
          height: auto;
          width: auto;
          margin: auto;

          @include map-breakpoint($base-modal-container-border-radius-map)
            using($breakpoint, $value) {
            border-radius: $value;
          }
        }
      }
    }
  }

  &__header {
    flex: none;
    margin: 1em #{1em + $base-modal-btn-close-padding * 2};
    min-height: 1em; // Close button height

    &--left {
      margin: 0.75rem 1rem 0;
    }
  }

  &__header-title {
    text-align: center;

    #{$block-selector}__header & {
      text-align: left;
    }

    #{$block-selector}__header--center & {
      text-align: center;
    }

    &--big {
      text-transform: uppercase;
      font-size: 1.25rem;
    }
  }

  &__body {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain; // See https://developer.mozilla.org/fr/docs/Web/CSS/overscroll-behavior

    height: 100%;
    @at-root %body-full-height {
      flex: 1;
      min-height: 0;
    }

    #{$block-selector}--full-screen & {
      @extend %body-full-height;
    }

    @each $size, $bp in $base-modal-full-screen-available-breakpoint-map {
      #{$block-selector}--full-screen-until-#{$size} & {
        @extend %body-full-height;

        @include breakpoint($bp) {
          flex: 0 1 auto;
        }
      }
    }
  }

  &__body-container {
    padding: 0 0.5rem 0.5rem;
    @include map-breakpoint($base-modal-section-container-margin-map)
      using($breakpoint, $value) {
      margin: $value;
    }
  }

  &__subheader,
  &__footer,
  &__actions {
    flex: none;
  }

  &__subheader-container,
  &__footer-container,
  &__actions-container {
    margin: $base-modal-actions-margin;
  }

  &__close-btn {
    position: absolute;
    right: 0;
    top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: $base-modal-btn-close-padding;
    margin-left: auto;
    border: 0;
    color: inherit;
    line-height: inherit;
    text-align: center;
    text-decoration: none;
    text-transform: inherit;
    background: none;
    font-weight: inherit;
    font-style: normal;
    cursor: pointer;
    border-radius: 0;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: $color-secondary;
      outline: none;
    }

    &::before {
      @extend %icon;
      @extend %icon--close;
      display: block;
    }
  }

  &__close-btn-label {
    @include sr-only();
  }
}
</style>
