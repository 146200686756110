<template>
  <div class="onboarding-render" v-show="isVisible">
    <VACCheckboxGroup
      v-if="
        element['#type'] === OBJECT_TYPE.CHECKBOXES ||
        element['#type'] === OBJECT_TYPE.CHECKBOXES_TOGGLE ||
        element['#type'] === OBJECT_TYPE.TAG_CHECKBOXES
      "
      :label="element['#title']"
      :options="options"
      :toggle="element['#type'] === OBJECT_TYPE.CHECKBOXES_TOGGLE"
      :tag="element['#type'] === OBJECT_TYPE.TAG_CHECKBOXES"
      :name="element['#name']"
      @input="handleChange"
      v-model="inputValue"
    />
    <p
      class="onboarding-render__content"
      v-if="element['#type'] === OBJECT_TYPE.MARKUP"
    >
      {{ element.value }}
    </p>
    <FieldImageCheckbox
      v-if="element['#type'] === OBJECT_TYPE.IMAGE_CHECKBOXES"
      v-model="inputValue"
      :name="element['#name']"
      label=""
      :options="options"
      :is-nullable="true"
    />
    <VACInput
      v-if="element['#type'] === OBJECT_TYPE.TEXTFIELD && isVisible"
      v-model="inputValue"
      type="text"
      :name="element['#name']"
      :label="element['#title'] ?? ''"
      :placeholder="element['#placeholder']"
    />
    <VACRadioGroup
      v-if="element['#type'] === OBJECT_TYPE.RADIOS"
      :name="element['#name']"
      :label="element['#title']"
      appearance="vertical"
      v-model="inputValue"
      :options="options"
    />
    <p
      class="onboarding-render__description"
      v-if="element['#description'] && element['#description']['#markup']"
    >
      <VACNotificationStatus
        :pending-message="$t('account.notifications.status.pending')"
        :denied-message="$t('account.notifications.status.denied')"
        :granted-message="$t('account.notifications.status.granted')"
      />
    </p>
  </div>
</template>
<script>
const OBJECT_TYPE = Object.freeze({
  CHECKBOXES: 'checkboxes',
  CHECKBOXES_TOGGLE: 'toggle_checkboxes',
  IMAGE_CHECKBOXES: 'image_checkboxes',
  TAG_CHECKBOXES: 'tag_checkboxes',
  MARKUP: 'markup',
  TEXTFIELD: 'textfield',
  RADIOS: 'radios',
});
import Onboarding from '@/mixins/Onboarding';
import FieldImageCheckbox from '../form/FieldImageCheckbox.vue';
import {
  VACCheckboxGroup,
  VACInput,
  VACRadioGroup,
  VACNotificationStatus,
} from '@webqam-vac-ui/lib';
export default {
  name: 'OnboardingRender',
  components: {
    VACCheckboxGroup,
    FieldImageCheckbox,
    VACInput,
    VACRadioGroup,
    VACNotificationStatus,
  },
  mixins: [Onboarding],
  props: {
    value: {
      type: [String, Array, Boolean, Number, Object],
    },
    element: {
      type: Object,
      required: true,
    },
    allResponses: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      OBJECT_TYPE,
    };
  },
  computed: {
    isVisible() {
      if (this.element['#depends_on']) {
        if (this.element['#depends_on']['type'].startsWith('is:')) {
          const requiredValue =
            this.element['#depends_on']['type'].split(':')[1];
          return requiredValue === '[]'
            ? this.allResponses[this.element['#depends_on']['origin']] &&
                this.allResponses[this.element['#depends_on']['origin']]
                  .length === 0
            : Array.isArray(
                this.allResponses[this.element['#depends_on']['origin']]
              )
            ? this.allResponses[this.element['#depends_on']['origin']].includes(
                requiredValue
              )
            : this.allResponses[this.element['#depends_on']['origin']] ===
              requiredValue;
        }
      }
      return true;
    },
    options() {
      let baseElements = this.element['#options'];
      if (
        [
          OBJECT_TYPE.TAG_CHECKBOXES,
          OBJECT_TYPE.CHECKBOXES_TOGGLE,
          OBJECT_TYPE.CHECKBOXES,
          OBJECT_TYPE.IMAGE_CHECKBOXES,
          OBJECT_TYPE.RADIOS,
        ].includes(this.element['#type'])
      ) {
        if (this.element['#depends_on']) {
          const dependency = this.element['#depends_on'];
          if (this.allResponses && this.allResponses[dependency.origin]) {
            if (dependency.type === 'same_value') {
              baseElements = this.element['#options'].filter((item) =>
                this.allResponses[dependency.origin].includes(`${item.value}`)
              );
            }
          }
        }
        return baseElements.map((item) => ({
          label: item.label,
          inputValue: `${item.value}`,
          src: item.src,
          value: `${item.value}`,
        }));
      }
      return this.element.options;
    },
    inputValue: {
      get() {
        if (!this.value) {
          return this.element['#type'] === OBJECT_TYPE.TEXTFIELD ? '' : {};
        }
        if (
          Array.isArray(this.value) &&
          [
            OBJECT_TYPE.TAG_CHECKBOXES,
            OBJECT_TYPE.CHECKBOXES_TOGGLE,
            OBJECT_TYPE.CHECKBOXES,
            OBJECT_TYPE.IMAGE_CHECKBOXES,
          ].includes(this.element['#type'])
        ) {
          return this.value.reduce(
            (acc, item) => ({ ...acc, [item]: true }),
            {}
          );
        }
        return this.value;
      },
      set(newVal) {
        if (
          [
            OBJECT_TYPE.TAG_CHECKBOXES,
            OBJECT_TYPE.CHECKBOXES_TOGGLE,
            OBJECT_TYPE.CHECKBOXES,
            OBJECT_TYPE.IMAGE_CHECKBOXES,
          ].includes(this.element['#type'])
        ) {
          this.$emit(
            'input',
            Object.keys(newVal).filter((key) => newVal[key] === true)
          );
        } else {
          this.$emit('input', newVal);
        }
      },
    },
  },
  methods: {
    handleChange() {
      if (this.element['#name'] === 'content_types_subscribe') {
        this.$OneSignal.registerForPushNotifications();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.onboarding-render {
  &__description {
    margin-top: 0.5rem;
    &:deep(.vac-icon) {
      margin-right: 0.25rem;
    }
  }
}
</style>
