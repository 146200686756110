<template>
  <div :class="blockClasses">
    <div class="event-badge__text">
      {{ $t('card_badge.' + status) }}
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'event-badge';
export default {
  name: 'EventBadge',
  props: {
    status: { type: String, default: undefined },
  },
  computed: {
    blockClasses() {
      return [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${this.status}`];
    },
  },
};
</script>

<style scoped lang="scss">
.event-badge {
  color: $color-white;
  font-family: $font-family-prelo-condensed;
  padding-left: 2em;
  padding-right: 2em;
  text-align: center;
  transform: rotate(45deg);
  width: 8em;

  &--booked {
    background-color: $color-beaver;
  }

  &--full,
  &--close {
    background-color: $color-dove-gray;
  }

  &--open {
    display: none;
  }
}
</style>
