<template>
  <div class="onboarding-intro">
    <div class="onboarding-intro__main">
      <div
        class="onboarding-intro__container onboarding-intro__container--medias"
      >
        <img
          class="onboarding-intro__media onboarding-intro__media--image"
          :src="startBack"
        />
        <div class="onboarding-intro__media onboarding-intro__media--video">
          <img :src="startFront" />
          <!-- <iframe
            class="onboarding-intro__video-player"
            src="https://www.youtube.com/embed/LbWqWb4b_xQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          -->
          <p class="onboarding-intro__video-title">
            {{ $t('onboarding.intro.videoTitle') }}
          </p>
        </div>
      </div>
      <div
        class="onboarding-intro__container onboarding-intro__container--bottom"
      >
        <div class="onboarding-intro__header">
          <span class="onboarding-intro__icon" />
          <p class="onboarding-intro__title">
            {{ $t('onboarding.intro.title') }}
          </p>
        </div>
        <p class="onboarding-intro__content">
          {{ $t('onboarding.intro.content') }}
        </p>
        <p class="onboarding-intro__content">
          <b>
            {{
              $t('onboarding.intro.count', {
                questions: onboardingQuestionCount,
                time: onboardingTime,
              })
            }}
          </b>
          <br />
          {{ $t('onboarding.intro.thanks') }}
        </p>
        <VACButton
          v-if="$matchMedia.xl"
          class="onboarding-intro__cta"
          primary
          icon="arrow-right"
          :label="$t('onboarding.start')"
          small
          @click.native="$emit('start')"
        />
      </div>
    </div>
    <div v-if="!$matchMedia.xl" class="onboarding-intro__footer">
      <VACButton
        class="onboarding-intro__cta"
        primary
        icon="arrow-right"
        :label="$t('onboarding.start')"
        small
        @click.native="$emit('start')"
      />
    </div>
  </div>
</template>
<script>
import Onboarding from '@/mixins/Onboarding';
import { VACButton } from '@webqam-vac-ui/lib';
import startBack from '../../assets/onboarding/onboarding-start.png';
import startFront from '../../assets/onboarding/onboarding-start-video-placeholder.png';

export default {
  name: 'OnboardingIntro',
  components: { VACButton },
  mixins: [Onboarding],
  data() {
    return {
      startBack,
      startFront,
    };
  },
};
</script>
<style scoped lang="scss">
.onboarding-intro {
  height: auto;
  @include breakpoint($breakpoint-s) {
    padding-bottom: 2rem;
  }
  @include breakpoint($breakpoint-m) {
    padding-bottom: 4rem;
  }
  @include breakpoint($breakpoint-desktop) {
    height: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    @include breakpoint($breakpoint-desktop) {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      place-items: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    @include breakpoint($breakpoint-desktop) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__icon {
    font-size: 4em;
    display: inline-flex;

    @include breakpoint($breakpoint-desktop) {
      font-size: 6em;
    }

    &::before {
      @extend %icon;
      @extend %icon--thc-big;
    }
  }

  &__container {
    height: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;

    @include breakpoint($breakpoint-desktop) {
      flex-basis: 50%;
    }

    &--bottom {
      margin-top: auto;
      @include breakpoint($breakpoint-desktop) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 40%;
      }
    }

    &--medias {
      position: relative;
      width: 100%;
      min-height: 80vw;
      aspect-ratio: 1 / 1;
      @include breakpoint($breakpoint-s) {
        aspect-ratio: unset;
        min-height: 50vh;
        margin-bottom: 2rem;
      }

      @include breakpoint($breakpoint-m) {
        aspect-ratio: unset;
        min-height: 60vh;
      }

      @include breakpoint($breakpoint-desktop) {
        min-height: unset;
        aspect-ratio: unset;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-prelo-condensed;
    font-size: 1.25em;
    margin-bottom: 0;

    @include breakpoint($breakpoint-desktop) {
      font-size: 2em;
    }
  }
  &__media {
    position: absolute;

    &--image {
      left: 0;
      top: 0;
      bottom: 2rem;
      width: 50%;
      @include breakpoint($breakpoint-s) {
        width: 40%;
        left: 20%;
      }
      @include breakpoint($breakpoint-desktop) {
        left: 0;
        width: 50%;
      }
    }

    &--video {
      right: 0;
      bottom: 0;
      height: 95%;
      width: 95%;
      display: flex;
      flex-direction: column;
      @include breakpoint($breakpoint-s) {
        right: 20%;
        width: 50%;
        height: 60%;
        bottom: 2rem;
      }

      @include breakpoint($breakpoint-desktop) {
        bottom: unset;
        right: 0;
        top: 2rem;
        height: 80%;
        width: 95%;
      }
    }
  }

  &__video {
    &-player {
      width: 100%;
      flex-grow: 1;
    }

    &-title {
      margin-bottom: 0;
    }
  }

  &__cta {
    width: 100%;
    text-transform: uppercase;

    @include breakpoint($breakpoint-desktop) {
      width: auto;
    }
  }

  &__footer {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
</style>
