<template>
  <transition :name="transitionName">
    <VACBackToTop v-if="display" class="back-to-top" @click="goToTop" />
  </transition>
</template>

<script>
import { VACBackToTop } from '@webqam-vac-ui/lib';

const BLOCK_SELECTOR = 'back-to-top';
const THRESHOLD = 200;

export default {
  name: 'BackToTop',
  components: { VACBackToTop },
  props: {
    transitionName: {
      type: String,
      default: `${BLOCK_SELECTOR}-`,
    },
  },
  data() {
    return {
      display: false,
    };
  },
  mounted() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      this.display = window.pageYOffset > THRESHOLD;
    },
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: 10%;
  right: 10%;
  z-index: 5;
  display: none;

  @include breakpoint($breakpoint-tablet) {
    display: flex;
  }

  &--enter-active,
  &--leave-active {
    transition-duration: $default-transition-duration;
    transition-property: opacity;
  }

  &--enter,
  &--leave-to {
    opacity: 0;
  }

  &--enter-to,
  &--leave {
    opacity: 1;
  }
}
</style>
