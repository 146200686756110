import Internationalization, {
  LOCALE_ARABIC,
  LOCALE_CHINESE,
  LOCALE_CHINESE_TAIWAN,
  LOCALE_ENGLISH,
  LOCALE_FRENCH,
  LOCALE_GERMAN,
  LOCALE_ITALIAN,
  LOCALE_JAPANESE,
  LOCALE_KOREAN,
  LOCALE_PORTUGUESE,
  LOCALE_RUSSIAN,
  LOCALE_SPANISH,
} from '@/utils/Internationalization';

export default {
  data() {
    return {
      regionsLanguages: {
        Int: [
          LOCALE_ARABIC,
          LOCALE_CHINESE_TAIWAN,
          LOCALE_CHINESE,
          LOCALE_ENGLISH,
          LOCALE_FRENCH,
          LOCALE_GERMAN,
          LOCALE_ITALIAN,
          LOCALE_JAPANESE,
          LOCALE_KOREAN,
          LOCALE_PORTUGUESE,
          LOCALE_RUSSIAN,
          LOCALE_SPANISH,
        ],
        CN: [LOCALE_ENGLISH, LOCALE_CHINESE_TAIWAN],
      },
    };
  },
  computed: {
    /**
     * @return {string}
     */
    region() {
      const country = Internationalization.getCountry().toUpperCase();

      return 'CN' === country ? country : 'Int';
    },
    /**
     * @return {string}
     */
    language() {
      return Internationalization.getCurrentLocale();
    },
  },
};
