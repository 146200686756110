<template>
  <div class="account-posts">
    <PageLoader v-if="isFetching" />
    <div v-else class="account-posts__container">
      <SetPseudonymModal
        v-if="showPseudonymDialog"
        @close="pseudonymDialogCallback"
      />
      <VACButton
        v-if="!isProfilePreview"
        type="button"
        appearance="link"
        class="magazine-article__back-btn"
        @click="goBack"
        icon="arrow-left"
        reverse
        :label="$t('wizard.header.back')"
      >
        {{ $t('wizard.header.back') }}
      </VACButton>
      <BaseTitle :level="1" class="account-posts__title">
        {{ pageTitle }}
      </BaseTitle>
      <template v-if="isProfilePublic || isProfilePreview">
        <AccountContentBlock v-if="isProfilePreview || canSeeSocial">
          <template #header-left>
            <BaseTitle class="account-posts__block-title" :level="2">
              {{
                isProfilePreview
                  ? $t('accountPosts.myStories')
                  : $t('accountPosts.stories')
              }}
            </BaseTitle>
          </template>
          <template #header-right>
            <RouterLink
              v-if="stories.length > 3"
              class="account-posts__link"
              :to="storiesLink"
            >
              <VACButton
                appearance="link"
                icon
                :label="
                  $t('accountPosts.seeAllStories', { count: totalStories })
                "
              />
            </RouterLink>
          </template>
          <template #default>
            <div v-if="stories.length > 0" class="account-posts__grid">
              <div
                v-for="story in storiesToShow"
                :key="story.ID"
                class="account-posts__grid-item-container"
              >
                <GoldenBookCard
                  :id="story.ID"
                  class="account-posts__grid-item"
                  :title="story.title"
                  with-counters
                  :comment-counter="story.count_conversation"
                  :like-counter="story.count_like"
                  :author="story.author.pseudo"
                  :author-id="story.author.ID"
                  :background-image="
                    story['images-portrait'][0]
                      ? story['images-portrait'][0].x1
                      : ''
                  "
                  light-social
                  :with-story="!!story.description"
                  :i-like="story.i_like"
                  :show-authoring-options="isProfilePreview"
                  @deleted="handlePostDeletion(story.ID)"
                />
              </div>
            </div>
            <div v-else-if="isProfilePreview" class="account-posts__empty">
              {{ $t('accountPosts.noPosts') }}
              <VACButton
                @click="handleShareClick"
                :label="$t('accountPosts.createFirst')"
              />
            </div>
          </template>
        </AccountContentBlock>
        <AccountContentBlock
          v-if="(isProfilePreview || canSeeSocial) && conversations.length > 0"
        >
          <template #header-left>
            <BaseTitle class="account-posts__block-title" :level="2">
              {{
                isProfilePreview
                  ? $t('accountPosts.myConversations')
                  : $t('accountPosts.conversations')
              }}
            </BaseTitle>
          </template>
          <template #header-right>
            <RouterLink
              v-if="totalConversations > 3"
              class="account-posts__link"
              :to="conversationsLink"
            >
              <VACButton
                appearance="link"
                icon
                :label="
                  $t('accountPosts.seeAllConversations', {
                    count: totalConversations,
                  })
                "
              />
            </RouterLink>
          </template>
          <template #default>
            <div class="account-posts__conversations">
              <ConversationSummaryCard
                v-for="conversation in conversations"
                :key="conversation.parent_id"
                class="account-posts__conversation"
                :node-id="conversation.parent_id"
                :node-type="conversation.parent_type"
                :name="conversation.parent_title"
                :image="conversation.image[0].x1"
                :interactions="generateInteraction(conversation)"
              />
            </div>
          </template>
        </AccountContentBlock>
        <RouterLink
          v-if="profileLink && isProfilePreview"
          :to="profileLink"
          class="account-posts__see-public-link"
        >
          <VACButton
            class="account-posts__see-public"
            @click="handleViewPublicProfile"
            :label="$t('accountPosts.seePublic')"
          />
        </RouterLink>
      </template>
      <template v-else>
        <p class="account-posts__private">
          {{ $t('accountPosts.private') }}
        </p>
        <img :src="require('@/assets/private.png')" alt="private profile" />
      </template>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@/components/BaseTitle';
import AccountContentBlock from '@/components/account/AccountContentBlock';

import ContentAPI from '@/api/ContentAPI';
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';
import PageLoader from '@/components/PageLoader';
import RouteName from '@/utils/RouteName';
import { VACButton } from '@webqam-vac-ui/lib';
import ConversationSummaryCard from '@/components/conversation/ConversationSummaryCard';
import SetPseudonymModal from '@/components/conversation/SetPseudonymModal';
import Auth from '@/mixins/Auth';
import { pushToDataLayer } from '@/utils/GTMMaker';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountPosts',
  components: {
    ConversationSummaryCard,
    VACButton,
    PageLoader,
    GoldenBookCard,
    AccountContentBlock,
    BaseTitle,
    SetPseudonymModal,
  },
  mixins: [Auth, Tracking],
  props: {
    profileId: { type: String, default: undefined },
  },
  data() {
    return {
      stories: [],
      totalStories: undefined,
      conversations: [],
      totalConversations: undefined,
      isFetching: true,
      userProfile: undefined,
      RouteName,
      showPseudonymDialog: false,
    };
  },
  computed: {
    userId() {
      return this.profileId ? this.profileId : this.userProfile.id;
    },
    storiesToShow() {
      if (
        this.$matchMedia.s &&
        !this.$matchMedia.xl &&
        this.stories.length > 2
      ) {
        return this.stories.slice(0, 2);
      }
      if (this.stories.length > 3) {
        return this.stories.slice(0, 3);
      }
      return this.stories;
    },
    isProfilePreview() {
      return this.$route.name === RouteName.ACCOUNT_POSTS;
    },
    isProfilePublic() {
      return this.canSeeSocial;
    },
    canSeeSocial() {
      return (
        this.isProfilePreview ||
        (this.profileId &&
          this.userProfile &&
          this.userProfile.share_social === '1')
      );
    },
    profileLink() {
      if (this.userProfile) {
        return {
          name: RouteName.USER_PROFILE,
          params: {
            profileId: this.userId,
          },
        };
      }
      return undefined;
    },
    storiesLink() {
      if (this.isProfilePreview) {
        return {
          name: RouteName.ACCOUNT_STORIES,
        };
      } else {
        return {
          name: RouteName.USER_STORIES,
          params: {
            profileId: this.userId,
          },
        };
      }
    },
    conversationsLink() {
      if (this.isProfilePreview) {
        return {
          name: RouteName.ACCOUNT_CONVERSATIONS,
        };
      } else {
        return {
          name: RouteName.USER_CONVERSATIONS,
          params: {
            profileId: this.userId,
          },
        };
      }
    },
    pageTitle() {
      return this.isProfilePreview
        ? this.$t('accountPosts.title')
        : this.userProfile.pseudo;
    },
  },
  watch: {
    $route() {
      this.stories = [];
      this.totalStories = undefined;
      this.conversations = [];
      this.userProfile = undefined;
      this.isFetching = true;
      this.initComponent();
    },
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async initComponent() {
      this.isFetching = true;
      if (!this.isProfilePreview) {
        if (this.profileId) {
          const { data } = await ContentAPI.getProfile(this.profileId);
          this.userProfile = data;
        } else {
          this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
        }
      } else {
        this.userProfile = this.$store.state.auth.userInfos;
      }

      this.trackPageView(
        this.isProfilePreview
          ? this.$t('accountPosts.title')
          : this.userProfile.pseudo
      );

      await this.fetchContent();
      this.isFetching = false;
    },
    async fetchContent() {
      this.isFetching = true;
      await Promise.all([
        new Promise((resolve) => {
          this.fetchStories().then(() => {
            resolve();
          });
        }),
        new Promise((resolve) => {
          this.fetchConversations().then(() => {
            resolve();
          });
        }),
      ]);
      this.isFetching = false;
    },
    async fetchStories() {
      if (this.isProfilePreview || this.canSeeSocial) {
        const { data } = await ContentAPI.getStories({
          user: this.userId,
        });
        this.totalStories = data.count;
        this.stories = data.nodes;
      }
    },
    async fetchConversations() {
      if (this.isProfilePreview || this.canSeeSocial) {
        const { data } = await ContentAPI.getUserConversations(this.userId);
        if (data.paragraphs.length > 2) {
          this.conversations = data.paragraphs.slice(0, 2);
        } else {
          this.conversations = data.paragraphs;
        }
        this.totalConversations = data.count;
      }
    },
    handlePostDeletion(id) {
      this.stories = this.stories.filter((story) => story.ID !== id);
      this.totalStories--;
    },
    generateInteraction(conversation) {
      return [
        {
          texte: conversation.texte,
          author: conversation.author,
          date: conversation.date,
          number_reply: conversation.number_reply,
          deleted: '0',
        },
      ];
    },
    handleShareClick() {
      if (this.userHasSetPseudonym) {
        this.$router.push({ name: RouteName.GOLDEN_BOOK_SHARE_STORY });
      } else {
        this.showPseudonymDialog = true;
      }
    },
    pseudonymDialogCallback() {
      this.showPseudonymDialog = false;
      if (this.userHasSetPseudonym) {
        this.$router.push({ name: RouteName.GOLDEN_BOOK_SHARE_STORY });
      }
    },
    handleViewPublicProfile() {
      pushToDataLayer({
        event: 'viewMyPublicProfil',
      });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${
        this.isProfilePreview
          ? this.$t('accountPosts.title')
          : this.userProfile
          ? this.$t('accountPosts.profileOf', { name: this.userProfile.pseudo })
          : ''
      }`,
    };
  },
};
</script>

<style scoped lang="scss">
.account-posts {
  &__title {
    font-family: $font-family-prelo-condensed;
    text-align: center;
  }

  &__grid {
    position: relative;

    @extend %story-card-grid;
  }

  &__grid-item-container {
    @extend %story-card-container;
  }

  &__grid-item {
    @extend %story-card;
  }

  &__block-title {
    font-size: 1em;
    text-transform: uppercase;
  }

  &__see-public {
    margin-top: -2em;
    margin-bottom: 2em;
    @include breakpoint($breakpoint-tablet) {
      width: 16.438em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__container {
    @extend %base-container;
    position: relative;
  }

  &__back-btn {
    @extend %back-btn;

    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @include breakpoint($breakpoint-tablet) {
      left: 3.438em;
      display: flex;
    }

    @include breakpoint($breakpoint-desktop) {
      left: 0;
    }
  }

  &__conversations {
    margin-left: -1em;
    margin-right: -1em;

    @include breakpoint($breakpoint-tablet) {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  &__conversation {
    margin-bottom: 1em;
    flex-basis: calc(50% - 1em);
    margin-left: 1em;
    margin-right: 1em;
    z-index: 2;

    @include breakpoint($breakpoint-tablet) {
      margin-bottom: 0;
    }
  }

  &__private {
    text-align: center;
  }

  &__see-public-link {
    margin: 0 auto;
    display: block;
    width: fit-content;
  }

  &__empty {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    background-color: $color-wild-sand;

    @include breakpoint($breakpoint-tablet) {
      min-height: 20em;
    }

    & .base-btn {
      margin-top: 1em;
    }
  }
}
</style>
