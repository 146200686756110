<template>
  <div class="account-notifications">
    <VACExpandableSection
      is-open
      large
      header-small-spacing
      class="account-notification__section"
    >
      <template #title>
        {{ $t('account.notifications.title') }}
      </template>
      <template #body>
        <div class="account-notifications__content">
          <p class="account-notifications__description">
            {{ $t('account.notifications.description') }}
          </p>
          <VACNotificationStatus
            :pending-message="$t('account.notifications.status.pending')"
            :denied-message="$t('account.notifications.status.denied')"
            :granted-message="$t('account.notifications.status.granted')"
          />
          <div class="account-notifications__groups" v-if="notificationsInfos">
            <div
              class="account-notifications__group"
              v-for="(value, key) in notificationsGroup"
              :key="key"
            >
              <p class="account-notifications__group-title">
                {{ $t(`account.notifications.groupTitle.${key}`) }}
              </p>
              <VACCheckbox
                v-for="(item, index) in value"
                :key="`${key}-${index}-${item}`"
                :label="$t(`account.notifications.type.${item}`)"
                :name="item"
                :inputValue="item"
                :disabled="isLoading"
                toggle
                v-model="notificationsInfos[item]"
              ></VACCheckbox>
            </div>
          </div>
          <VACButton
            v-if="!isLoading"
            @click="handleClick"
            :label="$t('account.notifications.save')"
          />
          <VACLoader v-else />
          <SuccessAlert v-if="showSuccess">
            {{ $t('account.notifications.saved') }}
          </SuccessAlert>
        </div>
      </template>
    </VACExpandableSection>
  </div>
</template>

<script>
import {
  VACExpandableSection,
  VACButton,
  VACCheckbox,
  VACNotificationStatus,
  VACLoader,
} from '@webqam-vac-ui/lib';
import SuccessAlert from '../alert/SuccessAlert.vue';
export default {
  name: 'AccountNotifications',
  components: {
    VACExpandableSection,
    VACButton,
    VACCheckbox,
    VACNotificationStatus,
    VACLoader,
    SuccessAlert,
  },
  data() {
    return {
      isLoading: false,
      showSuccess: false,
      notificationsInfos: undefined,
    };
  },
  computed: {
    notificationsGroup() {
      return this.$store.state.profile.notificationsDisplayHelper;
    },
  },
  methods: {
    async handleClick() {
      this.$OneSignal.registerForPushNotifications();
      this.isLoading = true;
      await this.$store.dispatch('profile/saveProfile', {
        notifications: this.notificationsInfos,
      });
      await this.$store.dispatch('profile/fetchProfile', {
        profileId: this.$store.state.auth.userInfos.id,
      });
      this.isLoading = false;
      this.showSuccess = true;
    },
  },
  mounted() {
    this.isLoading = true;
    this.$store
      .dispatch('profile/fetchProfile', {
        profileId: this.$store.state.auth.userInfos.id,
      })
      .then(() => {
        this.notificationsInfos = this.$store.state.profile.notifications;
        this.isLoading = false;
      });
  },
};
</script>

<style scoped lang="scss">
.account-notifications {
  &:deep(.vac-notification-status) {
    width: 100%;
    margin-bottom: 1rem;
  }

  &__description {
    width: 100%;
    font-family: $font-family-prelo;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__groups {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);

    @include breakpoint($breakpoint-desktop) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  &__group-title {
    font-size: 0.75rem;
    font-weight: bold;
    margin-bottom: 0;
  }
}
</style>
