<template>
  <div class="account-useful-links">
    <VACExpandableSection is-open large class="account__section">
      <template #title>
        {{ $t('account.usefulLinks.title') }}
      </template>
      <template #body>
        <Spinner v-if="loading" class="account-useful-links__loader" />
        <VACCardImageLinkList
          v-else
          :cards="cards"
          @link="handleClick($event)"
        />
      </template>
    </VACExpandableSection>
  </div>
</template>
<script>
import ContentAPI from '@/api/ContentAPI';
import Spinner from '@/components/Spinner';
import { VACCardImageLinkList, VACExpandableSection } from '@webqam-vac-ui/lib';
import { ServicesMutations } from '@/store/modules/services';
export default {
  name: 'AccountUsefulLinks',
  components: {
    VACCardImageLinkList,
    VACExpandableSection,
    Spinner,
  },
  data() {
    return {
      cards: null,
      loading: true,
    };
  },
  methods: {
    fetchLinks() {
      ContentAPI.getUsefulLinks().then(({ data }) => {
        this.cards = data;
      });
    },
    handleClick(link) {
      if (link.includes('#services')) {
        this.$store.commit(ServicesMutations.toggleModal, true);
      }
    },
  },

  mounted() {
    this.fetchLinks();
    this.loading = false;
  },
};
</script>
