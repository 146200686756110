<template>
  <div class="prehome-watch-card">
    <div class="prehome-watch-card__container">
      <img
        src="../../assets/home-watch-card.jpg"
        alt="watch photo"
        class="prehome-watch-card__image"
      />
      <div class="prehome-watch-card__body">
        <div class="prehome-watch-card__infos">
          {{ $t('prehome.watch_card_name') }}
        </div>
        <hr class="prehome-watch-card__hr" />

        <div
          v-for="property in properties"
          :key="property.label"
          class="prehome-watch-card__data"
        >
          <strong class="prehome-watch-card__key">
            {{ property.label }}
          </strong>
          <span class="prehome-watch-card__value">
            {{ property.value }}
          </span>
        </div>

        <div class="prehome-watch-card__reg">
          {{ $t('prehome.watch_card_description') }}
        </div>
      </div>
    </div>
    <div class="prehome-watch-card__backdrop"></div>
  </div>
</template>

<script>
export default {
  name: 'PrehomeWatchCard',
  data() {
    return {
      properties: [
        {
          label: this.$t('collection.item.reference'),
          value: '4500V/110A-B128',
        },
        {
          label: this.$t('collection.item.caseNumber'),
          value: '1A3Z476U',
        },
        {
          label: this.$t('collection.item.movementNumber'),
          value: 5100,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.prehome-watch-card {
  position: relative;
  width: 100%;
  margin: -4em auto 2em;
  transition: transform $default-transition-duration;

  &:hover {
    transform: scale(1.02);
  }

  @include breakpoint($pre-home-watch-card-tablet-breakpoint) {
    height: 25em;
    width: 33.75em;
    margin-top: -4em;
    margin-bottom: -4em;
  }

  &__hr {
    width: 2em;
    height: 1px;
    background-color: $color-default;
    transform: translate3d(-50%, 0, 0);
    margin: 1em 0;
    opacity: 0.5;

    @include breakpoint($pre-home-watch-card-tablet-breakpoint) {
      margin: 2em 0;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 1em 0.5em;
    box-shadow: 0 0 6px #00000029;
    font-family: $font-family-prelo-condensed;
    background-color: $color-white;
  }

  &__data,
  &__infos,
  &__reg {
    font-size: 0.75em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 1em;
    }
  }

  &__data,
  &__infos {
    font-weight: bold;
  }

  &__value {
    color: $color-primary;
  }

  &__image {
    display: block;
    max-height: 100%;
    height: 15.75em;
    width: auto;

    @include breakpoint($pre-home-watch-card-tablet-breakpoint) {
      height: auto;
    }
  }

  &__body {
    padding: 2em 0 2em 1em;

    @include breakpoint($breakpoint-tablet) {
      padding: 2em;
    }
  }

  &__ticks {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 21px;
  }

  &__tick {
    display: block;
    height: 1px;
    width: 12px;
    background-color: $color-mine-shaft;
    margin-left: 1px;
    margin-right: 1px;
    opacity: 0.16;

    &--active {
      opacity: 1;
    }
  }

  &__backdrop {
    height: 1em;
    background-color: $color-white;
    box-shadow: 0 0 6px #00000029;
    position: absolute;
    right: 8px;
    left: 8px;
    top: -8px;
  }

  &__infos {
    color: $color-beaver;
  }

  &__reg {
    @include line-clamp(3);

    margin-top: 1em;

    @include breakpoint($breakpoint-tablet) {
      @include line-clamp(5);
    }
  }
}
</style>
