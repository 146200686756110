import { createNamespacedHelpers, Module } from 'vuex';
import {
  mapStoreMutations,
  mapStoreGetters,
  prefixTypeFactory,
} from '@/utils/StoreUtils';
import { RootState } from '@/types/RootState';
import { CountriesState } from '@/types/CountriesState';

export const NAMESPACE = 'countries';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum CountriesMutation {
  setCountries = 'setCountries',
  setLookupCountry = 'setLookupCountry',
}

export enum CountriesGetters {
  getCountries = 'getCountries',
}

const module: Module<CountriesState, RootState> = {
  namespaced: true,
  state() {
    return {
      lookupCountry: null,
      countryList: [],
      countryMap: {},
    };
  },
  mutations: {
    ...mapStoreMutations(
      {
        [CountriesMutation.setCountries]: { prop: 'country', keyProp: 'code' },
      },
      true
    ),
    [CountriesMutation.setLookupCountry](state, value) {
      state.lookupCountry = value;
    },
  },
  getters: {
    ...mapStoreGetters({
      [CountriesGetters.getCountries]: 'country',
    }),
  },
};

export default module;
