import Internationalization from '@/utils/Internationalization';
import Auth from '@/utils/authentication';
import { pushToDataLayer } from '@/utils/GTMMaker';

enum AuthenticationStatus {
  LOGGED_IN = 'logged',
  NOT_LOGGED_IN = 'not logged',
}

export default async function () {
  const user = await Auth.retrievePersistentUser();
  const country = Internationalization.getCountry();
  const trackingData = {
    pgcountry: country && country.toUpperCase(),
    pglanguage: Internationalization.getCurrentLocale(),
  };

  pushToDataLayer({
    ...trackingData,
    usrstatus: user
      ? AuthenticationStatus.LOGGED_IN
      : AuthenticationStatus.NOT_LOGGED_IN,
  });
}
