import { ModuleTree } from 'vuex';
import { RootState } from '@/types/RootState';

const files = require.context('.', false, /\.ts$/);
const modules = {};

files.keys().forEach((key) => {
  if (key === './index.ts') return;
  modules[key.replace(/(\.\/|\.ts)/g, '')] = files(key).default;
});

export default modules as ModuleTree<RootState>;
