export default {
  computed: {
    gridId: () => 'gridConv',
  },
  methods: {
    requestMasonryRedraw() {
      this.$nextTick(() => {
        this.$redrawVueMasonry(this.gridId);
      });
    },
  },
};
