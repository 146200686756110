<template>
  <div class="article-recommendation">
    <p class="article-recommendation__title">
      {{ $t('magazine.more_articles') }}
    </p>
    <div class="article-recommendation__cards">
      <CardArticle
        v-for="(article, index) in articles"
        v-show="isShowable(index)"
        :key="`article-recommendation-${index}`"
        class="article-recommendation__card"
        :article="article"
      />
    </div>
  </div>
</template>

<script>
import CardArticle from '@/components/card/CardArticle';

export default {
  name: 'ArticleRecommendation',
  components: { CardArticle },
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    /**
     * @param {number} index
     * @return {boolean}
     */
    isShowable(index) {
      return this.$matchMedia.xl || index <= 1;
    },
  },
};
</script>

<style scoped lang="scss">
.article-recommendation {
  &__title {
    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    color: $color-dove-gray;

    @include breakpoint($breakpoint-tablet) {
      font-size: 1.25em;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-tablet) {
      justify-content: flex-start;
    }
  }

  &__card {
    @include breakpoint($breakpoint-xs) {
      $n: 2;
      $margin-left: 1em;

      flex-basis: calc((100% - #{$margin-left * ($n - 1)}) / #{$n});

      & + & {
        margin-left: $margin-left;
      }
    }

    @include breakpoint($breakpoint-l) {
      $n: 3;
      $margin-left: 2em;

      flex-basis: calc((100% - #{$margin-left * ($n - 1)}) / #{$n});

      & + & {
        margin-left: $margin-left;
      }
    }
  }
}
</style>
