import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';

export const NAMESPACE = 'flash';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum FlashMutation {
  addFlash = 'addFlash',
  updateFlash = 'updateFlash',
  clearFlash = 'clearFlash',
}

export enum FlashActions {
  addFlash = 'addFlash',
  removeFlash = 'removeFlash',
}

const module = {
  namespaced: true,
  state() {
    return {
      flashes: [],
    };
  },
  actions: {
    [FlashActions.addFlash]: ({ commit }, flash) => {
      commit(FlashMutation.addFlash, {
        ...flash,
        disabled: false,
      });
    },
    [FlashActions.removeFlash]: ({ state, commit }, index) => {
      const flash = state.flashes[index];

      if (flash) {
        commit(FlashMutation.updateFlash, {
          index,
          value: {
            ...flash,
            disabled: true,
          },
        });
      }
    },
  },
  mutations: {
    [FlashMutation.addFlash](state, value) {
      state.flashes.push(value);
    },
    [FlashMutation.updateFlash](state, { index, value }) {
      Vue.set(state.flashes, index, value);
    },
    [FlashMutation.clearFlash](state) {
      state.flashes = [];
    },
  },
};

export default module;
