<template>
  <div class="magazine">
    <h2 class="magazine__title">
      {{ $t('magazine.title') }}
    </h2>
    <div class="magazine__filters">
      <div class="magazine__filters-container">
        <VACButton
          v-for="filter in filters"
          :key="`magazine-filter-${filter.value}`"
          :class="filterClass(filter)"
          :label="filter.label"
          :to="{
            name: RouteName.MAGAZINE,
            params: { preFilter: filter.value },
          }"
          appearance="discrete"
          icon=""
        />
      </div>
    </div>
    <div class="magazine__header-container" v-if="preFilter === 'selection'">
      <VACIcon name="pen" />
      <VACButton
        class="magazine__cta"
        appearance="link"
        icon=""
        @click="startOnboarding"
        :label="$t('home.custom_content.edit')"
      />
    </div>
    <template v-if="preFilter === 'selection' && articles.length === 0">
      <OnboardingNotice
        @onboard="startOnboarding"
        class="magazine__onboarding"
        no-border
        no-logo
        inset-container
        :alternate-title="
          isOnboardingCompleted
            ? $t('magazine.empty.title')
            : $t('magazine.personalize.title')
        "
        :alternate-description="
          isOnboardingCompleted
            ? $t('magazine.empty.description')
            : $t('magazine.personalize.description')
        "
      />
    </template>
    <BaseGrid v-if="articles" class="magazine__articles">
      <BaseGridItem
        v-for="(article, index) in articles"
        :key="`magazine-article-${index}`"
        :size="getCardSize(index)"
        no-grow
      >
        <CardPlaceholder :height="0 === index ? '26em' : '31em'">
          <CardArticleFull
            v-if="article.ID"
            :size="getArticleSize(index)"
            :article="article"
            no-animation
          />
        </CardPlaceholder>
      </BaseGridItem>
    </BaseGrid>
    <Spinner v-else />
    <template v-if="hasMoreArticles">
      <Spinner v-if="isItemLoading" />
      <Spy @reached="nextPage" />
    </template>
    <BackToTop />
    <OnboardingModal v-if="isOnboardInProgress" @close="closeOnboarding" />
  </div>
</template>

<script>
import { VACButton, VACIcon } from '@webqam-vac-ui/lib';
import { mapGetters } from 'vuex';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseGridItem from '@/components/grid/BaseGridItem';
import CardArticleFull from '@/components/card/CardArticleFull';
import CardPlaceholder from '@/components/card/CardPlaceholder';
import Spy from '@/components/Spy';
import BackToTop from '@/components/BackToTop';
import RouteName from '@/utils/RouteName';
import Spinner from '@/components/Spinner';
import {
  ArticlesActions,
  ArticlesGetters,
  prefixType,
} from '@/store/modules/articles';
import Tracking from '@/mixins/Tracking';
import OnboardingNotice from '@/components/onboarding/OnboardingNotice.vue';
import OnboardingModal from '@/components/onboarding/OnboardingModal.vue';

const BLOCK_SELECTOR = 'magazine';

const fakeArticles = new Array(6).fill([{}]).flat();

export default {
  name: 'Magazine',
  components: {
    VACButton,
    VACIcon,
    Spinner,
    BackToTop,
    Spy,
    CardArticleFull,
    CardPlaceholder,
    BaseGridItem,
    BaseGrid,
    OnboardingNotice,
    OnboardingModal,
  },
  mixins: [Tracking],
  props: {
    preFilter: { type: String, default: undefined },
  },
  data() {
    return {
      isOnboardInProgress: false,
      created: false,
      RouteName,
      isItemLoading: false,
      filters: [
        {
          label: this.$t('magazine.filters.all'),
          value: undefined,
        },
        {
          label: this.$t('magazine.filters.mySelection'),
          value: 'selection',
        },
        {
          label: this.$t('magazine.filters.corporate'),
          value: '1',
        },
        {
          label: this.$t('magazine.filters.experience'),
          value: '2',
        },
        {
          label: this.$t('magazine.filters.testimony'),
          value: '3',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      isOnboardingCompleted: 'onboarding/isOnboardingCompleted',
    }),
    currentCategory() {
      return this.$store.getters[prefixType(ArticlesGetters.getArticles)](
        this.preFilter
      );
    },
    articles() {
      if (this.preFilter === 'selection') {
        return this.$store.getters['onboarding/magazineContent'];
      }
      return (
        (this.currentCategory && this.currentCategory.articles) || fakeArticles
      );
    },
    currentPageNum() {
      return this.currentCategory && this.currentCategory.currentPage;
    },
    currentTotal() {
      return this.currentCategory && this.currentCategory.total;
    },
    hasMoreArticles() {
      return this.articles && this.articles.length < this.currentTotal;
    },
  },
  watch: {
    $route() {
      this.fetchArticles();
    },
  },
  created() {
    this.fetchArticles();
  },
  methods: {
    startOnboarding() {
      this.isOnboardInProgress = true;
    },
    closeOnboarding() {
      this.isOnboardInProgress = false;
      this.$store.dispatch('onboarding/fetchData', true);
    },
    filterClass({ value }) {
      return [
        `${BLOCK_SELECTOR}__filter`,
        {
          [`${BLOCK_SELECTOR}__filter--active`]: value === this.preFilter,
        },
      ];
    },
    getArticleSize(index) {
      if (!this.$matchMedia.s) {
        return 0;
      }
      switch (index) {
        case 0:
          return 3;
        case 1:
          return 1;
        case 2:
          return 2;
      }
    },
    getCardSize(index) {
      switch (index) {
        case 0:
          return 100;
        case 1:
          return this.$matchMedia.xl ? 33 : 50;
        case 2:
          return this.$matchMedia.xl ? 67 : 50;
        default:
          return this.$matchMedia.xl ? 33 : 50;
      }
    },
    fetchArticles(page = 1, forceRefresh = false) {
      if (page === 1) {
        const filter = this.filters.find((f) => f.value === this.preFilter);

        this.trackPageView(this.$t('title.magazine'), {
          articleType: filter ? filter.label : this.$t('magazine.filters.all'),
        });
      }
      this.$store
        .dispatch(prefixType(ArticlesActions.fetchArticles), {
          category: this.preFilter,
          page,
          forceRefresh,
        })
        .then(() => {
          this.isItemLoading = false;
        });
    },
    nextPage() {
      if (!this.isItemLoading) {
        this.isItemLoading = true;
        this.fetchArticles(this.currentPageNum + 1);
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.magazine')}`,
    };
  },
};
</script>

<style scoped lang="scss">
.magazine {
  @extend %base-container;

  text-align: center;

  &__title {
    font-family: $font-family-prelo-condensed;
    color: $color-dove-gray;
    font-weight: normal;
    font-size: 2.5rem;
  }

  &__filters {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include breakpoint($breakpoint-m) {
      overflow: unset;
    }
  }

  &__filters-container {
    display: flex;
    width: fit-content;
    @include breakpoint($breakpoint-m) {
      justify-content: center;
      width: 100%;
    }
  }
  &__filter {
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-transform: initial;
    white-space: nowrap;
    font-size: 1rem;

    @include breakpoint($breakpoint-xxs) {
      padding-left: $base-btn-horizontal-padding;
      padding-right: $base-btn-horizontal-padding;
    }

    &--active {
      font-weight: bold;
    }
  }

  &__articles {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  &__loader {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  &__header-container {
    display: block;
    margin-left: auto;
    text-align: right;
    &:deep(.vac-icon) {
      margin-right: 0.5rem;
    }
  }

  &__cta {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}
</style>
