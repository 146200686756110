<template>
  <section ref="section" class="welcome">
    <div class="welcome__container">
      <div class="welcome__content">
        <BaseTitle :level="2" class="welcome__title">
          {{ $t('prehome.welcome.title') }}
        </BaseTitle>
        <p class="welcome__description">
          {{ $t('prehome.welcome.description') }}
        </p>
        <p class="welcome__endline">
          {{ $t('prehome.welcome.endline') }}
        </p>
      </div>
    </div>
    <div class="welcome__visual">
      <img
        alt=""
        class="welcome__image"
        src="../../assets/prehome/welcome/watch.png"
      />
    </div>
  </section>
</template>

<script>
import BaseTitle from '@/components/BaseTitle';
import ScrollAnimation from '@/mixins/ScrollAnimation';

export default {
  name: 'PrehomeWelcome',
  components: { BaseTitle },
  mixins: [ScrollAnimation],
  mounted() {
    if (this.$matchMedia.l) {
      this.scrollAnimation(this.$refs.section, 'welcome');
    }
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  $block-selector: &;
  $transition-duration: 500ms;
  $fast-transition-duration: 250ms;

  position: relative;
  text-align: center;

  &__container {
    @extend %base-container;
    position: relative;
    height: 100%;
  }

  &__visual {
    @include breakpoint($breakpoint-l) {
      width: 50%;
      margin-left: auto;
      margin-right: 0;
      opacity: 0;
      transition: opacity $transition-duration;
      transition-delay: $fast-transition-duration * 3;

      #{$block-selector}--animate & {
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }

  &__content {
    text-align: justify;

    @include breakpoint($breakpoint-l) {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      padding-right: 5%;
      padding-left: 1em;
      transform: translateY(100%);
      opacity: 0;
      transition: all $fast-transition-duration;
      transition-delay: $fast-transition-duration * 2;

      #{$block-selector}--animate & {
        transform: translateY(50%);
        opacity: 1;
        transition-delay: $transition-duration;
      }
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    letter-spacing: unset;
    text-transform: uppercase;
    font-size: 1.125em;
  }

  &__description {
    margin-top: 1em;
    color: $color-bombay;
    margin-bottom: 0;

    @include breakpoint($breakpoint-l) {
      opacity: 0;
      transform: translateY(100%);
      transition: all $fast-transition-duration;
      transition-delay: $fast-transition-duration;

      #{$block-selector}--animate & {
        opacity: 1;
        transform: translateY(0);
        transition-delay: $transition-duration + $fast-transition-duration;
      }
    }
  }

  &__endline {
    font-weight: bold;

    @include breakpoint($breakpoint-l) {
      opacity: 0;
      transform: translateY(100%);
      transition: all $fast-transition-duration;
      transition-delay: 0s;

      #{$block-selector}--animate & {
        opacity: 1;
        transform: translateY(0);
        transition-delay: $transition-duration + $fast-transition-duration * 2;
      }
    }
  }
}
</style>
