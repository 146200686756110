<template>
  <div class="browser-upgrade-bar">
    <i18n path="browserUpgradeBar.content">
      <template #link>
        <a
          :href="$t('browserUpgradeBar.link.url')"
          :title="$t('browserUpgradeBar.link.title')"
          target="_blank"
          rel="noreferrer noopener"
          v-text="$t('browserUpgradeBar.link.label')"
        ></a>
      </template>
    </i18n>
  </div>
</template>

<script>
export default {
  name: 'BrowserUpgradeBar',
};
</script>

<style lang="scss" scoped>
.browser-upgrade-bar {
  background: $color-warning;
  padding: 1em;
  margin-bottom: 0;

  // Consider browsers that don't support `@support` rule as outdated (ie. IE11)
  @supports (display: flex) {
    // Hide message for decent browsers
    display: none;
  }
}
</style>
