import ContentAPI from '@/api/ContentAPI';
import { prefixType, StoriesActions } from '@/store/modules/stories';

export default {
  methods: {
    setLike(nodeId, likeStatus) {
      ContentAPI.setLikeStatus(nodeId, likeStatus);
      this.$store.dispatch(prefixType(StoriesActions.updateLikeStatus), {
        nodeId,
        likeStatus,
      });
    },
  },
};
