<template>
  <div class="password-forgot">
    <transition name="fade">
      <PageLoader v-if="loading" translucide />
    </transition>
    <div class="password-forgot__container">
      <transition name="password-forgot-" mode="out-in">
        <VForm
          v-if="passwordView === 'phone'"
          ref="form"
          @submit.prevent="submitPasswordForgot"
        >
          <p class="password-forgot__description">
            {{ $t('password-forgot.cn.description') }}
          </p>
          <FieldPhoneNumber
            v-model="phone"
            class="password-forgot__phone"
            required
            prefix-disabled
          />
          <div class="password-forgot__robot">
            <VueHcaptcha ref="captcha" :sitekey="siteKey" @verify="onVerify" />
          </div>
          <BaseBtn primary large block type="submit">
            {{ $t('password-forgot.cn.submit') }}
          </BaseBtn>
        </VForm>
        <PinInput
          v-else-if="passwordView === 'pin'"
          class="password-forgot__pin"
          :phone-number="`0${phone.number}`"
          @submit="submitCode"
          @sendNewCode="loading = true"
          @receiveNewCode="loading = false"
        />
        <PasswordAccount
          v-else
          reset-password
          :handle-submit="submitResetPassword"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn';
import FieldPhoneNumber from '@/components/form/FieldPhoneNumber';
import PinInput from '@/components/form/PinInput';
import PasswordAccount from '@/components/account/PasswordAccount';
import RouteName from '@/utils/RouteName';
import PageLoader from '@/components/PageLoader';
import Captcha from '@/mixins/Captcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Internationalization from '@/utils/Internationalization';
import BusName from '@/utils/BusName';
import WeChatClient from '@/api/WeChatClient';
import Tracking from '@/mixins/Tracking';

const RESET_EVENT = BusName.RESET_FORGOTTEN_PASSWORD_STEPPER;

export default {
  name: 'CnPasswordForgot',
  components: {
    PageLoader,
    PasswordAccount,
    PinInput,
    FieldPhoneNumber,
    BaseBtn,
    VueHcaptcha,
  },
  mixins: [Captcha, Tracking],
  data() {
    return {
      passwordView: 'phone',
      loading: false,
      pinCode: null,
      phone: {
        prefix: '+86',
        number: '',
      },
    };
  },
  computed: {
    phoneNumber() {
      return `${this.phone.prefix}${this.phone.number}`;
    },
  },
  created() {
    if (!Internationalization.isCnCountry()) {
      this.trackPageView();
      this.$router.push({
        name: RouteName.PREHOME,
      });
    }
  },
  mounted() {
    this.$root.$on(RESET_EVENT, this.resetForm);
  },
  destroyed() {
    this.$root.$off(RESET_EVENT, this.resetForm);
  },
  methods: {
    submitPasswordForgot() {
      if (this.$refs.form.validate()) {
        if (this.hasCaptcha) {
          this.loading = true;
          WeChatClient.askPasswordForgotPinCode(`0${this.phone.number}`)
            .then(() => {
              this.loading = false;
              this.passwordView = 'pin';
            })
            .catch((err) => {
              this.flash(err.response.data.message, 'error');
              this.resetCaptcha();
            });
        } else {
          this.flash(this.$t('cms.contact.form.error.recaptcha'), 'error');
        }
      }
    },
    submitCode(pin) {
      this.pinCode = pin;
      this.passwordView = 'password';
    },
    /**
     * @param {string} password
     * @return {Promise<T | void>}
     */
    submitResetPassword(password) {
      this.loading = true;

      return WeChatClient.resetPasswordWithPhone({
        phone: this.phoneNumber,
        token: this.pinCode,
        password,
      })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: RouteName.PREHOME }).then(() => {
            this.flash(
              this.$t('account.personalInformations.success'),
              'success'
            );
          });
        })
        .catch((err) => {
          this.loading = false;
          const data = err.response.data;
          if (data.errors.find((e) => e.code === 'Auth_302')) {
            this.passwordView = 'pin';
            this.flash(this.$t('pin-confirmation.errors.pinInvalid'), 'error');
          } else {
            this.flash(err.response.data.message, 'error');
          }
        });
    },
    resetForm() {
      this.passwordView = 'phone';
    },
  },
};
</script>

<style scoped lang="scss">
.password-forgot {
  &__container {
    max-width: 22em;
    width: 100%;
    margin: 5em auto;
    padding: 0 1em;
  }

  &__description {
    font-size: 0.875em;
  }

  &--enter-active,
  &--leave-active {
    transition-property: transform, opacity;
    transition-duration: $default-transition-duration;
  }

  &--enter {
    transform: translate3d(50%, 0, 0);
    opacity: 0;
  }

  &--enter-to,
  &--leave {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &--leave-to {
    transform: translate3d(-50%, 0, 0);
    opacity: 0;
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
}
</style>
