import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import Auth from '@/utils/authentication';
import HttpMethods from '@/enums/HttpMethods';

export class ApiClientError extends Error {}
export class ApiClientAuthError extends ApiClientError {}
export class EventNotFoundError extends ApiClientError {}
export class ArticleNotFoundError extends ApiClientError {}
export class FaqNotFoundError extends ApiClientError {}
export class PageNotFoundError extends ApiClientError {}

abstract class AbstractApiClient {
  protected readonly BASE_URL: string;
  protected readonly username: string;
  protected readonly password: string;
  protected debug: boolean;

  constructor(baseUrl: string, username: string, password = '', debug = false) {
    this.BASE_URL = baseUrl;
    this.username = username;
    this.password = password;
    this.debug = debug;
  }

  protected async query({
    url,
    method = HttpMethods.GET,
    params,
    authenticate = true,
    data,
    headers = {},
  }: {
    url: string;
    method?: Method;
    params?: object;
    authenticate?: boolean;
    data?: object;
    headers?: object;
  }): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.BASE_URL,
      url,
      params,
      data,
      headers,
    };

    const country = Auth.retrievePersistentSession.country;
    const sessionToken = Auth.retrievePersistentSession.sessionToken;

    if (country) {
      config.params = {
        ...params,
        country,
      };
    }

    if (sessionToken) {
      config.headers = {
        ...headers,
        sessionToken,
      };
    }

    if (authenticate) {
      config.auth = {
        username: this.username,
        password: this.password,
      };
    }

    return axios(config);
  }
}

export default AbstractApiClient;
