<template>
  <div class="wiz-breadcrumb">
    <div class="wiz-breadcrumb__active" :style="activeStyles"></div>
  </div>
</template>

<script>
export default {
  name: 'WizardBreadcrumb',
  props: {
    currentStep: { type: Number, default: 1 },
    totalSteps: { type: Number, default: 1 },
  },
  computed: {
    activeStyles() {
      return {
        width: `${(100 / this.totalSteps) * this.currentStep}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wiz-breadcrumb {
  width: 100%;
}
</style>
