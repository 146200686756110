const listeners = {};

export default {
  methods: {
    /**
     * @param {string} event
     * @param {Function} callback
     */
    registerListener(event, callback) {
      if (!listeners[event]) {
        listeners[event] = true;

        this.$root.$on(event, callback);
      }
    },
  },
};
