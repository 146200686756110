<template>
  <div class="f-digitcode">
    <input
      id="digit_code"
      v-model="code"
      name="digit_code"
      class="f-digitcode__input"
      type="number"
      :maxlength="numberOfDigits"
      autocomplete="off"
    />
    <label for="digit_code" class="f-digitcode__label">
      <span class="f-digitcode__list">
        <span
          v-for="(digit, index) in numberOfDigits"
          :key="index"
          class="f-digitcode__item"
          :class="{
            'f-digitcode__item--active': code.length === index,
          }"
        >
          {{ code[index] }}
        </span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FieldDigitCode',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    numberOfDigits: { type: Number, default: 6 },
    value: { type: String, default: undefined },
  },
  computed: {
    code: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
        return this.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.f-digitcode {
  $block-selector: &;

  position: relative;
  width: 100%;
  padding-bottom: 2em;

  &__label {
    position: relative;
    display: block;
    width: 100%;
    height: 50px;
    cursor: text;
  }

  &__list {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex: 1;
    font-size: 3em;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 15%;
      right: 15%;
      height: 1px;
      background-color: $color-default;
      transition: all $default-transition-duration;
      border-radius: 1em;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__input:focus + &__label {
    #{$block-selector}__item--active::after {
      height: 2px;
      background-color: $color-primary;
    }
  }
}
</style>
