import { BreakpointMap } from '@webqam/vue-match-media';

export enum Breakpoint {
  XXXS = 'xxxs',
  // XXS = 'xxs',
  // XS = 'xs',
  S = 's',
  // M = 'm',
  L = 'l',
  XL = 'xl',
  // XXL = 'xxl',
  // XXXL = 'xxxl',
}

const breakpoints: BreakpointMap = Object.freeze({
  [Breakpoint.XXXS]: '20em',
  // [Breakpoint.XXS]: '23em',
  // [Breakpoint.XS]: '35em',
  [Breakpoint.S]: '48em',
  // [Breakpoint.M]: '50em',
  [Breakpoint.L]: '62em',
  [Breakpoint.XL]: '75em',
  // [Breakpoint.XXL]: '84em',
  // [Breakpoint.XXXL]: '96em',
});

export default breakpoints;
