import RouteName from '@/utils/RouteName';
import { flash } from '@/plugins/flash';
import i18n from '@/i18n';
import protectLoggedInRoutes from '@/router/middleware/protectLoggedInRoutes';
import trackUserStatus from '@/router/middleware/trackUserStatus';
import protectMemberOnlyRoutes from '@/router/middleware/protectMemberOnlyRoutes';
import processHomeLogin from '@/router/middleware/processHomeLogin';

/**
 * This function handle middleware pipeline execution
 * @param context
 */
export default async function (context) {
  const returnNow = false;

  const pipeline = [
    trackUserStatus,
    processHomeLogin,
    protectLoggedInRoutes,
    protectMemberOnlyRoutes,
  ];

  for (const middleware of pipeline) {
    try {
      const resp = await middleware({ ...context, returnNow });
      if (resp) {
        return resp;
      }
    } catch (e) {
      return context.router.push({ name: RouteName.PREHOME }).then(() => {
        flash(i18n.t('internal_error'), 'error');
      });
    }
  }
  return context.next();
}
