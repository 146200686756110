import Internationalization from '@/utils/Internationalization';

function getLinkForCurrentCountry() {
  return Internationalization.isCnCountry()
    ? process.env.VUE_APP_CCP_LINK_CN
    : process.env.VUE_APP_CCP_LINK;
}

export function getLocaleForLink() {
  const widgetLanguageMapper = {
    zh: 'zh-tw',
    cn: 'zh-cn',
  };
  if (widgetLanguageMapper[Internationalization.getCurrentLocale()]) {
    return widgetLanguageMapper[Internationalization.getCurrentLocale()];
  }
  return Internationalization.getCurrentLocale();
}

export function generateLocalizedCCPLink(subLink = '') {
  return `${getLinkForCurrentCountry()}${getLocaleForLink()}${subLink}`;
}
