import { render, staticRenderFns } from "./HomePresentation.vue?vue&type=template&id=72c410db&scoped=true&"
import script from "./HomePresentation.vue?vue&type=script&lang=js&"
export * from "./HomePresentation.vue?vue&type=script&lang=js&"
import style0 from "./HomePresentation.vue?vue&type=style&index=0&id=72c410db&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c410db",
  null
  
)

export default component.exports