<template>
  <div class="event-register-validation">
    <HeaderMember />
    <div class="event-register-validation__content-container">
      <transition name="fade">
        <div v-if="event" class="event-register-validation__content">
          <EventHeader
            class="event-register-validation__event-header"
            :event="event"
            hide-cta
          />
          <p
            v-for="(message, index) in $t('events.registration_thanks')"
            :key="index"
            class="event-register-validation__paragraph"
          >
            {{ message }}
          </p>
          <router-link :to="{ name: RouteName.EVENTS }">
            <BaseBtn primary class="event-register-validation__cta">
              {{ $t('events.back_to_all') }}
            </BaseBtn>
          </router-link>
        </div>
        <PageLoader v-else translucide />
      </transition>
    </div>

    <BaseFooter />
  </div>
</template>

<script>
import BaseFooter from '@/components/BaseFooter';

import ContentAPI from '@/api/ContentAPI';
import BaseBtn from '@/components/BaseBtn';
import RouteName from '@/utils/RouteName';
import HeaderMember from '@/components/header/HeaderMember';
import PageLoader from '@/components/PageLoader';
import EventHeader from '@/components/event/EventHeader';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'EventRegisterValidation',
  components: {
    PageLoader,
    HeaderMember,
    BaseBtn,
    BaseFooter,
    EventHeader,
  },
  mixins: [Tracking],
  props: {
    eventId: { type: String, default: undefined },
  },
  data() {
    return {
      event: undefined,
      RouteName,
    };
  },
  mounted() {
    this.trackPageView();
    this.fetchEvent();
  },
  methods: {
    fetchEvent() {
      ContentAPI.getEvent({ id: this.eventId }).then(
        (event) => (this.event = event)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.event-register-validation {
  display: flex;
  height: 100vh;
  flex-direction: column;

  &__content-container {
    flex-grow: 1;
    padding: 3em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__paragraph {
    margin-bottom: 0;
    min-height: 1em;
  }

  &__cta {
    margin-top: 2em;
    width: 100%;

    @include breakpoint($breakpoint-tablet) {
      width: 10em;
    }
  }

  &__event-header {
    ::v-deep {
      .event-header__date {
        margin-top: 4em;
      }
    }
  }
}
</style>
