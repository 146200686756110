import i18n from '@/i18n';

export const I18N_LOCALE_KEY = 'currentlocale';
export const I18N_COUNTRY_KEY = 'currentCountry';

export const LOCALE_ARABIC = 'ar';
export const LOCALE_CHINESE = 'cn';
export const LOCALE_CHINESE_TAIWAN = 'zh';
export const LOCALE_ENGLISH = 'en';
export const LOCALE_FRENCH = 'fr';
export const LOCALE_GERMAN = 'de';
export const LOCALE_ITALIAN = 'it';
export const LOCALE_JAPANESE = 'ja';
export const LOCALE_KOREAN = 'ko';
export const LOCALE_PORTUGUESE = 'pt';
export const LOCALE_RUSSIAN = 'ru';
export const LOCALE_SPANISH = 'es';
export const RTL_LANGUAGES = [LOCALE_ARABIC];

const availableLocales = [
  LOCALE_ARABIC,
  LOCALE_CHINESE,
  LOCALE_CHINESE_TAIWAN,
  LOCALE_ENGLISH,
  LOCALE_FRENCH,
  LOCALE_GERMAN,
  LOCALE_ITALIAN,
  LOCALE_JAPANESE,
  LOCALE_KOREAN,
  LOCALE_PORTUGUESE,
  LOCALE_RUSSIAN,
  LOCALE_SPANISH,
];

class Internationalization {
  getCurrentLocale(): string {
    const locale = localStorage.getItem(I18N_LOCALE_KEY) as string;

    return this.isValidLocale(locale)
      ? locale.toLowerCase()
      : this.getBrowserLocale();
  }

  getBrowserLocale(): string {
    const { language } = navigator;
    let locale;

    if (LOCALE_CHINESE_TAIWAN === language.toLowerCase()) {
      locale = language;
    } else {
      [locale] = (language && language.split('-')) || '';
    }

    return locale && this.isValidLocale(locale)
      ? locale.toLowerCase()
      : (process.env.VUE_APP_I18N_FALLBACK_LOCALE as string);
  }

  setLocale(locale: string): void {
    i18n.locale = locale.toLowerCase();
    localStorage.setItem(I18N_LOCALE_KEY, locale.toLowerCase());
  }

  setCountry(country: string): void {
    localStorage.setItem(I18N_COUNTRY_KEY, country.toLowerCase());
  }

  getCountry(): string | null {
    const country = localStorage.getItem(I18N_COUNTRY_KEY);
    return country && country.toLowerCase();
  }

  isCnCountry(country = this.getCountry()) {
    return country ? country.toLowerCase() === LOCALE_CHINESE : false;
  }

  isValidLocale(locale): boolean {
    return !!(
      locale &&
      this.getAllLocales().find((l) => l.value === locale.toLowerCase())
    );
  }

  getAllLocales(): { key: string; value: string }[] {
    return availableLocales.map((locale) => ({
      key: `locales.${locale.toLowerCase()}`,
      value: locale.toLowerCase(),
    }));
  }

  isRtlCountry(): boolean {
    const locale = this.getCurrentLocale();
    if (locale) {
      return RTL_LANGUAGES.includes(locale);
    }
    return false;
  }

  getPrivacyPolicyLink(): string {
    const domain = this.isCnCountry() ? 'cn' : 'com';
    const realm = this.isCnCountry() ? 'cn' : 'ww';
    const lang = this.getCurrentLocale();
    return `https://www.vacheron-constantin.${domain}/${realm}/${lang}/legal/privacy-policy.html`;
  }
}

export default new Internationalization();
