<template>
  <div class="contact-confirmation">
    <div class="contact-confirmation__content">
      <PageTitle>{{ $t('cms.contact.confirmation.title') }}</PageTitle>

      <p class="event-register-validation__paragraph">
        {{ $t('cms.contact.confirmation.text') }}
      </p>

      <router-link :to="{ name: routeName }">
        <BaseBtn primary class="contact-confirmation__cta">
          {{ $t('cms.contact.confirmation.button') }}
        </BaseBtn>
      </router-link>
    </div>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn';
import PageTitle from '@/components/page/PageTitle';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'ContactConfirmation',
  components: { BaseBtn, PageTitle },
  mixins: [Tracking],
  computed: {
    routeName() {
      // TODO redirect to pre-home if user is not logged in
      return RouteName.HOME;
    },
  },
  mounted() {
    this.trackPageView();
  },
};
</script>

<style scoped lang="scss">
.contact-confirmation {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3em;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  &__cta {
    margin-top: 2em;
    width: 100%;

    @include breakpoint($breakpoint-tablet) {
      width: 10em;
    }
  }
}
</style>
