<template>
  <header :class="classList">
    <SlideDownMenu
      :is-opened="menuOpened"
      :is-member="isMember"
      @closed="closeMenu"
    />

    <div class="header-member__container">
      <button
        class="header-member__button header-member__button--menu"
        @click="openMenu"
      ></button>
      <InlineMenu v-if="isMember" :menu-items="menuItems" />
      <router-link :to="{ name: RouteName.HOME }">
        <BaseLogo :minified="minified" :animated="isLogoAnimated" />
      </router-link>

      <div class="header-member__actions">
        <LanguageSelector
          v-if="this.$matchMedia.xl"
          class="header-member__language-selector"
        />
        <AccountButton />
      </div>
    </div>
  </header>
</template>

<script>
import AccountButton from '@/components/AccountButton';
import BaseLogo from '@/components/BaseLogo';
import InlineMenu from '@/components/InlineMenu';
import SlideDownMenu from '@/components/SlideDownMenu';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import Auth from '@/mixins/Auth';
import { Breakpoint } from '@/utils/breakpoints';
import RouteName from '@/utils/RouteName';

const BLOCK_SELECTOR = 'header-member';

export default {
  name: 'HeaderMember',
  components: {
    AccountButton,
    BaseLogo,
    InlineMenu,
    LanguageSelector,
    SlideDownMenu,
  },
  mixins: [Auth],
  data() {
    return {
      sentinelObserver: undefined,
      menuOpened: false,
      RouteName,
      minified: false,
    };
  },
  inject: ['getSentinel'],
  computed: {
    classList() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--sticky`]: this.$route.name !== RouteName.HOME,
          [`${BLOCK_SELECTOR}--guest`]: !this.isMember,
        },
      ];
    },
    menuItems() {
      return [
        {
          title: this.$t('member_menu.magazine'),
          link: this.$router.resolve({ name: RouteName.MAGAZINE }).href,
          active:
            this.$route.name === RouteName.MAGAZINE ||
            this.$route.name === RouteName.SINGLE_ARTICLE,
        },
        {
          title: this.$t('member_menu.events'),
          link: this.$router.resolve({ name: RouteName.EVENTS }).href,
          active:
            this.$route.name === RouteName.EVENTS ||
            this.$route.name === RouteName.SINGLE_EVENT ||
            this.$route.name === RouteName.REGISTER_EVENT,
        },
        {
          title: this.$t('member_menu.golden_book'),
          link: this.$router.resolve({ name: RouteName.GOLDEN_BOOK }).href,
          active: 0 === this.$route.name.indexOf(RouteName.GOLDEN_BOOK),
        },
      ];
    },
    isLogoAnimated() {
      return !this.$matchMedia[Breakpoint.XL];
    },
  },
  mounted() {
    this.$watch(
      'isLogoAnimated',
      function (isLogoAnimated) {
        if (isLogoAnimated) {
          this.initSentinelObserver();
        } else {
          this.removeObserver();
        }
      },
      { immediate: true }
    );
  },
  beforeDestroy() {
    this.removeObserver();
  },
  methods: {
    openMenu() {
      this.menuOpened = true;
      document.body.setAttribute('style', 'height: 100vh; overflow-y: hidden;');
    },
    closeMenu() {
      this.menuOpened = false;
      document.body.removeAttribute('style');
    },
    initSentinelObserver() {
      const observer = new IntersectionObserver(
        (entries) => {
          this.minified = entries[0].intersectionRatio !== 1;
        },
        { threshold: 1 }
      );

      observer.observe(this.getSentinel());

      this.sentinelObserver = observer;
    },
    removeObserver() {
      if (this.sentinelObserver) {
        this.sentinelObserver.disconnect();
        this.sentinelObserver = undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header-member {
  $block-selector: &;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  align-items: center;
  min-height: $header-member-min-height;
  position: relative;
  top: 0;
  z-index: 10;
  background-color: $color-white;

  &--sticky {
    position: sticky;
  }

  &__container {
    @extend %base-container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;

    @include breakpoint($breakpoint-tablet) {
      padding-left: 3.438em;
      padding-right: 3.438em;
    }

    @include breakpoint($breakpoint-desktop) {
      padding: 0;
      display: grid;
      grid-template-columns: 2fr 1.5fr 2fr;
    }

    #{$block-selector}--guest & {
      @include breakpoint($breakpoint-desktop) {
        padding: 0;
        display: flex;
      }
    }
  }

  &__button {
    min-height: $base-btn-min-height-em;

    &::before {
      @extend %icon;

      font-size: 1.4em;
    }

    &--menu {
      &::before {
        @extend %icon--menu;

        display: block;
      }

      @include breakpoint($breakpoint-desktop) {
        display: none;
      }
    }

    &--account {
      &.router-link-exact-active {
        &::before {
          font-weight: bold;
          color: $color-beaver;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &::before {
            font-weight: bold;
            color: $color-beaver;
          }
        }
      }

      &::before {
        @extend %icon--account;
      }
    }

    &--member {
      @include breakpoint($breakpoint-desktop) {
        padding: 0 1em;
      }
    }
  }

  &__language-selector {
    @include breakpoint($breakpoint-desktop) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $base-btn-min-height-em;
    }
  }

  &__actions {
    display: flex;
    @include breakpoint($breakpoint-desktop) {
      place-self: end end;
    }
  }
}
</style>
