<template>
  <div :class="blockClasses">
    <div :class="containerClasses">
      <div class="prehome-content-line__item">
        <slot name="left"> </slot>
      </div>
      <div class="prehome-content-line__item">
        <slot name="right"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'prehome-content-line';
export default {
  name: 'PrehomeContentLine',
  props: {
    greyBackground: { type: Boolean },
    reverseContent: { type: Boolean },
  },
  computed: {
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--grey-background`]: this.greyBackground,
        },
      ];
    },
    containerClasses() {
      return [
        `${BLOCK_SELECTOR}__container`,
        {
          [`${BLOCK_SELECTOR}__container--reversed`]: this.reverseContent,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.prehome-content-line {
  $item-spacing-tablet: 1em;

  &--grey-background {
    background-color: $color-wild-sand;
  }

  &__container {
    @extend %base-container;
    padding: 2.625em $mobile-gutters;
    display: flex;
    flex-direction: column;

    @include breakpoint($breakpoint-tablet) {
      padding-left: 3.125em;
      padding-right: 3.125em;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
    }

    @include breakpoint($breakpoint-desktop) {
      padding-left: 0;
      padding-right: 0;
      margin-right: auto;
      margin-left: auto;
    }

    &--reversed {
      flex-direction: column-reverse;

      @include breakpoint($breakpoint-tablet) {
        flex-direction: row-reverse;
      }
    }
  }

  &__item {
    @include breakpoint($breakpoint-tablet) {
      flex-basis: 40%;
    }
  }
}
</style>
