import { render, staticRenderFns } from "./AccountCollection.vue?vue&type=template&id=20019167&scoped=true&"
import script from "./AccountCollection.vue?vue&type=script&lang=js&"
export * from "./AccountCollection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20019167",
  null
  
)

export default component.exports