import Auth from '@/utils/authentication';

export default {
  computed: {
    /**
     * @return {LoggedUser}
     */
    currentSession() {
      return Auth.retrievePersistentSession;
    },
    /**
     * @return {boolean}
     */
    isMember() {
      return !!(this.currentSession && this.currentSession.hasTimepieces);
    },
    userInfos() {
      return this.$store.state.auth.userInfos;
    },
    userHasSetPseudonym() {
      return this.userInfos !== null && this.userInfos.pseudo;
    },
  },
};
