<template>
  <div :class="classList">{{ text }}</div>
</template>

<script>
const baseSelector = 'validator-item';

export default {
  name: 'PasswordValidatorItem',
  props: {
    isValid: Boolean,
    text: { type: String, default: '' },
  },
  computed: {
    classList() {
      const classList = [baseSelector];
      if (this.isValid) {
        classList.push(`${baseSelector}--is-valid`);
      }
      return classList;
    },
  },
};
</script>

<style lang="scss" scoped>
.validator-item {
  $element-selector: &;
  position: relative;

  &::before {
    @extend %icon;
    @extend %icon--close;
    color: $color-crown-of-thorns;
    font-size: 0.6em;
    font-weight: bold;
    padding-right: 0.6em;
    vertical-align: 0.15em;
  }

  &--is-valid#{$element-selector} {
    &::before {
      @extend %icon--check;
      color: $color-success;
    }
  }

  body.dir-rtl & {
    &::before {
      position: absolute;
      right: -2em;
      bottom: 0.6em;
    }
  }
}
</style>
