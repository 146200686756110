<template>
  <div class="member-home">
    <portal :to="PortalName.STICKY_HEADER">
      <OnboardingStickyHeader
        v-show="isOnboardingNecessary"
        :is-visible="isNoticeHeaderVisible"
        @onboard="startOnboarding"
      />
    </portal>
    <HomeAppDownload v-if="!isMember" :user="user" />
    <div class="member-home__news">
      <BaseGrid v-if="articles[0]" class="member-home__grid-news">
        <BaseGridItem :key="'home-head-articles-0'" :size="getCardSize(0)">
          <CardPlaceholder height="31em">
            <CardArticleFull
              v-if="articles[0].ID"
              :article="articles[0]"
              :blur="!isMember"
              :size="getArticleHeadingSize(0)"
              hide-paragraph
            />
          </CardPlaceholder>
        </BaseGridItem>
      </BaseGrid>
      <OnboardingNotice
        v-if="isOnboardingNecessary"
        ref="notice"
        @onboard="startOnboarding"
      />
      <HomeCustomContent
        v-if="isOnboardingCompleted"
        @requestOnboard="startOnboarding"
      />
      <BaseGrid v-if="articles" class="member-home__grid-news">
        <BaseGridItem
          v-for="(article, index) in headArticles"
          :key="`home-head-articles-${index}`"
          :size="getCardSize(index + 1)"
        >
          <CardPlaceholder height="26em">
            <CardArticleFull
              v-if="article.ID"
              :article="article"
              :blur="!isMember"
              :size="getArticleHeadingSize(index)"
              hide-paragraph
            />
          </CardPlaceholder>
        </BaseGridItem>
      </BaseGrid>
    </div>

    <div class="member-home__container">
      <BaseSurvey v-if="poll" :poll="poll" />
    </div>

    <HomeUpcomingEvents
      v-if="upcomingEvents.length"
      :events="upcomingEvents"
      :is-logged-in="isMember"
    />

    <BaseGrid v-if="articles" class="member-home__articles">
      <BaseGridItem
        v-if="promotedEvent"
        :size="secondGridSize(0)"
        class="member-home__dynamic-event"
      >
        <CardPlaceholder height="31em">
          <DynamicEvent :event="promotedEvent" :is-logged-in="isMember" />
        </CardPlaceholder>
      </BaseGridItem>

      <BaseGridItem
        v-for="(article, index) in gridArticles"
        :key="`home-grid-articles-${index}`"
        :size="secondGridSize(index + 1)"
      >
        <CardPlaceholder height="31em">
          <CardArticleFull
            v-if="article.ID"
            :article="article"
            :blur="!isMember"
            :position="index"
            :size="1"
            hide-paragraph
          />
        </CardPlaceholder>
      </BaseGridItem>
    </BaseGrid>

    <div v-if="isMember" class="member-home__see-more">
      <VACButton
        :to="{ name: RouteName.MAGAZINE }"
        appearance="primary"
        class="member-home__see-more-cta"
        href="javascript:void(0)"
      >
        {{ $t('home.see_all_articles') }}
      </VACButton>
    </div>
    <OnboardingModal v-if="isOnboardInProgress" @close="closeOnboarding" />
    <HomePresentation />
    <SocialNetworks :social-networks="socialNetworks" />
  </div>
</template>

<script>
import HomeAppDownload from '@/components/home/HomeAppDownload';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseGridItem from '@/components/grid/BaseGridItem';
import HomeUpcomingEvents from '@/components/home/HomeUpcomingEvents';
import DynamicEvent from '@/components/home/DynamicEvent';
import CardArticleFull from '@/components/card/CardArticleFull';
import CardPlaceholder from '@/components/card/CardPlaceholder';
import HomePresentation from '@/components/home/HomePresentation';
import SocialNetworks from '@/components/home/SocialNetworks';
import { VACButton } from '@webqam-vac-ui/lib';
import OnboardingNotice from '@/components/onboarding/OnboardingNotice';
import RouteName from '@/utils/RouteName';
import ContentAPI from '@/api/ContentAPI';
import Auth from '@/mixins/Auth';
import BaseSurvey from '@/components/BaseSurvey';
import OnboardingStickyHeader from '@/components/onboarding/OnboardingStickyHeader';
import Tracking from '@/mixins/Tracking';
import PortalName from '@/utils/PortalName';
import Onboarding from '@/mixins/Onboarding';
import OnboardingModal from '@/components/onboarding/OnboardingModal.vue';
import HomeCustomContent from '@/components/home/HomeCustomContent.vue';

const fakeData = new Array(10).fill([{}]).flat();

export default {
  name: 'MemberHome',
  components: {
    BaseSurvey,
    HomePresentation,
    CardArticleFull,
    CardPlaceholder,
    HomeUpcomingEvents,
    BaseGridItem,
    BaseGrid,
    HomeAppDownload,
    DynamicEvent,
    VACButton,
    OnboardingNotice,
    OnboardingStickyHeader,
    OnboardingModal,
    SocialNetworks,
    HomeCustomContent,
  },
  mixins: [Auth, Tracking, Onboarding],
  data() {
    return {
      articles: fakeData,
      upcomingEvents: fakeData.slice(0, 3),
      promotedEvent: undefined,
      poll: undefined,
      noticeObserver: undefined,
      isNoticeHeaderVisible: false,
      isOnboardInProgress: false,
      socialNetworks: [],
      RouteName,
      PortalName,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.userInfos.user;
    },
    headArticles() {
      return this.articles.slice(1, 3);
    },
    gridArticles() {
      return this.articles.slice(3, 7);
    },
  },
  created() {
    if (!this.currentSession) {
      this.$router.push({ name: RouteName.PREHOME });
    }

    this.trackPageView(this.$t('title.homepage'));

    ContentAPI.getHomepageContent().then((data) => {
      this.upcomingEvents = data.futurs_events;

      if (data.promote_article.length > 0) {
        this.articles = data.promote_article;
      }

      this.articles = data.last_articles;
      this.socialNetworks = data.social_network;

      if (data.promote_event.length > 0) {
        this.promotedEvent = data.promote_event[0];
      }
      if (data.promote_poll && data.promote_poll.length > 0) {
        this.poll = data.promote_poll[0];
      }
    });
  },
  methods: {
    startOnboarding() {
      this.isOnboardInProgress = true;
    },
    closeOnboarding() {
      this.isOnboardInProgress = false;
    },
    setupNoticeObserver() {
      this.noticeObserver = new IntersectionObserver((entries) => {
        this.isNoticeHeaderVisible = entries[0].intersectionRatio === 0;
      });
      this.noticeObserver.observe(this.$refs.notice.$el);
    },
    getCardSize(index) {
      switch (index) {
        case 0:
          return 100;
        case 1:
          return this.$matchMedia.xl ? 30 : 50;
        case 2:
          return this.$matchMedia.xl ? 70 : 50;
        default:
          return 30;
      }
    },
    getArticleHeadingSize(index) {
      if (!this.$matchMedia.s) {
        return 0;
      }
      switch (index) {
        case 0:
          return 3;
        case 1:
          return 1;
        case 2:
          return 2;
      }
    },
    secondGridSize(index) {
      switch (index) {
        case 0:
          return this.$matchMedia.xl ? 66.66 : 50;
        case 1:
          return this.$matchMedia.xl ? 33.33 : 50;
        default:
          return this.$matchMedia.xl ? 33.33 : 50;
      }
    },
  },
  watch: {
    isOnboardingNecessary() {
      if (
        this.isOnboardingNecessary &&
        this.$router.currentRoute.name === RouteName.HOME
      ) {
        this.$nextTick(() => {
          this.setupNoticeObserver();
        });
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.homepage')}`,
    };
  },
  mounted() {
    this.setupNoticeObserver();
  },
};
</script>

<style scoped lang="scss">
.member-home {
  &__container {
    @extend %base-container;
    margin-top: 1em;
    margin-bottom: 2.9em;
  }

  &__news {
    position: relative;
  }

  &__grid-news,
  &__articles {
    @extend %base-container;

    margin-bottom: 1em;
    padding-right: $home-mobile-padding;
    padding-left: $home-mobile-padding;

    @include breakpoint($breakpoint-tablet) {
      margin-bottom: 2.813em;
      padding-right: $home-tablet-padding;
      padding-left: $home-tablet-padding;
    }

    @include breakpoint($breakpoint-desktop) {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__grid-blur {
    @extend %block-abs-tlbr-zero;
    backdrop-filter: blur(4px);
  }

  &__see-more {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    @include breakpoint($breakpoint-tablet) {
      margin-top: 4em;
    }
  }

  &__see-more-cta {
    max-width: 18.75em;
    width: 100%;

    @include breakpoint($breakpoint-tablet) {
      max-width: 21em;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
