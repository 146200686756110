<template>
  <div class="golden-book-card-mini">
    <img
      :alt="title"
      :src="backgroundImage"
      class="golden-book-card-mini__image"
    />

    <div class="golden-book-card-mini__body">
      <GoldenBookStoryHeader
        :author="author"
        :author-id="authorId"
        :comment-counter="commentCounter"
        :date="date"
        :like-counter="likeCount"
        :title="title"
        :with-counters="withCounters"
        :i-like="likeStatus"
        @like="likeContent"
      />
    </div>
  </div>
</template>

<script>
import GoldenBookStoryHeader from '@/components/golden-book/GoldenBookStoryHeader';
import Likeable from '@/mixins/Likeable';

export default {
  name: 'GoldenBookCardMini',
  components: { GoldenBookStoryHeader },
  mixins: [Likeable],
  props: {
    nodeId: {
      type: String,
      default: undefined,
    },
    backgroundImage: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    withCounters: Boolean,
    likeCounter: {
      type: Number,
      default: 0,
    },
    commentCounter: {
      type: Number,
      default: 0,
    },
    iLike: Boolean,
    author: { type: String, default: undefined },
    authorId: { type: String, default: undefined },
  },
  data() {
    return {
      likeStatus: this.iLike,
    };
  },
  computed: {
    likeCount() {
      if (!this.iLike && this.likeStatus) {
        return this.likeCounter + 1;
      }
      if (this.iLike && !this.likeStatus) {
        return this.likeCounter - 1;
      }
      return this.likeCounter;
    },
  },
  methods: {
    likeContent() {
      if (this.nodeId) {
        this.likeStatus = !this.likeStatus;
        this.setLike(this.nodeId, this.likeStatus);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-card-mini {
  display: flex;
  flex-direction: row;

  &__image {
    height: 11.5em;
  }

  &__body {
    margin-left: 1.5em;
    font-family: $font-family-prelo-condensed;
  }

  ::v-deep {
    .golden-book-story-header {
      &__info {
        display: flex;
        flex-direction: column;
      }

      &__date {
        &::before {
          content: none;
        }
      }
    }
  }
}
</style>
