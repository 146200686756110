import Auth from '@/utils/authentication';
import RouteName from '@/utils/RouteName';
import { flash } from '@/plugins/flash';
import i18n from '@/i18n';
import storage from '@/utils/storage';
import AutoLoginStorage from '@/enums/AutoLoginStorage';

export default async function ({ to, router }) {
  const user = await Auth.retrievePersistentUser();
  if (!user) {
    Auth.clearSession();

    if (!to.meta.allowAnonymous) {
      const query = { redirect: to.fullPath };
      if (to.name !== RouteName.HOME) {
        storage.set(AutoLoginStorage.FORCE_LOGIN, true);
        storage.set(AutoLoginStorage.REDIRECT_AFTER_LOGIN, to.fullPath);
      }
      return router.push({ name: RouteName.PREHOME, query }).then(() => {
        flash(i18n.t('auth.forbidden'), 'error');
      });
    }
  }
}
