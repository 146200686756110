<template>
  <BaseAlert type="success">
    <slot></slot>
  </BaseAlert>
</template>

<script>
import BaseAlert from './BaseAlert';

export default {
  name: 'SuccessAlert',
  components: { BaseAlert },
};
</script>
