<template>
  <transition name="shrink">
    <div v-if="!shouldHideSurvey" class="base-survey">
      <BaseBtn
        v-if="currentPoll.is_closable === '1'"
        discrete
        class="base-survey__close"
        @click="handleClose"
      />
      <p class="base-survey__text base-survey__text--subtitle">
        {{ currentPoll.subtitle }}
      </p>
      <p class="base-survey__text base-survey__text--title">
        {{ currentPoll.question }}
      </p>
      <!-- Implement vote counter -->
      <div :class="answersClasses">
        <BaseBtn
          v-for="answer in currentPoll.anwsers"
          :key="answer.ID"
          :disabled="resultDisplay || selectedAnswers"
          discrete
          class="base-survey__answer"
          :style="answerStyle(answer)"
          @click="postVote(answer.ID)"
        >
          <template v-if="resultDisplay">
            <span class="base-survey__answer-label">
              {{ answer.answer }}
            </span>
            <span class="base-survey__answer-percentage">
              {{ $t('poll.percentage', { percent: answer.percent }) }}
            </span>
          </template>
          <Spinner
            v-else-if="selectedAnswers === answer.ID"
            class="base-survey__spinner"
          />
          <template v-else>
            {{ answer.answer }}
          </template>
        </BaseBtn>
      </div>
    </div>
  </transition>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';

import BaseBtn from '@/components/BaseBtn';
import Spinner from '@/components/Spinner';

const BLOCK_SELECTOR = 'base-survey';
export default {
  name: 'BaseSurvey',
  components: { Spinner, BaseBtn },
  props: {
    poll: { type: Object, required: true },
  },
  data() {
    return {
      updatedPoll: undefined,
      shouldHideSurvey: false,
      selectedAnswers: undefined,
    };
  },
  computed: {
    currentPoll() {
      return this.updatedPoll ? this.updatedPoll : this.poll;
    },
    resultDisplay() {
      return this.currentPoll.already_response;
    },
    answersClasses() {
      return [
        `${BLOCK_SELECTOR}__answers`,
        {
          [`${BLOCK_SELECTOR}__answers--two-lines`]:
            this.currentPoll.anwsers.length > 3,
          [`${BLOCK_SELECTOR}__answers--two-only`]:
            this.currentPoll.anwsers.length === 2,
        },
      ];
    },
    closableAnswer() {
      return this.currentPoll.anwsers.find(
        (item) => item.is_closable_answer === '1'
      );
    },
  },
  methods: {
    answerStyle(answer) {
      if (
        (this.$matchMedia.xl || this.currentPoll.anwsers.length === 2) &&
        this.resultDisplay
      ) {
        return {
          'flex-basis': answer.percent,
        };
      }
      return {};
    },
    handleClose() {
      this.shouldHideSurvey = true;
      if (this.closableAnswer && !this.resultDisplay) {
        this.postVote(this.closableAnswer.ID);
      }
    },
    async postVote(responseId) {
      this.selectedAnswers = responseId;
      const { data } = await ContentAPI.voteToPoll(this.poll.ID, responseId);
      this.updatedPoll = data;
      this.selectedAnswers = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.base-survey {
  $block-selector: &;
  $answers-border-style: 1px solid $color-shuttle-gray;

  position: relative;
  background-color: $color-athens-gray;
  background-image: url('../assets/bg_poll_mobile.svg');
  padding: 1.875em 1.125em;
  color: $color-black;
  overflow: hidden;

  @include breakpoint($breakpoint-desktop) {
    background-image: url('../assets/bg_poll_desktop.svg');
  }

  &__close {
    position: absolute;
    top: 0.688em;
    right: 0.688em;
    &::before {
      @extend %icon;
      @extend %icon--close;
    }
  }

  &__text {
    text-align: center;
    font-family: $font-family-prelo-condensed;

    &--title {
      font-weight: bold;
      font-size: 1.25em;
    }

    &--subtitle {
      font-size: 0.75em;
      margin-bottom: 0;
    }
  }

  &__answers {
    display: flex;

    @include breakpoint($breakpoint-tablet) {
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    #{$block-selector}__answer {
      flex-basis: calc(100% / 3);

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right: 0;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 0;
      }
    }

    &--two-only {
      #{$block-selector}__answer {
        &:first-child {
          border-right: $answers-border-style;
        }
      }
    }

    &--two-lines {
      flex-wrap: wrap;

      @include breakpoint($breakpoint-desktop) {
        flex-wrap: nowrap;
      }

      #{$block-selector}__answer {
        flex-basis: 50%;
        border-bottom: 0;

        @include breakpoint($breakpoint-desktop) {
          flex-basis: 25%;
          border-bottom: $answers-border-style;
          border-left: 0;
        }

        &:first-child {
          border-bottom-left-radius: 0;

          @include breakpoint($breakpoint-desktop) {
            border-bottom-left-radius: 5px;
            border-bottom: $answers-border-style;
            border-left: $answers-border-style;
          }
        }

        &:last-child {
          border-top-right-radius: 0;
          border-bottom: $answers-border-style;
          border-left: $answers-border-style;

          @include breakpoint($breakpoint-desktop) {
            border-top-right-radius: 5px;
          }
        }

        &:nth-child(odd) {
          border-right: 0;
        }

        &:nth-child(2) {
          border-top-right-radius: 5px;

          @include breakpoint($breakpoint-desktop) {
            border-top-right-radius: 0;
            border-left: $answers-border-style;
          }
        }

        &:nth-last-child(2) {
          border-bottom-left-radius: 5px;
          border-bottom: $answers-border-style;

          @include breakpoint($breakpoint-desktop) {
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }

  &__answer {
    flex-grow: 1;
    border: $answers-border-style;
    background-color: $color-athens-gray-lighter;
    height: 3.125em;
    font-family: $font-family-prelo-condensed;
    min-width: 5em;
    transition: all $default-transition-duration;
  }

  &__answer-label {
    font-size: 0.875em;
  }

  &__answer-percentage {
    font-weight: bold;
    display: block;
    margin-top: -0.25em;
  }

  &__spinner {
    transform: scale(0.5);
  }
}
.shrink-enter-active,
.shrink-leave-active {
  transition: all $default-transition-duration;
  transform-origin: top;
}

.shrink-enter,
.shrink-leave-to {
  transform: scaleY(0);
  max-height: 0;
}

.shrink-enter-to,
.shrink-leave {
  transform: scaleY(1);
  max-height: 10em;
}
</style>
