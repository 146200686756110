import { LoggedUser } from '@/types/LoggedUser';
import store from '@/store';
import { AuthMutation, prefixType } from '@/store/modules/auth';
import Storage from './storage';
import router from '@/router';
import RouteName from '@/utils/RouteName';
import i18n from '@/i18n';
import { flash } from '@/plugins/flash';
import HydraClient from '@/api/HydraClient';
import {
  morphHydraDataToLegacyFormat,
  morphWeChatDataToLegacyFormat,
} from '@/utils/SSOHelpers';
import { checkForWatchInCCP } from '@/api/CCPApi';
import AuthenticationRealms from '@/enums/AuthenticationRealms';
import WeChatClient from '@/api/WeChatClient';

export const AUTH_SESSION_COUNTRY = 'auth_session_country';
export const CURRENT_COUNTRY = 'currentCountry';
export const AUTH_SESSION_HAS_TIMEPIECES = 'auth_session_has_timepieces';
export const AUTH_SESSION_SESSION_TOKEN = 'auth_session_session_token';
export const AUTH_SESSION_SESSION_TOKEN_ID = 'auth_session_session_token_id';

class Auth {
  login(data) {
    store.commit(prefixType(AuthMutation.setCurrentSession), data);
    this.persist(data);
  }

  clearSession() {
    store.commit(prefixType(AuthMutation.setCurrentSession), null);
    Storage.remove(AUTH_SESSION_COUNTRY);
    Storage.remove(AUTH_SESSION_HAS_TIMEPIECES);
    Storage.remove(AUTH_SESSION_SESSION_TOKEN);
    Storage.remove(AUTH_SESSION_SESSION_TOKEN_ID);
  }

  logout() {
    this.clearSession();

    if (!router?.currentRoute?.meta?.allowAnonymous) {
      return router.push({ name: RouteName.PREHOME }).then(() => {
        flash(i18n.t('auth.logout'), 'info');
      });
    } else {
      flash(i18n.t('auth.logout'), 'info');
    }
  }

  persist({ country, hasTimepieces, sessionToken, tokenId }: LoggedUser) {
    Storage.set(AUTH_SESSION_COUNTRY, country);
    Storage.set(AUTH_SESSION_HAS_TIMEPIECES, hasTimepieces);
    Storage.set(AUTH_SESSION_SESSION_TOKEN, sessionToken);
    Storage.set(AUTH_SESSION_SESSION_TOKEN_ID, tokenId);
  }

  get retrieve() {
    return {
      country: Storage.get(AUTH_SESSION_COUNTRY) as string,
      locale: Storage.get(CURRENT_COUNTRY) as string,
      hasTimepieces: Storage.get(AUTH_SESSION_HAS_TIMEPIECES) === 'true',
      sessionToken: Storage.get(AUTH_SESSION_SESSION_TOKEN) as string,
      tokenId: Storage.get(AUTH_SESSION_SESSION_TOKEN_ID) as string,
    };
  }

  get retrievePersistentSession() {
    return store.state['auth'].currentSession || this.retrieve;
  }

  async retrievePersistentUser() {
    if (store.state['auth'].currentSession) {
      return store.state['auth'].currentSession;
    }
    const { country, sessionToken } = this.retrieve;

    if (country && sessionToken) {
      try {
        let formattedData;
        if (country === AuthenticationRealms.CN) {
          const response = await WeChatClient.getCurrentUserInfos();
          formattedData = morphWeChatDataToLegacyFormat(
            {
              ...response.data,
              hasWatch: await checkForWatchInCCP(response.data.uuid),
            },
            sessionToken
          );
        } else {
          const response = await HydraClient.getCurrentUserInfos();
          formattedData = morphHydraDataToLegacyFormat(
            {
              ...response.data,
              hasWatch: await checkForWatchInCCP(response.data.id),
            },
            sessionToken
          );
        }
        this.persist({
          ...formattedData,
          tokenId: this.retrieve.tokenId,
        });
        await store.dispatch('onboarding/fetchData');
        store.commit(prefixType(AuthMutation.setCurrentSession), this.retrieve);
        store.commit(prefixType(AuthMutation.setUserInfos), formattedData);
        return formattedData;
      } catch (e) {
        flash(i18n.t('auth.sessionLost'), 'error');
        return null;
      }
    }

    return null;
  }
}

export default new Auth();
