<template>
  <div ref="section" class="content">
    <PrehomeContentLine class="content__container" grey-background>
      <template #left>
        <div class="content__articles">
          <CardArticleFull
            :article="articles[0]"
            class="content__card-article content__card-article--main"
            no-interaction
            force-show-title
          />
          <CardArticleFull
            :article="articles[1]"
            class="content__card-article content__card-article--desktop-only"
            :article-id="1"
            no-interaction
            force-show-title
          />
        </div>
      </template>
      <template #right>
        <h2 class="content__line-title content__line-title--event">
          {{ $t('prehome.content.title') }}
        </h2>

        <div class="content__line-content">
          {{ $t('prehome.content.description') }}
        </div>
      </template>
    </PrehomeContentLine>
  </div>
</template>

<script>
import PrehomeContentLine from '@/components/prehome/PrehomeContentLine';
import CardArticleFull from '@/components/card/CardArticleFull';
import ScrollAnimation from '@/mixins/ScrollAnimation';

export default {
  name: 'PrehomeContent',
  components: { CardArticleFull, PrehomeContentLine },
  mixins: [ScrollAnimation],
  data() {
    return {
      articles: [
        {
          title: this.$t('prehome.articles')[0].title,
          'images-portrait-card-maxi': [
            { x1: require('../../assets/article-card-1.png') },
          ],
        },
        {
          title: this.$t('prehome.articles')[1].title,
          'images-portrait-card-maxi': [
            { x1: require('../../assets/article-card-2.png') },
          ],
        },
      ],
    };
  },
  mounted() {
    if (this.$matchMedia.l) {
      this.scrollAnimation(this.$refs.section, 'content');
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  $block-selector: &;
  $transition-duration: 500ms;

  @include breakpoint($breakpoint-l) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__container {
    width: 100%;
  }

  &__line-title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.125em;
    margin-bottom: 0.56em;
    text-transform: uppercase;

    &--event {
      margin-top: 0.5em;

      @include breakpoint($breakpoint-l) {
        margin-top: 0;
      }
    }
  }

  &__line-content {
    font-family: $font-family-prelo;
    color: $color-bombay;
    margin-bottom: 1.25em;

    @include breakpoint($breakpoint-l) {
      margin-bottom: 0;
    }
  }

  &__articles {
    display: flex;
    margin-left: -0.5em;
    margin-right: -0.5em;
    justify-content: center;

    @include breakpoint($breakpoint-l) {
      justify-content: flex-start;
    }
  }

  &__card-article {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 250px;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-l) {
      margin-bottom: 0;
    }

    &--main {
      @include breakpoint($breakpoint-l) {
        opacity: 0;
        transition: opacity $transition-duration;
        transition-delay: 0s;

        #{$block-selector}--animate & {
          opacity: 1;
          transition-delay: $transition-duration;
        }
      }
    }

    &--desktop-only {
      display: none;

      @include breakpoint($breakpoint-l) {
        display: block;
        opacity: 0;
        transition: opacity $transition-duration;
        transition-delay: $transition-duration;

        #{$block-selector}--animate & {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }
  }
}
</style>
