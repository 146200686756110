<template>
  <span ref="container" :class="classList" class="base-logo">
    <span
      ref="logo"
      :style="
        minified && {
          transform: `translateX(${translateX}px) rotate(90deg)`,
        }
      "
      class="base-logo__logo"
    ></span>

    <span v-if="!logoOnly && showTag" class="base-logo__text-group">
      <span class="base-logo__name" v-text="$t('the_hour_club')"></span>
      <span class="base-logo__tagline" v-text="$t('conoisseur_club')"></span>
    </span>
  </span>
</template>

<script>
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer/lib/ResizeObserver';

const BLOCK_SELECTOR = 'base-logo';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'BaseLogo',
  props: {
    logoOnly: Boolean,
    hideTagLineUntilS: Boolean,
    animated: Boolean,
    minified: Boolean,
    hasBackground: Boolean,
  },
  data() {
    return {
      resizeObserver: undefined,
      translateX: 0,
    };
  },
  computed: {
    classList() {
      return {
        [`${BLOCK_SELECTOR}--hide-tagline-until-s`]: this.hideTagLineUntilS,
        [`${BLOCK_SELECTOR}--animated`]: this.animated,
        [`${BLOCK_SELECTOR}--minified`]: this.minified,
        [`${BLOCK_SELECTOR}--logo-background`]: this.hasBackground,
      };
    },
    showTag() {
      return this.$matchMedia.s || !this.hideTagLineUntilS;
    },
  },
  mounted() {
    this.initResizeObserver();
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    initResizeObserver() {
      return new Promise((resolve) => {
        this.resizeObserver = new ResizeObserver((entries) => {
          entries.forEach(({ contentRect: { width } }) => {
            this.updateTranslateX(width);
          });

          resolve();
        });

        this.resizeObserver.observe(this.$refs.container);
      });
    },
    updateTranslateX(width = undefined) {
      const containerWidth =
        width || this.$refs.container.getBoundingClientRect().width;
      this.translateX = containerWidth / 2.15;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-logo {
  $block-selector: &;
  $transition-duration: 1000ms;
  $transition-timing-function: linear;
  display: inline-flex;
  align-items: center;
  font-family: $font-family-prelo-condensed;
  line-height: 1.2;
  overflow: hidden;
  color: $color-black;

  &__logo {
    vertical-align: middle;
    width: 1em;
    height: 1em;
    font-size: 2.5em;
    transform: translateX(0) rotate(0deg);

    #{$block-selector}--animated & {
      will-change: transform;
      transition: $transition-duration/2 transform $transition-timing-function;
    }

    #{$block-selector}--animated#{$block-selector}--minified & {
      transition-delay: $transition-duration/2;
    }

    #{$block-selector}--logo-background & {
      background-color: rgba(0, 0, 0, 0.02);
      border-radius: 50%;
      aspect-ratio: 1/1;
      padding: 0.5rem;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::before {
      @extend %icon;
      @extend %icon--thc-big;
      display: block;
    }
  }

  &__text-group {
    display: flex;
    flex-direction: column;
    margin-left: 0.625em;

    #{$block-selector}--minified & {
      opacity: 0;
    }

    #{$block-selector}--animated & {
      will-change: opacity;
      transition: $transition-duration/2 opacity $transition-timing-function
        $transition-duration/2;
    }

    #{$block-selector}--animated#{$block-selector}--minified & {
      transition-delay: 0s;
    }
  }

  &__name {
    font-size: 1.375em;
  }

  &__tagline {
    font-size: 0.75em;

    #{$block-selector}--hide-tagline-until-s & {
      display: none;

      @include breakpoint($breakpoint-s) {
        display: block;
      }
    }
  }
  body.dir-rtl & {
    &__text-group {
      margin-left: 0;
      margin-right: 0.625em;
    }
  }
}
</style>
