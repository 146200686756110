<template>
  <div class="gallery-thumbs">
    <div class="gallery-thumbs__thumbs-container">
      <div
        v-for="(image, index) in images"
        :key="`gallery-thumb-${index}`"
        :class="thumbClass(index)"
        @click="emitClickEvent(index)"
      >
        <img class="gallery-thumbs__image" :src="image.x1" alt="" />
      </div>
    </div>
    <div class="gallery-thumbs__paginations-container">
      <div
        v-for="(image, index) in images"
        :key="`gallery-thumb-pagination-${index}`"
        :class="paginationClass(index)"
      ></div>
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'gallery-thumbs';

export default {
  name: 'GalleryThumbs',
  props: {
    images: { type: Array, default: undefined },
    activeItem: { type: Number, default: undefined },
  },
  methods: {
    thumbClass(index) {
      return [
        `${BLOCK_SELECTOR}__thumb`,
        { [`${BLOCK_SELECTOR}__thumb--active`]: index === this.activeItem },
      ];
    },
    paginationClass(index) {
      return [
        `${BLOCK_SELECTOR}__pagination`,
        {
          [`${BLOCK_SELECTOR}__pagination--active`]: index === this.activeItem,
        },
      ];
    },
    emitClickEvent(index) {
      this.$emit('click', index);
    },
  },
};
</script>

<style scoped lang="scss">
.gallery-thumbs {
  $block-selector: &;
  margin-top: 1em;

  &__thumbs-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;
    height: 5em;
    overflow: hidden;
    margin-left: 0.5em;
    margin-right: 0.5em;
    cursor: pointer;

    &--active {
      border: 1px solid $color-white;

      #{$block-selector}__image {
        opacity: 0.5;
      }
    }
  }

  &__paginations-container {
    display: flex;
    width: 100%;
    margin-top: 1em;
    justify-content: center;
  }

  &__pagination {
    width: 1em;
    border-top: 1px solid $color-black;
    opacity: 0.45;
    margin-left: 1px;
    margin-right: 1px;

    &--active {
      opacity: 1;
    }
  }
}
</style>
