import { render, staticRenderFns } from "./AccountPosts.vue?vue&type=template&id=bdb13298&scoped=true&"
import script from "./AccountPosts.vue?vue&type=script&lang=js&"
export * from "./AccountPosts.vue?vue&type=script&lang=js&"
import style0 from "./AccountPosts.vue?vue&type=style&index=0&id=bdb13298&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdb13298",
  null
  
)

export default component.exports