var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"magazine"},[_c('h2',{staticClass:"magazine__title"},[_vm._v(" "+_vm._s(_vm.$t('magazine.title'))+" ")]),_c('div',{staticClass:"magazine__filters"},[_c('div',{staticClass:"magazine__filters-container"},_vm._l((_vm.filters),function(filter){return _c('VACButton',{key:`magazine-filter-${filter.value}`,class:_vm.filterClass(filter),attrs:{"label":filter.label,"to":{
          name: _vm.RouteName.MAGAZINE,
          params: { preFilter: filter.value },
        },"appearance":"discrete","icon":""}})}),1)]),(_vm.preFilter === 'selection')?_c('div',{staticClass:"magazine__header-container"},[_c('VACIcon',{attrs:{"name":"pen"}}),_c('VACButton',{staticClass:"magazine__cta",attrs:{"appearance":"link","icon":"","label":_vm.$t('home.custom_content.edit')},on:{"click":_vm.startOnboarding}})],1):_vm._e(),(_vm.preFilter === 'selection' && _vm.articles.length === 0)?[_c('OnboardingNotice',{staticClass:"magazine__onboarding",attrs:{"no-border":"","no-logo":"","inset-container":"","alternate-title":_vm.isOnboardingCompleted
          ? _vm.$t('magazine.empty.title')
          : _vm.$t('magazine.personalize.title'),"alternate-description":_vm.isOnboardingCompleted
          ? _vm.$t('magazine.empty.description')
          : _vm.$t('magazine.personalize.description')},on:{"onboard":_vm.startOnboarding}})]:_vm._e(),(_vm.articles)?_c('BaseGrid',{staticClass:"magazine__articles"},_vm._l((_vm.articles),function(article,index){return _c('BaseGridItem',{key:`magazine-article-${index}`,attrs:{"size":_vm.getCardSize(index),"no-grow":""}},[_c('CardPlaceholder',{attrs:{"height":0 === index ? '26em' : '31em'}},[(article.ID)?_c('CardArticleFull',{attrs:{"size":_vm.getArticleSize(index),"article":article,"no-animation":""}}):_vm._e()],1)],1)}),1):_c('Spinner'),(_vm.hasMoreArticles)?[(_vm.isItemLoading)?_c('Spinner'):_vm._e(),_c('Spy',{on:{"reached":_vm.nextPage}})]:_vm._e(),_c('BackToTop'),(_vm.isOnboardInProgress)?_c('OnboardingModal',{on:{"close":_vm.closeOnboarding}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }