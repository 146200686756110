import {
  ApiClientAuthError,
  ApiClientError,
} from '@/api/abstract/AbstractApiClient';
import HttpMethods from '@/enums/HttpMethods';
import AuthenticationRealms from '@/enums/AuthenticationRealms';
import AccountAPIInterface from '@/api/abstract/AccountAPIInterface';
import UpdateAccountParam from '@/types/UpdateAccountParam';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import Auth from '@/utils/authentication';

export class HydraClient implements AccountAPIInterface {
  protected readonly BASE_URL: string;
  protected readonly username: string;
  protected readonly password: string;
  protected debug: boolean;

  constructor(baseUrl: string, username: string, password = '', debug = false) {
    this.BASE_URL = baseUrl;
    this.username = username;
    this.password = password;
    this.debug = debug;
  }

  protected async query({
    url,
    method = HttpMethods.GET,
    params,
    authenticate = true,
    data,
    headers = {},
  }: {
    url: string;
    method?: Method;
    params?: object;
    authenticate?: boolean;
    data?: object;
    headers?: object;
  }): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.BASE_URL,
      url,
      params,
      data,
      headers,
    };

    const country = Auth.retrievePersistentSession.country;
    const sessionToken = Auth.retrievePersistentSession.sessionToken;

    if (country) {
      config.params = {
        ...params,
        country,
      };
    }

    if (sessionToken) {
      config.headers = {
        ...headers,
        sessionToken,
      };
    }

    if (authenticate) {
      config.auth = {
        username: this.username,
        password: this.password,
      };
    }

    return axios(config);
  }

  /**
   * Return URL to start Hydra auth process
   */
  async getAuthUrl() {
    return await this.query({
      url: `api/oauth2/auth`,
    });
  }

  /**
   * Get an authentication token for user
   * @param accessCode
   */
  async getAuthToken(accessCode: string) {
    return await this.query({
      url: `api/oauth2/token`,
      method: HttpMethods.POST,
      data: {
        code: accessCode,
        country: AuthenticationRealms.WW,
      },
    });
  }

  /**
   * Revoke the current Hydra token
   */
  async revokeToken(tokenId) {
    return await this.query({
      url: `api/oauth2/revoke`,
      method: HttpMethods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        id_token: tokenId,
      },
    });
  }

  /**
   * Get current logged in user info
   */
  async getCurrentUserInfos() {
    return await this.query({
      url: `api/v2/current/customer`,
    });
  }

  /**
   * Process login for a user
   * @param email user's email
   * @param password users's password
   * @param challenge hydra login challenge
   * @param recaptcha recaptcha code
   */
  async login(
    email: string,
    password: string,
    challenge: string,
    recaptcha: string
  ) {
    return await this.query({
      url: `api/v2/oidc/login-mail`,
      method: HttpMethods.POST,
      data: {
        id: email,
        idType: 'EMAIL',
        password: password,
        passwordType: 'PASSWORD',
        login_challenge: challenge,
        country: AuthenticationRealms.WW,
      },
      headers: {
        'g-recaptcha-response': recaptcha,
      },
    });
  }

  /**
   * Update password for current connected user
   * @param oldPassword
   * @param newPassword
   */
  async updatePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    await this.query({
      url: `api/v2/user/password`,
      method: HttpMethods.PUT,
      data: {
        oldPassword,
        newPassword,
        passwordEncruptionKeyId: null,
        country: AuthenticationRealms.WW,
      },
    });
  }

  /**
   * Update current logged in user account
   * @param params
   */
  async updateAccount(params: UpdateAccountParam) {
    await this.query({
      url: `api/v2/current/customer`,
      method: HttpMethods.PUT,
      data: {
        brand: 'vac',
        email: params.email,
        title: params.title,
        gender: params.gender,
        firstName: params.firstName,
        localFirstName: params.firstName,
        lastName: params.lastName,
        localLastName: params.lastName,
        preferredLanguage: params.language,
        originSite: '001',
        consent: {
          contactByBrand: params.allowBrandContact,
          clientNewsletter: params.allowBrandContact,
        },
      },
      params: {
        websiteCountry: AuthenticationRealms.WW,
      },
    });
  }
}

if (!process.env.VUE_APP_API_URL) {
  throw new ApiClientError('API URL is undefined');
}

if (!process.env.VUE_APP_API_AUTH_BASIC_USER) {
  throw new ApiClientAuthError('API auth user is undefined');
}

export default new HydraClient(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_API_AUTH_BASIC_USER,
  process.env.VUE_APP_API_AUTH_BASIC_PASSWORD,
  !!(process.env.VUE_APP_API_DEBUG && JSON.parse(process.env.VUE_APP_API_DEBUG))
);
