<template>
  <DefaultLayout>
    <template #header>
      <TheHeaderMemberSentinel ref="sentinel" />
      <BaseHeader />
    </template>

    <template #default>
      <slot></slot>
    </template>
  </DefaultLayout>
</template>

<script>
import TheHeaderMemberSentinel from '@/components/header/TheHeaderMemberSentinel';
import DefaultLayout from './DefaultLayout';
import BaseHeader from '@/components/header/BaseHeader';

export default {
  name: 'MemberLayout',
  components: { TheHeaderMemberSentinel, BaseHeader, DefaultLayout },
  provide() {
    return {
      getSentinel: this.getSentinel,
    };
  },
  methods: {
    getSentinel() {
      return this.$refs.sentinel.$el;
    },
  },
  mounted() {
    this.$store.dispatch(
      'profile/fetchProfile',
      this.$store.state.auth.userInfos.id
    );
  },
};
</script>

<style scoped></style>
