import Internationalization from '@/utils/Internationalization';
import CountryCode from '@/types/CountryCode';
import store from '../store';
import {
  NAMESPACE,
  prefixType,
  CaptchaMutations,
} from '@/store/modules/captcha';

export default {
  data() {
    return {
      captchaToken: null,
    };
  },
  computed: {
    /**
     * @return {boolean}
     */
    isHCaptcha() {
      const isHCaptcha =
        this.$store.state[NAMESPACE].isHCaptcha || this.isChina();

      store.commit(prefixType(CaptchaMutations.setIsHCaptcha), isHCaptcha);

      return isHCaptcha;
    },
    /**
     * @return {string}
     */
    captchaComponentName() {
      return this.isHCaptcha ? 'VueHcaptcha' : 'VueRecaptcha';
    },
    /**
     * @return {string}
     */
    siteKey() {
      return this.isHCaptcha
        ? process.env.VUE_APP_HCAPTCHA_SITE_KEY
        : process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY;
    },
    /**
     * @return {boolean}
     */
    hasCaptcha() {
      return null !== this.captchaToken;
    },
    /**
     * @return {Object}
     */
    captchaHeader() {
      return {
        [this.isHCaptcha ? 'h-captcha-response' : 'g-recaptcha-response']:
          this.captchaToken,
      };
    },
  },
  methods: {
    /**
     * @return {boolean}
     */
    isChina() {
      return (
        CountryCode.China.toLowerCase() ===
        Internationalization.getCountry().toLowerCase()
      );
    },
    /**
     * @param {string} response
     */
    onVerify(response) {
      if (response) {
        this.captchaToken = response;
      }
    },
    resetCaptcha() {
      if (this.$refs.captcha) {
        this.captchaToken = null;
        this.$refs.captcha.reset();
      }
    },
  },
};
