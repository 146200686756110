<template>
  <section class="device">
    <div class="device__content">
      <BaseTitle :level="2" class="device__title">
        {{ $t('prehome.device.title') }}
      </BaseTitle>
      <p class="device__description">
        {{ $t('prehome.device.description') }}
      </p>
    </div>
    <div class="device__visual">
      <img alt="" class="device__image" src="../../assets/prehome/device.png" />
    </div>
  </section>
</template>

<script>
import BaseTitle from '@/components/BaseTitle';

export default {
  name: 'PrehomeDevice',
  components: { BaseTitle },
};
</script>

<style lang="scss" scoped>
.device {
  $block-selector: &;

  @extend %base-container;

  position: relative;
  text-align: center;

  @include breakpoint($breakpoint-l) {
    margin-bottom: 5em;
  }

  &__visual {
    position: relative;
    height: 100%;

    @include breakpoint($breakpoint-l) {
      width: 50%;
    }
  }

  &__image {
    position: relative;
    display: block;
    margin: 0 auto;
  }

  &__content {
    text-align: justify;

    @include breakpoint($breakpoint-l) {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      transform: translate3d(0, -50%, 0);
      padding-right: 1em;
      padding-left: 5%;
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    letter-spacing: inherit;
    text-transform: uppercase;
    font-size: 1.125em;
  }

  &__description {
    margin-top: 1em;
    color: $color-bombay;
  }

  body.dir-rtl & {
    &__visual {
      position: relative;
      height: 100%;

      @include breakpoint($breakpoint-l) {
        width: 50%;
        margin-right: auto;
      }
    }
  }
}
</style>
