import { pushToDataLayer } from '@/utils/GTMMaker';

export default {
  data() {
    return {
      TrackingAreas: Object.freeze({
        CONTENT: 'content',
        COMMENT: 'comment',
      }),
      currentPage: this.$route.name,
    };
  },
  methods: {
    pushToLayer(payload) {
      pushToDataLayer(payload);
    },
    trackReadAll() {
      this.pushToLayer({
        event: 'readAllConversations',
      });
    },
    trackUserProfilClick(section) {
      this.pushToLayer({
        event: 'userProfilClick',
        pgType: this.currentPage,
        pgSection: section,
      });
    },
    trackPageView(pageTitle = '', additionnalDatas = {}) {
      this.pushToLayer({
        event: 'virtualPageView',
        page: this.$route.fullPath,
        pgTitle: pageTitle,
        pgType: this.$route.name,
        ...additionnalDatas,
      });
    },
  },
};
