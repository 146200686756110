<template>
  <v-dialog v-model="open" width="500">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    show: Boolean,
    title: { type: String, default: undefined },
  },
  data() {
    return {
      open: this.show,
    };
  },
  watch: {
    show() {
      this.open = this.show;
    },
  },
};
</script>

<style scoped></style>
