<template>
  <div class="account-questionnaire">
    <VACExpandableSection
      is-open
      large
      class="account-questionnaire__container"
    >
      <template #title>
        {{ $t('account.questionnaire.title') }}
      </template>
      <template #body>
        <div class="account-questionnaire__content">
          <p
            class="account-questionnaire__description"
            v-html="
              $t('account.questionnaire.description', {
                count: onboardingQuestionCount,
                minutes: onboardingTime,
              })
            "
          />
          <VACButton
            primary
            icon="arrow-right"
            :label="buttonLabel"
            @click="handleClick"
          />
        </div>
      </template>
    </VACExpandableSection>
    <OnboardingModal v-if="isOnboardInProgress" @close="closeModal" />
  </div>
</template>
<script>
import Onboarding from '@/mixins/Onboarding';
import OnboardingModal from '@/components/onboarding/OnboardingModal';
import { VACExpandableSection, VACButton } from '@webqam-vac-ui/lib';
export default {
  name: 'AccountQuestionnaire',
  components: { VACExpandableSection, VACButton, OnboardingModal },
  mixins: [Onboarding],
  data() {
    return {
      isOnboardInProgress: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.isOnboardingCompleted
        ? this.$t('account.questionnaire.edit')
        : this.$t('account.questionnaire.start');
    },
  },
  methods: {
    handleClick() {
      this.isOnboardInProgress = true;
    },
    closeModal() {
      this.isOnboardInProgress = false;
    },
  },
};
</script>
<style scoped lang="scss">
.account-questionnaire {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
