import RouteName from '@/utils/RouteName';
import storage from '@/utils/storage';
/**
 * This function handle login after HydraSSO redirection
 * @param to
 * @param next
 * @param router
 */
export default async function ({ to, router }) {
  if (to.name === RouteName.HOME && to.query.error) {
    storage.set('SSO_ERROR', true);
    return router.push({
      name: RouteName.ACCOUNT_ASK_LOGIN,
    });
  }

  if (to.name === RouteName.HOME && to.query.code) {
    return router.push({
      name: RouteName.ACCOUNT_PROCESS_LOGIN,
      query: to.query,
    });
  }
}
