<template>
  <div ref="wrapper" class="home-app-download__wrapper">
    <div class="home-app-download">
      <div class="home-app-download__container">
        <p class="home-app-download__content">
          {{ $t('home.app_download.dear', { username: userLastName }) }} <br />
        </p>
        <p
          v-for="(content, index) in $t('home.app_download.content')"
          :key="`home-dl-app-${index}`"
          class="home-app-download__content"
          v-text="$t(`home.app_download.content[${index}]`)"
        ></p>
        <a :href="ccpLink" class="home-app-download__cta">
          {{ $t('home.app_download.button') }}
        </a>
      </div>
    </div>
    <transition name="home-app-download-">
      <div v-if="sticky" class="home-app-download__sticky">
        <div class="home-app-download__sticky-container">
          <div class="home-app-download__sticky-description">
            {{ $t('home.app_download.description') }}
          </div>
          <a
            :href="ccpLink"
            class="home-app-download__cta home-app-download__cta--sticky"
          >
            {{ $t('home.app_download.button') }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RouteName from '@/utils/RouteName';
import UserData from '@/mixins/UserData';
import Internationalization from '@/utils/Internationalization';

export default {
  name: 'HomeAppDownload',
  mixins: [UserData],
  props: {
    user: { type: [Object, undefined], default: undefined },
  },
  data() {
    return {
      RouteName,
      sticky: false,
    };
  },
  computed: {
    userLastName() {
      const country = this.user && this.user.websiteCountry;
      return (
        this.user &&
        `${this.getFirstName(this.user, country)} ${this.getLastName(
          this.user,
          country
        )}`
      );
    },
    position() {
      return this.$refs.wrapper.getBoundingClientRect();
    },
    ccpLink() {
      return Internationalization.isCnCountry()
        ? process.env.VUE_APP_CCP_LINK_CN
        : process.env.VUE_APP_CCP_LINK;
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  destroyed() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      const windowTop = window.pageYOffset;
      this.sticky = windowTop >= this.position.bottom;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-app-download {
  $block-selector: &;

  width: 100%;
  background-image: url('../../assets/mobile-top-picture.png');
  background-color: $color-wild-sand;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2em $mobile-gutters;
  margin-bottom: 3em;
  color: $color-white;

  @include breakpoint($breakpoint-tablet) {
    background-image: url('../../assets/top-picture.png');
    padding: 5em 0;
  }

  &--enter-active,
  &--leave-active {
    transition: transform $default-transition-duration;
  }

  &--enter,
  &--leave-to {
    transform: translate3d(0, -100%, 0);
  }

  &--enter-to,
  &--leave {
    transform: translate3d(0, 0, 0);
  }

  &__sticky {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    background-image: url('../../assets/top-background-sticky.png');
    background-size: cover;
    color: $color-white;
    padding: 0.5em;

    @include breakpoint($breakpoint-tablet) {
      height: 5.75em;
      padding: 0;
    }
  }

  &__sticky-container {
    @extend %base-container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    height: 100%;

    @include breakpoint($breakpoint-tablet) {
      flex-direction: row;
      text-align: initial;
    }
  }

  &__container {
    @extend %base-container;
    padding-left: 0.5em;
    padding-right: 0.5em;

    @include breakpoint($breakpoint-tablet) {
      padding-left: 3.438em;
      padding-right: 3.438em;
    }

    @include breakpoint($breakpoint-desktop) {
      padding: 0;
    }
  }

  &__content {
    margin-bottom: 0;

    @include breakpoint($breakpoint-tablet) {
      width: 50%;
    }
  }

  &__badge {
    margin-top: 1em;
    width: 9em;

    &:last-of-type {
      margin-left: 1em;
    }
  }

  &__cta {
    @extend %base-btn;
    @extend %base-btn--border;
    border-radius: 0.3125em;
    margin-top: 1.5em;
    font-family: $font-family-prelo-condensed;

    &--sticky {
      width: 100%;

      @include breakpoint($breakpoint-tablet) {
        width: auto;
        margin-top: 0;
      }
    }
  }
  body.dir-rtl & {
    .home-app-download {
      text-align: -webkit-left;
    }
  }
}
</style>
