<template>
  <div v-if="show" :class="classList">
    <div class="base-alert__body">
      <span class="base-alert__type">{{ $t(`flash.type.${type}`) }} </span>
      <slot></slot>
    </div>
    <div v-if="!cantDeny" class="base-alert__icon" @click="close"></div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'base-alert';

export default {
  name: 'BaseAlert',
  props: {
    type: {
      type: String,
      default: 'info',
    },
    cantDeny: Boolean,
  },
  data() {
    return {
      show: true,
    };
  },
  computed: {
    classList() {
      return [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${this.type}`];
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-alert {
  $block-selector: &;

  font-family: $font-family-prelo-condensed;
  position: relative;
  margin: 1em 0;
  min-height: 3.4375em;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: $color-wild-sand;
  color: $color-black;
  padding: 1em 1.25em;
  border: 1px solid transparent;

  &__icon {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity $default-transition-duration;

    &::before {
      @extend %icon;
      @extend %icon--close;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__type {
    #{$block-selector}--info & {
      color: $color-info;
    }

    #{$block-selector}--danger &,
    #{$block-selector}--error & {
      color: $color-danger;
    }

    #{$block-selector}--warning & {
      color: $color-warning;
    }

    #{$block-selector}--success & {
      color: $color-success;
    }
  }
}
</style>
