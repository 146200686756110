<template>
  <div class="card-more-events">
    <router-link
      v-if="!noInteraction"
      class="card-more-events__link"
      :to="{ name: RouteName.EVENTS }"
    />
    <span class="card-more-events__title">
      {{ $t('home.events.more') }}
    </span>
  </div>
</template>

<script>
import RouteName from '@/utils/RouteName';
export default {
  name: 'CardMoreEvents',
  props: {
    noInteraction: Boolean,
  },
  data() {
    return {
      RouteName,
    };
  },
};
</script>

<style scoped lang="scss">
.card-more-events {
  @extend %base-card;
  background-color: $color-wild-sand;
  background-image: url('../../assets/bg-more-events.png');
  background-position: right bottom;
  background-size: 50%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 0.75em;
  height: 100%;
  position: relative;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    font-size: 1.375em;
  }

  &__link {
    @extend %block-abs-tlbr-zero;
  }
}
</style>
