<template>
  <div class="page-not-found">
    <h2 class="page-not-found__title">
      {{ $t('pageNotFound.title') }}
    </h2>

    <p class="page-not-found__description">
      {{ $t('pageNotFound.description') }}
    </p>

    <div class="page-not-found__image-container">
      <img
        src="../assets/not-found.png"
        alt="Page not found"
        class="page-not-found__image"
      />

      <div class="page-not-found__image-background"></div>
    </div>

    <router-link
      v-slot="{ href }"
      class="page-not-found__button"
      :to="{ name: routeName }"
    >
      <VACButton
        :href="href"
        appearance="primary"
        :label="$t('pageNotFound.button')"
      />
    </router-link>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import RouteName from '@/utils/RouteName';

export default {
  name: 'PageNotFound',
  components: { VACButton },
  computed: {
    routeName() {
      return RouteName.HOME;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-not-found {
  @extend %base-container;
  text-align: center;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.875em;
    text-transform: uppercase;
  }

  &__description,
  &__image-container {
    margin-top: 2.5em;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    position: relative;
    z-index: 2;
  }

  &__image-background {
    $margin: 5em;

    position: absolute;
    z-index: 1;
    top: $margin;
    left: -100%;
    right: calc(100% - #{$margin});
    bottom: #{-1 * $margin / 2};
    background-color: $color-wild-sand;
  }

  &__button {
    margin-top: 3.625em;
    margin-bottom: 2.875em;
  }
}
</style>
