<template>
  <div class="auth-footer">
    <div class="auth-footer__title">
      {{ $t('auth.form.footer.title') }}
    </div>

    <VACButton
      appearance="secondary"
      class="auth-modal__btn"
      full-width
      @click="handleSignup"
    >
      {{ $t('auth.signUp') }}
    </VACButton>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import BusName from '../../utils/BusName';

export default {
  name: 'AuthFooter',
  components: { VACButton },
  methods: {
    handleSignup() {
      this.$nextTick(() => this.$root.$emit(BusName.OPEN_REGISTER_MODAL));
    },
  },
};
</script>

<style scoped lang="scss">
.auth-footer {
  flex: 1;
  margin: 1.75em -2.5em -2.5em;
  padding: 1.25em 2.5em 2.5em;
  border-top: 1px solid $color-default;
  background-color: $color-wild-sand;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.125em;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1em;
  }
}
</style>
