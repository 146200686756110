<template>
  <div class="golden-book-upload-card">
    <div class="golden-book-grid__card golden-book-upload-card__container">
      <img
        :src="thumbnail"
        :alt="$t(`goldenBook.upload.pending`)"
        class="golden-book-upload-card__thumbnail"
      />
      <div class="golden-book-upload-card__play">
        <span class="icon icon--play"></span>
      </div>
      <div class="golden-book-upload-card__status">
        <div :class="loaderClasses">
          <span
            v-if="isUploaded"
            class="golden-book-upload-card__check icon icon--check"
          ></span>
        </div>
        <span class="golden-book-upload-card__status-text">{{
          $t(`goldenBook.upload.${currentStatus}`)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
const LOADER_CLASS = 'golden-book-upload-card__loader';
export default {
  name: 'GoldenBookUploadCard',
  props: {
    thumbnail: {
      type: String,
      default: null,
    },
    isUploaded: {
      type: Boolean,
    },
  },
  computed: {
    currentStatus() {
      return this.isUploaded ? 'status-uploaded' : 'status-pending';
    },
    loaderClasses() {
      return [
        LOADER_CLASS,
        {
          [`${LOADER_CLASS}--pending`]: !this.isUploaded,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.golden-book-upload-card {
  margin-bottom: 1em;
  @include breakpoint($breakpoint-desktop) {
    margin-bottom: 0;
  }
  &__container {
    display: grid;
    grid-template-areas: 'box';
    background-color: $color-rhino;
    min-height: 30.813rem;
    width: 20.625rem;
  }
  &__thumbnail {
    grid-area: box;
    place-self: stretch;
    object-fit: cover;
  }

  &__play {
    grid-area: box;
    place-self: center center;
    font-size: 6rem;
    color: rgba(255, 255, 255, 0.6);
  }

  &__status {
    grid-area: box;
    place-self: end stretch;
    display: flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.5rem;
  }

  &__status-text {
    line-height: 1;
    font-family: $font-prelo-condensed;
    font-weight: 300;
    color: $color-white;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    transform-origin: center center;
    position: relative;
    border: 1px solid $color-white;
    border-radius: 50%;

    &--pending {
      animation: rotating 2s linear infinite;
      border-color: rgba($color-white, 0.5) rgba($color-white, 0.5)
        rgba($color-white, 0.5) $color-white;
    }
  }

  &__check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
    font-size: 0.75rem;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
