import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';
import ContentAPI from '@/api/ContentAPI';

export const NAMESPACE = 'events';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum EventsGetters {
  getEvents = 'getEvents',
}

export enum EventsMutation {
  createCategory = 'createCategory',
  updateCategory = 'updateCategory',
  updateAllCategories = 'updateAllCategories',
}

export enum EventsActions {
  fetchEvents = 'fetchEvents',
  destroyCache = 'destroyCache',
}

export enum EventsCategories {
  all = 'all',
  myEvents = 'my_events',
  search = 'search',
}

const module = {
  namespaced: true,
  state() {
    return {
      categories: [],
    };
  },
  getters: {
    [EventsGetters.getEvents]: (state) => (category) => {
      return (
        state.categories &&
        state.categories.find((cat) => cat.category === category)
      );
    },
  },
  actions: {
    [EventsActions.fetchEvents]: async (
      { state, commit, getters },
      { category, page = 1, itemPerPage = 6, forceRefresh = false, ...filters }
    ) => {
      const catObject = getters[EventsGetters.getEvents](category);

      if (!catObject || catObject.currentPage < page || forceRefresh) {
        let data;

        if (category === EventsCategories.myEvents) {
          data = await ContentAPI.getMyEvents({
            page,
            itemPerPage,
          });
        } else {
          data = await ContentAPI.getEvents({
            page,
            itemPerPage,
            ...filters,
          });
        }

        const eventProperty =
          category === EventsCategories.myEvents ? 'future_events' : 'nodes';

        if (catObject) {
          const index = state.categories.findIndex(
            (cat) => cat.category === category
          );

          commit(EventsMutation.updateCategory, {
            index,
            value: {
              ...catObject,
              events:
                catObject.events === null || forceRefresh
                  ? data[eventProperty]
                  : catObject.events.concat(data[eventProperty]),
              passedEvents:
                category === EventsCategories.myEvents &&
                (catObject.passedEvents === null
                  ? data.passed_events.nodes
                  : catObject.passedEvents.concat(data.passed_events.nodes)),
              currentPage: page,
              maxPage: data.nbPage,
              total: data.count,
            },
          });
        } else {
          commit(EventsMutation.createCategory, {
            category,
            events: data[eventProperty],
            passedEvents:
              category === EventsCategories.myEvents &&
              data.passed_events.nodes,
            currentPage: page,
            maxPage: data.nbPage,
            total: data.count,
            countries: category === EventsCategories.all && data.filterCountry,
          });
        }
      }
    },
    [EventsActions.destroyCache]({ commit }) {
      commit(EventsMutation.updateAllCategories, []);
    },
  },
  mutations: {
    [EventsMutation.createCategory](state, value) {
      state.categories.push(value);
    },
    [EventsMutation.updateCategory](state, { index, value }) {
      Vue.set(state.categories, index, value);
    },
    [EventsMutation.updateAllCategories](state, value) {
      state.categories = [...value];
    },
  },
};

export default module;
