<template>
  <div :class="classes">
    <div class="onboarding-notice__container">
      <div class="onboarding-notice__medias">
        <img
          class="onboarding-notice__image onboarding-notice__image--first"
          src="../../assets/onboarding-notice-2.jpg"
        />
        <img
          class="onboarding-notice__image onboarding-notice__image--second"
          src="../../assets/onboarding-notice-1.png"
        />
      </div>
      <div class="onboarding-notice__text">
        <p
          class="onboarding-notice__title"
          v-text="alternateTitle ?? $t('onboarding.notice.title', { name })"
        ></p>
        <p
          class="onboarding-notice__description"
          v-text="alternateDescription ?? $t('onboarding.notice.description')"
        ></p>
        <p class="onboarding-notice__description">
          {{ $t('onboarding.notice.time') }}
          <b>{{
            $t('onboarding.notice.count', {
              count: onboardingQuestionCount,
              minutes: onboardingTime,
            })
          }}</b>
        </p>
        <VACButton
          class="onboarding-notice__cta"
          appearance="primary"
          @click.native="$emit('onboard')"
          :label="$t('onboarding.notice.start')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Onboarding from '@/mixins/Onboarding';
import { VACButton } from '@webqam-vac-ui/lib';

const BLOCK_SELECTOR = 'onboarding-notice';

export default {
  name: 'OnboardingNotive',
  components: { VACButton },
  mixins: [Onboarding],
  props: {
    noBorder: {
      type: Boolean,
    },
    noLogo: {
      type: Boolean,
    },
    insetContainer: {
      type: Boolean,
    },
    alternateTitle: {
      type: String,
    },
    alternateDescription: {
      type: String,
    },
  },
  computed: {
    name() {
      return this.$store.state.auth.userInfos.user.firstName1;
    },
    classes() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--no-border`]: this.noBorder,
          [`${BLOCK_SELECTOR}--no-logo`]: this.noLogo,
          [`${BLOCK_SELECTOR}--inset-container`]: this.insetContainer,
        },
      ];
    },
  },
};
</script>
<style scoped lang="scss">
.onboarding-notice {
  $block-selector: &;
  background-color: $color-gallery;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-image: url('../../assets/pattern-onboarding.svg');
  background-position-x: right;
  background-position-y: bottom;
  border-bottom: 3px solid $color-black;

  &--no-border {
    border-bottom-width: 0;
  }

  &--no-logo {
    background-image: none;
  }

  @include breakpoint($breakpoint-desktop) {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
    margin-bottom: 4.25rem;
  }

  &__container {
    @extend %base-container;
    display: flex;
    flex-direction: column;

    @include breakpoint($breakpoint-desktop) {
      flex-direction: row;
    }

    #{$block-selector}--inset-container & {
      @include breakpoint($breakpoint-desktop) {
        padding: 0 6.25rem;
      }
    }
  }

  &__title {
    font-size: 1.875rem;
    font-family: $font-family-prelo-condensed;
    text-transform: uppercase;
  }

  &__medias {
    position: relative;
    width: 100%;
    height: 15.625rem;

    @include breakpoint($breakpoint-desktop) {
      height: 18.5rem;
      width: 36rem;
    }

    #{$block-selector}--inset-container & {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      @include breakpoint($breakpoint-desktop) {
        width: 40rem;
      }
    }
  }

  &__text {
    text-align: left;

    #{$block-selector}--inset-container & {
      @include breakpoint($breakpoint-desktop) {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
      }
    }
  }

  &__image {
    position: absolute;
    width: 11rem;
    height: 11rem;

    @include breakpoint($breakpoint-desktop) {
      width: 15.25rem;
      height: 15.25rem;
    }

    &--first {
      top: 0;
      left: 0;
      z-index: 2;
    }

    &--second {
      top: 3rem;
      left: 3rem;
      z-index: 1;
    }
  }

  &__cta {
    #{$block-selector}--inset-container & {
      width: 100%;

      @include breakpoint($breakpoint-desktop) {
        margin-top: auto;
        width: fit-content;
      }
    }
  }
}
</style>
