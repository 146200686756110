<template>
  <div class="event-header">
    <div class="event-header__article">
      <div class="event-header__container">
        <img
          ref="eventImage"
          class="event-header__image"
          :src="eventImage"
          :alt="$t('events.header_alt')"
        />
        <p class="event-header__date">
          {{ eventDate }}
        </p>
        <h2 class="event-header__title">
          {{ event.title }}
        </h2>
        <p class="event-header__location">
          {{ eventLocation }}
        </p>
        <VACButton
          v-if="!hideCta && eventStatus !== 'close'"
          :to="routerLink"
          class="event-header__cta"
          appearance="primary"
        >
          {{ $t('events.cta.' + eventStatus) }}
        </VACButton>
      </div>
    </div>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import { formatDate } from '@/utils/DateUtils';
import RouteName from '@/utils/RouteName';

export default {
  name: 'EventHeader',
  components: { VACButton },
  props: {
    event: { type: Object, default: undefined },
    hideCta: { type: Boolean },
    isEnrolled: { type: Boolean },
  },
  data() {
    return {
      RouteName,
      showLayer: false,
    };
  },
  computed: {
    routerLink() {
      if (this.event.status === 'full' && !this.isEnrolled) {
        return { name: RouteName.CONTACT };
      } else {
        return {
          name: RouteName.REGISTER_EVENT,
          params: {
            eventId: this.event.ID,
          },
        };
      }
    },
    eventDate() {
      let dateString = '';
      dateString = formatDate(this.event.date[0].value);
      if (this.event.date[0].end_value) {
        dateString +=
          ' ' +
          this.$t('events.to') +
          ' ' +
          formatDate(this.event.date[0].end_value);
      }
      return dateString;
    },
    eventImage() {
      if (this.$matchMedia.s) {
        return this.event['landscape-single'][0].x3;
      }
      return this.event['landscape-single'][0].x1;
    },
    eventStatus() {
      if (this.isEnrolled) {
        return 'booked';
      }
      return this.event.status;
    },
    computedHeight() {
      return `height: calc(${this.backgroundHeight}px - 9em)`;
    },
    eventLocation() {
      let location = '';
      if (this.event.city && this.event.city !== 'null') {
        location += this.event.city;
      }
      if (this.event.country && this.event.country !== 'null') {
        if (location) {
          location += ', ';
        }
        location += this.event.country;
      }
      if (location === '') {
        return undefined;
      }
      return location;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.eventImage.addEventListener('load', () => {
        this.showLayer = true;
      });
    });
  },
};
</script>

<style scoped lang="scss">
.event-header {
  &__image {
    margin-bottom: 1em;
  }

  &__container {
    @extend %base-container;

    text-align: center;
  }

  &__article {
    position: relative;
    z-index: 2;
  }

  &__date {
    color: $color-beaver;
    font-size: 0.8125em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 0.875em;
      text-align: center;
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    font-size: 1.125em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 1.5625em;
      text-align: center;
      margin-bottom: 1.25em;
    }
  }

  &__location {
    color: $color-black;
    margin-bottom: 1.6em;

    @include breakpoint($breakpoint-tablet) {
      text-align: center;
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    width: 16.438em;
    margin-bottom: 3.125em;
  }
}
</style>
