<template>
  <div
    ref="myDiv"
    :class="blockClasses"
    @mouseenter="startVideoPlay"
    @mouseleave="stopVideoPlay"
  >
    <div v-if="showAuthoringOptions" class="golden-book-card__menu">
      <VMenu offset-y bottom left>
        <template #activator="{ on, attrs }">
          <BaseBtn
            discrete
            class="golden-book-card__dropdown-button"
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <VList>
          <VListItem>
            <VListItemTitle>
              <RouterLink
                :to="{
                  name: RouteName.GOLDEN_BOOK_EDIT_STORY,
                  params: { storyId: id },
                }"
              >
                <BaseBtn class="golden-book-card__dropdown-item" discrete>
                  {{ $t('accountPosts.edit') }}
                </BaseBtn>
              </RouterLink>
            </VListItemTitle>
          </VListItem>
          <VListItem>
            <VListItemTitle>
              <BaseBtn
                class="golden-book-card__dropdown-item golden-book-card__dropdown-item--danger"
                discrete
                @click="showDeleteModal = true"
              >
                {{ $t('accountPosts.delete') }}
              </BaseBtn>
            </VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>
    </div>
    <DeleteGoldenBookPostModal
      v-if="showAuthoringOptions && showDeleteModal"
      @cancel="showDeleteModal = false"
      @delete="deleteStory"
    />
    <RouterLink
      v-if="id"
      class="golden-book-card__link"
      :to="{
        name: RouteName.GOLDEN_BOOK_SINGLE_STORY,
        params: { storyId: id },
      }"
    />
    <div :class="backgroundClasses">
      <img
        v-if="!withVideo"
        ref="background"
        :src="definedBackgroundImage"
        class="golden-book-card__image"
        alt="card image"
      />
      <video
        v-else
        class="golden-book-card__image golden-book-card__image--video"
        :src="video"
        :poster="definedBackgroundImage"
        muted
        loop
        ref="videoPlayer"
      />
    </div>
    <div class="golden-book-card__overlay"></div>

    <div class="golden-book-card__tags">
      <p v-show="withStory" class="golden-book-card__story">
        {{ $t('goldenBook.withStory') }}
      </p>
      <p v-show="withVideo" class="golden-book-card__story">
        {{ $t('goldenBook.withVideo') }}
      </p>
    </div>
    <p class="golden-book-card__title">
      {{ title }}
    </p>
    <div class="golden-book-card__footer">
      <RouterLink
        v-if="authorId"
        :to="{
          name: RouteName.USER_PROFILE,
          params: {
            profileId: authorId,
          },
        }"
        class="golden-book-card__author"
        @click.native="trackUserProfilClick(TrackingAreas.CONTENT)"
      >
        {{ author }}
      </RouterLink>
      <p v-else class="golden-book-card__author">
        {{ author }}
      </p>

      <GoldenBookStorySocial
        v-if="withCounters"
        :comment-counter="commentCounter"
        :light="lightSocial"
        :like-counter="likeCount"
        :i-like="iLikeStatus"
        @like="likeStory"
        @comment="redirectToComment"
      />
    </div>
  </div>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';

import GoldenBookStorySocial from '@/components/golden-book/GoldenBookStorySocial';
import BaseBtn from '@/components/BaseBtn';
import DeleteGoldenBookPostModal from '@/components/golden-book/DeleteGoldenBookPostModal';

import RouteName from '@/utils/RouteName';
import Likeable from '@/mixins/Likeable';
import Tracking from '@/mixins/Tracking';

const BLOCK_SELECTOR = 'golden-book-card';

export default {
  name: 'GoldenBookCard',
  components: { DeleteGoldenBookPostModal, GoldenBookStorySocial, BaseBtn },
  mixins: [Likeable, Tracking],
  props: {
    id: { type: String, default: undefined },
    backgroundImage: {
      type: String,
      default: undefined,
    },
    title: {
      type: String,
      default: null,
    },
    author: {
      type: String,
      default: null,
    },
    lightSocial: Boolean,
    withBorder: Boolean,
    withStory: Boolean,
    withCounters: Boolean,
    withVideo: Boolean,
    likeCounter: {
      type: Number,
      default: 0,
    },
    commentCounter: {
      type: Number,
      default: 0,
    },
    iLike: Boolean,
    showAuthoringOptions: Boolean,
    authorId: {
      type: String,
      default: undefined,
    },
    video: {
      type: String,
      default: undefined,
    },
    small: { type: Boolean },
  },
  data() {
    return {
      RouteName,
      iLikeStatus: this.iLike,
      showDeleteModal: false,
      myObserver: undefined,
    };
  },
  computed: {
    likeCount() {
      if (!this.iLike && this.iLikeStatus) {
        return this.likeCounter + 1;
      }
      if (this.iLike && !this.iLikeStatus) {
        return this.likeCounter - 1;
      }
      return this.likeCounter;
    },
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--bordered`]: this.withBorder,
          [`${BLOCK_SELECTOR}--video`]: this.withVideo,
          [`${BLOCK_SELECTOR}--small`]: this.small,
        },
      ];
    },
    backgroundClasses() {
      return [
        `${BLOCK_SELECTOR}__background`,
        {
          [`${BLOCK_SELECTOR}__background--placeholder`]:
            !this.hasBackgroundImage,
        },
      ];
    },
    definedBackgroundImage() {
      if (this.hasBackgroundImage) {
        return this.backgroundImage;
      }
      return require('../../assets/golden-book-placeholder.svg');
    },
    hasBackgroundImage() {
      return typeof this.backgroundImage !== 'undefined';
    },
    amILinking() {
      return true;
    },
  },
  methods: {
    likeStory() {
      this.iLikeStatus = !this.iLikeStatus;
      this.setLike(this.id, this.iLikeStatus);
    },
    deleteStory() {
      ContentAPI.deleteStory(this.id);
      this.$emit('deleted');
    },
    redirectToComment() {
      this.$router.push({
        name: RouteName.GOLDEN_BOOK_SINGLE_STORY,
        params: {
          storyId: this.id,
        },
        hash: '#comments',
      });
    },
    startVideoPlay() {
      if (this.video && this.$refs.videoPlayer) {
        this.$refs.videoPlayer.play();
      }
    },
    stopVideoPlay() {
      if (this.video && this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
      }
    },
  },
  mounted() {
    if (!this.$matchMedia.xl) {
      this.myObserver = new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio >= 0.8) {
            this.startVideoPlay();
          } else {
            this.stopVideoPlay();
          }
        },
        { threshold: [0.2, 0.8] }
      );
      this.myObserver.observe(this.$refs.myDiv);
    }
  },
  beforeDestroy() {
    if (this.myObserver) {
      this.myObserver.disconnect();
      delete this.myObserver;
    }
  },
};
</script>

<style scoped lang="scss">
.golden-book-card {
  $block-selector: &;
  background-color: $color-rhino;
  background-size: cover;
  min-height: 32.375em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: $color-white;
  position: relative;
  overflow: hidden;

  &--bordered {
    border: 1px solid $color-black;
  }

  &:not(&--video):hover {
    cursor: pointer;

    .golden-book-card__background {
      transform: scale(1.2);
    }
  }

  &__background {
    transition: transform $default-transition-duration;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(0deg, #00000080 0%, #54545400 30%);
    width: 100%;
    z-index: 1;

    &--placeholder {
      display: flex;
      justify-content: center;
      #{$block-selector}__image {
        width: auto;
        height: auto;
      }
    }
  }

  &__story {
    border-radius: 1.875em;
    border: 1px solid $color-white;
    font-family: $font-family-prelo-condensed;
    padding: 0.375em 0.625em;
    width: max-content;
    margin-bottom: 0.2em;
    z-index: 2;
    pointer-events: none;
  }

  &__title {
    @include line-clamp(2);

    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    font-size: 1.375em;
    margin-bottom: 0;
    z-index: 2;
    text-align: left;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 4;
  }

  &__author {
    color: $color-white;
    margin-bottom: 0;
    z-index: 4;
  }

  &__image {
    height: 100%;
    width: 100%;

    &--video {
      object-fit: cover;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      0deg,
      #00000080 0%,
      #54545400 30%,
      #00000000 50%,
      #54545400 80%,
      #00000080 100%
    );
    z-index: 2;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }

  &__menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
  }

  &__dropdown-button {
    min-width: 2.5em;
    min-height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-wild-sand;
    transition-property: transform, background-color;
    transition-duration: $default-transition-duration;

    &::before {
      @extend %icon;
      @extend %icon--menu-wallet;
      font-size: 1.2em;
      vertical-align: middle;
      color: $color-black;
    }

    &[aria-expanded='true'] {
      transform: rotate(90deg);
    }
  }

  &__dropdown-item {
    width: 8em;
    text-align: left;
    display: block;

    &--danger {
      color: $color-crown-of-thorns;
    }
  }

  &__tags {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
