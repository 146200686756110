<template>
  <div ref="eventSearch" class="event-search">
    <VForm ref="form" class="event-search__form" @submit.prevent="sendFilters">
      <div class="event-search__row">
        <FieldDatepickerRange
          v-model="filters.dates"
          class="event-search__field"
          :label-start="$t('events.search.date.label.start')"
          :label-end="$t('events.search.date.label.end')"
          @change="canSubmit = true"
        />
        <VACMultiSelect
          ref="country"
          v-model="filters.countryCode"
          name="country"
          :label="$t('events.search.countryCode.label')"
          :options="countryCodes"
          class="event-search__field"
          @change="clearInput"
        />
      </div>
      <div class="event-search__row">
        <VACCheckbox
          v-model="filters.onlyUpcomingEvents"
          :inputValue="filters.onlyUpcomingEvents"
          class="event-search__field"
          :label="$t('events.search.onlyUpcomingEvents.label')"
          @change="canSubmit = true"
        />
        <VACButton
          type="submit"
          class="event-search__field"
          :disabled="!canSubmit"
          appearance="primary"
          full-width
          :label="$t('events.search.submit')"
        >
        </VACButton>
      </div>
    </VForm>
    <BaseBtn
      discrete
      danger
      block
      class="event-search__reset"
      @click="resetFilters"
    >
      {{ $t('events.search.reset') }}
    </BaseBtn>
  </div>
</template>

<script>
import { VACButton, VACMultiSelect, VACCheckbox } from '@webqam-vac-ui/lib';
import FieldDatepickerRange from '@/components/form/FieldDatepickerRange';
import BaseBtn from '@/components/BaseBtn';

export default {
  name: 'EventSearch',
  components: {
    FieldDatepickerRange,
    BaseBtn,
    VACButton,
    VACMultiSelect,
    VACCheckbox,
  },
  props: {
    events: { type: Array, default: undefined },
    countries: { type: Object, default: undefined },
  },
  data() {
    return {
      countryInput: '',
      canSubmit: false,
      filters: {
        dates: [],
        onlyUpcomingEvents: false,
      },
    };
  },
  computed: {
    nbResults() {
      return (this.events && this.events.length) || 0;
    },
    countryCodes() {
      return (
        this.countries &&
        Object.keys(this.countries).map((key) => ({
          label: this.countries[key],
          value: key,
        }))
      );
    },
  },
  methods: {
    resetFilters() {
      this.filters = {
        dates: [],
        onlyUpcomingEvents: false,
      };
      this.canSubmit = true;
      this.sendFilters();
    },
    clearInput() {
      this.countryInput = '';
      this.canSubmit = true;
    },
    sendFilters() {
      if (this.canSubmit) {
        this.$emit('filter', this.filters);
      }
    },
    closeCountryAutocomplete() {
      this.$refs.country.blur();
    },
  },
};
</script>

<style scoped lang="scss">
.event-search {
  width: 100%;
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;

  &__form {
    padding: 1.5em;
    background-color: $color-default-background;
    max-height: calc(100vh - 6.25em - 3.375em);
    width: 100%;
    box-shadow: 0 0 5px #00000029;
  }

  &__row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint($breakpoint-tablet) {
      flex-wrap: nowrap;
    }
  }

  &__field {
    flex-basis: 100%;
    margin-bottom: 1rem;

    @include breakpoint($breakpoint-tablet) {
      flex-basis: 48%;
      flex-grow: 0;
    }
  }

  &__results {
    font-family: $font-prelo-condensed;
    font-size: 1em;
    padding-bottom: 0.75em;
    text-align: center;
    font-weight: normal;
  }

  &__reset {
    margin-top: 1em;
  }

  &__country-ok {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
</style>
