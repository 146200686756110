import Vue from 'vue';
import VueRouter from 'vue-router';
import type { RouteConfigSingleView } from 'vue-router/types/router';
import RouteName from '@/utils/RouteName';
import executeMiddlewarePipeline from '@/router/executeMiddlewarePipeline';

//Layouts
import PreHomeLayout from '../layouts/PreHomeLayout.vue';
import MemberLayout from '../layouts/MemberLayout.vue';
//Views
import Prehome from '../views/Prehome.vue';
import MemberHome from '../views/MemberHome.vue';
import Magazine from '../views/magazine/Magazine.vue';
import MagazineArticle from '../views/magazine/MagazineArticle.vue';
import Faq from '../views/cms/Faq.vue';
import EventGrid from '../views/events/EventGrid.vue';
import EventSingle from '../views/events/EventSingle.vue';
import EventRegister from '../views/events/EventRegister.vue';
import CmsContent from '../views/cms/CmsContent.vue';
import EventRegisterValidation from '../views/events/EventRegisterValidation.vue';
import PageNotFound from '../views/PageNotFound.vue';
import Contact from '../views/contact/Contact.vue';
import ContactConfirmation from '../views/contact/ContactConfirmation.vue';
import AccountDetails from '../views/account/AccountDetails.vue';
import PasswordForgot from '../views/account/PasswordForgot.vue';
import CnPasswordForgot from '../views/account/CnPasswordForgot.vue';
import ResetPassword from '../views/account/ResetPassword.vue';
import AccountConfirmation from '../views/account/AccountConfirmation.vue';
import AlreadyEmailAccount from '../views/account/AlreadyEmailAccount.vue';
import AlreadyEmailAccountPassword from '../views/account/AlreadyEmailAccountPassword.vue';
import GoldenBookGrid from '../views/golden-book/GoldenBookGrid.vue';
import GoldenBookVagMedia from '../views/golden-book/GoldenBookVagMedia.vue';
import GoldenBookNewStory from '../views/golden-book/GoldenBookNewStory.vue';
import GoldenBookSingleStory from '../views/golden-book/GoldenBookSingleStory.vue';
import ConversationSingle from '../views/ConversationSingle.vue';
import AccountPosts from '../views/account/AccountPosts.vue';
import AccountStories from '../views/account/AccountStories.vue';
import AccountConversations from '../views/account/AccountConversations.vue';
import AccountCollection from '../views/account/AccountCollection.vue';
import AccountLoginCallback from '../views/account/AccountLoginCallback.vue';
import AccountWeChatAssociation from '../views/account/AccountWeChatAssociation.vue';
import AccountHydraLogin from '../views/account/AccountHydraLogin.vue';
import AccountAskHydraLogin from '../views/account/AccountAskHydraLogin.vue';

import store from '../store';
import { FlashMutation, prefixType } from '@/store/modules/flash';
import Auth from '@/utils/authentication';

Vue.use(VueRouter);

const routes: RouteConfigSingleView[] = [
  {
    path: '/',
    name: RouteName.PREHOME,
    component: Prehome,
    meta: { layout: PreHomeLayout, allowAnonymous: true },
    beforeEnter: (to, from, next) => {
      const currentSession = Auth.retrievePersistentSession;
      const isMember = currentSession && currentSession.hasTimepieces;

      if (isMember) {
        next({ name: RouteName.HOME, replace: true });
      }

      next();
    },
  },
  {
    path: '/home',
    name: RouteName.HOME,
    component: MemberHome,
    meta: { layout: MemberLayout },
  },
  {
    path: '/magazine/:preFilter?',
    name: RouteName.MAGAZINE,
    component: Magazine,
    meta: { layout: MemberLayout, memberRequired: true },
    props: true,
  },
  {
    path: '/magazine/article/:articleId',
    name: RouteName.SINGLE_ARTICLE,
    component: MagazineArticle,
    meta: { layout: MemberLayout, memberRequired: true, customDataLayer: true },
    props: true,
  },
  {
    path: '/magazine/article/:nodeId/conversations',
    name: RouteName.ARTICLE_CONVERSATION,
    component: ConversationSingle,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/events/:preFilter?',
    name: RouteName.EVENTS,
    component: EventGrid,
    meta: { layout: MemberLayout, memberRequired: true },
    props: true,
  },
  {
    path: '/event/:eventId',
    name: RouteName.SINGLE_EVENT,
    component: EventSingle,
    meta: { layout: MemberLayout, memberRequired: true, customDataLayer: true },
    props: true,
  },
  {
    path: '/event/register/:eventId',
    name: RouteName.REGISTER_EVENT,
    component: EventRegister,
    meta: { layout: MemberLayout, memberRequired: true },
    props: true,
  },
  {
    path: '/event/register/validation/:eventId',
    name: RouteName.EVENT_REGISTRATION_CONFIRMATION,
    component: EventRegisterValidation,
    props: true,
  },
  {
    path: '/faq',
    name: RouteName.FAQ,
    component: Faq,
    meta: { layout: MemberLayout },
  },
  {
    path: '/cms/:contentId',
    name: RouteName.CMS,
    component: CmsContent,
    meta: { layout: MemberLayout, allowAnonymous: true },
  },
  {
    path: '*',
    name: RouteName.PAGE_NOT_FOUND,
    component: PageNotFound,
    meta: { layout: MemberLayout },
  },
  {
    path: '/contact',
    name: RouteName.CONTACT,
    component: Contact,
    meta: { layout: MemberLayout, allowAnonymous: true },
  },
  {
    path: '/contact/confirmation',
    name: RouteName.CONTACT_CONFIRMATION,
    component: ContactConfirmation,
    meta: { layout: MemberLayout, allowAnonymous: true },
  },
  {
    path: '/account',
    name: RouteName.ACCOUNT_DETAILS,
    component: AccountDetails,
    meta: { layout: MemberLayout },
  },
  {
    path: '/account/posts',
    name: RouteName.ACCOUNT_POSTS,
    component: AccountPosts,
    meta: { layout: MemberLayout },
  },
  {
    path: '/account/stories',
    name: RouteName.ACCOUNT_STORIES,
    component: AccountStories,
    meta: { layout: MemberLayout },
  },
  {
    path: '/account/conversations',
    name: RouteName.ACCOUNT_CONVERSATIONS,
    component: AccountConversations,
    meta: { layout: MemberLayout },
  },
  {
    path: '/password-forgot',
    name: RouteName.PASSWORD_FORGOT,
    component: PasswordForgot,
    meta: {
      layout: PreHomeLayout,
      pageTitle: 'password-forgot.title' as string,
      allowAnonymous: true,
    },
  },
  {
    path: '/password-forgot/cn',
    name: RouteName.PASSWORD_FORGOT_CN,
    component: CnPasswordForgot,
    meta: {
      layout: PreHomeLayout,
      pageTitle: 'password-forgot.title' as string,
      allowAnonymous: true,
    },
  },
  {
    path: '/confirm-account',
    name: RouteName.ACCOUNT_CONFIRMATION,
    component: AccountConfirmation,
    meta: {
      layout: PreHomeLayout,
      pageTitle: 'confirm-account.title' as string,
      allowAnonymous: true,
    },
  },
  {
    path: '/reset-password',
    name: RouteName.RESET_PASSWORD,
    component: ResetPassword,
    meta: {
      layout: PreHomeLayout,
      pageTitle: 'reset-password.title' as string,
      allowAnonymous: true,
    },
  },
  {
    path: '/collection',
    name: RouteName.COLLECTION,
    component: AccountCollection,
    meta: { layout: MemberLayout, memberRequired: false },
  },
  {
    path: '/already-email-account',
    name: RouteName.ALREADY_EMAIL_ACCOUNT,
    component: AlreadyEmailAccount,
    meta: {
      layout: MemberLayout,
      allowAnonymous: true,
      onlyCn: true,
    },
  },
  {
    path: '/already-email-account-password',
    name: RouteName.ALREADY_EMAIL_ACCOUNT_PASSWORD,
    component: AlreadyEmailAccountPassword,
    meta: {
      layout: MemberLayout,
      allowAnonymous: true,
      onlyCn: true,
    },
  },
  {
    path: '/golden-book',
    name: RouteName.GOLDEN_BOOK,
    component: GoldenBookGrid,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/golden-book/upload-a-media',
    name: RouteName.GOLDEN_BOOK_VAG_MEDIA,
    component: GoldenBookVagMedia,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/golden-book/share-a-new-story',
    name: RouteName.GOLDEN_BOOK_SHARE_STORY,
    component: GoldenBookNewStory,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/golden-book/story/:storyId/edit',
    name: RouteName.GOLDEN_BOOK_EDIT_STORY,
    component: GoldenBookNewStory,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/golden-book/story/:storyId',
    name: RouteName.GOLDEN_BOOK_SINGLE_STORY,
    component: GoldenBookSingleStory,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/golden-book/story/:nodeId/conversations',
    name: RouteName.GOLDEN_BOOK_CONVERSATION,
    component: ConversationSingle,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/profile/:profileId',
    name: RouteName.USER_PROFILE,
    component: AccountPosts,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/profile/:profileId/stories',
    name: RouteName.USER_STORIES,
    component: AccountStories,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/profile/:profileId/conversations',
    name: RouteName.USER_CONVERSATIONS,
    component: AccountConversations,
    meta: {
      layout: MemberLayout,
      memberRequired: true,
    },
    props: true,
  },
  {
    path: '/login/callback',
    name: RouteName.ACCOUNT_LOGIN_CALLBACK,
    component: AccountLoginCallback,
    meta: {
      layout: PreHomeLayout,
      allowAnonymous: true,
      excludedFromTracking: true,
    },
  },
  {
    path: '/login/engage',
    name: RouteName.ACCOUNT_ASK_LOGIN,
    component: AccountAskHydraLogin,
    meta: {
      layout: PreHomeLayout,
      allowAnonymous: true,
      excludedFromTracking: true,
    },
  },
  {
    path: '/login/process',
    name: RouteName.ACCOUNT_PROCESS_LOGIN,
    component: AccountHydraLogin,
    meta: {
      layout: PreHomeLayout,
      allowAnonymous: true,
      excludedFromTracking: true,
    },
  },
  {
    path: '/login/wechat/:uid',
    name: RouteName.ACCOUNT_WECHAT_ASSOCIATION,
    component: AccountWeChatAssociation,
    meta: {
      layout: PreHomeLayout,
      allowAnonymous: true,
      excludedFromTracking: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  store.commit(prefixType(FlashMutation.clearFlash));
  return executeMiddlewarePipeline({ to, from, next, router });
});

export default router;
