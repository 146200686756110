<template>
  <div class="aea">
    <MainTitle>
      {{ $t('alreadyEmailAccount.title') }}
    </MainTitle>
    <div class="aea__container">
      <WizardStepper
        :start="3"
        :steps="steps"
        :left-image="leftImage"
        :right-image="rightImage"
      >
        <template #step-3="stepProps">
          <WizardStep
            v-bind="stepProps"
            :title="$t('alreadyEmailAccount.step3.title')"
            :loading="passwordLoading"
          >
            <template #default>
              <PasswordAccount
                reset-password
                :handle-submit="submitPassword.bind(this, stepProps.next)"
              />
            </template>
            <template #help-title>
              {{ $t('alreadyEmailAccount.step1.helper.title') }}
            </template>
            <template #help>
              <p class="aea__help-description">
                {{ $t('alreadyEmailAccount.step1.helper.description') }}
              </p>
            </template>
          </WizardStep>
        </template>
        <template #step-4="stepProps">
          <WizardStep
            v-bind="stepProps"
            :title="$t('alreadyEmailAccount.step4.title')"
            :loading="phoneLoading"
          >
            <template #default>
              <PinInput
                v-if="pinView"
                class="aea__pin"
                :phone-number="`0${phone.number}`"
                ask-code
                @submit="submitCode($event, stepProps.next)"
              />
              <VForm v-else ref="form" @submit.prevent="askPinCode">
                <p class="aea__description">
                  {{ $t('alreadyEmailAccount.step4.description') }}
                </p>
                <FieldPhoneNumber
                  v-model="phone"
                  :label="$t('auth.register.form.phone.label')"
                  required
                  :rules="[
                    (v) => !!v || $t('auth.register.form.phone.required'),
                  ]"
                  prefix-disabled
                />
              </VForm>
            </template>
            <template #footer>
              <div class="aea__footer">
                <template v-if="pinView">
                  <BaseBtn
                    danger
                    block
                    class="aea__previous"
                    @click="pinView = false"
                  >
                    {{ $t('alreadyEmailAccount.step4.cancel') }}
                  </BaseBtn>
                </template>
                <BaseBtn v-else primary block @click="askPinCode">
                  {{ $t('alreadyEmailAccount.step4.submit') }}
                </BaseBtn>
              </div>
            </template>
          </WizardStep>
        </template>
        <template #step-5="stepProps">
          <WizardStep
            v-bind="stepProps"
            :title="$t('alreadyEmailAccount.step5.title')"
            no-step
          >
            <template #default>
              <div class="aea__description">
                {{ $t('alreadyEmailAccount.step5.description') }}
              </div>
              <div class="aea__description">
                {{ $t('alreadyEmailAccount.step5.description2') }}
              </div>
            </template>
            <template #footer>
              <AuthLogin v-slot="{ open }">
                <BaseBtn block primary @click="trackAndOpen(open)">
                  {{ $t('alreadyEmailAccount.step5.submit') }}
                </BaseBtn>
              </AuthLogin>
            </template>
          </WizardStep>
        </template>
      </WizardStepper>
    </div>
  </div>
</template>

<script>
import WizardStepper from '@/components/wizard/WizardStepper';
import RouteName from '@/utils/RouteName';
import WizardStep from '@/components/wizard/WizardStep';
import PasswordAccount from '@/components/account/PasswordAccount';
import leftImage from '@/assets/stepper/transfer-account-left.png';
import rightImage from '@/assets/stepper/transfer-account-right.png';
import PinInput from '@/components/form/PinInput';
import FieldPhoneNumber from '@/components/form/FieldPhoneNumber';
import BaseBtn from '@/components/BaseBtn';
import ContentAPI from '@/api/ContentAPI';
import AuthLogin from '@/components/auth/AuthLogin';
import { CountriesGetters, prefixType } from '@/store/modules/countries';
import MainTitle from '@/components/MainTitle';
import Internationalization from '@/utils/Internationalization';
import WeChatClient from '@/api/WeChatClient';
import CommonAccountAPI from '@/api/CommonAccountAPI';
import { pushToDataLayer } from '@/utils/GTMMaker';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AlreadyEmailAccountPassword',
  components: {
    MainTitle,
    AuthLogin,
    BaseBtn,
    FieldPhoneNumber,
    PinInput,
    PasswordAccount,
    WizardStep,
    WizardStepper,
  },
  mixins: [Tracking],
  data() {
    return {
      RouteName,
      leftImage,
      rightImage,
      pinView: false,
      phone: { prefix: '+86', number: '' },
      steps: 4,
      passwordLoading: false,
      phoneLoading: false,
      password: '',
      countries: null,
      sessionToken: null,
    };
  },
  created() {
    this.trackPageView();
    if (!Internationalization.isCnCountry()) {
      Internationalization.setCountry('CN');
      window.location.reload();
    } else {
      this.countries =
        this.$store.getters[prefixType(CountriesGetters.getCountries)];
      pushToDataLayer({
        event: 'virtualPageview',
        page: 'account/merge/step3',
      });
    }
  },
  methods: {
    /**
     * @param {function} nextCallback
     * @param {string} password
     * @return {Promise<T | void>}
     */
    submitPassword(nextCallback, password) {
      const { token } = this.$route.query;
      this.passwordLoading = true;

      return CommonAccountAPI.resetPasswordWithEmail({
        encryptedToken: token,
        password,
        country: 'CN',
      })
        .then(({ data }) => {
          this.sessionToken = data.sessionToken;
          this.passwordLoading = false;
          this.password = password;
          nextCallback();
          pushToDataLayer({
            event: 'virtualPageview',
            page: 'account/merge/step4',
          });
        })
        .catch((err) => {
          this.passwordLoading = false;
          this.flash(err.response.data.message, 'error');
        });
    },
    askPinCode() {
      this.phoneLoading = true;
      ContentAPI.checkPhone(`${this.phone.prefix}${this.phone.number}`, 'CN')
        .then(({ data }) => {
          if (data && !data.mobilePhoneExists) {
            this.phoneLoading = false;
            this.pinView = true;
            pushToDataLayer({
              event: 'virtualPageview',
              page: 'account/merge/step5',
            });
            WeChatClient.askPhoneConfirmation(`0${this.phone.number}`);
          } else {
            this.phoneLoading = false;
            this.flash(
              this.$t('alreadyEmailAccount.errors.phoneAlreadyExists'),
              'error'
            );
          }
        })
        .catch(({ response }) => {
          this.phoneLoading = false;
          this.flash(response.data.message, 'error');
        });
    },
    submitCode(mobilePhoneToken, nextCallback) {
      const countryPhone = this.countries.find(
        (c) => c.phone === this.phone.prefix
      );
      this.phoneLoading = true;
      ContentAPI.updateUser(
        {
          mobilePhone: this.phone.number,
          mobilePhoneCountry: countryPhone && countryPhone.code,
          mobilePhoneToken,
          country: 'CN',
        },
        this.sessionToken
      )
        .then(() => {
          this.steps = 5;
          nextCallback();
          this.phoneLoading = false;
          pushToDataLayer({
            event: 'accountSuccessfullyMerged',
          });
        })
        .catch((err) => {
          this.phoneLoading = false;
          this.flash(err.response.data.message, 'error');
        });
    },
    trackAndOpen(open) {
      pushToDataLayer({
        event: 'accountMerged_login',
      });
      open();
    },
  },
  /**
   * @param {Object} to
   * @param {Object} from
   * @param {function} next
   */
  beforeRouteEnter(to, from, next) {
    const { email, token } = to.query;

    if (email && token) {
      next();
    } else {
      next({ path: RouteName.PREHOME });
    }
  },
};
</script>

<style scoped lang="scss">
.aea {
  &__description {
    font-size: 0.875em;
    white-space: pre-line;
    margin-bottom: 0.5em;
  }

  &__previous {
    margin-top: 0.25em;
  }

  &__footer {
    padding: 0 1.25em;
  }
}
</style>
