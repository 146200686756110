<template>
  <div class="golden-book-single-story">
    <template v-if="story && isFullyLoaded">
      <div class="golden-book-single-story__container">
        <VACButton
          appearance="link"
          class="golden-book-single-story__back-btn"
          @click="goBack"
          reverse
          icon="arrow-left"
          :label="$t('wizard.header.back')"
        />

        <GoldenBookStoryHeader
          :author-id="story.author.ID"
          :author="story.author.pseudo"
          :comment-counter="story.count_conversation"
          :date="date"
          :like-counter="likeCount"
          :title="story.title"
          with-counters
          :i-like="iLike"
          @like="likeStory"
          @comment="scrollToComment"
        />
        <div class="golden-book-single-story__content">
          <div class="golden-book-single-story__media">
            <div v-if="story.video" class="golden-book-single-story__video">
              <VideoPlayer :video="story.video" story />
            </div>

            <img
              v-else
              :src="image"
              alt=""
              class="golden-book-single-story__image"
            />
          </div>
          <div class="golden-book-single-story__texts">
            <div
              v-if="story.description"
              class="golden-book-single-story__description"
            >
              <p
                v-for="(paragraph, index) in paragraphs"
                :key="`paragraph-${index}`"
              >
                {{ paragraph }}
              </p>
            </div>

            <div class="golden-book-single-story__author">
              {{ story.author.pseudo }}
            </div>
          </div>
        </div>
      </div>

      <StartConversation
        id="comments"
        ref="startConversation"
        :is-submitting="submittingConversation"
        @message="submitMessage($event)"
      />

      <ConversationGrid
        :node-id="storyId"
        :node-type="NodeType.STORY"
        @conversationCountEdit="handleConversationUpdate"
      />

      <div
        v-if="story.crossContent.length > 0"
        class="golden-book-single-story__container golden-book-single-story__similar"
      >
        <div class="golden-book-single-story__cross-content">
          <div
            v-for="(content, index) in story.crossContent"
            :key="content.ID"
            class="golden-book-single-story__cross-container"
          >
            <GoldenBookCard
              v-if="
                ($matchMedia.xxxs && !$matchMedia.s) ||
                (index < 2 && $matchMedia.s) ||
                $matchMedia.xl
              "
              :id="content.ID"
              class="golden-book-single-story__cross-card"
              :title="content.title"
              with-counters
              :comment-counter="content.count_conversation"
              :like-counter="content.count_like"
              :author="content.author.pseudo"
              :author-id="content.author.ID"
              :background-image="content['images-portrait'][0].x1"
              light-social
              :with-story="!!content.description"
            />
          </div>
        </div>
      </div>
      <NotificationRequest
        v-if="AIShowModal"
        :authorizations="[AuthorizationType.STORY_COMMENT]"
        content-type="comment"
        @close="handleMessageSubmission(commentMessage)"
      />
    </template>

    <PageLoader v-else />
  </div>
</template>

<script>
import AuthorizationType from '@/utils/AuthorizationType';
import ContentAPI from '@/api/ContentAPI';
import GoldenBookStoryHeader from '@/components/golden-book/GoldenBookStoryHeader';
import PageLoader from '@/components/PageLoader';
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';

import RouteName from '@/utils/RouteName';
import StartConversation from '@/components/conversation/StartConversation';
import ConversationGrid from '@/components/conversation/ConversationGrid';
import { VACButton } from '@webqam-vac-ui/lib';
import VideoPlayer from '@/components/VideoPlayer';

import Likeable from '@/mixins/Likeable';
import BusName from '@/utils/BusName';
import { preloadImage } from '@/utils/Preloading';
import NodeType from '@/utils/NodeType';
import Tracking from '@/mixins/Tracking';
import NotificationRequest from '@/components/NotificationRequest.vue';
import AuthorizationInterceptor from '@/mixins/AuthorizationInterceptor';

export default {
  name: 'GoldenBookSingleStory',
  components: {
    ConversationGrid,
    StartConversation,
    GoldenBookStoryHeader,
    PageLoader,
    GoldenBookCard,
    VACButton,
    VideoPlayer,
    NotificationRequest,
  },
  mixins: [Likeable, Tracking, AuthorizationInterceptor],
  props: {
    storyId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      RouteName,
      story: null,
      iLike: undefined,
      submittingConversation: false,
      NodeType,
      isImageLoaded: false,
      AuthorizationType,
      commentMessage: undefined,
    };
  },
  computed: {
    paragraphs() {
      if (this.story.description) {
        return this.story.description.split('\n');
      }
      return [];
    },
    /**
     * @return {Date}
     */
    date() {
      return new Date(this.story.date * 1000);
    },
    /**
     * @return {String}
     */
    image() {
      return this.story['images-portrait'][0].x1;
    },
    likeCount() {
      if (!this.story.i_like && this.iLike) {
        return this.story.count_like + 1;
      }
      if (this.story.i_like && !this.iLike) {
        return this.story.count_like - 1;
      }
      return this.story.count_like;
    },
    hasVideo() {
      return this.story.video;
    },
    isFullyLoaded() {
      return this.story.video || this.isImageLoaded;
    },
  },
  watch: {
    storyId() {
      this.story = undefined;
      this.fetchStory();
    },
    isImageLoaded(loaded) {
      if (loaded) {
        if (this.$route.hash === '#comments') {
          setTimeout(() => {
            this.scrollToComment();
          }, 200);
        }
      }
    },
  },
  mounted() {
    this.fetchStory();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    submitMessage(message) {
      this.commentMessage = message;
      if (
        !localStorage.getItem(`${AuthorizationType.STORY_COMMENT}_refused`) &&
        !this.$store.state.profile.notifications[
          AuthorizationType.STORY_COMMENT
        ]
      ) {
        this.AIShowModal = true;
      } else {
        this.handleMessageSubmission(message);
      }
    },
    async fetchStory() {
      let article = undefined;
      const { data } = await ContentAPI.getStories({
        id: this.storyId,
      });
      if (data.nodes.length === 1) {
        article = data.nodes[0];
      }
      if (article) {
        this.story = article;
        this.trackPageView(
          `${this.$t('title.golden_book')} | ${this.story.title}`
        );
        preloadImage(this.story['images-portrait'][0].x1, () => {
          this.isImageLoaded = true;
        });
        this.iLike = this.story.i_like;
      } else {
        throw new Error(
          `An error has occurred while fetching story #${this.storyId}`
        );
      }
    },
    async handleMessageSubmission(message) {
      this.submittingConversation = true;
      const { data } = await ContentAPI.postConversation(this.storyId, message);
      this.$root.$emit(BusName.NEW_CONVERSATION, data);
      this.$refs.startConversation.clearText();
      this.submittingConversation = false;
      this.commentMessage = undefined;
      if (this.AIShowModal) {
        this.AIShowModal = false;
      }
    },
    likeStory() {
      this.iLike = !this.iLike;
      this.setLike(parseInt(this.storyId), this.iLike);
    },
    handleConversationUpdate(payload) {
      this.story.count_conversation = payload;
    },
    scrollToComment() {
      const offsetTop = -100;
      const y =
        this.$refs.startConversation.$el.getBoundingClientRect().top +
        window.pageYOffset +
        offsetTop;

      window.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.golden_book')} | ${
        this.story ? this.story.title : ''
      }`,
    };
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');

.golden-book-single-story {
  $margin: 1.5em;

  &__container {
    @extend %base-container;
  }

  &__content {
    display: grid;
    margin-bottom: 2rem;
    gap: 1.85rem;
    @include breakpoint($breakpoint-l) {
      grid-template-columns: 1fr 2fr;
    }
  }

  &__back-btn {
    gap: 0;
    padding-right: 0.25rem;
  }

  &__image,
  &__description,
  &__author {
    margin-top: $margin;

    @include breakpoint($breakpoint-l) {
      margin: $margin auto 0;
    }
  }

  &__image {
    position: relative;
    display: block;
    margin: $margin auto 0;

    @include breakpoint($breakpoint-s) {
      max-width: 26.25em;
    }
  }

  &__video {
    position: relative;
    margin: $margin auto 0;
    aspect-ratio: 33/49;

    @include breakpoint($breakpoint-s) {
      max-width: 26.25em;
    }
  }

  &__description,
  &__author {
    @include breakpoint($breakpoint-l) {
      max-width: 44em;
    }
  }

  &__author {
    padding-bottom: 0.5em;
    font-family: 'Mr Dafoe', sans-serif;
    text-align: right;
  }

  ::v-deep {
    .golden-book-story-header {
      position: relative;
      text-align: center;
    }

    .golden-book-story-social {
      margin-top: 1.5em;
      justify-content: center;
    }

    .dynamic-background-layer__background {
      display: none;

      @include breakpoint($breakpoint-tablet) {
        display: block;
      }
    }
  }

  &__separator {
    border-top: 0.5px solid $color-shuttle-gray;
    margin-top: 1em;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-l) {
      width: 38.125em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__cross-title {
    font-family: $font-prelo-condensed;
    margin-bottom: 1em;
    margin-top: 0.875em;
  }

  &__cross-content {
    @include breakpoint($breakpoint-tablet) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: dense;
      grid-gap: 2.75em;
      grid-auto-rows: minmax(100px, auto);
    }

    @include breakpoint($breakpoint-desktop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__cross-container {
    margin-bottom: 1em;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  &__cross-card {
    min-height: 30.813em;
    width: 20.625em;
  }

  &__similar {
    border-top: 2px solid $color-wild-sand;
    padding-top: 2rem;
  }
}
</style>
