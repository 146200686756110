<template>
  <div :class="blockClasses">
    <div class="magazine-slider__slides">
      <BlockGallery
        :images="paragraph.images"
        out-width
        arrows
        :image-per-slide="
          paragraph.alignment === 'full-width' && paragraph.images.length > 1
            ? slidesPerViewMultipleSlides
            : 1
        "
      />
    </div>
    <!--eslint-disable-next-line vue/no-v-html-->
    <p class="magazine-slider__content" v-html="paragraph.description"></p>
  </div>
</template>
<script>
import BlockGallery from '@/components/gallery/BlockGallery';

const BLOCK_SELECTOR = 'magazine-slider';

export default {
  name: 'MagazineSlider',
  components: { BlockGallery },
  props: {
    paragraph: { type: Object, required: true },
  },
  computed: {
    slidesPerViewMultipleSlides() {
      return this.isLandscape
        ? 1
        : this.$matchMedia.xl
        ? 3
        : this.$matchMedia.s
        ? 2
        : 1;
    },
    blockClasses() {
      return [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${this.paragraph.alignment}`];
    },
    isLandscape() {
      return this.paragraph.bundle === 'slider_landscape';
    },
  },
};
</script>
<style lang="scss">
.magazine-slider {
  $block-selector: &;

  &--left,
  &--right {
    @include breakpoint($breakpoint-tablet) {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    #{$block-selector}__slides {
      @include breakpoint($breakpoint-tablet) {
        width: 40%;
      }
    }
  }

  &--right {
    @include breakpoint($breakpoint-tablet) {
      flex-direction: row-reverse;
    }
  }

  &--full-width {
    @include breakpoint($breakpoint-tablet) {
      width: calc(100% + 2em);
      margin-left: -1em;
    }
  }

  &__content {
    @extend %base-container;
    margin-top: 1em;
  }
}
</style>
