import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import 'normalize.css';
import '@/scss/main.scss';
import vuetify from './plugins/vuetify';
import VueMatchMedia from '@webqam/vue-match-media';
import breakpoints from './utils/breakpoints';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import PortalVue from 'portal-vue';
import 'core-js/stable';
import 'core-js/modules/es.object.from-entries';
import 'regenerator-runtime/runtime';
import FlashPlugin from '@/plugins/flash';
import { VueMasonryPlugin } from 'vue-masonry';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import GTMMaker from '@/utils/GTMMaker';
import VueCookies from 'vue-cookies';
import OneSignalVue from 'onesignal-vue';

Vue.use(VueMatchMedia, { breakpoints });
Vue.use(PortalVue);
Vue.use(VueAwesomeSwiper);
Vue.use(VueMeta);
Vue.use(FlashPlugin);
Vue.use(VueMasonryPlugin);
Vue.use(PerfectScrollbar);
Vue.use(VueCookies);
Vue.use(OneSignalVue);

Vue.config.productionTip = false;

GTMMaker(process.env.VUE_APP_GTM);

new Vue({
  name: 'App',
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  beforeMount() {
    if (process.env.VUE_APP_OS_ID) {
      this.$OneSignal.init({
        appId: process.env.VUE_APP_OS_ID,
        safari_web_id: process.env.VUE_APP_IS_SAFARI_WID,
      });
    }
  },
}).$mount('#app');
