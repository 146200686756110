<template>
  <div class="golden-book-story-header">
    <h1 class="golden-book-story-header__title">{{ title }}</h1>

    <div class="golden-book-story-header__info">
      <span v-if="author" class="golden-book-story-header__by">
        {{ $t('goldenBook.story.header.by') }}
        <RouterLink
          :to="{
            name: RouteName.USER_PROFILE,
            params: { profileId: authorId },
          }"
          class="golden-book-story-header__by-link"
          @click.native="trackUserProfilClick(TrackingAreas.CONTENT)"
        >
          {{ author }}
        </RouterLink>
      </span>
      <span class="golden-book-story-header__date">{{ formattedDate }}</span>
    </div>

    <GoldenBookStorySocial
      v-if="withCounters"
      :comment-counter="commentCounter"
      :i-like="iLike"
      :like-counter="likeCounter"
      @like="$emit('like')"
      @comment="$emit('comment')"
    />
  </div>
</template>

<script>
import GoldenBookStorySocial from '@/components/golden-book/GoldenBookStorySocial';
import { formatDate } from '@/utils/DateUtils';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';
export default {
  name: 'GoldenBookStoryHeader',
  components: {
    GoldenBookStorySocial,
  },
  mixins: [Tracking],
  props: {
    title: {
      type: String,
      required: true,
    },
    author: {
      default: undefined,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    authorId: {
      type: String,
      default: undefined,
    },
    date: {
      type: Date,
      required: true,
    },
    withCounters: Boolean,
    iLike: Boolean,
    likeCounter: {
      type: Number,
      default: 0,
    },
    commentCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    /**
     * @return {string}
     */
    formattedDate() {
      return formatDate(this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-story-header {
  &__title {
    font-size: 1.5625em;
    font-weight: 200;
    text-transform: uppercase;
  }

  &__info {
    margin-top: 0.625em;
  }

  &__by-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__by {
    display: block;
    @include breakpoint($breakpoint-tablet) {
      display: inline;
      &::after {
        content: '•';
        margin: 0 0.5em;
      }
    }
  }
}
</style>
