<template>
  <component
    :is="componentType"
    ref="field"
    v-model="content"
    :label="$t('conversation.placeholder')"
    class="field-text-with-emoji"
    :rules="rules"
    :disabled="disabled"
    :max-length="counter"
    :current-length="content.length"
    :name="name"
    rows="2"
    no-resize
    required
    @extended="$emit('extended')"
  >
    <template #append>
      <EmojiPicker
        class="field-text-with-emoji__picker"
        @insert="insertEmoji($event)"
      />
    </template>
  </component>
</template>

<script>
import { VACInput, VACTextarea } from '@webqam-vac-ui/lib';
import EmojiPicker from '@/components/form/EmojiPicker';

export default {
  name: 'FieldTextWithEmoji',
  components: { EmojiPicker, VACInput, VACTextarea },
  props: {
    value: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    counter: { type: Number, default: 300 },
    textArea: Boolean,
    name: { type: String, default: 'field' },
    disabled: Boolean,
  },
  data() {
    return {
      isOverflowing: false,
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    componentType() {
      return this.textArea ? VACTextarea : VACInput;
    },
    componentSelector() {
      return this.textArea ? 'textarea' : 'input';
    },
  },
  methods: {
    insertEmoji(emoji) {
      const position = this.$refs.field.$el.querySelector(
        this.componentSelector
      ).selectionStart;
      this.content =
        this.content.slice(0, position) +
        emoji.native +
        this.content.slice(position);
    },
  },
};
</script>

<style scoped lang="scss">
.field-text-with-emoji {
  &::v-deep {
    .v-text-field__slot {
      textarea {
        overflow: hidden;
        padding-right: 2em;
        line-height: 1.5em;
      }
    }
  }

  &__picker {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
