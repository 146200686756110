<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal
      is-active
      class="golden-book-preview-modal"
      transition-name="golden-book-preview-modal-"
      @close="close"
    >
      <template #container>
        <div class="golden-book-preview-modal__header">
          <VACButton
            class="golden-book-preview-modal__close"
            discrete
            icon="close"
            @click="close"
          />

          <div class="golden-book-preview-modal__title">
            {{ $t('goldenBook.new.preview.title') }}
          </div>
        </div>

        <div class="golden-book-preview-modal__body">
          <GoldenBookCard
            :background-image="storyImageUrl"
            :title="story.title"
            :with-story="!!story.message"
          />

          <VACButton
            class="golden-book-preview-modal__button"
            :label="$t('goldenBook.new.preview.buttons.close')"
            full-width
            @click="close"
          />
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import BaseModal from '@/components/BaseModal';
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';
import PortalName from '@/utils/PortalName';

export default {
  name: 'GoldenBookPreviewModal',
  components: { VACButton, BaseModal, GoldenBookCard },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      PortalName,
    };
  },
  computed: {
    /**
     * @return {string|null}
     */
    storyImageUrl() {
      return this.story && this.story.croppedFile
        ? this.story.croppedFile.url
        : this.story.mediaData
        ? this.story.mediaData.data
        : undefined;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-preview-modal {
  &__header {
    @extend %base-modal__header;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    font-weight: bold;
    color: $color-black;
    text-transform: uppercase;
  }

  &__body {
    padding: 1em;
  }

  &__button {
    margin-top: 1em;
  }

  ::v-deep {
    .base-modal {
      &__container {
        max-width: 22em;
        width: 100%;
        overflow: auto;
      }
    }

    .golden-book-card {
      margin: 0;

      &__background {
        min-height: 29.875em;
        width: 100%;
      }
    }
  }
}
</style>
