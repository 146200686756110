<template>
  <div>
    <VACExpandableSection
      is-open
      large
      header-small-spacing
      class="account-boutique"
      @toggle="isOpen = $event"
    >
      <template #title>
        {{ $t('account.myBoutique.title') }}
      </template>
      <template v-if="findLink" #title-link>
        <VACButton
          v-if="isOpen"
          appearance="link"
          :href="findLink"
          target="_blank"
          class="account-boutique__find"
          icon="outside-link"
          underlined-label
          :label="$t('account.myBoutique.findMore')"
        />
      </template>
      <template #body>
        <Spinner v-if="isLoading" />
        <div v-else class="account-boutique__body">
          <template v-if="boutique">
            <div class="account-boutique__banner-container">
              <img
                v-if="bannerImage"
                :src="bannerImage"
                class="account-boutique__banner"
              />
            </div>
            <div class="account-boutique__infos">
              <div class="account-boutique__name">{{ boutique.title }}</div>
              <div class="account-boutique__columns">
                <ul class="account-boutique__list account-boutique__infos-list">
                  <template v-for="(item, name, id) in infosList">
                    <li
                      v-if="item"
                      :key="`info-item-${id}`"
                      class="account-boutique__info-item"
                    >
                      <span :class="iconClasses(name)"></span>
                      <span class="account-boutique__inner-item">
                        {{ item }}
                      </span>
                    </li>
                  </template>

                  <li class="account-boutique__info-item">
                    <div :class="iconClasses('address')"></div>
                    <div class="account-boutique__inner-item">
                      <div
                        v-for="(line, name) in storeAddress"
                        :key="name"
                        class="account-boutique__address-line"
                      >
                        {{ line }}
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  v-if="boutique.services"
                  class="account-boutique__services"
                >
                  <div class="account-boutique__services-title">
                    {{ $t('account.myBoutique.services') }}
                  </div>
                  <ul
                    class="account-boutique__list account-boutique__services-list"
                  >
                    <li
                      v-for="(service, index) of boutique.services"
                      :key="`service-${index}`"
                      class="account-boutique__services-item"
                    >
                      - {{ service }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="account-boutique__button-container">
              <VACButton
                :label="$t('account.myBoutique.bookAppointment')"
                :href="bookingLink"
                target="_blank"
                class="account-boutique__book"
              />
            </div>
          </template>
          <template v-else>
            <div class="account-boutique__fallback">
              <p class="account-boutique__fallback-message">
                {{ $t('account.myBoutique.fallbackMessage') }}
              </p>
              <VACButton
                primary
                icon="arrow-right"
                target="_blank"
                :href="findLink"
                :label="$t('account.myBoutique.findBoutique')"
              />
            </div>
          </template>
        </div>
      </template>
    </VACExpandableSection>
  </div>
</template>
<script>
import ContentAPI from '@/api/ContentAPI';
import Spinner from '@/components/Spinner';
import languageMixin from '@/mixins/Language';
import bannerFallback from '../../assets/store_banner.jpg';

const ICON_CLASS = 'icon';
import { VACButton, VACExpandableSection } from '@webqam-vac-ui/lib';
export default {
  name: 'AccountBoutique',
  components: {
    VACExpandableSection,
    VACButton,
    Spinner,
  },
  mixins: [languageMixin],
  data() {
    return {
      boutique: null,
      loading: true,
      isOpen: true,
      bannerFallback,
    };
  },
  computed: {
    infosList() {
      return {
        phone: this.boutique.phone,
        email: this.boutique.email,
      };
    },
    storeAddress() {
      return {
        line1: this.boutique.address.address_line1,
        line2: `${this.boutique.address.postal_code}, ${this.boutique.address.locality}`,
      };
    },
    findLink() {
      return this.language === 'en'
        ? 'https://stores.vacheron-constantin.com/search'
        : `https://stores.vacheron-constantin.com/${this.language}/search`;
    },
    bookingLink() {
      return `https://www.vacheron-constantin.com/${
        this.region === 'Int' ? 'ww' : 'cn'
      }/${this.language}/secure/boutique-appointment.html`;
    },
    isLoading() {
      return this.loading && !this.boutique;
    },
    bannerImage() {
      return this.boutique.image ?? this.bannerFallback;
    },
  },
  methods: {
    iconClasses(name) {
      return [
        'account-boutique__info-icon',
        ICON_CLASS,
        [`${ICON_CLASS}--${name}`],
      ];
    },
    async getInfos() {
      await ContentAPI.getPreferredStore(
        this.$store.state.auth.userInfos.id
      ).then(({ data }) => {
        if (data) {
          this.boutique = data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.getInfos();
  },
};
</script>
<style lang="scss">
.account-boutique {
  position: relative;
  &__find {
    padding: 0;
    display: flex;
    gap: 0.5rem;

    &.base-btn {
      font-family: $font-prelo;
      .base-btn__body::after {
        transform: scale(1);
      }
    }
  }

  &__banner-container {
    background-color: $color-rhino;
    height: 9.375rem;
    margin: 0 1rem -4.75rem;
    position: relative;
    z-index: 2;
    @include breakpoint($breakpoint-desktop) {
      height: 13.438rem;
      margin: 0 2rem -1.75rem;
    }
  }

  &__banner {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__infos {
    padding: 6rem 1rem 0.75rem;
    background-color: $color-wild-sand;
    margin: 0 -2.25rem;
    @include breakpoint($breakpoint-desktop) {
      padding: 3.75rem 2rem 0.75rem;
    }
  }

  &__columns {
    position: relative;
    display: grid;
    gap: 1rem;
    @include breakpoint($breakpoint-tablet) {
      grid-template-columns: 1fr 1.25fr;
    }
  }

  .v-application &__list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__name {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  &__info-icon {
    color: $color-tan;
  }

  &__info-item {
    margin-bottom: 0.5rem;
    display: flex;
    gap: 0.25rem;
  }

  &__inner-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  &__info-icon {
    margin-top: 0.25rem;
  }

  &__book {
    margin-top: 2rem;
  }

  &__button-container {
    padding: 1rem;
  }

  &__fallback {
    width: calc(100% + 4.5em);
    margin: 0 -2.25em -2.2em;
    background-color: $color-wild-sand;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    font-family: $font-family-prelo;
    @include breakpoint($breakpoint-tablet) {
      padding: 2rem 2rem 2.5rem;
    }
  }

  &__address-line {
    margin-bottom: 0;
  }

  &__button-container {
    display: grid;
    place-items: center;
  }

  &__find {
    &.vac-button {
      gap: 0.5rem;
    }
    .vac-icon {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
}
</style>
