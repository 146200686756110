<template>
  <div class="spy"></div>
</template>

<script>
export default {
  name: 'Spy',
  mounted() {
    this.observe();
  },
  methods: {
    observe() {
      const observer = new IntersectionObserver(
        function (observables) {
          observables.forEach(
            function (observable) {
              if (observable.isIntersecting) {
                this.$emit('reached');
              }
            }.bind(this)
          );
        }.bind(this)
      );

      observer.observe(this.$el);
    },
  },
};
</script>
