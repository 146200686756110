<template>
  <div class="onboarding-question">
    <div
      class="onboarding-question__container onboarding-question__container--images"
      v-if="!noImage"
    >
      <img
        v-if="
          onboardingCurrentQuestion['#page_images'].background &&
          onboardingCurrentQuestion['#page_images'].background.display !== null
        "
        :src="onboardingCurrentQuestion['#page_images'].background.src"
        :class="[
          'onboarding-question__image',
          'onboarding-question__image--background',
          `onboarding-question__image--${onboardingCurrentQuestion['#page_images'].background.display}`,
          {
            'onboarding-question__image--solo':
              !onboardingCurrentQuestion['#page_images'].foreground,
          },
        ]"
        alt=""
      />
      <img
        v-if="
          onboardingCurrentQuestion['#page_images'].foreground &&
          onboardingCurrentQuestion['#page_images'].foreground.display !== null
        "
        :src="onboardingCurrentQuestion['#page_images'].foreground.src"
        :class="[
          'onboarding-question__image',
          'onboarding-question__image--foreground',
          `onboarding-question__image--${onboardingCurrentQuestion['#page_images'].foreground.display}`,
        ]"
        alt=""
      />
    </div>
    <div
      :class="[
        'onboarding-question__container',
        'onboarding-question__container--question',
        {
          ['onboarding-question__container--solo']: noImage,
          ['onboarding-question__container--center']: isStatDisplay,
        },
      ]"
    >
      <VACStepper
        v-if="!isStatDisplay"
        :max="onboardingQuestionCount"
        :current="onboardingCurrentQuestionIndex + 1"
        :center="noImage && $matchMedia.xl"
      />
      <p class="onboarding-question__title">
        <span
          v-if="isStatDisplay && typeof statPercent !== 'undefined'"
          class="onboarding-question__number"
        >
          {{ statPercent }} %
        </span>
        {{ pageTitle }}
      </p>
      <OnboardingRender
        class="onboarding-question__render"
        v-for="(item, index) in onboardingCurrentQuestion.elements"
        :key="`render-${index}`"
        :element="item"
        :all-responses="value"
        v-model="selection[item['#name']]"
      />
      <template v-if="$matchMedia.l">
        <div class="onboarding-question__actions">
          <VACButton
            v-if="!isStatDisplay"
            class="onboarding-question__button"
            :label="$t('onboarding.question.prev')"
            appearance="secondary"
            icon="arrow-left"
            reverse
            @click.native="goBack"
          />
          <VACButton
            class="onboarding-question__button"
            :label="$t('onboarding.question.next')"
            appearance="primary"
            @click.native="submitResponse"
          />
        </div>
      </template>
      <template v-else>
        <Portal :to="PortalName.MODAL_ACTIONS">
          <div class="onboarding-question__actions">
            <VACButton
              v-if="!isStatDisplay"
              class="onboarding-question__button"
              :label="$t('onboarding.question.prev')"
              appearance="secondary"
              icon="arrow-left"
              reverse
              @click.native="goBack"
            />
            <VACButton
              class="onboarding-question__button"
              :label="$t('onboarding.question.next')"
              appearance="primary"
              @click.native="submitResponse"
            />
          </div>
        </Portal>
      </template>
    </div>
  </div>
</template>
<script>
import Onboarding from '@/mixins/Onboarding';
import { VACStepper, VACButton } from '@webqam-vac-ui/lib';
import OnboardingRender from './OnboardingRender.vue';
import PortalName from '@/utils/PortalName';

export default {
  name: 'OnboardingQuestion',
  mixins: [Onboarding],
  components: { VACStepper, OnboardingRender, VACButton },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      PortalName,
    };
  },
  computed: {
    selection: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', { ...this.value, ...newVal });
      },
    },
    selectedSubject() {
      if (
        this.isStatDisplay &&
        this.value[this.onboardingCurrentQuestion['#stats_input_origin']]
      ) {
        const previousQuestion =
          this.onboardingData.form.pages[
            this.onboardingCurrentQuestionIndex - 1
          ];
        if (previousQuestion) {
          const answers = previousQuestion.elements.find(
            (element) =>
              element['#name'] ===
              this.onboardingCurrentQuestion['#stats_input_origin']
          );
          if (
            answers &&
            this.onboardingCurrentQuestion['#stats_input_origin']
          ) {
            const userAnswers = answers['#options'].filter((answer) =>
              this.value[
                this.onboardingCurrentQuestion['#stats_input_origin']
              ].includes(answer.value)
            );
            if (userAnswers && userAnswers.length > 0) {
              return userAnswers.sort((a, b) => b.percentage - a.percentage)[0];
            }
          }
        }
      }
      return { percentage: 0, label: '' };
    },
    isStatDisplay() {
      return !!this.onboardingCurrentQuestion['#stats_input_origin'];
    },
    statPercent() {
      return this.selectedSubject ? this.selectedSubject.percentage : undefined;
    },
    pageTitle() {
      if (this.isStatDisplay && this.statPercent) {
        return `${this.onboardingCurrentQuestion['#title'].replace(
          '{selected}',
          this.selectedSubject.label
        )}`;
      }
      return this.onboardingCurrentQuestion['#title'];
    },
    noImage() {
      return (
        !this.onboardingCurrentQuestion['#page_images'] ||
        (!this.onboardingCurrentQuestion['#page_images'].background &&
          !this.onboardingCurrentQuestion['#page_images'].foreground) ||
        (!this.$matchMedia.xl &&
          this.onboardingCurrentQuestion['#page_images'].hide_mobile_image)
      );
    },
  },
  methods: {
    getCleanedForKey(key) {
      return key.replaceAll('[checkboxes]', '');
    },
    submitResponse() {
      this.$emit('goNext', this.selection);
    },
    goBack() {
      if (this.onboardingCurrentQuestionIndex > 0) {
        this.$emit('goBack');
      } else {
        this.$emit('goIntro');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.onboarding-question {
  $desktop-image-left: 3em;
  $desktop-image-top: 5.5em;
  $desktop-image-bottom: 3.3em;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 3rem;
  overflow-y: scroll;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  @include breakpoint($breakpoint-desktop) {
    min-height: 35rem;
    flex-direction: row;
    overflow: visible;
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint($breakpoint-desktop) {
      display: block;
    }
    &--question {
      flex-grow: 1;

      @include breakpoint($breakpoint-desktop) {
        padding-left: 7.1875rem;
      }
    }

    &--images {
      position: relative;
      width: 70%;
      flex-basis: 35%;
      min-height: 18rem;
      flex-shrink: 0;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint($breakpoint-desktop) {
        flex-basis: 40%;
        min-width: 31.25rem;
        height: auto;
        margin-bottom: -#{$desktop-image-bottom};
        margin-top: -#{$desktop-image-top};
        margin-left: -#{$desktop-image-left};
      }
    }

    &--center {
      @include breakpoint($breakpoint-desktop) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--solo {
      padding: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint($breakpoint-desktop) {
        align-items: center;
        max-width: 43.75rem;
      }
    }
  }

  &__image {
    display: block;
    object-fit: cover;
    max-width: none;
    box-sizing: border-box;
    width: 8.4375rem;
    height: 11.875rem;

    @include breakpoint($breakpoint-desktop) {
      width: auto;
      height: auto;
    }

    &--background {
      position: absolute;
      left: 0;

      @include breakpoint($breakpoint-desktop) {
        top: 0;
      }
    }

    &--full {
      bottom: 0;
      right: 0;
      height: 100%;

      @include breakpoint($breakpoint-desktop) {
        bottom: 0;
      }
    }

    &--normal {
      width: 8.4375rem;

      @include breakpoint($breakpoint-xs) {
        width: 12rem;
        height: 17rem;
      }

      @include breakpoint($breakpoint-desktop) {
        top: calc($desktop-image-top / 2);
        left: $desktop-image-left;
        bottom: $desktop-image-bottom;
        width: 25rem;
        height: 35rem;
      }
    }

    &--foreground {
      position: absolute;
      right: 0;
      @include breakpoint($breakpoint-desktop) {
        max-height: 20rem;
        width: unset;
        right: -4.5em;
        height: auto;
      }
    }

    &--middle {
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint($breakpoint-xs) {
        width: 12rem;
        height: 17rem;
      }
    }

    &--bottom {
      bottom: 0;
      top: auto;

      @include breakpoint($breakpoint-desktop) {
        bottom: $desktop-image-bottom;
      }
    }

    &--solo {
      position: relative;
      width: 13.5rem;
      height: 19.9375rem;
      margin-left: -20%;

      @include breakpoint($breakpoint-desktop) {
        position: absolute;
        margin-left: 0;
        width: 100%;
        height: 90%;
      }
    }
  }

  &__title {
    color: $color-black;
    font-family: $font-family-prelo-condensed;
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-top: 0.625rem;

    @include breakpoint($breakpoint-desktop) {
      font-size: 1.875rem;
    }
  }

  &__render {
    margin-bottom: 1rem;
    width: 100%;
  }

  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.25rem;
    background: linear-gradient(180deg, transparent 0%, white 120%);
    margin-top: auto;
    display: flex;
    gap: 1rem;
    align-items: center;

    @include breakpoint($breakpoint-desktop) {
      margin-top: 0;
      position: relative;
      padding-left: 0;
      inset: 0;
      background: none;
    }
  }

  &__button {
    flex-grow: 1;
    font-size: 0.75rem;
    flex-basis: 50%;

    @include breakpoint($breakpoint-desktop) {
      width: 11.25rem;
      flex-grow: 0;
      flex-basis: auto;
    }
  }

  &__number {
    display: block;
    font-weight: bold;
    font-size: 6.25rem;
    color: $color-indian-khaki;
    font-family: $font-family-prelo-condensed;
  }
}
</style>
