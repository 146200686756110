<template>
  <div v-if="article" class="card-article">
    <router-link
      :to="{
        name: RouteName.SINGLE_ARTICLE,
        params: { articleId: article.ID },
      }"
      class="card-article__link"
    />
    <div class="card-article__image-container">
      <img
        :src="article['images-thumbnail'][0].x1"
        alt=""
        class="card-article__image"
      />
      <ArticleBadge
        class="card-article__badge"
        :type="article.category.label"
      />
    </div>
    <div class="card-article__infos">
      <p class="card-article__info card-article__info--date">
        {{ formattedDate }}
      </p>
      <p class="card-article__info card-article__info--name">
        {{ article.title }}
      </p>
      <!--eslint-disable vue/no-v-html-->
      <p
        class="card-article__info card-article__info--description"
        v-html="getFirstParagraph"
      ></p>
      <!--eslint-enable vue/no-v-html-->
    </div>
  </div>
</template>

<script>
import ArticleBadge from '@/components/badge/ArticleBadge';
import RouteName from '@/utils/RouteName';
import { formatDate } from '@/utils/DateUtils';
import { stripHtml } from '@/utils/String';

export default {
  name: 'CardArticle',
  components: { ArticleBadge },
  props: {
    article: { type: Object, default: undefined },
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    getFirstParagraph() {
      if (this.article.description) {
        const splitArt = this.article.description
          .split('</p>')[0]
          .replace('<p>', '');
        return stripHtml(splitArt);
      }
      return undefined;
    },
    formattedDate() {
      return formatDate(this.article.date);
    },
  },
};
</script>

<style scoped lang="scss">
.card-article {
  $block-selector: &;
  background-color: $color-white;
  width: 20em;
  box-shadow: 0 0 6px #00000029;
  position: relative;
  margin-bottom: 1em;

  @include breakpoint($breakpoint-tablet) {
    margin-bottom: 0;
  }

  &:hover {
    #{$block-selector}__image {
      transform: scale(1.2);
    }
  }

  &__image {
    width: 100%;
    transition: transform $default-transition-duration;
  }

  &__image-container {
    overflow: hidden;
    position: relative;
  }

  &__badge {
    position: absolute;
    bottom: 1em;
  }

  &__infos {
    padding: 0.625em;
  }

  &__info {
    display: block;
    font-family: $font-family-prelo-condensed;
    color: $color-black;

    &--date {
      color: $color-beaver;
      font-size: 0.75em;
      margin-bottom: 0.825em;
    }

    &--name {
      font-weight: bold;
      font-size: 1.25em;
      text-transform: uppercase;
      margin-bottom: 0.5em;
    }

    &--description {
      @include line-clamp(3);

      margin-bottom: 0;
    }
  }

  &__link {
    @extend %block-abs-tlbr-zero;
    z-index: 2;
  }
}
</style>
