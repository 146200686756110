<template>
  <div class="contact">
    <MainTitle>{{ $t('cms.contact.title') }}</MainTitle>
    <div class="contact__container">
      <!-- eslint-disable vue/no-v-html, vue-i18n/no-v-html -->
      <p
        class="contact__description"
        v-html="$t('cms.contact.description')"
      ></p>
      <!--eslint-enable-->
      <ContactForm />
    </div>
  </div>
</template>

<script>
import ContactForm from '@/components/form/ContactForm';
import MainTitle from '@/components/MainTitle';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'Contact',
  components: { MainTitle, ContactForm },
  mixins: [Tracking],
  mounted() {
    this.trackPageView('Contact');
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__container {
    @extend %base-container;
  }

  &__description {
    text-align: center;
  }
}
</style>
