import Internationalization from '@/utils/Internationalization';
import i18n from '@/i18n';
import { DateTimeFormatOptions, TranslateResult } from 'vue-i18n';
import LangMap from '@/enums/LangMap';

const localeTable = Object.freeze({
  cn: 'zh',
  en: 'en',
});

function getTransLocale() {
  if (LangMap.has(Internationalization.getCurrentLocale())) {
    return LangMap.get(Internationalization.getCurrentLocale());
  }
  return Internationalization.getCurrentLocale();
}

export function toIsoDate(date: Date): string {
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() + 1
  }-${date.getUTCDate()}`;
}

function isValidDate(date) {
  return (
    typeof date === 'object' &&
    typeof date.getTime === 'function' &&
    !isNaN(date.getTime())
  );
}

function checkTimestamp(date) {
  const tmp = date.toString().split('-');

  if (tmp.length === 1) {
    // it must be a timestamp
    return date * 1000;
  }

  return date;
}

export function formatDate(
  date,
  locales = getTransLocale(),
  options: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
) {
  if (date) {
    const dateObj =
      date instanceof Date ? date : new Date(checkTimestamp(date));

    if (isValidDate(dateObj)) {
      return new Intl.DateTimeFormat(locales, options).format(dateObj);
    }
  }

  return null;
}

export function formatDateAndTime(date) {
  return formatDate(
    date,
    localeTable[Internationalization.getCurrentLocale()],
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }
  );
}

export function frenchDateToDate(date): Date {
  if (typeof date === 'string') {
    const explodedDate = date.split('/');
    return new Date(`${explodedDate[2]}-${explodedDate[1]}-${explodedDate[0]}`);
  }
  return new Date();
}

export function getAge(birthDate): number {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export function timestampToRelativeDate(date): string | TranslateResult {
  const dateFormatter = new Intl.RelativeTimeFormat(
    localeTable[Internationalization.getCurrentLocale()]
  );

  if (typeof date === 'string') {
    date = new Date(parseInt(date) * 1000);
  }
  if (typeof date === 'number') {
    date = new Date(date * 1000);
  }
  const now = new Date();

  const diffMs = now.getTime() - date.getTime();
  const diffMins = Math.floor(diffMs / 60000);
  const diffHrs = Math.floor(diffMs / 36e5);
  const diffDays = Math.floor(diffMs / 864e5);

  if (diffMins === 0) {
    return i18n.t('now');
  }

  if (diffMins < 60) {
    return dateFormatter.format(-diffMins, 'minute');
  }

  if (diffHrs < 24) {
    return dateFormatter.format(-diffHrs, 'hour');
  }

  if (diffDays < 7) {
    return dateFormatter.format(-diffDays, 'day');
  }

  return dateFormatter.format(-Math.floor(diffDays / 7), 'week');
}
