<template>
  <VTextField
    v-model="email"
    :disabled="disabled"
    :label="emailLabel"
    :required="required"
    :rules="emailRules"
    @input="$emit('input', email)"
  />
</template>

<script>
export default {
  name: 'FieldEmail',
  props: {
    required: Boolean,
    disabled: Boolean,
    value: { type: String, default: '' },
    rules: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    emailLabel() {
      const localizedLabel = this.$i18n.t('auth.form.email.label');
      return this.required ? `${localizedLabel} *` : localizedLabel;
    },
    emailRules() {
      return (
        this.rules ||
        (this.required
          ? [
              (v) => !!v || this.$t('auth.form.validation.required'),
              (v) =>
                /.+@.+\..+/.test(v) || this.$t('auth.form.validation.email'),
            ]
          : [
              (v) =>
                /.+@.+\..+/.test(v) || this.$t('auth.form.validation.email'),
            ])
      );
    },
  },
  watch: {
    value() {
      this.email = this.value;
    },
  },
  mounted() {
    this.email = this.value;
  },
};
</script>

<style scoped></style>
