<template>
  <portal :to="PortalName.STICKY_HEADER">
    <transition name="sticky-slide-up">
      <div v-if="show" class="sticky-header">
        <div class="sticky-header__container">
          <div class="sticky-header__content">
            <VACButton
              appearance="discrete"
              class="sticky-header__close"
              icon=""
              @click="hideHeader"
            />
            <slot name="left"></slot>
          </div>
          <div class="sticky-header__content">
            <slot name="right"></slot>
          </div>
        </div>
        <div
          class="sticky-header__progress"
          :style="`width:${progress}%`"
        ></div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';

import PortalName from '@/utils/PortalName';

export default {
  name: 'StickyHeader',
  components: { VACButton },
  props: {
    observerSelector: { type: String, required: true },
    progress: { type: Number, default: 0 },
  },
  data() {
    return {
      show: false,
      PortalName,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    hideHeader() {
      this.show = false;
    },
    setupIntersectionObserver() {
      const observer = new IntersectionObserver(
        function (observables) {
          observables.forEach(
            function (observable) {
              this.show = !observable.isIntersecting;
            }.bind(this)
          );
        }.bind(this),
        {
          threshold: 0.99,
        }
      );
      const items = document.querySelectorAll(this.observerSelector);
      items.forEach(function (item) {
        observer.observe(item);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5em;
  background-color: $color-white;
  z-index: 10;
  display: none;
  padding: 1em;
  box-shadow: 0 3px 6px #00000029;

  @include breakpoint($breakpoint-tablet) {
    display: block;
  }

  &__container {
    @extend %base-container;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__close {
    &::before {
      @extend %icon;
      @extend %icon--menu;
    }
    margin-right: 1em;
  }

  &__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: $color-beaver;
    transition: width $default-transition-duration;
  }
}

.sticky-slide-up-enter-active,
.sticky-slide-up-leave-active {
  transition: transform $default-transition-duration linear;
}

.sticky-slide-up-enter,
.sticky-slide-up-leave-to {
  transform: translateY(-5em);
}

.sticky-slide-up-enter-to,
.sticky-slide-up-leave {
  transform: translateY(0);
}
</style>
