<template>
  <div class="contact-form">
    <VForm
      ref="form"
      v-model="isFormValid"
      :lazy-validation="isFormLazy"
      class="contact-form__form"
      @submit.prevent="sendContactMessage"
    >
      <VACInput
        type="text"
        v-model="form.name"
        :disabled="isLoadingData"
        :label="$t('cms.contact.form.fullname')"
        :name="$t('cms.contact.form.fullname')"
        :rules="rules.name"
        required
      />
      <VACInput
        type="email"
        v-model="form.email"
        :disabled="isLoadingData"
        :label="$t('cms.contact.form.email')"
        :name="$t('cms.contact.form.email')"
        :rules="rules.email"
      />
      <VACPhone
        :country-codes="countries"
        :label="$t('cms.contact.form.phone')"
        :name="$t('cms.contact.form.phone')"
        :rules="rules.phone"
        :disabled="isLoadingData"
        v-model="form.phone"
      />

      <VACInput
        type="text"
        class="contact-form__fullwidth-field"
        :name="$t('cms.contact.form.subject')"
        v-model="form.subject"
        :label="$t('cms.contact.form.subject')"
        :rules="rules.subject"
        required
      />
      <VACTextarea
        v-model="form.message"
        class="contact-form__fullwidth-field"
        :label="$t('cms.contact.form.message')"
        :name="$t('cms.contact.form.message')"
        :rules="rules.message"
        :max-length="MESSAGE_MAX_LENGTH"
        :current-length="form.message.length"
        @overflowing="isOverflowing = true"
      />
      <div class="contact-form__captcha">
        <component
          :is="captchaComponentName"
          ref="captcha"
          :sitekey="siteKey"
          @verify="onVerify"
        />
      </div>
      <ErrorAlert v-if="error" @close="error = null">{{ error }}</ErrorAlert>
      <div class="contact-form__footer">
        <VACCheckbox
          v-model="form.allowMarketingInformations"
          :label="$t('auth.form.marketing-informations.label')"
          input-value="false"
          name="allowMarketing"
        />
        <!-- eslint-disable vue/no-v-html, vue-i18n/no-v-html -->
        <div class="contact-form__conditions">
          <p
            v-if="isCN"
            v-html="
              $t('cms.contact.privacy.preSubmit', {
                link: $t('cms.contact.privacy.link'),
              })
            "
          ></p>
          <p>
            {{ $t('auth.form.marketing-format') }}
            <a :href="privacyPolicyLink" target="_blank">{{
              $t('auth.form.privacy-policy')
            }}</a>
          </p>
        </div>

        <!-- eslint-enable -->
        <VACButton
          class="contact-form__submit"
          :disabled="isSending || isOverflowing"
          appearance="primary"
          type="submit"
        >
          {{ $t('cms.contact.form.submit') }}
        </VACButton>
        <p v-if="isCN">
          {{ $t('cms.contact.privacy.submit') }}
        </p>
      </div>
    </VForm>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import ContentAPI from '@/api/ContentAPI';
import {
  VACInput,
  VACTextarea,
  VACCheckbox,
  VACButton,
  VACPhone,
} from '@webqam-vac-ui/lib';
import BaseBtn from '@/components/BaseBtn';
import ErrorAlert from '@/components/alert/ErrorAlert';
import RouteName from '@/utils/RouteName';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Captcha from '@/mixins/Captcha';
import Auth from '@/mixins/Auth';
import UserData from '@/mixins/UserData';
import FieldEmail from '@/components/form/FieldEmail';
import FieldPhoneNumber from '@/components/form/FieldPhoneNumber';
import { pushToDataLayer } from '@/utils/GTMMaker';
import Internationalization from '@/utils/Internationalization';

const MESSAGE_MAX_LENGTH = 500;

export default {
  name: 'ContactForm',
  components: {
    VACInput,
    VACTextarea,
    VACCheckbox,
    VACButton,
    VACPhone,
    ErrorAlert,
    BaseBtn,
    VueRecaptcha,
    VueHcaptcha,
    FieldEmail,
    FieldPhoneNumber,
  },
  mixins: [Auth, Captcha, UserData],
  data() {
    return {
      RouteName,
      MESSAGE_MAX_LENGTH,
      isLoadingData: true,
      isFormValid: '',
      isFormLazy: true,
      error: null,
      countries: [],
      messageRules: [
        (v) =>
          v.length <= MESSAGE_MAX_LENGTH ||
          this.$t('form.rules.max_length', { nb: MESSAGE_MAX_LENGTH }),
      ],
      form: {
        name: '',
        email: '',
        phone: {
          prefix: this.isChina() ? '+86' : '',
          number: '',
        },
        subject: null,
        message: '',
        allowMarketingInformations: false,
      },
      rules: {
        name: [(v) => !!v || this.$t('cms.contact.form.error.name.required')],
        email: [
          (v) =>
            !v ||
            /.+@.+\..+/.test(v) ||
            this.$t('cms.contact.form.error.email.invalid'),
        ],
        phone: [
          (v) =>
            !v ||
            /\d+/.test(v) ||
            this.$t('cms.contact.form.error.phone.invalid'),
        ],
        subject: [
          (v) => !!v || this.$t('cms.contact.form.error.subject.required'),
        ],
        message: [
          (v) => !!v || this.$t('cms.contact.form.error.message.required'),
          (v) =>
            v.length <= 300 ||
            this.$t('cms.contact.form.error.message.maxlength'),
        ],
      },
      isSending: false,
    };
  },
  computed: {
    privacyPolicyLink() {
      return Internationalization.getPrivacyPolicyLink();
    },
    isCN() {
      return Internationalization.isCnCountry();
    },
    user() {
      return this.$store.state.auth.userInfos.user;
    },
  },
  mounted() {
    this.countries = this.$store.state['countries'].countryList.map(
      (country) => this.$store.state['countries'].countryMap[country].phone
    );
    if (this.currentSession.sessionToken) {
      this.initFormData();
    } else {
      this.isLoadingData = false;
    }
  },
  methods: {
    initFormData() {
      this.form.name = `${this.getFirstName(this.user)} ${this.getLastName(
        this.user
      )}`.trim();
      this.form.email = this.user.emailAddress;
      if (this.user.phone) {
        this.form.phone = this.user.phone;
      }
      this.isLoadingData = false;
    },
    async sendContactMessage() {
      this.error = null;

      if (!this.isSending && this.$refs.form.validate()) {
        this.isSending = true;

        if (this.hasCaptcha) {
          const { name, email, subject, message } = this.form;
          const phone = this.form.phone.number
            ? this.buildPhoneNumber(this.form.phone)
            : undefined;
          const headers = this.captchaHeader;
          const result = await ContentAPI.postCustomerContact({
            name,
            email,
            phone,
            subject,
            message: `optin_CN=${
              this.form.allowMarketingInformations ? '1' : '0'
            } ${message}`,
            headers,
          }).catch((err) => {
            this.error = err.response.data.message;
            this.isSending = false;
          });

          if (result) {
            pushToDataLayer({
              event: 'ContactFormSubmission',
            });
            this.redirectToConfirmation();
          }

          this.resetCaptcha();
        } else {
          this.error = this.$t('cms.contact.form.error.recaptcha');
        }

        this.isSending = false;
      } else if (!this.form.email && !this.form.phone.number) {
        this.error = this.$t('cms.contact.form.error.emailPhone');
      }
    },
    redirectToConfirmation() {
      this.$router.push({
        name: RouteName.CONTACT_CONFIRMATION,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form {
  &__form {
    display: grid;
    gap: 1rem;
    @include breakpoint($breakpoint-s) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint($breakpoint-l) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__fullwidth-field {
    width: 100%;

    @include breakpoint($breakpoint-m) {
      grid-column: span 2;
    }

    @include breakpoint($breakpoint-l) {
      grid-column: span 3;
    }
  }

  &__conditions {
    padding: 1rem 0;
    @include breakpoint($breakpoint-m) {
      grid-column: span 2;
    }

    @include breakpoint($breakpoint-l) {
      grid-column: span 3;
    }
  }

  &__captcha {
    display: flex;
    margin-bottom: 1.25em;
    @include breakpoint($breakpoint-m) {
      grid-column: span 2;
    }

    @include breakpoint($breakpoint-l) {
      grid-column: span 3;
    }
  }

  &__submit {
    margin-left: auto;
    margin-right: auto;
    max-width: 19.625em;
    margin-bottom: 1em;
  }

  &__footer {
    margin: 0 auto 1.25em;
    @include breakpoint($breakpoint-m) {
      grid-column: span 2;
    }

    @include breakpoint($breakpoint-l) {
      grid-column: span 3;
    }
  }
}
</style>
