<template>
  <DefaultLayout>
    <template #header>
      <BaseHeader />
      <MainTitle v-if="pageTitle">{{ pageTitle }}</MainTitle>
    </template>

    <template #default>
      <slot></slot>
    </template>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from './DefaultLayout';
import BaseHeader from '@/components/header/BaseHeader';
import MainTitle from '@/components/MainTitle';

export default {
  name: 'PreHomeLayout',
  components: { DefaultLayout, BaseHeader, MainTitle },
  computed: {
    pageTitle() {
      return this.$i18n.t(this.$route.meta.pageTitle);
    },
  },
};
</script>
