<template>
  <div ref="section" class="prehome">
    <PrehomeContentLine>
      <template #left>
        <h2 class="prehome__line-title">
          {{ $t('prehome.heading_5') }}
        </h2>

        <div class="prehome__line-content">
          {{ $t('prehome.content_5') }}
        </div>
      </template>

      <template #right>
        <div class="prehome__events">
          <CardEvent
            class="prehome__event prehome__event--main"
            :event="events[0]"
            small
            no-interaction
            no-infos
          />
          <CardEvent
            class="prehome__event prehome__event--for-desktop"
            :event="events[1]"
            small
            no-interaction
            no-infos
          />
        </div>
      </template>
    </PrehomeContentLine>
  </div>
</template>

<script>
import PrehomeContentLine from '@/components/prehome/PrehomeContentLine';
import CardEvent from '@/components/card/CardEvent';
import ScrollAnimation from '@/mixins/ScrollAnimation';

export default {
  name: 'PrehomeEvent',
  components: { CardEvent, PrehomeContentLine },
  mixins: [ScrollAnimation],
  data() {
    return {
      events: [
        {
          ID: 0,
          title: this.$t('prehome.events')[0].title,
          date: [{ value: this.$t('prehome.events')[0].date }],
          city: this.$t('prehome.events')[0].city,
          country: this.$t('prehome.events')[0].country,
          'images-main': [{ x2: require('../../assets/home-event-1.png') }],
        },
        {
          ID: 0,
          title: this.$t('prehome.events')[1].title,
          date: [{ value: this.$t('prehome.events')[1].date }],
          city: this.$t('prehome.events')[1].city,
          country: this.$t('prehome.events')[1].country,
          'images-main': [{ x2: require('../../assets/home-event-2.png') }],
        },
      ],
    };
  },
  mounted() {
    if (this.$matchMedia.l) {
      this.scrollAnimation(this.$refs.section, 'prehome');
    }
  },
};
</script>

<style lang="scss" scoped>
.prehome {
  $block-selector: &;
  $transition-duration: 500ms;

  margin: 4em 0;
  overflow: visible !important;

  &__line-title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.125em;
    margin-bottom: 0.56em;
    text-transform: uppercase;

    &--event {
      margin-top: 0.5em;

      @include breakpoint($breakpoint-l) {
        margin-top: 0;
      }
    }
  }

  &__line-content {
    font-family: $font-family-prelo;
    color: $color-bombay;
    margin-bottom: 1.25em;

    @include breakpoint($breakpoint-l) {
      margin-bottom: 0;
    }
  }

  &__store-icon {
    margin-top: 1em;
    display: inline-block;
    height: 2.5em;
    margin-left: 0.313em;
    margin-right: 0.313em;
  }

  &__line-stores {
    margin-left: -0.313em;
    margin-right: -0.313em;
  }

  &__margin-top {
    margin-top: 5em;
  }

  &__events {
    @include breakpoint($breakpoint-l) {
      display: flex;
      margin-left: -0.5em;
      margin-right: -0.5em;
    }
  }

  &__event {
    margin-left: 0.5em;
    margin-right: 0.5em;

    &--main {
      @include breakpoint($breakpoint-l) {
        opacity: 0;
        transition: opacity $transition-duration;
        transition-delay: 0s;

        #{$block-selector}--animate & {
          opacity: 1;
          transition-delay: 0s;
        }
      }
    }

    &--for-desktop {
      display: none;

      @include breakpoint($breakpoint-l) {
        display: block;
        opacity: 0;
        transition: opacity $transition-duration;
        transition-delay: $transition-duration;

        #{$block-selector}--animate & {
          opacity: 1;
          transition-delay: $transition-duration;
        }
      }
    }
  }

  &__articles {
    display: flex;
    margin-left: -0.5em;
    margin-right: -0.5em;
    justify-content: center;

    @include breakpoint($breakpoint-l) {
      justify-content: flex-start;
    }
  }

  &__card-article {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 250px;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-l) {
      margin-bottom: 0;
    }

    &--desktop-only {
      display: none;

      @include breakpoint($breakpoint-l) {
        display: block;
      }
    }
  }

  &__presentation-section {
    &--first {
      margin-top: 2em;

      @include breakpoint($breakpoint-l) {
        margin-top: 0;
      }
    }

    &--second {
      background-color: $color-wild-sand;
      display: flex;
      flex-direction: column;
    }
  }

  &__presentation-section-container {
    @extend %base-container;

    padding-left: 1em;
    padding-right: 1em;

    #{$block-selector}__presentation-section--second & {
      margin-top: 2.5em;
      margin-bottom: 2.5em;
    }

    &--sticky-content {
      $gradient-step: 25%;
      $gradient-color: $color-wild-sand;
      background-image: linear-gradient(
        to bottom,
        transparent 0,
        transparent $gradient-step,
        $gradient-color $gradient-step,
        $gradient-color 100%
      );

      @include breakpoint($breakpoint-l) {
        background: none;
      }
    }
  }

  &__presentation-section-container-content {
    @include breakpoint($breakpoint-l) {
      padding-right: 60%;

      &--sticky-content {
        margin-left: 40%;
      }
    }
  }

  &__presentation-sticky-content {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint($breakpoint-l) {
      display: block;
      margin-right: 0;
    }
  }

  &__store-link-toolbar {
    margin-top: 1em;
  }

  &::v-deep {
    .prehome-content-line__item:last-child {
      @include breakpoint($breakpoint-l) {
        flex-basis: 60%;
      }
    }
  }
}
</style>
