class Storage {
  get(key: string) {
    return localStorage.getItem(key);
  }
  set(key: string, value): void {
    localStorage.setItem(key, value);
  }
  remove(key: string): void {
    localStorage.removeItem(key);
  }
}

export default new Storage();
