<template>
  <div class="slide-down-menu-item">
    <component :is="itemType" v-bind="boundProps" @click.prevent="handleMenu">
      {{ item.title }}
    </component>
    <div v-if="item.subitems" :class="submenuClasses">
      <SlideDownMenuItem
        v-for="(subitem, index) in item.subitems"
        :key="`subitem-menu-${item.title}-${index}`"
        :item="subitem"
        sub-item
        @closeMenu="$emit('closeMenu')"
      />
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'slide-down-menu-item';

export default {
  name: 'SlideDownMenuItem',
  props: {
    item: { type: Object, default: undefined },
    subItem: Boolean,
  },
  data() {
    return {
      submenuOpened: false,
    };
  },
  computed: {
    itemType() {
      return this.item.link ? 'a' : 'button';
    },
    boundProps() {
      const props = {
        disabled: !!this.item.disabled,
        class: [
          `${BLOCK_SELECTOR}__item`,
          {
            [`${BLOCK_SELECTOR}__item--subitem`]: this.subItem,
            [`${BLOCK_SELECTOR}__item--button-open`]:
              this.itemType === 'button' && !this.submenuOpened,
            [`${BLOCK_SELECTOR}__item--button-close`]:
              this.itemType === 'button' && this.submenuOpened,
            [`${BLOCK_SELECTOR}__item--disabled`]: !!this.item.disabled,
          },
        ],
      };

      if (this.item.link) {
        props.href = this.item.link;
      }
      return props;
    },
    submenuClasses() {
      return [
        `${BLOCK_SELECTOR}__submenu-container`,
        {
          [`${BLOCK_SELECTOR}__submenu-container--opened`]: this.submenuOpened,
        },
      ];
    },
  },
  methods: {
    handleMenu() {
      if (this.item.link) {
        if (this.$router.currentRoute.path !== this.item.link) {
          this.$router.push(this.item.link);
        }
        this.$emit('closeMenu');
      } else {
        this.toggleSubmenu();
      }
    },
    toggleSubmenu() {
      this.submenuOpened = !this.submenuOpened;
    },
  },
};
</script>

<style scoped lang="scss">
%item-button-template {
  position: relative;
  width: 100%;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.slide-down-menu-item {
  $block-selector: &;
  margin-left: 1em;
  margin-right: 1em;

  @include breakpoint($breakpoint-tablet) {
    margin-left: 0;
    margin-right: 0;
  }

  &__item {
    font-family: $font-family-prelo;
    font-weight: 300;
    font-size: 1.25em;
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;
    text-transform: uppercase;
    border-bottom: 1px solid $color-gallery;

    &--subitem {
      text-transform: none;
      border-bottom: none;
    }

    &--button-close {
      @extend %item-button-template;
      display: flex;

      &::after {
        @extend %icon;
        @extend %icon--slider;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &--button-open {
      @extend %item-button-template;
      display: flex;

      &::after {
        @extend %icon;
        @extend %icon--plus;
      }
    }
  }

  &__submenu-container {
    overflow: hidden;
    transition: max-height $default-transition-duration * 2;
    padding-left: 1em;
    max-height: 0;

    &--opened {
      max-height: 18em;
    }
  }
}
</style>
