import Internationalization from '@/utils/Internationalization';
import { CountriesGetters, prefixType } from '@/store/modules/countries';

export default {
  computed: {},
  methods: {
    /**
     * @param {string} countryCode
     * @return {string}
     */
    getFirstNameParam(countryCode) {
      return Internationalization.isCnCountry(countryCode)
        ? 'firstName1'
        : 'firstName1';
    },
    /**
     * @param {Object} data
     * @param {string} countryCode
     * @return {string}
     */
    getFirstName(data, countryCode) {
      return data && data[this.getFirstNameParam(countryCode)];
    },
    /**
     * @param {string} countryCode
     * @return {string}
     */
    getLastNameParam(countryCode) {
      return Internationalization.isCnCountry(countryCode)
        ? 'lastName1'
        : 'lastName1';
    },
    /**
     * @param {Object} data
     * @param {string} countryCode
     * @return {string}
     */
    getLastName(data, countryCode) {
      return data && data[this.getLastNameParam(countryCode)];
    },
    /**
     * @param {string} mobilePhone
     * @param {string} mobilePhoneCountry
     * @return {{number: string, prefix: string}}
     */
    parsePhoneNumber({ mobilePhone, mobilePhoneCountry }) {
      const phoneCountry = this.$store.getters[
        prefixType(CountriesGetters.getCountries)
      ].find((country) => country.code === mobilePhoneCountry);

      return {
        prefix: phoneCountry && phoneCountry.phone,
        number:
          phoneCountry &&
          mobilePhone &&
          mobilePhone.substring(phoneCountry.phone.length),
      };
    },
    /**
     * @param {string} prefix
     * @param {string} number
     * @return {string}
     */
    buildPhoneNumber({ prefix, number }) {
      return `${prefix}${number}`;
    },
  },
};
