<template>
  <section class="introduction">
    <transition appear name="header-">
      <header ref="header" class="introduction__header">
        <transition appear name="header-container-">
          <div ref="container" class="introduction__header-container">
            <h1 class="introduction__title">
              {{ $t('prehome.introduction.welcome') }}
              <strong class="introduction__important">
                {{ $t('prehome.introduction.title') }}
              </strong>
            </h1>
            <p class="introduction__punchline">
              <AuthLogin v-slot="{ open }">
                <button class="introduction__link" @click="open">
                  {{ $t('prehome.introduction.login') }}
                </button>
              </AuthLogin>
              {{ $t('prehome.introduction.or') }}
              <AuthRegister v-slot="{ open }">
                <button class="introduction__link" @click="open">
                  {{ $t('prehome.introduction.register') }}
                </button>
              </AuthRegister>
              {{ $t('prehome.introduction.punchline') }}
            </p>
          </div>
        </transition>

        <div ref="images" class="introduction__images">
          <transition appear name="image1-">
            <img
              alt=""
              class="introduction__image introduction__image--1"
              data-speed="4"
              src="../../assets/prehome/introduction1.png"
            />
          </transition>
          <transition appear name="image2-">
            <img
              alt=""
              class="introduction__image introduction__image--2"
              data-speed="3"
              src="../../assets/prehome/introduction2.png"
            />
          </transition>
          <transition appear name="image3-">
            <img
              alt=""
              class="introduction__image introduction__image--3"
              data-speed="2"
              src="../../assets/prehome/introduction3.png"
            />
          </transition>
        </div>
      </header>
    </transition>
    <div class="introduction__more"></div>
  </section>
</template>

<script>
import AuthLogin from '@/components/auth/AuthLogin';
import AuthRegister from '@/components/auth/AuthRegister';

export default {
  name: 'PrehomeIntroduction',
  components: { AuthRegister, AuthLogin },
  mounted() {
    setTimeout(() => {
      this.move();
    }, 500 * 5);
  },
  destroyed() {
    window.removeEventListener('scroll', () => {
      // destroyed
    });

    window.removeEventListener('mousemove', () => {
      // destroyed
    });
  },
  methods: {
    move() {
      window.addEventListener(
        'scroll',
        () => {
          const scrollTop = window.pageYOffset;
          if (this.$refs.images) {
            [].forEach.call(
              this.$refs.images.querySelectorAll('img'),
              (image) => {
                const speed = image.getAttribute('data-speed');
                image.style.transform = `translateY(${-(scrollTop / speed)}px)`;
              }
            );
          }
        },
        {
          passive: true,
        }
      );
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.introduction {
  $block-selector: &;

  @extend %base-container;
  position: relative;
  max-width: $prehome-container-width;
  margin-bottom: 10em;

  &__header {
    position: relative;
    background-image: url('../../assets/prehome/introduction-title.png');
    background-size: cover;
    max-width: 49.375em;

    @include breakpoint($breakpoint-tablet) {
      padding: 2.25em 4.25em 15.625em;
      margin-top: 5em;
    }
  }

  &__header-container {
    max-width: 18.75em;
    padding: 1.875em;

    @include breakpoint($breakpoint-tablet) {
      padding: 0;
    }
  }

  &__title {
    font-weight: normal;
    line-height: 1;
    color: $color-black;
    margin: 0;
    font-size: 2.1875em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 3.125em;
    }
  }

  &__important {
    display: block;
    font-weight: bold;
  }

  &__punchline {
    display: block;
    margin-top: 0.625em;
  }

  &__link {
    display: inline;
    text-decoration: underline;
  }

  &__images {
    padding-left: 0.75em;
    pointer-events: none;

    @include breakpoint($breakpoint-tablet) {
      padding-left: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__image {
    height: auto;
    display: inline-block;
    vertical-align: top;

    &--1 {
      width: 38%;
      margin-right: 3.8%;
      margin-top: 2.75em;
    }

    &--2 {
      width: 32%;
    }

    &--3 {
      width: 36%;
      margin-left: -3.125em;
      margin-top: 6.75em;
    }

    @include breakpoint($breakpoint-s) {
      position: absolute;

      &--1 {
        top: 52%;
        left: 4.25em;
        width: auto;
        margin-right: 0;
        margin-top: 0;
      }

      &--2 {
        top: -5em;
        left: 55%;
        width: auto;
      }

      &--3 {
        top: 50%;
        right: -15%;
        width: auto;
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  &__more {
    position: absolute;
    bottom: -4.375em;
    left: 50%;

    text-align: center;
    width: 1em;
    height: 1em;
    margin: 2.75em auto 2em -0.5em;
    border-left: 1px solid $color-default;
    border-bottom: 1px solid $color-default;
    transform: rotate(-45deg);
    animation: scrollIconAnimation 2s infinite;
    box-sizing: border-box;
  }

  @keyframes scrollIconAnimation {
    0% {
      transform: rotate(-45deg) translate(0, 0);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: rotate(-45deg) translate(-1.25em, 1.25em);
      opacity: 0;
    }
  }

  &::v-deep {
    .auth-login,
    .auth-register {
      display: inline;
    }
  }

  // ANIMATIONS
  $transition-duration: 500ms;
  .header,
  .header-container,
  .image1,
  .image2,
  .image3 {
    &--enter-active,
    &--leave-active {
      transition-property: opacity, transform;
      transition-duration: $transition-duration;
    }

    &--enter,
    &--leave-to {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    &--enter-to,
    &--leave {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .header {
    &--enter-active,
    &--leave-active {
      transition-delay: $default-transition-duration;
    }
  }

  .header-container {
    &--enter-active,
    &--leave-active {
      transition-delay: $transition-duration;
    }
  }

  .image1 {
    &--enter-active,
    &--leave-active {
      transition-delay: $transition-duration * 2;
    }
  }

  .image2 {
    &--enter-active,
    &--leave-active {
      transition-delay: $transition-duration * 3;
    }
  }

  .image3 {
    &--enter-active,
    &--leave-active {
      transition-delay: $transition-duration * 4;
    }
  }
  body.dir-rtl & {
    &__image {
      height: auto;
      display: inline-block;
      vertical-align: top;

      &--1 {
        width: 38%;
        margin-left: 3.8%;
        margin-top: 2.75em;
      }

      &--2 {
        width: 32%;
      }

      &--3 {
        width: 36%;
        margin-right: -3.125em;
        margin-top: 6.75em;
      }
      @include breakpoint($breakpoint-s) {
        &--1 {
          top: 45%;
          left: 32.25em;
          width: auto;
          margin-left: 0;
          margin-top: 0;
        }

        &--2 {
          top: -5em;
          right: 55%;
          width: auto;
        }

        &--3 {
          top: 50%;
          left: -15%;
          width: auto;
          margin-top: 0;
          margin-right: auto;
        }
      }
    }
  }
}
</style>
