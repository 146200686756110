<template>
  <div class="golden-book-grid">
    <div ref="container" class="golden-book-grid__container">
      <transition name="fade">
        <Spinner v-if="isFetching" class="golden-book-grid__loader" />
      </transition>
      <GoldenBookHeader />
      <div class="golden-book-grid__content">
        <GoldenBookUploadCard
          v-if="pending && storiesIds"
          class="golden-book-grid__card-container"
          :thumbnail="thumbnail"
          :is-uploaded="pending.ID === storiesIds[0]"
        />
        <div
          v-for="(story, index) in stories"
          :key="story.ID"
          :class="cardClass(story, index)"
        >
          <GoldenBookCard
            :id="story.ID"
            class="golden-book-grid__card"
            :title="story.title"
            with-counters
            :comment-counter="story.count_conversation"
            :like-counter="story.count_like"
            :author="story.author.pseudo"
            :author-id="story.author.ID"
            :background-image="cardImage(story, index)"
            light-social
            :with-story="!!story.description"
            :with-video="!!story.video"
            :video="story.video"
            :i-like="story.i_like"
          />
        </div>
      </div>
    </div>
    <Spy v-if="!isEverythingLoaded" @reached="loadMoreStories" />
    <Spinner v-if="!isEverythingLoaded && !isFetching" />
  </div>
</template>

<script>
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';
import GoldenBookHeader from '@/components/golden-book/GoldenBookHeader';
import GoldenBookUploadCard from '@/components/golden-book/GoldenBookUploadCard';
import Spinner from '@/components/Spinner';
import Spy from '@/components/Spy';

import {
  prefixType,
  StoriesActions,
  StoriesGetters,
} from '@/store/modules/stories';

import RouteName from '@/utils/RouteName';
import debounce from 'lodash.debounce';
import Tracking from '@/mixins/Tracking';

const BLOCK_SELECTOR = 'golden-book-grid';
export default {
  name: 'GoldenBookGrid',
  components: {
    Spy,
    Spinner,
    GoldenBookHeader,
    GoldenBookCard,
    GoldenBookUploadCard,
  },
  mixins: [Tracking],
  props: {
    thumbnail: {
      type: String,
    },
  },
  data() {
    return {
      RouteName,
      oneMargin: '0px',
      isLoading: false,
      watchForScrollDebounced: debounce(this.watchScroll, 500),
    };
  },
  computed: {
    stories() {
      if (this.pending) {
        return this.$store.state.stories.stories.slice(0, -1);
      } else {
        return this.$store.state.stories.stories;
      }
    },
    storiesIds() {
      return this.stories.length > 0 ? this.stories.map((obj) => obj.ID) : null;
    },
    isFetching() {
      return this.$store.state.stories.isFetching;
    },
    isEverythingLoaded() {
      return this.$store.getters[prefixType(StoriesGetters.allStoriesLoaded)];
    },
    pending() {
      return this.$store.state.stories.pendingStory;
    },
  },
  mounted() {
    this.trackPageView(this.$t('title.golden_book'));
    this.loadFilters();
    this.loadStories();
    this.oneMargin = `${
      (window.innerWidth - this.$refs.container.clientWidth) / 2 - 7
    }px`;
    this.setupScrollWatcher();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.watchScroll);
  },
  methods: {
    setupScrollWatcher() {
      window.addEventListener('scroll', this.watchScroll);
    },
    watchScroll() {
      if (!this.isLoading) {
        const h = document.documentElement,
          b = document.body,
          st = 'scrollTop',
          sh = 'scrollHeight';

        const percent =
          ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
        if (percent > 50) {
          this.loadStories();
        }
      }
    },
    async loadFilters() {
      await this.$store.dispatch(prefixType(StoriesActions.initFilters));
    },
    async loadStories() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.$store.dispatch(prefixType(StoriesActions.fetchStories));
        this.isLoading = false;
      }
    },
    loadMoreStories() {
      if (this.$store.state.stories.currentPage > 0 && !this.isFetching) {
        this.loadStories();
      }
    },
    cardImage(item, index) {
      if (
        item['images-landscape'].length > 0 &&
        this.cardCanBeHighlighted(index)
      ) {
        return item['images-landscape'][0] && item['images-landscape'][0].x1;
      }
      return item['images-portrait'][0] && item['images-portrait'][0].x1;
    },
    cardClass(item, index) {
      return [
        `${BLOCK_SELECTOR}__card-container`,
        {
          [`${BLOCK_SELECTOR}__card-container--highlighted`]:
            this.cardCanBeHighlighted(index) &&
            item['images-landscape'].length > 0,
        },
      ];
    },
    cardCanBeHighlighted(index) {
      if (this.$matchMedia.xl) {
        const position = (index + 1) % 3;
        return position > 0;
      }
      return false;
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.golden_book')}`,
    };
  },
};
</script>

<style scoped lang="scss">
.golden-book-grid {
  $block-selector: &;
  position: relative;

  &__container {
    @extend %base-container;
    margin-bottom: 1em;
    position: relative;
  }

  &__loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.9);
  }

  &__content {
    @extend %story-card-grid;
    grid-auto-flow: dense;
  }

  &__card-container {
    @extend %story-card-container;

    &--highlighted {
      @include breakpoint($breakpoint-desktop) {
        grid-column: span 2;
      }

      #{$block-selector}__card {
        width: 100%;
      }
    }
  }

  &__card {
    @extend %story-card;
  }
}
</style>
