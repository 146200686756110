import {
  ApiClientAuthError,
  ApiClientError,
} from '@/api/abstract/AbstractApiClient';
import HttpMethods from '@/enums/HttpMethods';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import Auth from '@/utils/authentication';

class CommonAccountAPI {
  protected readonly BASE_URL: string;
  protected readonly username: string;
  protected readonly password: string;
  protected debug: boolean;

  constructor(baseUrl: string, username: string, password = '', debug = false) {
    this.BASE_URL = baseUrl;
    this.username = username;
    this.password = password;
    this.debug = debug;
  }

  protected async query({
    url,
    method = HttpMethods.GET,
    params,
    authenticate = true,
    data,
    headers = {},
  }: {
    url: string;
    method?: Method;
    params?: object;
    authenticate?: boolean;
    data?: object;
    headers?: object;
  }): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      method,
      baseURL: this.BASE_URL,
      url,
      params,
      data,
      headers,
    };

    const country = Auth.retrievePersistentSession.country;
    const sessionToken = Auth.retrievePersistentSession.sessionToken;

    if (country) {
      config.params = {
        ...params,
        country,
      };
    }

    if (sessionToken) {
      config.headers = {
        ...headers,
        sessionToken,
      };
    }

    if (authenticate) {
      config.auth = {
        username: this.username,
        password: this.password,
      };
    }

    return axios(config);
  }
  /**
   * Send forgot password request
   * @param email
   * @param country
   * @param headers
   */
  async sendPasswordForgotRequest(email: string, country = 'WW', headers = {}) {
    return await this.query({
      url: '/api/token/email',
      method: HttpMethods.POST,
      data: {
        email,
        type: 'PASSWORD_RESET',
        country,
      },
      headers,
    });
  }

  /**
   * Edit THC profile
   * @param pseudo
   * @param shareSocial
   */
  async editProfile(pseudo, shareSocial, notifications) {
    return await this.query({
      url: `/api/profile`,
      method: HttpMethods.PUT,
      data: {
        pseudo,
        share_social: shareSocial,
        share_collection: false,
        notifications,
      },
    });
  }

  /**
   * Check for pseudonym availability
   * @param pseudo
   */
  async checkPseudo(pseudo) {
    return await this.query({
      url: `/api/pseudo/exist`,
      params: {
        pseudo,
      },
    });
  }

  async resetPasswordWithEmail(config, headers = {}) {
    if (!config.country) {
      config.country = 'WW';
    }

    return await this.query({
      url: '/api/password/reset-token',
      method: HttpMethods.POST,
      data: config,
      headers,
    });
  }

  async checkWatchPresence(uuid: string) {
    const { data } = await axios.get(
      `${process.env.VUE_APP_API_CCP_CONTROL_URL}${uuid}/portfolio`
    );
    return data;
  }
}

if (!process.env.VUE_APP_API_URL) {
  throw new ApiClientError('API URL is undefined');
}

if (!process.env.VUE_APP_API_AUTH_BASIC_USER) {
  throw new ApiClientAuthError('API auth user is undefined');
}

export default new CommonAccountAPI(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_API_AUTH_BASIC_USER,
  process.env.VUE_APP_API_AUTH_BASIC_PASSWORD,
  !!(process.env.VUE_APP_API_DEBUG && JSON.parse(process.env.VUE_APP_API_DEBUG))
);
