<template>
  <div :class="blockClasses">
    <div :class="likeClasses" @click="$emit('like')">{{ likeCounter }}</div>
    <div :class="commentClasses" @click="$emit('comment')">
      {{ commentCounter }}
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'golden-book-story-social';

export default {
  name: 'GoldenBookStorySocial',
  props: {
    likeCounter: {
      type: Number,
      default: 0,
    },
    commentCounter: {
      type: Number,
      default: 0,
    },
    iLike: Boolean,
    light: Boolean,
  },
  computed: {
    /**
     * @return {Array}
     */
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--light`]: this.light,
        },
      ];
    },
    /**
     * @return {Object}
     */
    likeClasses() {
      return [
        ...this.getReactionClasses('like'),
        {
          [`${BLOCK_SELECTOR}__reaction--liking`]: this.iLike,
        },
      ];
    },
    commentClasses() {
      return [...this.getReactionClasses('comment')];
    },
  },
  methods: {
    /**
     * @param {string} name
     * @return {Array}
     */
    getReactionClasses(name) {
      return [
        `${BLOCK_SELECTOR}__reaction`,
        `${BLOCK_SELECTOR}__reaction--${name}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-story-social {
  $block-selector: &;

  display: flex;

  &--light {
    #{$block-selector} {
      &__reaction {
        &::after,
        &::before {
          color: $color-white;
        }

        &--liking {
          &::after {
            color: $color-white;
          }

          &::before {
            color: $color-tan;
          }
        }
      }
    }
  }

  &__reaction {
    position: relative;
    padding-left: 1.5em;
    padding-top: 0.2em;

    &::after,
    &::before {
      @extend %icon;

      position: absolute;
      top: 0.3em;
      left: 0;
    }

    &::after {
      cursor: pointer;
      color: $color-black;
    }

    &--like {
      border-right: 1px solid $color-shuttle-gray;
      padding-right: 1em;

      &::after {
        @extend %icon--thumbs;
      }
    }

    &--liking {
      &::after {
        color: $color-white;
      }
      &::before {
        @extend %icon--thumbs-full;

        color: $color-tan;
      }
    }

    &--comment {
      margin-left: 1em;
      cursor: pointer;

      &::after {
        @extend %icon--comment;
      }
    }
  }
}
body {
  &.dir-rtl {
    .golden-book-story-social {
      &__reaction {
        padding-right: 1em;

        &--like {
          border-right: none;
        }
        &--comment {
          border-right: 1px solid $color-shuttle-gray;
          margin-right: 1em;
        }
      }
    }
  }
}
</style>
