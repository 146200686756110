<template>
  <div class="magazine-article">
    <PageLoader v-if="!article" />
    <div v-if="article" class="magazine-article__content">
      <StickyHeader
        observer-selector=".magazine-article__title"
        :progress="progress"
      >
        <template #left>
          <span class="magazine-article__title magazine-article__title--top">
            {{ article.title }}
          </span>
        </template>
        <template #right>
          <GoldenBookStorySocial
            class="magazine-article__interactions magazine-article__interactions--header"
            :comment-counter="article.count_conversation"
            :like-counter="likeCount"
            :i-like="iLike"
            @like="likeStory"
            @comment="scrollToComment"
          />
        </template>
      </StickyHeader>
      <div class="magazine-article__head">
        <VACButton
          appearance="link"
          class="magazine-article__back-btn"
          @click="goBack"
          icon="arrow-left"
          reverse
          :label="$t('wizard.header.back')"
        />
      </div>
      <template v-if="articleImages.length > 0">
        <div class="magazine-article__slider">
          <BlockGallery
            arrows
            :category="article.category.label"
            :images="articleImages"
            :is-fullscreen="fullscreenGallery"
            :video="article.video"
            @updateFullscreen="handleFullscreen"
          />
        </div>
      </template>

      <article ref="article" class="magazine-article__article">
        <div class="magazine-article__header">
          <p class="magazine-article__date">
            {{ formattedDate }}
          </p>
          <h2 ref="articleTitle" class="magazine-article__title">
            {{ article.title }}
          </h2>
        </div>
        <GoldenBookStorySocial
          class="magazine-article__interactions"
          :comment-counter="article.count_conversation"
          :like-counter="likeCount"
          :i-like="iLike"
          @like="likeStory"
          @comment="scrollToComment"
        />
        <!--eslint-disable vue/no-v-html-->
        <div
          class="magazine-article__description"
          v-html="article.description"
        ></div>
        <!--eslint-enable vue/no-v-html-->
        <div
          v-for="(paragraph, index) in article.paragraphs"
          :key="`article-content-${index}`"
          :class="`magazine-article__paragraph magazine-article__paragraph--${paragraph.bundle}`"
        >
          <MagazineParagraph
            v-if="paragraph.bundle === 'article_content'"
            :paragraph="paragraph"
          />
          <BaseSurvey v-if="paragraph.bundle === 'poll'" :poll="paragraph" />
          <MagazineSlider
            v-if="
              paragraph.bundle === 'slider' ||
              paragraph.bundle === 'slider_landscape'
            "
            :paragraph="paragraph"
          />
        </div>
      </article>
    </div>

    <StartConversation
      ref="startConversation"
      :is-submitting="submittingConversation"
      @message="submitMessage($event)"
    />

    <ConversationGrid
      :node-id="articleId"
      :node-type="NodeType.ARTICLE"
      @conversationCountEdit="handleConversationUpdate"
    />

    <div v-if="article" class="magazine-article__content">
      <template v-if="article.related && article.related.length > 0">
        <hr class="magazine-article__separator" />
        <ArticleRecommendation :articles="article.related" />
      </template>

      <div class="magazine-article__see-more">
        <router-link
          class="magazine-article__see-more-link"
          :to="{ name: RouteName.MAGAZINE }"
        >
          <VACButton
            appearance="secondary"
            :label="$t('home.see_all_articles')"
            center
          />
        </router-link>
      </div>
    </div>
    <NotificationRequest
      v-if="AIShowModal"
      :authorizations="[AuthorizationType.ARTICLE_COMMENT]"
      content-type="comment"
      @close="handleMessageSubmission(commentMessage)"
    />
  </div>
</template>

<script>
import AuthorizationType from '@/utils/AuthorizationType';
import { VACButton } from '@webqam-vac-ui/lib';
import BlockGallery from '@/components/gallery/BlockGallery';
import MagazineParagraph from '@/components/magazine/MagazineParagraph';
import ArticleRecommendation from '@/components/ArticleRecommendation';
import PageLoader from '@/components/PageLoader';
import StickyHeader from '@/components/StickyHeader';
import GoldenBookStorySocial from '@/components/golden-book/GoldenBookStorySocial';
import BaseSurvey from '@/components/BaseSurvey';

import { formatDate } from '@/utils/DateUtils';
import ContentAPI from '@/api/ContentAPI';
import RouteName from '@/utils/RouteName';
import StartConversation from '@/components/conversation/StartConversation';
import Likeable from '@/mixins/Likeable';
import ConversationGrid from '@/components/conversation/ConversationGrid';
import BusName from '@/utils/BusName';
import NodeType from '@/utils/NodeType';
import MagazineSlider from '@/components/magazine/MagazineSlider';
import Tracking from '@/mixins/Tracking';
import AuthorizationInterceptor from '@/mixins/AuthorizationInterceptor';
import NotificationRequest from '@/components/NotificationRequest.vue';

export default {
  name: 'MagazineArticle',
  components: {
    MagazineSlider,
    BaseSurvey,
    ConversationGrid,
    StartConversation,
    StickyHeader,
    ArticleRecommendation,
    MagazineParagraph,
    BlockGallery,
    PageLoader,
    GoldenBookStorySocial,
    VACButton,
    NotificationRequest,
  },
  mixins: [Likeable, Tracking, AuthorizationInterceptor],
  props: {
    articleId: { type: String, default: undefined },
  },
  data() {
    return {
      backgroundHeight: '0em',
      article: undefined,
      fullscreenGallery: false,
      RouteName,
      progress: 0,
      iLike: undefined,
      submittingConversation: false,
      NodeType,
      AuthorizationType,
      commentMessage: undefined,
    };
  },
  computed: {
    articleImages() {
      const images = [];
      this.article['images-landscape-large'].forEach((image) => {
        images.push(image);
      });
      if (this.fullscreenGallery) {
        this.article['images-portrait'].forEach((image) => {
          images.push(image);
        });
        this.article.paragraphs.forEach((paragraph) => {
          if (paragraph['in_gallery'] === '1') {
            if (paragraph.image[0]) {
              images.push(paragraph.image[0]);
            }
          }
        });
      }
      return images;
    },
    formattedDate() {
      return formatDate(this.article.date);
    },
    likeCount() {
      if (!this.article.i_like && this.iLike) {
        return this.article.count_like + 1;
      }
      if (this.article.i_like && !this.iLike) {
        return this.article.count_like - 1;
      }
      return this.article.count_like;
    },
  },
  watch: {
    articleId() {
      this.article = undefined;
      this.fetchArticle();
    },
  },
  mounted() {
    this.fetchArticle();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.watchForScroll);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    submitMessage(message) {
      this.commentMessage = message;
      if (
        !localStorage.getItem(`${AuthorizationType.ARTICLE_COMMENT}_refused`) &&
        !this.$store.state.profile.notifications[
          AuthorizationType.ARTICLE_COMMENT
        ]
      ) {
        this.AIShowModal = true;
      } else {
        this.handleMessageSubmission(message);
      }
    },
    watchForScroll() {
      const winTop = window.pageYOffset || document.documentElement.scrollTop;
      const targetBottom =
        this.$refs.article.offsetTop + this.$refs.article.scrollHeight;
      const windowBottom = winTop + window.outerHeight;
      this.progress =
        100 -
        ((targetBottom - windowBottom + window.outerHeight / 3) /
          (targetBottom - window.outerHeight + window.outerHeight / 3)) *
          100;
    },
    fetchArticle() {
      ContentAPI.getArticle({ id: this.articleId })
        .then((article) => {
          this.article = article;
          this.iLike = this.article.i_like;
          this.$nextTick(() => {
            window.addEventListener('scroll', this.watchForScroll);
          });
          this.trackPageView(
            `Magazine - ${this.article.category.label} - ${this.article.title}`
          );
        })
        .catch(() => {
          this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
        });
    },
    handleFullscreen(payload) {
      this.fullscreenGallery = payload;
      if (payload) {
        document.body.setAttribute(
          'style',
          'height: 100vh; overflow-y: hidden;'
        );
      } else {
        document.body.removeAttribute('style');
      }
    },
    likeStory() {
      this.iLike = !this.iLike;
      this.setLike(parseInt(this.articleId), this.iLike);
    },
    async handleMessageSubmission(message) {
      this.submittingConversation = true;
      const { data } = await ContentAPI.postConversation(
        this.articleId,
        message
      );
      this.$root.$emit(BusName.NEW_CONVERSATION, data);
      this.$refs.startConversation.clearText();
      this.submittingConversation = false;
      this.commentMessage = undefined;
      if (this.AIShowModal) {
        this.AIShowModal = false;
      }
    },
    handleConversationUpdate(payload) {
      this.article.count_conversation = payload;
    },
    scrollToComment() {
      this.$refs.startConversation.$el.scrollIntoView({
        behavior: 'smooth',
      });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.magazine')}${
        this.article ? ` | ${this.article.title}` : ''
      }`,
    };
  },
};
</script>

<style scoped lang="scss">
.magazine-article {
  &__head {
    @extend %base-container;
    margin-bottom: 2rem;
  }
  &__back-btn {
    gap: 0;
    padding-right: 0.25rem;
  }
  &__date {
    color: $color-beaver;
    font-size: 0.8125em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 0.875em;
      text-align: center;
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    font-weight: bold;
    font-size: 1.125em;

    @include breakpoint($breakpoint-tablet) {
      font-size: 1.5625em;
      text-align: center;
      margin-bottom: 0.825em;
    }

    &--top {
      margin-bottom: 0;
      font-size: 1.4em;
    }
  }

  &__header {
    @extend %base-container;
  }

  &__slider {
    @extend %base-container;
    margin-bottom: 1em;
  }

  &__article {
    position: relative;
    margin-bottom: 2em;
  }

  &__separator {
    margin-top: 1em;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-tablet) {
      visibility: hidden;
    }

    @include breakpoint($breakpoint-desktop) {
      visibility: visible;
      width: 80%;
      margin: 2em auto;
    }
  }

  &__background {
    display: none;
    position: absolute;
    top: 10em;
    left: 0;
    width: 8em;
    z-index: 0;
    background-color: $color-wild-sand;
    transition: width $default-transition-duration * 2;

    @include breakpoint($breakpoint-tablet) {
      display: block;
    }

    &--full {
      width: 100%;
    }
  }

  &__see-more {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 2em;

    @include breakpoint($breakpoint-tablet) {
      margin-top: 4em;
      margin-bottom: 4em;
    }
  }

  &__see-more-link {
    margin-left: 1em;
    margin-right: 1em;

    @include breakpoint($breakpoint-tablet) {
      width: 18em;
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__description {
    @extend %base-container;
    text-align: center;
  }

  &__interactions {
    margin-bottom: 1.625em;
    justify-content: center;

    &--header {
      margin-bottom: 0;
    }
  }

  &__paragraph {
    @extend %base-container;
    margin-top: 1em;
  }
}
</style>
