<template>
  <transition name="fade">
    <div v-if="event && isEnrolled !== undefined" class="event-single">
      <div class="event-single__head">
        <VACButton
          discrete
          class="event-single__back-btn"
          @click="goBack"
          icon=""
          :label="$t('wizard.header.back')"
        />
      </div>
      <EventHeader :event="event" :is-enrolled="isEnrolled" />
      <div class="event-single__content">
        <!--eslint-disable-next-line vue/no-v-html-->
        <div class="event-single__description" v-html="event.description"></div>
        <EventStore class="event-single__store" :event="event" />
      </div>
    </div>
    <PageLoader v-else translucide />
  </transition>
</template>

<script>
import RouteName from '@/utils/RouteName';

import EventStore from '@/components/event/EventStore';
import EventHeader from '@/components/event/EventHeader';
import { VACButton } from '@webqam-vac-ui/lib';

import ContentAPI from '@/api/ContentAPI';
import PageLoader from '@/components/PageLoader';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'EventSingle',
  components: { PageLoader, EventHeader, EventStore, VACButton },
  mixins: [Tracking],
  props: {
    eventId: { type: String, default: undefined },
  },
  data() {
    return {
      event: undefined,
      isEnrolled: undefined,
      RouteName,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    ContentAPI.getEvent({ id: this.eventId })
      .then((data) => {
        this.event = data;
        this.trackPageView(`Event - ${this.event.title}`);
        ContentAPI.getMyEvents({}).then((enrolled) => {
          if (enrolled.future_events) {
            enrolled.future_events.forEach((enrolledEvent) => {
              if (enrolledEvent.ID === this.event.ID) {
                this.isEnrolled = true;
              }
            });
            if (this.isEnrolled === undefined) {
              this.isEnrolled = false;
            }
          }
        });
      })
      .catch(() => {
        this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
      });
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.events')}${
        this.event ? ` | ${this.event.title}` : ''
      }`,
    };
  },
};
</script>

<style scoped lang="scss">
.event-single {
  overflow: hidden;
  &__head {
    @extend %base-container;
    margin-bottom: 2rem;
  }
  &__back-btn {
    gap: 0;
    padding-right: 0.25rem;
  }

  &__content {
    @extend %base-container;
    display: flex;
    flex-direction: column;

    @include breakpoint($breakpoint-desktop) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 5em;
    }
  }

  &__description {
    @include breakpoint($breakpoint-desktop) {
      max-width: 70%;
    }
  }

  &__store {
    @include breakpoint($breakpoint-desktop) {
      margin-left: 3em;
      flex-basis: 50%;
      .dir-rtl & {
        padding-right: 2rem;
      }
    }
  }
}
</style>
