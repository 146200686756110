<template>
  <div class="field-image-checkbox">
    <div
      class="field-image-checkbox__answer"
      v-for="(answer, index) in options"
      :key="`${name}-${index}`"
    >
      <input
        type="checkbox"
        v-model="selection[answer.value]"
        :id="`${name}[${index}]`"
        :name="`${name}`"
        :value="answer.value"
        class="field-image-checkbox__input"
        :checked="selection[answer.value]"
        @change="handleCheck"
      />
      <label class="field-image-checkbox__label" :for="`${name}[${index}]`">
        <span class="field-image-checkbox__icon" />
        <img :src="answer.src" :alt="answer.label" />
        <p class="field-image-checkbox__text">{{ answer.label }}</p>
      </label>
    </div>
    <VACCheckbox
      v-if="isNullable"
      v-model="none"
      class="field-image-checkbox__none"
      @input="handleNone"
      :name="`${name}-false`"
      inputValue="false"
      :label="$t('onboarding.none')"
    />
  </div>
</template>
<script>
import { VACCheckbox } from '@webqam-vac-ui/lib';

export default {
  name: 'FieldImageCheckbox',
  components: { VACCheckbox },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isNullable: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selection: {},
      none: false,
    };
  },
  methods: {
    handleNone() {
      if (this.none) {
        this.selection = {};
      }
      this.$emit('input', { ...this.selection });
    },
    handleCheck() {
      if (this.none) {
        this.none = false;
      }
      this.$emit('input', { ...this.selection });
    },
  },
  watch: {
    value(newVal) {
      this.selection = newVal;
    },
  },
  mounted() {
    this.selection = this.value;
  },
};
</script>
<style scoped lang="scss">
.field-image-checkbox {
  $block-selector: &;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: fit-content;
  gap: 0.625rem;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint($breakpoint-desktop) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.875rem;
  }

  &__input {
    @include sr-only();
    &:checked + #{$block-selector}__label::before,
    &:checked + #{$block-selector}__label #{$block-selector}__icon {
      opacity: 1;
    }
  }

  &__answer {
    width: 100%;
  }

  &__label {
    position: relative;
    display: block;
    cursor: pointer;
    width: 8.875rem;

    &::before {
      $border-offset: -4px;
      position: absolute;
      content: '';
      top: $border-offset;
      bottom: $border-offset;
      left: $border-offset;
      right: $border-offset;
      z-index: 3;
      opacity: 0;
      border: 1px solid $color-black;
      transition: opacity $default-transition-duration;
    }

    &::after {
      position: absolute;
      content: '';
      inset: 0;
      background: transparent linear-gradient(0deg, #000000 0%, #00000000 40%)
        0% 0% no-repeat padding-box;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-color: black;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    transition: opacity $default-transition-duration;

    &::before {
      @extend %icon;
      @extend %icon--check;
    }
  }

  &__none {
    grid-column: 1/3;
    @include breakpoint($breakpoint-desktop) {
      grid-column: 1/5;
    }
  }

  &__text {
    position: absolute;
    z-index: 2;
    margin-bottom: 0;
    bottom: 0.625rem;
    left: 0;
    right: 0;
    text-align: center;
    color: $color-white;
    font-size: 0.75rem;
  }
}
</style>
