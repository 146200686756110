<template>
  <div class="auth-register">
    <slot v-bind="{ open }"></slot>
    <AuthRegisterModal v-if="modal" @close="close" />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import AuthRegisterModal from '@/components/auth/AuthRegisterModal';
import Bus from '@/mixins/Bus';
import BusName from '@/utils/BusName';

export default {
  name: 'AuthRegister',
  components: { AuthRegisterModal },
  mixins: [Bus],
  data() {
    return {
      modal: false,
      BusName,
    };
  },
  mounted() {
    this.registerListener(BusName.OPEN_REGISTER_MODAL, this.open);
  },
  methods: {
    open() {
      this.modal = true;
      disableBodyScroll(document.body);
    },
    close() {
      this.modal = false;
      enableBodyScroll(document.body);
    },
  },
};
</script>
