<template>
  <div class="onboarding-loading">
    <img
      class="onboarding-loading__loader"
      src="../../assets/loader-logo.gif"
      alt=""
    />
    <p class="onboarding-loading__title">
      {{ $t('onboarding.loading.title') }}
    </p>
    <p class="onboarding-loading__thanks">
      {{ $t('onboarding.loading.thanks') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'OnboardingLoading',
};
</script>
<style lang="scss">
.onboarding-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__loader {
    flex-grow: 0;
    width: 5rem;
    height: 5rem;
  }

  &__title {
    margin-top: 1em;
    font-family: $font-family-prelo-condensed;
    font-size: 1.875rem;
    text-transform: uppercase;
  }
}
</style>
