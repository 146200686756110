<template>
  <div class="auth-login">
    <slot v-bind="{ open }"></slot>
    <component
      :is="authComponent"
      v-if="modal"
      @close="close"
      @register="showRegisterModal"
    />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import AuthCNModal from '@/components/auth/AuthCNModal';
import AuthROTWModal from '@/components/auth/AuthROTWModal';
import Bus from '@/mixins/Bus';
import BusName from '@/utils/BusName';
import Internationalization from '@/utils/Internationalization';

export default {
  name: 'AuthLogin',
  mixins: [Bus],
  data() {
    return {
      modal: false,
      BusName,
    };
  },
  computed: {
    authComponent() {
      return Internationalization.getCountry() === 'cn'
        ? AuthCNModal
        : AuthROTWModal;
    },
  },
  mounted() {
    this.registerListener(BusName.OPEN_LOGIN_MODAL, this.open);
  },
  methods: {
    open() {
      this.modal = true;
      disableBodyScroll(document.body);
    },
    close() {
      this.modal = false;
      enableBodyScroll(document.body);
    },
    showRegisterModal() {
      this.close();
      this.$nextTick(() => this.$root.$emit(BusName.OPEN_REGISTER_MODAL));
    },
  },
};
</script>
