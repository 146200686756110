<template>
  <BaseModal
    :is-active="isOpen"
    big-title
    centered-title
    @close="handleClose"
    class="services-modal"
  >
    <template #header-title>{{ $t('services.title') }}</template>
    <div class="services-modal__body">
      <div class="services-modal__description">
        {{ $t('services.description') }}
      </div>
      <VACSelect
        :options="countriesList"
        name="countries"
        :label="$t('services.countryLabel')"
        v-model="selectedCountry"
        @update="handleCountries"
      />
      <VACSelect
        v-if="otherCountry"
        name="languages"
        :label="$t('services.langLabel')"
        :options="languagesList"
        v-model="selectedLanguage"
      />
      <VACButton
        :class="buttonClasses"
        :disabled="!enabledLink"
        :href="servicesLink ?? ''"
        :target="'_blank'"
        icon=""
      >
        <span class="vac-button__label">
          {{ $t('services.cta') }}
        </span>
        <VACIcon name="outside-link" class="services-modal__btn-icon" white />
      </VACButton>
    </div>
  </BaseModal>
</template>
<script>
import BaseModal from '@/components/BaseModal.vue';
import { VACSelect, VACButton, VACIcon } from '@webqam-vac-ui/lib';
import PortalName from '@/utils/PortalName';
import { ServicesMutations } from '@/store/modules/services';

const OTHER_COUNTRY = 'other';
const countries = [
  {
    label: 'Australia',
    value: 'au',
    lang: 'en',
  },
  {
    label: 'China',
    value: 'cn',
    lang: 'zh',
  },
  {
    label: 'France',
    value: 'fr',
    lang: 'fr',
  },
  {
    label: 'Germany',
    value: 'de',
    lang: 'de',
  },
  {
    label: 'Italy',
    value: 'it',
    lang: 'it',
  },
  {
    label: 'Switzerland',
    value: 'ch',
    lang: 'en',
  },
  {
    label: 'UK',
    value: 'gb',
    lang: 'en',
  },
  {
    label: 'USA',
    value: 'us',
    lang: 'en',
  },
  {
    label: 'Hong Kong SAR, China',
    value: 'hk',
    lang: 'en',
  },
  {
    label: 'Japan',
    value: 'jp',
    lang: 'ja',
  },
  {
    label: 'Korea, Republic of',
    value: 'kr',
    lang: 'ko',
  },
  {
    label: 'Saudi Arabia',
    value: 'sa',
    lang: 'ar',
  },
  {
    label: 'Singapore',
    value: 'sg',
    lang: 'en',
  },
  {
    label: 'United Arab Emirates',
    value: 'ae',
    lang: 'ar',
  },
  {
    label: 'Other',
    value: 'other',
    lang: 'other',
  },
];
const languages = [
  {
    label: 'English',
    value: 'English',
    lang: 'en',
  },
  {
    label: 'Français',
    value: 'Français',
    lang: 'fr',
  },
  {
    label: 'Deutsch',
    value: 'Deutsch',
    lang: 'de',
  },
  {
    label: 'Italiano',
    value: 'Italiano',
    lang: 'it',
  },
  {
    label: '中国人',
    value: '中国人',
    lang: 'cn',
  },
  {
    label: '中文',
    value: '中文',
    lang: 'zh',
  },
  {
    label: 'عربي',
    value: 'عربي',
    lang: 'ar',
  },
  {
    label: 'Español',
    value: 'Español',
    lang: 'es',
  },
  {
    label: 'Português',
    value: 'Português',
    lang: 'pt',
  },
  {
    label: '日本',
    value: '日本',
    lang: 'en',
  },
  {
    label: '한국어',
    value: '한국어',
    lang: 'en',
  },
  {
    label: 'Русский',
    value: 'Русский',
    lang: 'en',
  },
];

export default {
  name: 'ServicesModal',
  components: { BaseModal, VACSelect, VACButton, VACIcon },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedCountry: undefined,
      selectedLanguage: undefined,
      PortalName,
    };
  },
  computed: {
    buttonClasses() {
      return [
        'services-modal__btn',
        { [`services-modal__btn--disabled`]: !this.enabledLink },
      ];
    },
    countriesList() {
      return countries;
    },
    languagesList() {
      return languages;
    },
    currentCountry() {
      if (this.selectedCountry) {
        return this.countriesList.find(
          (country) => country['value'] === this.selectedCountry
        );
      }
      return false;
    },
    hasLanguage() {
      return this.otherCountry && this.currentLanguage;
    },
    hasCountry() {
      return this.currentCountry && !this.otherCountry;
    },
    enabledLink() {
      return this.hasLanguage || this.hasCountry;
    },
    currentLanguage() {
      if (this.selectedLanguage) {
        return this.languagesList.find(
          (language) => language['value'] === this.selectedLanguage
        );
      }
      return false;
    },
    otherCountry() {
      return this.currentCountry.value === OTHER_COUNTRY;
    },
    servicesLink() {
      if (this.currentCountry && !this.otherCountry) {
        return `https://www.vacheron-constantin.com/${this.currentCountry.value}/${this.currentCountry.lang}/services/all-services.html`;
      } else if (this.otherCountry && this.currentLanguage) {
        return `https://www.vacheron-constantin.com/ww/${this.currentLanguage.lang}/services/all-services.html`;
      } else {
        return 'https://www.vacheron-constantin.com/ww/en/services/all-services.html';
      }
    },
  },
  methods: {
    handleCountries() {
      if (this.selectedLanguage && !this.otherCountry) {
        this.selectedLanguage = undefined;
      }
    },
    handleClose() {
      this.$store.commit(ServicesMutations.toggleModal, false);
    },
  },
};
</script>

<style lang="scss">
.services-modal {
  &__body {
    max-width: 25rem;
    display: grid;
    justify-content: center;
    gap: 1rem;
  }

  &__btn {
    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
