export function randomStr(): string {
  return Math.random().toString(36).substr(2);
}

export function stripHtml(html): string {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;

  return tmp.textContent || tmp.innerText || '';
}
