<template>
  <PageLoader />
</template>

<script>
import PageLoader from '../../components/PageLoader';
import HydraClient from '@/api/HydraClient';
import Storage from '@/utils/storage';
import authentication, {
  AUTH_SESSION_SESSION_TOKEN,
} from '@/utils/authentication';
import { morphHydraDataToLegacyFormat } from '@/utils/SSOHelpers';
import store from '@/store';
import RouteName from '@/utils/RouteName';
import { flash } from '@/plugins/flash';
import i18n from '@/i18n';
import { checkForWatchInCCP } from '@/api/CCPApi';
import AutoLogin from '@/mixins/AutoLogin';
import { pushToDataLayer } from '@/utils/GTMMaker';

export default {
  name: 'AccountHydraLogin',
  components: { PageLoader },
  mixins: [AutoLogin],
  async mounted() {
    try {
      const rezToken = await HydraClient.getAuthToken(this.$route.query.code);
      Storage.set(AUTH_SESSION_SESSION_TOKEN, rezToken.data.access_token);
      // Checking access Token from drupal API
      if (rezToken.data.access_token) {
        const { data } = await HydraClient.getCurrentUserInfos();
        const formattedData = morphHydraDataToLegacyFormat(
          {
            ...data,
            hasWatch: await checkForWatchInCCP(data.id),
            tokenId: rezToken.data.id_token,
          },
          rezToken.data.access_token
        );
        authentication.login(formattedData);
        store.commit('auth/setUserInfos', formattedData);
        await store.dispatch('onboarding/fetchData');
        pushToDataLayer({
          event: 'login',
        });
        this.redirectAfterAutoLogin();
      } else {
        this.redirectWithError();
      }
    } catch (e) {
      this.redirectWithError();
    }
  },
  methods: {
    redirectWithError() {
      this.$router
        .push({
          name: RouteName.PREHOME,
        })
        .then(() => {
          flash(i18n.t('internal_error'), 'error');
        });
    },
  },
};
</script>

<style scoped></style>
