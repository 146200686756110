<template>
  <div class="home-custom-content">
    <div class="home-custom-content__container">
      <div class="home-custom-content__header">
        <p class="home-custom-content__title">
          {{ $t('home.custom_content.title') }}
        </p>
        <div class="home-custom-content__header-container">
          <VACIcon name="pen" />
          <VACButton
            class="home-custom-content__cta"
            appearance="link"
            icon=""
            @click="$emit('requestOnboard')"
          >
            {{ $t('home.custom_content.edit') }}
          </VACButton>
        </div>
      </div>
      <Swiper ref="customContentSwiper" :options="swiperOptions">
        <SwiperSlide v-for="(content, index) in customContent" :key="index">
          <CardArticleFull
            class="home-custom-content__card"
            :article="content"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import { VACButton, VACIcon } from '@webqam-vac-ui/lib';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import CardArticleFull from '../card/CardArticleFull.vue';
export default {
  name: 'HomeCustomContent',
  components: { CardArticleFull, Swiper, SwiperSlide, VACButton, VACIcon },
  data() {
    return {
      swiperOptions: {
        slidesPerView: this.$matchMedia.xl ? 4 : 1.25,
        cssMode: true,
        spaceBetween: 20,
      },
    };
  },
  computed: {
    customContent() {
      return [...this.$store.state.onboarding.content]
        .sort(() => Math.random() - 0.5)
        .slice(0, 4);
    },
  },
};
</script>
<style scoped lang="scss">
.home-custom-content {
  padding: 1.25rem 0.625rem;
  padding-right: 0;
  border-bottom: 3px solid $color-black;
  background-color: $color-gallery;
  margin-bottom: 1.25rem;

  @include breakpoint($breakpoint-desktop) {
    margin-bottom: 2.75rem;
    padding: 1.25rem 0.625rem;
  }

  &__container {
    padding: 0;
    @extend %base-container;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.125rem;
    padding-right: 0.625rem;

    &-container:deep(.vac-icon) {
      margin-right: 0.5rem;
    }
  }

  &__title {
    text-transform: uppercase;
    font-family: $font-family-prelo-condensed;
    color: $color-black;
    font-weight: bold;
    font-size: 1.25rem;
    margin: 0;
  }

  &__cta {
    font-size: 0.875rem;
  }
}
</style>
