<template>
  <div class="golden-book-header">
    <SetPseudonymModal
      v-if="showPseudonymDialog"
      @close="pseudonymDialogCallback"
    />
    <StickyHeader observer-selector=".golden-book-header">
      <template #left>
        {{ $t('goldenBook.stickyText') }}
      </template>
      <template #right>
        <VACButton
          appearance="primary"
          icon=""
          class="golden-book-header__share"
          @click="handleShareClick"
        >
          {{ $t('goldenBook.share') }}
        </VACButton>
      </template>
    </StickyHeader>
    <BaseTitle class="golden-book-header__title" :level="1">
      {{ $t('goldenBook.title') }}
    </BaseTitle>

    <p class="golden-book-header__description">
      {{ $t('goldenBook.description') }}
    </p>

    <div class="golden-book-header__bottom">
      <GoldenBookFilters class="golden-book-header__filters" />
      <p v-if="this.$matchMedia.s" class="golden-book-header__count">
        {{ storiesCount }}
      </p>
      <VACButton
        class="golden-book-header__submit"
        icon="arrow-right"
        appearance="primary"
        @click="handleShareClick"
        :label="$t('goldenBook.share')"
      />
    </div>
    <GoldenBookRecordModal
      v-if="canShowModal && mobileShareModal"
      @close="handleClose($event)"
    />
  </div>
</template>

<script>
import BaseTitle from '@/components/BaseTitle';
import { VACButton } from '@webqam-vac-ui/lib';
import GoldenBookFilters from '@/components/golden-book/GoldenBookFilters';
import RouteName from '@/utils/RouteName';
import Auth from '@/mixins/Auth';
import SetPseudonymModal from '@/components/conversation/SetPseudonymModal';
import StickyHeader from '@/components/StickyHeader';
import GoldenBookRecordModal from '@/components/golden-book/GoldenBookRecordModal';

export default {
  name: 'GoldenBookHeader',
  components: {
    SetPseudonymModal,
    GoldenBookFilters,
    BaseTitle,
    StickyHeader,
    GoldenBookRecordModal,
    VACButton,
  },
  mixins: [Auth],
  data() {
    return {
      showFiltersState: false,
      showPseudonymDialog: false,
      mobileShareModal: false,
    };
  },
  computed: {
    showFilter() {
      return this.$matchMedia.s ? true : this.showFiltersState;
    },
    doNotShowModal() {
      return localStorage.getItem('hideStoryInfoModal');
    },
    canShowModal() {
      return !this.$matchMedia.l && !this.doNotShowModal;
    },
    storiesCount() {
      return this.$t('goldenBook.count', {
        number: this.$store.state.stories.totalItems,
      });
    },
  },
  methods: {
    handleShareClick() {
      if (this.userHasSetPseudonym) {
        this.$matchMedia.l
          ? this.$router.push({ name: RouteName.GOLDEN_BOOK_SHARE_STORY })
          : this.canShowModal
          ? (this.mobileShareModal = true)
          : this.$router.push({ name: RouteName.GOLDEN_BOOK_VAG_MEDIA });
      } else {
        this.showPseudonymDialog = true;
      }
    },
    handleClose(payload) {
      this.mobileShareModal = false;
      if (!payload.goBack) {
        this.$router.push({ name: RouteName.GOLDEN_BOOK_VAG_MEDIA });
      }
    },
    pseudonymDialogCallback() {
      this.showPseudonymDialog = false;
      if (this.userHasSetPseudonym) {
        this.$router.push({ name: RouteName.GOLDEN_BOOK_SHARE_STORY });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.golden-book-header {
  &__title {
    text-align: center;
    font-family: $font-prelo-condensed;
  }

  &__description {
    text-align: center;
    @include breakpoint($breakpoint-tablet) {
      max-width: 48em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1em;
    background-color: $color-white;
    box-shadow: 0 0 10px #00000029;
    border-top: 1px solid $color-beaver;
    z-index: 7;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;

    @include breakpoint($breakpoint-tablet) {
      background-color: transparent;
      position: relative;
      box-shadow: none;
      border: 0;
      padding: 0;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: 1fr 5rem 1fr;
      place-items: end center;
    }
  }

  &__count {
    margin-bottom: 0;
  }

  &__submit {
    flex-grow: 1;
    @include breakpoint($breakpoint-tablet) {
      flex-grow: unset;
      place-self: end end;
    }
  }

  &__open-filters {
    position: relative;
    margin-left: 1em;

    @include breakpoint($breakpoint-tablet) {
      display: none;
    }
  }

  &__filter-count {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    background-color: $color-beaver;
    color: $color-white;
    border-radius: 50%;
    font-size: 0.625em;
    font-weight: bold;
    width: 1.25em;
    height: 1.25em;
  }

  &__filters {
    overflow: hidden;
    width: fit-content;

    @include breakpoint($breakpoint-tablet) {
      margin-top: 1em;
      place-self: end start;
    }
  }
}
</style>
