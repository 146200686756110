import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';
import ContentAPI from '@/api/ContentAPI';
import StoriesState from '@/types/StoriesState';
import { CollectionsPrefix, StoriesOrder } from '@/utils/StoriesConstants';
import StoriesFilter from '@/types/StoriesFilter';

export const NAMESPACE = 'stories';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum StoriesGetters {
  allStoriesLoaded = 'allStoriesLoaded',
  getFilterCount = 'getFilterCount',
  getStory = 'getStory',
}

export enum StoriesMutations {
  updateCurrentPage = 'updateCurrentPage',
  updateTotalPage = 'updateTotalPage',
  updateTotalItems = 'updateTotalItems',
  pushStories = 'pushStories',
  resetState = 'resetState',
  resetSearch = 'resetSearch',
  setThemeFilters = 'setThemeFilters',
  setCollectionFilters = 'setCollectionFilters',
  setOrder = 'setOrder',
  setFetchingState = 'setFetchingState',
  setAvailableFilters = 'setAvailableFilters',
  setLikeForItem = 'setLikeForItem',
  setCommentCountForItem = 'setCommentCountForItem',
  setUserFilter = 'setUserFilter',
}

export enum StoriesActions {
  fetchStories = 'fetchStories',
  refreshStories = 'refreshStories',
  initFilters = 'initFilters',
  updateLikeStatus = 'updateLikeStatus',
  updateCommentCount = 'updateCommentCount',
  pollingHandler = 'pollingHandler',
}

const INITAL_STATE: StoriesState = {
  stories: [],
  currentPage: 0,
  totalPages: 1,
  totalItems: 0,
  isFetching: false,
  availableFilters: [],
  filtersThemes: [],
  filtersCollection: [],
  order: StoriesOrder.DATE,
  userFilter: null,
  pendingStory: null,
};

const module = {
  namespaced: true,
  state() {
    return { ...INITAL_STATE };
  },
  getters: {
    [StoriesGetters.allStoriesLoaded]: (state: StoriesState) => {
      return state.currentPage === state.totalPages;
    },
    [StoriesGetters.getFilterCount]: (state: StoriesState) => {
      return state.filtersThemes.length + state.filtersCollection.length;
    },
    [StoriesGetters.getStory]: (state) => (id) => {
      return state.stories.find((story) => story.ID === id);
    },
  },
  actions: {
    [StoriesActions.pollingHandler]: async ({ state, commit, dispatch }) => {
      const pollingInterval = setInterval(() => {
        ContentAPI.pollingVideo(state.pendingStory.ID).then((res) => {
          if (res.data.status === true) {
            clearInterval(pollingInterval);
            state.pendingStory = null;
            commit(StoriesMutations.resetState);
            dispatch(StoriesActions.refreshStories);
          }
        });
      }, 5000);
    },
    [StoriesActions.initFilters]: async ({ commit }) => {
      const { data } = await ContentAPI.getStoriesFilters();
      const themeOptions: StoriesFilter[] = [];
      data.filterThemes.forEach((item) => {
        themeOptions.push({
          label: item.label,
          value: item.ID,
        });
      });
      const collections: StoriesFilter[] = [];
      data.filterCollections.forEach((item) => {
        collections.push({
          label: item.label,
          value: item.ID,
        });
      });
      themeOptions.push({
        label: 'Collections',
        prefix: CollectionsPrefix,
        options: collections,
      });
      commit(StoriesMutations.setAvailableFilters, themeOptions);
    },
    [StoriesActions.fetchStories]: async ({ commit, state }) => {
      if (state.currentPage < state.totalPages) {
        commit(StoriesMutations.setFetchingState, state.currentPage === 0);
        const { data } = await ContentAPI.getStories({
          page: state.currentPage + 1,
          themeArray: state.filtersThemes.map((obj) => obj.value),
          collectionArray: state.filtersCollection.map((obj) => obj.value),
          sort: state.order,
          user: state.userFilter,
        });
        commit(StoriesMutations.updateCurrentPage, data.currentPage);
        commit(StoriesMutations.updateTotalPage, data.nbPage);
        commit(StoriesMutations.updateTotalItems, data.count);
        commit(StoriesMutations.pushStories, data.nodes);
        commit(StoriesMutations.setFetchingState, false);
      }
    },
    [StoriesActions.refreshStories]: ({ commit, dispatch }) => {
      commit(StoriesMutations.resetSearch);
      dispatch(StoriesActions.fetchStories);
    },
    [StoriesActions.updateLikeStatus]: (
      { commit, state },
      { nodeId, likeStatus }
    ) => {
      const item = state.stories.findIndex(
        (item) => item.ID === nodeId || item.ID === `${nodeId}`
      );
      if (item >= 0) {
        commit(StoriesMutations.setLikeForItem, {
          index: item,
          status: likeStatus,
        });
      }
    },
    [StoriesActions.updateCommentCount]: (
      { commit, state },
      { nodeId, count }
    ) => {
      const item = state.stories.findIndex(
        (item) => item.ID === nodeId || item.ID === `${nodeId}`
      );
      if (item >= 0) {
        commit(StoriesMutations.setCommentCountForItem, {
          index: item,
          count,
        });
      }
    },
  },
  mutations: {
    [StoriesMutations.setUserFilter]: (
      state: StoriesState,
      value: string | null
    ) => {
      state.userFilter = value;
    },
    [StoriesMutations.updateCurrentPage]: (
      state: StoriesState,
      value: number
    ) => {
      state.currentPage = value;
    },
    [StoriesMutations.updateTotalPage]: (
      state: StoriesState,
      value: number
    ) => {
      state.totalPages = value;
    },
    [StoriesMutations.updateTotalItems]: (
      state: StoriesState,
      value: number
    ) => {
      state.totalItems = value;
    },
    [StoriesMutations.pushStories]: (state: StoriesState, value) => {
      state.stories = [...state.stories, ...value];
    },
    [StoriesMutations.resetState]: (state: StoriesState) => {
      Object.assign(state, INITAL_STATE);
    },
    [StoriesMutations.setCollectionFilters]: (
      state: StoriesState,
      value: Array<string>
    ) => {
      state.filtersCollection = [...value];
    },
    [StoriesMutations.setThemeFilters]: (
      state: StoriesState,
      value: Array<string>
    ) => {
      state.filtersThemes = [...value];
    },
    [StoriesMutations.resetSearch]: (state: StoriesState) => {
      state.stories = INITAL_STATE.stories;
      state.currentPage = INITAL_STATE.currentPage;
      state.totalPages = INITAL_STATE.totalPages;
      state.totalItems = INITAL_STATE.totalItems;
    },
    [StoriesMutations.setOrder]: (state: StoriesState, order: StoriesOrder) => {
      state.order = order;
    },
    [StoriesMutations.setFetchingState]: (
      state: StoriesState,
      value: boolean
    ) => {
      state.isFetching = value;
    },
    [StoriesMutations.setAvailableFilters]: (
      state: StoriesState,
      filters: Array<StoriesFilter>
    ) => {
      state.availableFilters = filters;
    },
    [StoriesMutations.setLikeForItem]: (
      state: StoriesState,
      payload: { index: number; status: boolean }
    ) => {
      state.stories[payload.index].i_like = payload.status;
      if (payload.status) {
        state.stories[payload.index].count_like++;
      } else {
        state.stories[payload.index].count_like--;
      }
    },
    [StoriesMutations.setCommentCountForItem]: (
      state: StoriesState,
      payload: { index: number; count: number }
    ) => {
      state.stories[payload.index].count_conversation = payload.count;
    },
  },
};

export default module;
