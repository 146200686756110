<template>
  <div class="password-forgot">
    <div class="password-forgot__container">
      <WizardStepper
        ref="stepper"
        :back-to="RouteName.PREHOME"
        :left-image="leftImage"
        :right-image="rightImage"
        :steps="2"
      >
        <template #step-1="stepProps">
          <WizardStep
            :loading="pending"
            :title="$t('password-forgot.steps.confirm-account.title')"
            v-bind="{ ...stepProps }"
          >
            <template #default>
              <VForm
                ref="form"
                @submit.prevent="submitResetPassword(stepProps.next)"
              >
                <p class="password-forgot__description">
                  {{ $t('password-forgot.description') }}
                </p>
                <VACInput
                  class="password-forgot__email"
                  type="email"
                  v-model="email"
                  :label="$t('password-forgot.label')"
                />
                <div class="password-forgot__robot">
                  <component
                    :is="captchaComponentName"
                    ref="captcha"
                    :sitekey="siteKey"
                    @verify="onVerify"
                  />
                </div>
                <ErrorAlert v-if="errorMessage" @close="errorMessage = null">{{
                  errorMessage
                }}</ErrorAlert>
              </VForm>
            </template>
            <template #footer>
              <VACButton
                full-width
                center
                :label="$t('password-forgot.submit')"
                @click="submitResetPassword(stepProps.next)"
              />
            </template>
          </WizardStep>
        </template>
        <template #step-2="stepProps">
          <WizardStep
            :title="$t('password-forgot.steps.confirmation.title')"
            v-bind="{ ...stepProps }"
          >
            <template #default>
              <p class="password-forgot__description">
                {{ $t('password-forgot.steps.confirmation.email') }}
                <strong class="password-forgot__email">{{ email }}</strong>
              </p>
              <p class="password-forgot__description">
                {{ $t('password-forgot.steps.confirmation.details') }}
              </p>
            </template>
            <template #footer>
              <VACButton
                full-width
                center
                :to="RouteName.PREHOME"
                block
                large
                primary
              >
                {{ $t('password-forgot.goToHome') }}
              </VACButton>
            </template>
          </WizardStep>
        </template>
      </WizardStepper>
    </div>
  </div>
</template>

<script>
import { VACInput, VACButton } from '@webqam-vac-ui/lib';
import ErrorAlert from '@/components/alert/ErrorAlert';
import WizardStepper from '@/components/wizard/WizardStepper';
import WizardStep from '@/components/wizard/WizardStep';
import RouteName from '@/utils/RouteName';
import leftImage from '../../assets/stepper/password-forgot-left.png';
import rightImage from '../../assets/stepper/password-forgot-right.png';
import VueRecaptcha from 'vue-recaptcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Captcha from '@/mixins/Captcha';
import Internationalization from '@/utils/Internationalization';
import BusName from '@/utils/BusName';
import CommonAccountAPI from '@/api/CommonAccountAPI';
import Tracking from '@/mixins/Tracking';

const RESET_EVENT = BusName.RESET_FORGOTTEN_PASSWORD_STEPPER;

export default {
  name: 'PasswordForgot',
  components: {
    WizardStep,
    WizardStepper,
    ErrorAlert,
    VACButton,
    VACInput,
    VueRecaptcha,
    VueHcaptcha,
  },
  mixins: [Captcha, Tracking],
  data() {
    return {
      RouteName,
      pending: false,
      errorMessage: null,
      email: '',
      leftImage,
      rightImage,
    };
  },
  created() {
    this.trackPageView();
    if (Internationalization.isCnCountry()) {
      this.$router.push({
        name: RouteName.PREHOME,
      });
    }
  },
  mounted() {
    this.$root.$on(RESET_EVENT, this.resetStepper);
  },
  destroyed() {
    this.$root.$off(RESET_EVENT, this.resetStepper);
  },
  methods: {
    resetStepper() {
      this.$refs.stepper.restart();
    },
    submitResetPassword(nextCallback) {
      if (this.$refs.form.validate()) {
        if (this.hasCaptcha) {
          this.pending = true;
          this.errorMessage = null;
          CommonAccountAPI.sendPasswordForgotRequest(
            this.email,
            'WW',
            this.captchaHeader
          )
            .then(() => {
              this.pending = false;
              nextCallback();
            })
            .catch((err) => {
              this.pending = false;
              if (err.response.status === 500) {
                this.errorMessage = this.$t('password-forgot.error');
              } else {
                this.errorMessage = err.response.data.message;
              }
              this.resetCaptcha();
            });
        } else {
          this.errorMessage = this.$t('cms.contact.form.error.recaptcha');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-forgot {
  &__description {
    font-size: 0.875em;
  }

  &__email {
    margin-bottom: 1rem;
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
}
</style>
