<template>
  <div class="aea">
    <MainTitle>
      {{ $t('alreadyEmailAccount.title') }}
    </MainTitle>
    <div class="aea__container">
      <WizardStepper
        ref="stepper"
        :back-to="RouteName.PREHOME"
        :left-image="leftImage"
        :right-image="rightImage"
        :steps="4"
      >
        <template #step-1="stepProps">
          <WizardStep
            v-bind="stepProps"
            :title="$t('alreadyEmailAccount.step1.title')"
            :loading="emailLoading"
          >
            <template #default>
              <p class="aea__description">
                {{ $t('alreadyEmailAccount.step1.description') }}
              </p>
              <VForm ref="form" @submit.prevent="submit(stepProps.next)">
                <FieldEmail v-model="email" />
                <div class="aea__robot">
                  <VueHcaptcha
                    v-if="isHCaptcha"
                    ref="captcha"
                    :sitekey="siteKey"
                    @verify="onVerify"
                  />
                </div>
                <ErrorAlert v-if="errorMessage" @close="errorMessage = null">{{
                  errorMessage
                }}</ErrorAlert>
              </VForm>
            </template>
            <template #footer>
              <BaseBtn primary block @click="submit(stepProps.next)">
                {{ $t('alreadyEmailAccount.step1.submit') }}
              </BaseBtn>
            </template>
            <template #help-title>
              {{ $t('alreadyEmailAccount.step1.helper.title') }}
            </template>
            <template #help>
              <p
                v-for="description in helpDescription"
                :key="description"
                class="aea__help-description"
              >
                {{ description }}
              </p>
            </template>
          </WizardStep>
        </template>
        <template #step-2="stepProps">
          <WizardStep
            v-bind="{ ...stepProps }"
            :title="$t('alreadyEmailAccount.step2.title')"
          >
            <template #default>
              <p class="aea__description">
                {{ $t('alreadyEmailAccount.step2.email') }}
                <strong class="aea__email">{{ email }}</strong>
              </p>
              <p class="aea__description">
                {{ $t('alreadyEmailAccount.step2.details') }}
              </p>
            </template>
            <template #help-title>
              {{ $t('alreadyEmailAccount.step1.helper.title') }}
            </template>
            <template #help>
              <p
                v-for="description in helpDescription"
                :key="description"
                class="aea__help-description"
              >
                {{ description }}
              </p>
            </template>
          </WizardStep>
        </template>
      </WizardStepper>
    </div>
  </div>
</template>

<script>
import WizardStepper from '@/components/wizard/WizardStepper';
import RouteName from '@/utils/RouteName';
import WizardStep from '@/components/wizard/WizardStep';
import BaseBtn from '@/components/BaseBtn';
import FieldEmail from '@/components/form/FieldEmail';
import ErrorAlert from '@/components/alert/ErrorAlert';
import leftImage from '@/assets/stepper/transfer-account-left.png';
import rightImage from '@/assets/stepper/transfer-account-right.png';
import ContentAPI from '@/api/ContentAPI';
import MainTitle from '@/components/MainTitle';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import Captcha from '@/mixins/Captcha';
import Internationalization from '@/utils/Internationalization';
import BusName from '@/utils/BusName';
import CommonAccountAPI from '@/api/CommonAccountAPI';
import { pushToDataLayer } from '@/utils/GTMMaker';
import Tracking from '@/mixins/Tracking';

const RESET_EVENT = BusName.RESET_ALREADY_EMAIL_ACCOUNT_STEPPER;

export default {
  name: 'AlreadyEmailAccount',
  components: {
    MainTitle,
    ErrorAlert,
    FieldEmail,
    BaseBtn,
    WizardStep,
    WizardStepper,
    VueHcaptcha,
  },
  mixins: [Captcha, Tracking],
  data() {
    return {
      RouteName,
      errorMessage: null,
      email: '',
      emailLoading: false,
      leftImage,
      rightImage,
      helpDescription: this.$t('alreadyEmailAccount.step1.helper.description'),
    };
  },
  created() {
    this.trackPageView();
    if (!Internationalization.isCnCountry()) {
      Internationalization.setCountry('CN');
      window.location.reload();
    } else {
      pushToDataLayer({
        event: 'virtualPageview',
        page: 'account/merge/step1',
      });
    }
  },
  mounted() {
    this.$root.$on(RESET_EVENT, this.resetStepper);
  },
  destroyed() {
    this.$root.$off(RESET_EVENT, this.resetStepper);
  },
  methods: {
    submit(nextCallback) {
      if (this.$refs.form.validate()) {
        if (this.hasCaptcha) {
          this.errorMessage = null;
          this.emailLoading = true;
          ContentAPI.checkEmail(this.email).then(({ data }) => {
            if (data && data.emailExists && !data.mobilePhoneVerified) {
              // send email
              CommonAccountAPI.sendPasswordForgotRequest(
                this.email,
                'CN',
                this.captchaHeader
              )
                .then(() => {
                  nextCallback();
                  pushToDataLayer({
                    event: 'virtualPageview',
                    page: 'account/merge/step2',
                  });
                })
                .catch((err) => {
                  this.errorMessage = err.response.data.message;
                })
                .finally(() => {
                  this.emailLoading = false;
                });
            } else if (!data.emailExists) {
              this.emailLoading = false;
              this.errorMessage = this.$t(
                'alreadyEmailAccount.errors.emailDoesntExist'
              );
            } else if (data.mobilePhoneVerified) {
              this.emailLoading = false;
              this.errorMessage = this.$t(
                'alreadyEmailAccount.errors.alreadyVerified'
              );
            }
          });
        } else {
          this.errorMessage = this.$t('cms.contact.form.error.recaptcha');
        }
      }
    },
    resetStepper() {
      this.$refs.stepper.restart();
    },
  },
};
</script>

<style scoped lang="scss">
.aea {
  &__description {
    font-size: 0.875em;
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__help-description {
    font-family: $font-family-prelo-condensed;
    white-space: pre-wrap;
  }
}
</style>
