<template>
  <div class="golden-book-crop-warning">
    <p class="golden-book-crop-warning__text" :style="textStyles">
      <span class="golden-book-crop-warning__title">{{
        $t('goldenBook.new.crop.warning.0')
      }}</span>

      {{ $t('goldenBook.new.crop.warning.1') }}
    </p>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer/lib/ResizeObserver';

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

export default {
  name: 'GoldenBookCropperWarning',
  i18n,
  data() {
    return {
      resizeObserver: null,
      fontSize: '1em',
    };
  },
  computed: {
    /**
     * @return {Object}
     */
    textStyles() {
      return {
        fontSize: this.fontSize,
      };
    },
  },
  mounted() {
    this.initResizeObserver();
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          this.fontSize = `${entry.contentRect.width / 16}px`;
        });
      });

      this.resizeObserver.observe(this.$el.parentElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-crop-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-family: $font-prelo-condensed;
  line-height: 1;
  color: $color-white;
  background-color: rgba($color-crown-of-thorns, 0.5);

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    font-size: 1em;
    text-align: center;
  }

  &__title {
    margin-bottom: 0.25em;
    font-weight: bold;
  }
}
</style>
