<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal is-active class="delete-conversation-modal">
      <template #container>
        <div class="delete-conversation-modal__header">
          <BaseBtn
            class="delete-conversation-modal__close"
            discrete
            @click="close"
          />
          <div class="delete-conversation-modal__title">
            {{ $t('conversation.deleteModal.title') }}
          </div>
        </div>
        <div class="delete-conversation-modal__body">
          <p>
            {{ $t('conversation.deleteModal.description') }}
          </p>
          <div class="delete-conversation-modal__action">
            <BaseBtn danger @click="$emit('cancel')">
              {{ $t('conversation.deleteModal.cancel') }}
            </BaseBtn>
            <BaseBtn primary @click="$emit('delete')">
              {{ $t('conversation.deleteModal.confirm') }}
            </BaseBtn>
          </div>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import BaseBtn from '@/components/BaseBtn';

import PortalName from '@/utils/PortalName';
export default {
  name: 'DeleteGoldenBookPostModal',
  components: { BaseModal, BaseBtn },
  data() {
    return {
      PortalName,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.delete-conversation-modal {
  &__header {
    @extend %base-modal__header;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    font-family: $font-prelo-condensed;
  }

  &__action {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    padding: 1em;
    width: 100%;

    @include breakpoint($breakpoint-desktop) {
      width: 20.5em;
    }
  }
}
</style>
