import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';

export const NAMESPACE = 'auth';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum LoaderMutation {
  setLoaderStatus = 'setLoaderStatus',
}

export enum LoaderActions {
  enableLoader = 'enableLoader',
  disableLoader = 'disableLoader',
}

const module = {
  namespaced: true,
  state() {
    return {
      loaderStatus: false,
    };
  },
  mutations: {
    [LoaderMutation.setLoaderStatus](state, value) {
      state.loaderStatus = value;
    },
  },
  actions: {
    [LoaderActions.enableLoader]({ commit }) {
      commit(LoaderMutation.setLoaderStatus, true);
    },
    [LoaderActions.disableLoader]({ commit }) {
      commit(LoaderMutation.setLoaderStatus, false);
    },
  },
};

export default module;
