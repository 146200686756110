<template>
  <!-- eslint-disable vue-i18n/no-raw-text -->
  <div class="cms-content">
    <Spinner v-if="loading" />

    <template v-else>
      <MainTitle>{{ title }}</MainTitle>

      <!--eslint-disable vue/no-v-html-->
      <div class="cms-content__description" v-html="description"></div>
      <!--eslint-enable vue/no-v-html-->
    </template>
  </div>
  <!--eslint-enable-->
</template>

<script>
import ContentAPI from '@/api/ContentAPI';
import Spinner from '@/components/Spinner';
import MainTitle from '@/components/MainTitle';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'CmsContent',
  components: { MainTitle, Spinner },
  mixins: [Tracking],
  data() {
    return {
      page: undefined,
      loading: true,
    };
  },
  computed: {
    title() {
      return this.page ? this.page.nodes[0].title : null;
    },
    description() {
      return this.page ? this.page.nodes[0].description : null;
    },
  },
  created() {
    this.fetchPageContent();
  },
  methods: {
    fetchPageContent() {
      this.loading = true;
      ContentAPI.getPage({
        lang: this.$i18n.locale,
        slug: this.$route.params.contentId,
      })
        .then((response) => {
          this.loading = false;
          this.page = response;
          this.trackPageView(this.title);
        })
        .catch(() => {
          this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
        });
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.title}`,
    };
  },
};
</script>

<style scoped lang="scss">
.cms-content {
  @extend %base-container;

  &__description {
    ::v-deep a {
      display: inline-block;
    }
  }
}
</style>
