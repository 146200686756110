<template>
  <div :class="paragraphClasses">
    <!--eslint-disable-next-line vue/no-v-html-->
    <p class="magazine-paragraph__content" v-html="paragraph.description"></p>
    <div
      v-if="paragraph.image[0]"
      class="magazine-paragraph__content magazine-paragraph__content--image"
    >
      <img
        class="magazine-paragraph__image"
        :src="paragraph.image[0].x1"
        :alt="$t('magazine.image.alt')"
      />
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'magazine-paragraph';
export default {
  name: 'MagazineParagraph',
  props: {
    paragraph: { type: Object, default: undefined },
  },
  computed: {
    paragraphClasses() {
      return [
        `${BLOCK_SELECTOR}`,
        {
          [`${BLOCK_SELECTOR}--reverted`]: this.paragraph.alignment === 'left',
          [`${BLOCK_SELECTOR}--middle`]: this.paragraph.alignment === 'middle',
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.magazine-paragraph {
  $block-selector: &;

  display: flex;
  flex-wrap: wrap;

  @include breakpoint($breakpoint-desktop) {
    flex-wrap: nowrap;
    align-items: center;
    margin-left: -1em;
    margin-right: -1em;
  }

  &--reverted {
    #{$block-selector} {
      &__content {
        order: 1;

        &--image {
          order: 0;
        }
      }
    }
  }

  &--middle {
    flex-direction: column-reverse;
  }

  &__content {
    flex-basis: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.5em;

    @include breakpoint($breakpoint-desktop) {
      flex-basis: 60%;
      flex-grow: 1;
      margin-left: 1em;
      margin-right: 1em;
    }

    &--image {
      text-align: center;

      @include breakpoint($breakpoint-desktop) {
        flex-basis: 40%;
      }
    }
  }

  &__image {
    @include breakpoint($breakpoint-tablet) {
      max-width: 50%;
    }

    @include breakpoint($breakpoint-desktop) {
      max-width: 100%;
    }
  }
}
</style>
