<template>
  <VForm class="golden-book-form" @input="$emit('update', form)">
    <VACInput
      type="text"
      name="pseudonym"
      :label="$t('goldenBook.new.fields.pseudonym')"
      :value="form.pseudonym"
      class="golden-book-form__field golden-book-form__field--pseudonym"
      disabled
    />

    <div class="golden-book-form__row">
      <div class="golden-book-form__cell">
        <VACSelect
          v-model="form.theme"
          name="themes"
          :disabled="themes.length === 0"
          :options="themes"
          :label="$t('goldenBook.new.fields.theme')"
          class="golden-book-form__field golden-book-form__field--theme"
          @update="handleThemeChange"
          required
        />
      </div>

      <div class="golden-book-form__cell">
        <VACSelect
          v-if="hasCollections"
          v-model="form.collection"
          name="collections"
          :disabled="collections.length === 0"
          :options="collections"
          :label="$t('goldenBook.new.fields.collection')"
          class="golden-book-form__field golden-book-form__field--collection"
          @input="handleCollectionChange($event)"
          required
        />
      </div>
    </div>

    <template v-if="form.croppedFile">
      <div class="golden-book-form__file">
        <div class="golden-book-form__file-name">
          {{ form.croppedFile.name }}
        </div>

        <VACButton appearance="discrete" @click="deleteFile" icon="">
          {{ $t('goldenBook.new.fields.file.delete') }}
          <VACIcon class="golden-book-form__cross" name="cross" />
        </VACButton>
      </div>
    </template>

    <template v-else-if="!media">
      <FieldFile
        ref="fileField"
        :accept="accept"
        show-drop-zone
        @change="setUploadedFile"
        @error="uploadError = true"
      >
        <template #buttonLabel>{{
          $t('goldenBook.new.fields.file.input')
        }}</template>

        <template #separatorLabel>{{
          $t('goldenBook.new.fields.file.separator')
        }}</template>

        <template #dropLabel>
          <div>
            <span
              v-for="(label, index) in $t('goldenBook.new.fields.file.drop')"
              :key="index"
              :class="`golden-book-form__drop-label--${index}`"
              class="golden-book-form__drop-label"
              >{{ label }}
            </span>
          </div>
        </template>
      </FieldFile>

      <ErrorAlert v-if="uploadError" @close="uploadError = false">
        {{ $t('goldenBook.new.fields.file.drop.2') }}
      </ErrorAlert>
    </template>

    <div>
      <VACInput
        ref="title"
        name="title"
        v-model="form.title"
        :label="$t('goldenBook.new.fields.title')"
        class="golden-book-form__field golden-book-form__field--title"
        required
      >
        <template #append>
          <EmojiPicker @insert="insertEmoji('title', 'input', $event)" />
        </template>
      </VACInput>
    </div>

    <div>
      <VACTextarea
        ref="message"
        name="message"
        v-model="form.message"
        :label="$t('goldenBook.new.fields.message')"
        class="golden-book-form__field golden-book-form__field--message"
        :max-length="3000"
        :current-length="form.message.length"
        @overflowing="isOverflowing = true"
      >
        <template #append>
          <EmojiPicker @insert="insertEmoji('message', 'textarea', $event)" />
        </template>
      </VACTextarea>
    </div>

    <transition name="fade">
      <BaseAlert v-if="isInError" type="error" @close="isInError = false">
        {{ $t('goldenBook.new.error.generic') }}
      </BaseAlert>
    </transition>
    <div class="golden-book-form__buttons">
      <VACButton
        v-if="$matchMedia.l"
        appearance="link"
        :label="$t('goldenBook.new.buttons.preview')"
        icon=""
        class="golden-book-form__button golden-book-form__button--preview"
        @click.prevent="$emit('preview')"
      />

      <VACButton
        type="button"
        :disabled="!canShare || isSubmitting || isOverflowing"
        class="golden-book-form__button golden-book-form__button--share"
        :label="$t('goldenBook.new.buttons.share')"
        @click="handleAuthorization"
      />
    </div>

    <GoldenBookCropModal
      v-if="modal"
      :file="form.uploadedFile"
      @close="closeCropModal"
      @crop="setCroppedFile"
    />

    <transition name="golden-book-form__inner-">
      <div v-if="isSubmitting" class="golden-book-form__loading-overlay">
        <Spinner />
      </div>
    </transition>
    <NotificationRequest
      v-if="showAuthorizationModal"
      :authorizations="[AuthorizationType.STORY]"
      content-type="story"
      @close="handleCloseAuthorizations"
    />
  </VForm>
</template>

<script>
import AuthorizationType from '@/utils/AuthorizationType';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import ContentAPI from '@/api/ContentAPI';
import {
  VACSelect,
  VACButton,
  VACInput,
  VACTextarea,
  VACIcon,
} from '@webqam-vac-ui/lib';
import Spinner from '@/components/Spinner';
import ErrorAlert from '@/components/alert/ErrorAlert';
import EmojiPicker from '@/components/form/EmojiPicker';
import FieldFile from '@/components/form/FieldFile';
import GoldenBookCropModal from '@/components/golden-book/GoldenBookCropModal';
import BaseAlert from '@/components/alert/BaseAlert';
import NotificationRequest from '../NotificationRequest.vue';
import { prefixType, StoriesMutations } from '@/store/modules/stories';
import { preloadImage } from '@/utils/Preloading';

const THEME_COLLECTION_ID = 0;

export default {
  name: 'GoldenBookForm',
  components: {
    BaseAlert,
    EmojiPicker,
    ErrorAlert,
    FieldFile,
    GoldenBookCropModal,
    Spinner,
    VACButton,
    VACSelect,
    VACInput,
    VACTextarea,
    NotificationRequest,
    VACIcon,
  },
  props: {
    predefinedDatas: { type: Object, default: () => ({}) },
    media: { type: Object, default: undefined },
  },
  data() {
    return {
      AuthorizationType,
      form: {
        pseudonym: this.$store.state.auth.userInfos.pseudo,
        theme: null,
        collection: null,
        uploadedFile: null,
        croppedFile: null,
        mediaData: null,
        title: '',
        message: '',
      },
      themes: [],
      collections: [],
      accept: ['image/jpeg', 'image/png'],
      modal: false,
      uploadError: false,
      deleteButtonIcon: 'mdi-close',
      isSubmitting: false,
      isInError: false,
      isOverflowing: false,
      showAuthorizationModal: false,
    };
  },
  computed: {
    /**
     * @return {boolean}
     */
    hasCollections() {
      return THEME_COLLECTION_ID === this.form.theme;
    },
    /**
     * @return {boolean}
     */
    canShare() {
      return this.form.title.length > 0 && null !== this.mediaData;
    },
    /**
     * @return {boolean}
     */
    isEditMode() {
      return this.predefinedDatas && this.predefinedDatas.ID;
    },
    mediaData() {
      return this.media ?? this.croppedFile;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      ContentAPI.getStoriesFilters().then(({ data }) => {
        this.collections = data.filterCollections.map(
          ({ ID: value, ...rest }) => ({
            value,
            ...rest,
          })
        );
        this.themes = data.filterThemes
          .filter((item) => item.read_only === false)
          .map(({ ID: value, ...rest }) => ({
            value,
            ...rest,
          }));
        this.themes.push({
          value: THEME_COLLECTION_ID,
          label: this.$t('goldenBook.new.fields.collection'),
        });
      });
      if (this.isEditMode) {
        this.form.title = this.predefinedDatas.title;
        this.form.message = this.predefinedDatas.description;
        this.form.theme = this.predefinedDatas.theme.ID;
        this.form.mediaData = this.media;
        if (this.predefinedDatas.collection.length === 1) {
          this.form.collection = this.predefinedDatas.collection[0].ID;
        }
        fetch(this.predefinedDatas['images-portrait'][0].x1)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = (result) => {
              this.form.croppedFile = {
                name: this.$t('goldenBook.edit.currentImage'),
                url: result.target.result,
              };
            };
            reader.readAsDataURL(blob);
          });
      } else {
        this.form.mediaData = this.media;
      }
    },
    /**
     * @param {String} key
     * @param {Object} emoji
     */
    insertEmoji(key, type, emoji) {
      const position = this.$refs[key].$el.querySelector(type).selectionStart;
      this.form[key] =
        this.form[key].slice(0, position) +
        emoji.native +
        this.form[key].slice(position);
    },
    /**
     * @param {FileList} files
     */
    setUploadedFile(files) {
      this.form.uploadedFile = files[0];
      this.modal = true;
      this.uploadError = false;

      disableBodyScroll(document.body);
    },
    /**
     * @param file
     */
    setCroppedFile(file) {
      this.form.croppedFile = {
        name: this.form.uploadedFile.name,
        url: file,
      };
      this.form.uploadedFile = null;
      this.closeCropModal();
    },
    deleteFile() {
      this.form.croppedFile = null;
    },
    closeCropModal() {
      this.$refs.fileField.reset();
      this.modal = false;

      enableBodyScroll(document.body);
    },
    handleThemeChange(payload) {
      this.form.theme = payload.map(({ value: ID, ...rest }) => ({
        ID,
        ...rest,
      }));
    },
    handleCollectionChange(payload) {
      this.form.collection = payload;
    },
    handleAuthorization() {
      if (
        !localStorage.getItem(`${AuthorizationType.STORY}_refused`) &&
        !this.$store.state.profile.notifications[AuthorizationType.STORY]
      ) {
        this.showAuthorizationModal = true;
      } else {
        this.share();
      }
    },
    handleCloseAuthorizations() {
      this.share();
      this.showAuthorizationModal = false;
    },
    share() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      if (this.isEditMode) {
        ContentAPI.updateStory({
          id: this.predefinedDatas.ID,
          title: this.form.title,
          description: this.form.message,
          image: {
            filename: this.mediaData.name,
            data: this.mediaData.data,
          },
          hasVideo: this.mediaData.isVideo,
          theme:
            THEME_COLLECTION_ID === this.form.theme
              ? undefined
              : this.form.theme,
          collection: this.form.collection,
        })
          .then(({ data }) => {
            preloadImage(data['images-portrait'][0].x1);
            this.$store.commit(prefixType(StoriesMutations.resetState));
            this.$emit('create');
          })
          .catch(() => {
            this.isSubmitting = false;
            this.isInError = true;
          });
      } else {
        const formMedia = this.form.mediaData
          ? typeof this.mediaData.data === 'string'
            ? this.mediaData.data
            : ''
          : this.form.croppedFile.url;
        const formMediaName = this.form.mediaData
          ? this.form.mediaData.name
          : this.form.croppedFile.name;
        ContentAPI.postStory({
          title: this.form.title,
          description: this.form.message,
          image: {
            filename: formMediaName,
            data: formMedia,
          },
          hasVideo: this.form.mediaData ? this.form.mediaData.isVideo : false,
          theme:
            THEME_COLLECTION_ID === this.form.theme
              ? undefined
              : this.form.theme,
          collection: this.form.collection,
        })
          .then(({ data }) => {
            preloadImage(data['images-portrait'][0].x1);
            this.$store.commit(prefixType(StoriesMutations.resetState));
            this.$emit('create', data);
          })
          .catch(() => {
            this.isSubmitting = false;
            this.isInError = true;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  @include breakpoint($breakpoint-l) {
    gap: 0;
  }
  &__row {
    margin: 0;
    @include breakpoint($breakpoint-l) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0;
    }
  }

  &__cell {
    @include breakpoint($breakpoint-l) {
      width: calc(50% - 1.375em);
    }
    &:nth-child(2) {
      margin-top: 0.75rem;
      @include breakpoint($breakpoint-l) {
        margin-top: 0;
      }
    }
  }

  &__field {
    &--title,
    &--message {
      @include breakpoint($breakpoint-tablet) {
        padding-top: 2.25em;
      }
      ::v-deep .v-input__append-inner {
        margin-top: 0;
      }
    }

    &--message {
      ::v-deep .v-input {
        &__slot {
          display: flex;
        }

        &__append-inner {
          align-self: flex-end;
        }
      }
    }
  }

  &__drop-label {
    &--0,
    &--1 {
      color: $color-black;
    }

    &--2 {
      font-size: 0.75em;
    }
  }

  &__file {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.657em 1em;
    border-radius: 5px;
    font-family: $font-prelo-condensed;
    background-color: $color-wild-sand;

    ::v-deep {
      .v-icon {
        &::before {
          font-size: 0.75em;
        }
      }
    }
  }

  &__file-name {
    @include line-clamp(1);
    max-width: 15em;
    color: $color-beaver;

    @include breakpoint($breakpoint-l) {
      max-width: 100%;
    }
  }

  &__buttons {
    max-width: 20em;
    margin: 2em auto 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__button {
    &--share {
      font-weight: bold;
    }

    &--preview {
      @include breakpoint($breakpoint-l) {
        display: none;
      }

      ::v-deep .base-btn__body {
        color: $color-beaver;
      }
    }
  }

  &__loading-overlay {
    @extend %loading-overlay;
  }

  &__cross {
    margin-left: 0.5rem;
  }
}
</style>
