import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      animation: null,
    };
  },
  methods: {
    scrollAnimation(el, blockSelector) {
      const animatedClass = `${blockSelector}--animate`;

      setTimeout(() => {
        this.animation = ScrollTrigger.create({
          trigger: el,
          start: 'top center',
          end: 'bottom top',
          // markers: true, // enable debug
          onEnter: () => {
            if (el) {
              el.classList.add(animatedClass);
            }
          },
        });
      }, 100);
    },
  },
  destroyed() {
    if (this.animation) {
      if (this.animation.scrollTrigger) {
        this.animation.scrollTrigger.kill();
      }

      this.animation.kill();
    }
  },
};
