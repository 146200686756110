<template>
  <VForm ref="form" class="email-auth-form" @submit.prevent="login">
    <VACInput
      v-model="form.email"
      type="email"
      name="email"
      required
      :label="$t('auth.form.email.label')"
    />
    <VACInput
      v-model="form.password"
      :label="$t('auth.form.password.label')"
      :append-icon="revealPassword ? 'eye-stroked' : 'eye'"
      :type="revealPassword ? 'text' : 'password'"
      name="email_auth_form_password"
      :rules="rules.password"
      @toggle="revealPassword = !revealPassword"
    >
      <template #append>
        <VACButton
          appearance="discrete"
          class="email-auth-form__toggle"
          icon=""
          @click.prevent="revealPassword = !revealPassword"
        >
          <VACIcon :name="revealPassword ? 'eye-stroked' : 'eye'" />
        </VACButton>
      </template>
    </VACInput>
    <VACButton
      appearance="link"
      class="email-auth-form__forgot-password"
      @click="forgotPassword"
    >
      {{ $t('auth.form.passwordForgot') }}
    </VACButton>
    <div class="email-auth-form__robot">
      <component
        :is="captchaComponentName"
        ref="captcha"
        :sitekey="siteKey"
        @verify="onVerify"
      />
      <div v-if="errorMessage || forceError" class="email-auth-form__error">
        {{ forceError ? forceError : errorMessage }}
      </div>
    </div>
    <VACButton
      class="email-auth-form__login-button"
      appearance="primary"
      type="submit"
    >
      {{ $t('auth.form.submit') }}
    </VACButton>
  </VForm>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import FieldEmail from '@/components/form/FieldEmail';
import { VACButton, VACIcon, VACInput } from '@webqam-vac-ui/lib';
import RouteName from '@/utils/RouteName';
import LoginForm from '@/mixins/Auth/LoginForm';
import Captcha from '@/mixins/Captcha';
import HydraClient from '@/api/HydraClient';

export default {
  name: 'EmailAuthForm',
  components: {
    VACIcon,
    VACButton,
    VACInput,
    FieldEmail,
    VueRecaptcha,
    VueHcaptcha,
  },
  mixins: [Captcha, LoginForm],
  props: {
    forceError: { type: String, default: undefined },
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      revealPassword: false,
      errorMessage: null,
      rules: {
        password: [(v) => !!v || this.$t('form.rules.required')],
      },
      passwordForgotRouteName: RouteName.PASSWORD_FORGOT,
    };
  },
  methods: {
    /**
     * @return {Promise<void>}
     */
    async callApi() {
      this.$store.dispatch('loader/enableLoader');
      const rez = await HydraClient.login(
        this.form.email,
        this.form.password,
        this.$route.query.login_challenge,
        this.captchaToken
      );
      window.location = rez.data.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.email-auth-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;
  &__forgot-password {
    text-align: center;
    align-self: center;

    ::v-deep .base-btn__body::after {
      content: none;
    }
  }

  ::v-deep .vac-input__input-container {
    height: 1rem;
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  &__error {
    font-size: 0.75em;
    font-style: italic;
    color: $color-danger;
    display: block;
    margin-top: 0.5em;
    text-align: center;
  }

  &__login-button {
    font-weight: bold;
  }

  &__toggle {
    min-width: 1rem;
  }
}
</style>
