<template>
  <div class="default-layout-header">
    <!-- TODO Do stuff here -->
  </div>
</template>

<script>
export default {
  name: 'DefaultLayoutHeader',
};
</script>

<style lang="scss" scoped>
.default-layout-header {
}
</style>
