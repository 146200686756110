<template>
  <section class="home-presentation">
    <div class="home-presentation__container">
      <div class="home-presentation__content-wrapper">
        <h3 class="home-presentation__title">
          {{ $t('home.presentation.title') }}
        </h3>
        <!-- eslint-disable vue/no-v-html, vue-i18n/no-v-html -->
        <p
          class="home-presentation__description"
          v-html="$t('home.presentation.description')"
        ></p>
        <!--eslint-enable-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HomePresentation',
};
</script>

<style lang="scss" scoped>
.home-presentation {
  $block-selector: &;

  position: relative;
  margin-top: 2.5em;
  background-color: $color-black;
  background-image: url('../../assets/mobile-bottom-picture.png');
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  color: $color-white;

  @include breakpoint($breakpoint-tablet) {
    margin-top: 3.438em;
    background-image: url('../../assets/bottom-picture.png');
  }

  @include breakpoint($breakpoint-desktop) {
    margin: 5em 0;
  }

  &__title {
    font-family: $font-family-prelo-condensed;
  }

  &__description {
    font-size: 0.875em;
  }

  &__container {
    @extend %base-container;

    display: flex;
    justify-content: flex-end;
    padding: 1em 1em 10em;

    @include breakpoint($breakpoint-tablet) {
      padding: 3.5em 1em 2.5em;
    }

    @include breakpoint($breakpoint-desktop) {
      padding: 6.25em 0;
    }
  }

  &__content-wrapper {
    width: 100%;
    padding-bottom: 20%;

    @include breakpoint($breakpoint-tablet) {
      width: 50%;
      padding-bottom: 0;
    }

    @include breakpoint($breakpoint-desktop) {
      padding-left: 1em;
    }
  }
  body.dir-rtl & {
    &__container {
      display: flex;
      justify-content: flex-start;
    }
  }
}
</style>
