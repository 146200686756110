<template>
  <div class="account-button">
    <VACButton
      :class="isMember ? 'account-button__button--member' : ''"
      class="account-button__button account-button__button--account"
      appearance="discrete"
      :data-open="menuOpen"
      icon="user"
      ref="button"
      @click="menuHandler"
    />
    <transition name="fade-top">
      <div v-show="menuOpen" class="account-button__menu" ref="menu">
        <template v-if="isGuest">
          <ul class="account-button__member-menu">
            <li class="account-button__menu-item">
              <AuthLogin v-if="isCN" v-slot="{ open }">
                <VACButton
                  class="account-button__menu-link account-button__logout"
                  appearance="discrete"
                  @click="open"
                  :label="$t('header.visitors.login')"
                />
              </AuthLogin>
              <VACButton
                v-else
                appearance="discrete"
                icon=""
                class="account-button__menu-link account-button__logout"
                @click="startHydraFlow"
              >
                {{ $t('header.visitors.login') }}
              </VACButton>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul class="account-button__member-menu">
            <li
              v-for="(item, index) in items"
              :key="index"
              class="account-button__menu-item"
            >
              <RouterLink v-if="item.link.name" :to="item.link">
                <VACButton
                  appearance="discrete"
                  class="account-button__menu-link"
                  icon=""
                >
                  {{ item.title }}
                </VACButton>
              </RouterLink>
              <VACButton
                v-else
                icon=""
                :href="item.link.href"
                :attributes="cookiesAllowed ? { target: '_blank' } : {}"
                class="account-button__menu-link"
                appearance="discrete"
              >
                {{ item.title }}
              </VACButton>
            </li>
            <li class="account-button__menu-item">
              <VACButton
                appearance="discrete"
                icon=""
                class="account-button__menu-link"
                @click="showServicesModal"
              >
                {{ $t('services.label') }}
              </VACButton>
            </li>
            <li class="account-button__menu-item">
              <VACButton
                appearance="discrete"
                icon=""
                class="account-button__menu-link account-button__logout"
                @click="logout"
              >
                {{ $t('member_menu.logout') }}
              </VACButton>
            </li>
          </ul>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { ServicesMutations } from '@/store/modules/services';
import { VACButton } from '@webqam-vac-ui/lib';
import Auth from '@/mixins/Auth';
import AuthLogin from '@/components/auth/AuthLogin';
import RouteName from '@/utils/RouteName';
import AccountAPIWrapper from '@/api/AccountAPIWrapper';
import Internationalization from '@/utils/Internationalization';
import { generateLocalizedCCPLink } from '@/utils/CCP';

export default {
  name: 'AccountButton',
  components: {
    VACButton,
    AuthLogin,
  },
  mixins: [Auth],
  props: {
    isGuest: {
      type: Boolean,
    },
  },
  data() {
    return {
      cookiesAllowed: false,
      menuOpen: false,
      servicesOpen: false,
    };
  },
  computed: {
    isCN() {
      return Internationalization.isCnCountry();
    },
    clickTargets() {
      return [this.$refs.button, this.$refs.menu];
    },
    items() {
      return [
        {
          title: this.$t('member_menu.my_account'),
          link: {
            name: RouteName.ACCOUNT_DETAILS,
          },
        },
        {
          title: this.$t('member_menu.my_posts'),
          link: {
            name: RouteName.ACCOUNT_POSTS,
          },
        },
        {
          title: this.$t('member_menu.my_collection'),
          link: {
            href: generateLocalizedCCPLink('/portfolio'),
          },
        },
      ];
    },
  },
  methods: {
    async logout() {
      await AccountAPIWrapper.logout();
      this.$router.push({ name: RouteName.PREHOME });
    },
    startHydraFlow() {
      this.$router.push({ name: RouteName.ACCOUNT_ASK_LOGIN });
    },
    menuHandler() {
      this.menuOpen = !this.menuOpen;
    },
    closeDropdown(e) {
      const menu = this.$refs.menu;
      const button = this.$refs.button;
      if (
        button &&
        button.$el &&
        !menu.contains(e.target) &&
        e.target !== button &&
        !button.$el.contains(e.target)
      ) {
        this.menuOpen = false;
      }
    },
    showServicesModal() {
      this.$store.commit(ServicesMutations.toggleModal, true);
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped lang="scss">
.account-button {
  position: relative;
  &__menu {
    width: 10.25em;
    position: absolute;
    top: 3.5rem;
    right: 0;
    box-shadow: 0 5px 5px rgba($color-black, 0.15);
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent #ffffff transparent;
      position: absolute;
      top: 0;
      right: 1rem;
      transform: translateY(-100%);
    }
  }

  &__member-menu {
    background-color: $color-white;
    box-shadow: 0 0 0.5rem rgba($color-black, 0.15);
    margin-top: 0;
    list-style: none;
  }

  &__menu-item {
    position: relative;
    min-height: 0;
  }

  &__menu-link {
    font-size: 1rem;
    text-transform: initial;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  &__button {
    min-height: $base-btn-min-height-em;
    text-transform: initial;

    &::before {
      @extend %icon;
      font-size: 1.4em;
    }

    &--account {
      &.router-link-exact-active {
        &::before {
          font-weight: bold;
          color: $color-beaver;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          &::before {
            font-weight: bold;
            color: $color-black;
          }
        }
      }
    }
  }

  .fade-top-enter-active {
    transition-property: opacity, transform;
    transition-duration: 0.5s;
  }

  .fade-top-enter,
  .fade-top-leave-to {
    opacity: 0;
    transform: translateY(-10%);
    transition-duration: 0.5s;
  }
}
</style>
