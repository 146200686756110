<template>
  <Portal :to="PortalName.APP_FOOTER">
    <div :class="menuClasses">
      <div class="slide-down-menu__container">
        <div class="slide-down-menu__menu">
          <div class="slide-down-menu__header">
            <VACButton
              appearance="discrete"
              icon="close"
              class="slide-down-menu__close"
              @click="emitClose(true, $event)"
            />
            <BaseLogo
              class="slide-down-menu__logo"
              hide-tag-line-until-s
              :logo-only="!this.$matchMedia.s"
            />
            <AccountButton class="slide-down-menu__account" />
          </div>
          <div class="slide-down-menu__menu-items">
            <SlideDownMenuItem
              v-for="(item, index) in menuItems"
              :key="`slide-menu-item-${index}`"
              :item="item"
              @closeMenu="emitClose(true)"
            />
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import BaseLogo from '@/components/BaseLogo';
import { VACButton } from '@webqam-vac-ui/lib';
import PortalName from '@/utils/PortalName';
import SlideDownMenuItem from '@/components/SlideDownMenuItem';
import AccountButton from '@/components/AccountButton';
import RouteName from '@/utils/RouteName';

const BLOCK_SELECTOR = 'slide-down-menu';
export default {
  name: 'SlideDownMenu',
  components: { AccountButton, SlideDownMenuItem, VACButton, BaseLogo },
  props: {
    isOpened: { type: Boolean },
    isMember: Boolean,
  },
  data() {
    return {
      PortalName,
    };
  },
  computed: {
    menuClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--is-opened`]: this.isOpened,
        },
      ];
    },
    /**
     * @return {Array}
     */
    menuItems() {
      const disabled = !this.isMember;

      return [
        {
          title: this.$t('member_menu.home'),
          link: this.$router.resolve({ name: RouteName.HOME }).href,
        },
        {
          title: this.$t('member_menu.magazine'),
          link: undefined,
          disabled,
          subitems: [
            {
              title: this.$t('magazine.filters.all'),
              link: this.$router.resolve({ name: RouteName.MAGAZINE }).href,
            },
            {
              title: this.$t('magazine.filters.mySelection'),
              link: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 'selection' },
              }).href,
            },
            {
              title: this.$t('magazine.filters.experience'),
              link: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 1 },
              }).href,
            },
            {
              title: this.$t('magazine.filters.testimony'),
              link: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 2 },
              }).href,
            },
            {
              title: this.$t('magazine.filters.testimony'),
              link: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 3 },
              }).href,
            },
          ],
        },
        {
          title: this.$t('member_menu.events'),
          link: undefined,
          disabled,
          subitems: [
            {
              title: this.$t('member_menu.all_events'),
              link: this.$router.resolve({ name: RouteName.EVENTS }).href,
            },
            {
              title: this.$t('member_menu.my_events'),
              link: this.$router.resolve({
                name: RouteName.EVENTS,
                params: { preFilter: 'my_events' },
              }).href,
            },
          ],
        },
        {
          title: this.$t('member_menu.golden_book'),
          link: this.$router.resolve({ name: RouteName.GOLDEN_BOOK }).href,
          disabled,
        },
      ];
    },
  },
  methods: {
    emitClose(forceClose, event) {
      if (
        (event && event.target.classList.contains(BLOCK_SELECTOR)) ||
        forceClose
      ) {
        this.$emit('closed');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.slide-down-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  height: 100vh;
  background-color: rgba($color-shuttle-gray, 0.7);
  z-index: 20;
  transform: translateY(-100%);
  transition: transform $default-transition-duration;

  @include breakpoint($breakpoint-desktop) {
    display: none;
  }

  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: 2rem 3fr 2rem;
    place-items: center center;
  }

  &__account {
    @include breakpoint($breakpoint-tablet) {
      display: none;
    }
  }

  &--is-opened {
    transform: translateY(0);
  }

  &__container {
    background-color: $color-white;
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;
    height: 100%;
    background-image: url('../assets/background-mobile-menu.png');
    background-position: bottom left;
    background-size: contain;
    overflow-y: scroll;

    @include breakpoint($breakpoint-tablet) {
      height: 90%;
      background-image: url('../assets/background-tablet-menu.png');
    }
  }

  &__menu {
    width: 100%;
    padding: 0 2rem;
    padding-bottom: 10em;
  }

  &__menu-items {
    max-width: 20.688em;
    margin: 1rem auto;
  }

  &__logo {
    place-self: center center;
    justify-content: center;
  }
}
</style>
