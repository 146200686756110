<template>
  <transition name="fade">
    <div v-if="event" class="event-register">
      <EventHeader :event="event" hide-cta />
      <div class="event-register__container">
        <div class="event-register__infos">
          <router-link
            :to="{
              name: RouteName.SINGLE_EVENT,
              params: { eventId: event.ID },
            }"
          >
            <BaseBtn block discrete class="event-register__back">
              {{ $t('events.register.go_back') }}
            </BaseBtn>
          </router-link>
          <!--eslint-disable vue/no-v-html-->
          <p class="event-register__infos-description">
            {{ eventDescription }}
          </p>
          <!--eslint-enable vue/no-v-html-->
        </div>
        <EventRegisterForm
          :register-infos="registerInfos"
          :event="event"
          class="event-register__form"
        />
      </div>
    </div>
    <PageLoader v-else translucide />
  </transition>
</template>

<script>
import EventHeader from '@/components/event/EventHeader';
import EventRegisterForm from '@/components/event/EventRegisterForm';
import BaseBtn from '@/components/BaseBtn';
import ContentAPI from '@/api/ContentAPI';
import RouteName from '@/utils/RouteName';
import { stripHtml } from '@/utils/String';
import PageLoader from '@/components/PageLoader';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'EventRegister',
  components: { PageLoader, BaseBtn, EventRegisterForm, EventHeader },
  mixins: [Tracking],
  props: {
    eventId: { type: String, default: undefined },
  },
  data() {
    return {
      event: undefined,
      registerInfos: undefined,
      RouteName,
    };
  },
  computed: {
    eventDescription() {
      const maxLength = 600;
      let stringStripped = stripHtml(this.event.description);
      if (stringStripped.length < maxLength) {
        return stringStripped;
      }
      stringStripped = stringStripped.substr(0, maxLength);

      stringStripped = stringStripped.substr(
        0,
        Math.min(stringStripped.length, stringStripped.lastIndexOf(' '))
      );
      return stringStripped + '...';
    },
  },
  mounted() {
    this.trackPageView();
    ContentAPI.getEvent({ id: this.eventId }).then((data) => {
      ContentAPI.getMyEvents({}).then((enrolledData) => {
        if (enrolledData.future_events) {
          enrolledData.future_events.forEach((enrolled) => {
            if (enrolled.ID === data.ID) {
              this.registerInfos = enrolled.register;
            }
          });
        }
        this.event = data;
      });
    });
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('title.events')}${
        this.event ? ` | ${this.event.title}` : ''
      }`,
    };
  },
};
</script>

<style scoped lang="scss">
.event-register {
  &__container {
    @extend %base-container;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @include breakpoint($breakpoint-tablet) {
      justify-content: space-between;
      align-items: center;
    }

    @include breakpoint($breakpoint-desktop) {
      flex-direction: row;
      overflow: initial;
      margin-bottom: 2em;
    }
  }

  &__infos {
    padding: 1em;

    @include breakpoint($breakpoint-tablet) {
      position: relative;
      margin-top: 0.5em;
      flex-basis: 30%;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        box-shadow: 0 0 5px #00000029;
        pointer-events: none;
      }
    }

    @include breakpoint($breakpoint-desktop) {
      margin-top: 0;
    }
  }

  &__infos-title {
    font-weight: bold;
    font-family: $font-family-prelo-condensed;
  }

  &__form {
    width: 100%;
    margin-top: 3.188em;
    margin-bottom: 3.188em;

    @include breakpoint($breakpoint-desktop) {
      width: auto;
      flex-basis: 70%;
      padding-left: 3em;
      margin: 0;
    }
  }

  &__back {
    font-family: $font-family-prelo-condensed;
    font-size: 18px;
    justify-content: left;
    padding-left: 0;
    margin-bottom: 3.75em;

    &::before {
      @extend %icon;
      @extend %icon--arrow-left;
      z-index: 1;
      margin-left: 1em;
      margin-right: 0.5em;
      transition: all $default-transition-duration;
    }

    &:hover {
      &::before {
        margin-left: 0.5em;
        margin-right: 1em;
      }
    }
  }
}
</style>
