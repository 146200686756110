<template>
  <div class="account-we-chat-association">
    <WizardStepper
      :steps="1"
      :start="1"
      :left-image="leftImage"
      :right-image="rightImage"
    >
      <template #step-1>
        <WizardStep :title="$t('auth.wechat')" no-step bold-title>
          <PhoneAuthForm wechat-association />
          <AuthFooter />
        </WizardStep>
      </template>
    </WizardStepper>
  </div>
</template>

<script>
import AuthFooter from '@/components/auth/AuthFooter';
import WizardStepper from '@/components/wizard/WizardStepper';
import WizardStep from '@/components/wizard/WizardStep';
import leftImage from '@/assets/stepper/password-forgot-left.png';
import rightImage from '@/assets/stepper/password-forgot-right.png';
import PhoneAuthForm from '@/components/form/PhoneAuthForm';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountWeChatAssociation',
  components: { PhoneAuthForm, AuthFooter, WizardStepper, WizardStep },
  mixins: [Tracking],
  data() {
    return {
      leftImage,
      rightImage,
    };
  },
  mounted() {
    this.trackPageView();
  },
};
</script>

<style scoped lang="scss"></style>
