<template>
  <div class="conversation-single">
    <PageLoader v-if="!content || !loaded" relative />
    <template v-else>
      <div class="conversation-single__header">
        <RouterLink :to="backRoute" class="conversation-single__back-btn">
          {{ $t('wizard.header.back') }}
        </RouterLink>
        <GoldenBookCardMini
          :node-id="nodeId"
          class="conversation-single__card"
          :author="content.author"
          :author-id="content.authorId"
          :title="content.title"
          :background-image="content.image"
          :like-counter="content.likes"
          :comment-counter="content.conversations"
          :date="new Date(parseInt(content.date) * 1000)"
          :i-like="content.iLike"
          with-counters
        />
      </div>
      <StartConversation
        ref="startConversation"
        :is-submitting="submittingConversation"
        @message="handleMessageSubmission"
      />
    </template>
    <ConversationGrid
      v-show="loaded"
      :node-id="nodeId"
      is-full
      :node-type="contentType"
      @loaded="conversationLoaded"
      @conversationCountEdit="handleConversationUpdate"
    />
  </div>
</template>

<script>
import PageLoader from '@/components/PageLoader';

import NodeType from '@/utils/NodeType';

import ContentAPI from '@/api/ContentAPI';
import GoldenBookCardMini from '@/components/golden-book/GoldenBookCardMini';
import StartConversation from '@/components/conversation/StartConversation';
import ConversationGrid from '@/components/conversation/ConversationGrid';
import RouteName from '@/utils/RouteName';
import BusName from '@/utils/BusName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'ConversationSingle',
  components: {
    ConversationGrid,
    StartConversation,
    GoldenBookCardMini,
    PageLoader,
  },
  mixins: [Tracking],
  props: {
    nodeId: { type: String, required: true },
  },
  data() {
    return {
      content: undefined,
      loaded: false,
      NodeType,
      submittingConversation: false,
    };
  },
  computed: {
    contentType() {
      return this.$route.path.split('/')[2];
    },
    backRoute() {
      if (this.contentType === NodeType.STORY) {
        return {
          name: RouteName.GOLDEN_BOOK_SINGLE_STORY,
          params: {
            storyId: this.nodeId,
          },
        };
      }
      if (this.contentType === NodeType.ARTICLE) {
        return {
          name: RouteName.SINGLE_ARTICLE,
          params: {
            articleId: this.nodeId,
          },
        };
      }
      return undefined;
    },
  },
  mounted() {
    this.fetchContent();
  },
  methods: {
    conversationLoaded() {
      this.loaded = true;
    },
    fetchContent() {
      if (this.contentType === NodeType.STORY) {
        this.fetchStory();
      }
      if (this.contentType === NodeType.ARTICLE) {
        this.fetchArticle();
      }
    },
    async fetchStory() {
      const { data } = await ContentAPI.getStories({ id: this.nodeId });
      if (data.nodes.length === 1) {
        this.content = {
          title: data.nodes[0].title,
          author: data.nodes[0].author.pseudo,
          authorId: data.nodes[0].author.ID,
          date: data.nodes[0].date,
          likes: data.nodes[0].count_like,
          conversations: data.nodes[0].count_conversation,
          iLike: data.nodes[0].i_like,
          image: data.nodes[0]['images-portrait'][0].x1,
        };
        this.trackPageView(
          this.$t('accountPosts.conversationsOf', {
            name: this.content.title,
          })
        );
      } else {
        throw new Error('Error while fetching content');
      }
    },
    async fetchArticle() {
      const article = await ContentAPI.getArticle({ id: this.nodeId });
      this.content = {
        title: article.title,
        author: undefined,
        date: article.date,
        likes: article.count_like,
        conversations: article.count_conversation,
        iLike: article.i_like,
        image: article['images-portrait'][0].x1,
      };
      this.trackPageView(
        this.$t('accountPosts.conversationsOf', {
          name: this.content.title,
        })
      );
    },
    async handleMessageSubmission(message) {
      this.submittingConversation = true;
      const { data } = await ContentAPI.postConversation(this.nodeId, message);
      this.$root.$emit(BusName.NEW_CONVERSATION, data);
      this.$refs.startConversation.clearText();
      this.submittingConversation = false;
    },
    handleConversationUpdate(payload) {
      this.content.conversations = payload;
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${
        this.content
          ? this.$t('accountPosts.conversationsOf', {
              name: this.content.title,
            })
          : ''
      }`,
    };
  },
};
</script>

<style scoped lang="scss">
.conversation-single {
  &__header {
    @extend %base-container;
  }
  &__card {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-bottom: 2.188em;

    ::v-deep {
      .golden-book-story-social {
        margin-top: 1em;
      }
    }

    @include breakpoint($breakpoint-tablet) {
      ::v-deep {
        .golden-book-story-header__info {
          flex-direction: row;
        }
        .golden-book-story-header__by {
          &::before {
            content: ' · ';
          }
        }
        .golden-book-story-social {
          margin-top: 1.563em;
          margin-left: 1em;
        }
      }
    }
  }

  &__back-btn {
    gap: 0;
    padding-right: 0.25rem;
  }
}
</style>
