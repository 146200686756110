<template>
  <PageLoader v-if="isEditMode && isFetching" />
  <div v-else class="golden-book-new-story">
    <div
      class="golden-book-new-story__container golden-book-new-story__container--new"
    >
      <MainTitle class="golden-book-new-story__title">{{
        pageTitle
      }}</MainTitle>

      <p v-if="!editNode" class="golden-book-new-story__description">
        {{ $t('goldenBook.new.description') }}
      </p>

      <div class="golden-book-new-story__content">
        <GoldenBookForm
          :predefined-datas="editNode"
          :media="media"
          class="golden-book-new-story__form"
          @create="showCongrats($event)"
          @preview="showPreviewModal"
          @update="updatePreview"
        />

        <div class="golden-book-new-story__preview">
          <div class="golden-book-new-story__preview-wrapper">
            <div class="golden-book-new-story__preview-title">
              {{ $t('goldenBook.new.preview.title') }}
            </div>

            <GoldenBookCard
              ref="preview"
              small
              :author="$store.state.auth.userInfos.pseudo"
              :author-id="$store.state.auth.userInfos.userId"
              :background-image="storyImageUrl"
              :title="preview.title"
              :with-story="!!preview.message"
              class="golden-book-new-story__card-preview"
            />
          </div>
        </div>
      </div>

      <GoldenBookPreviewModal
        v-if="previewModal && $matchMedia.l"
        :story="preview"
        @close="closePreviewModal"
      />
    </div>
  </div>
</template>

<script>
import MainTitle from '@/components/MainTitle';
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';
import GoldenBookForm from '@/components/golden-book/GoldenBookForm';
import GoldenBookPreviewModal from '@/components/golden-book/GoldenBookPreviewModal';
import RouteName from '@/utils/RouteName';
import PageLoader from '@/components/PageLoader';
import ContentAPI from '@/api/ContentAPI';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'GoldenBookNewStory',
  components: {
    PageLoader,
    MainTitle,
    GoldenBookCard,
    GoldenBookForm,
    GoldenBookPreviewModal,
  },
  mixins: [Tracking],
  props: {
    storyId: { type: String, default: undefined },
    media: { type: Object, default: undefined },
  },
  data() {
    return {
      previewModal: false,
      preview: {},
      isFetching: false,
      RouteName,
      editNode: undefined,
      chunks: undefined,
    };
  },
  computed: {
    /**
     * @return {boolean}
     */
    isEditMode() {
      return this.$route.name === RouteName.GOLDEN_BOOK_EDIT_STORY;
    },
    /**
     * @return {object|null}
     */
    storyImageUrl() {
      if (this.media && this.media.isVideo) {
        return this.media.thumbnail;
      } else {
        return this.preview && this.preview.croppedFile
          ? this.preview.croppedFile.url
          : this.preview.mediaData && !this.media.isVideo
          ? this.preview.mediaData.data
          : undefined;
      }
    },
    /**
     * @return {boolean}
     */
    isAdvancedAnimation() {
      return this.$vuetify.breakpoint.width >= 992; // $breakpoint-l: 62em;
    },
    /**
     * @return {string}
     */
    pageTitle() {
      return this.editNode
        ? this.$t('goldenBook.edit.title')
        : this.$t('goldenBook.new.title');
    },
  },
  mounted() {
    this.trackPageView('Golden Book | New Story');
    if (this.isEditMode) {
      this.fetchStoryToEdit();
    }
  },
  methods: {
    goToStoryPage() {
      if (this.media && this.media.thumbnail && !this.$matchMedia.l) {
        this.$router.push({
          name: RouteName.GOLDEN_BOOK,
          params: { thumbnail: this.media.thumbnail },
        });
      } else {
        this.$router.push({ name: RouteName.GOLDEN_BOOK });
      }
    },
    showPreviewModal() {
      this.previewModal = true;
    },
    /**
     * @param {Object} data
     */
    updatePreview(data) {
      this.preview = data;
      this.preview.date = new Date();
    },
    closePreviewModal() {
      this.previewModal = false;
    },
    showCongrats(story) {
      if (this.media && this.media.isVideo && story) {
        ContentAPI.uploadStoryVideo(story.ID, this.media.data);
        this.$store.state.stories.pendingStory = story;
        this.$store.dispatch('stories/pollingHandler');
      }
      this.goToStoryPage();
    },
    async fetchStoryToEdit() {
      this.isFetching = true;
      const { data } = await ContentAPI.getStories({ id: this.storyId });
      if (data.nodes.length === 1) {
        this.editNode = data.nodes[0];
        this.isFetching = false;
      } else {
        this.$router.push({ name: RouteName.PAGE_NOT_FOUND });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-new-story {
  $block-selector: &;
  $transition-duration: 1s;

  @extend %base-container;

  &--congrats {
    position: relative;

    #{$block-selector} {
      &__container {
        &--new {
          opacity: 0;
        }
      }
    }
  }

  &__description {
    margin: 2rem 2rem 3rem;
    text-align: center;
  }

  &__container {
    transition: opacity $transition-duration;

    &--congrats {
      margin-top: -6em;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition-delay: $transition-duration;

      @media (max-width: 991px) {
        transform: none !important;
      }

      @include breakpoint($breakpoint-l) {
        flex-direction: row;
      }
    }

    &--enter-active,
    &--leave-active {
      @media (max-width: 991px) {
        transition: opacity $transition-duration $transition-duration;
      }
    }

    &--enter,
    &--leave-to {
      @media (max-width: 991px) {
        opacity: 0;
      }
    }

    &--enter-to,
    &--leave {
      @media (max-width: 991px) {
        opacity: 1;
      }
    }

    &--content {
      #{$block-selector} {
        &__created-description {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    @include breakpoint($breakpoint-l) {
      gap: 5rem;
    }
  }

  &__form {
    flex-grow: 1;
    margin-bottom: 3em;
  }

  &__card-preview {
    @include breakpoint($breakpoint-l) {
      min-height: unset;
      min-width: unset;
      min-width: 16.25rem;
      aspect-ratio: 33 / 52;
    }
  }

  &__preview {
    display: none;

    @include breakpoint($breakpoint-l) {
      display: block;
      background-color: $color-white;
      box-shadow: 0 0 10px $color-alto;
      padding: 2rem 2.25rem;
      height: fit-content;
    }
  }

  &__preview-wrapper {
    @include breakpoint($breakpoint-l) {
      position: relative;
    }
  }

  &__preview-title {
    font-size: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $color-black;
  }

  &__card-wrapper,
  &__created-description {
    padding: 0.25em;
  }

  &__card-wrapper {
    width: 100%;
    background-color: $color-wild-sand;

    @media (max-width: 991px) {
      transform: none !important;
    }

    @include breakpoint($breakpoint-l) {
      width: auto;
      padding: 1em;
      margin-right: 1em;
    }
  }

  &__card {
    &--small {
      @include breakpoint($breakpoint-l) {
        display: none;
      }
    }

    &--large {
      display: none;

      @include breakpoint($breakpoint-l) {
        width: 20.625em;
        display: flex;
      }
    }
  }
  &__created-description {
    @include breakpoint($breakpoint-l) {
      opacity: 0;
      transition: opacity $transition-duration $transition-duration;
    }
  }

  ::v-deep {
    .golden-book-card {
      margin-right: 0;

      &__background {
        min-height: 30.875em;
        width: 20.625em;
      }
    }

    .golden-book-card-mini {
      &__body {
        display: flex;
        flex-direction: column;
      }

      &__title {
        flex: 1%;
      }

      &__info {
        display: flex;
        flex-direction: column;
      }
    }
  }
  body.dir-rtl & {
    .golden-book-card {
      margin-right: 1em;
    }
    &__preview {
      margin-right: 1em;
      &-title {
        margin-right: 1em;
      }
    }
    &__preview-wrapper {
      &::after {
        right: 100%;
        left: 0;
      }
    }
  }
}
</style>
