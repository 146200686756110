<template>
  <div id="app" class="app">
    <transition name="fade">
      <PageLoader v-if="displayLoading" />
    </transition>
    <IE v-if="Navigator.isInternetExplorer" />
    <template v-else>
      <v-app>
        <BrowserUpgradeBar />

        <component
          :is="$route.meta.layout || 'div'"
          v-if="hasCountries"
          class="app__content"
        >
          <router-view />
        </component>
        <div v-else class="app__loading">
          <Spinner />
        </div>
        <ServicesModal
          :is-open="showServicesModal"
          @close="console.log('close')"
        />
        <PortalTarget :name="PortalName.APP_FOOTER" multiple />
        <PortalTarget :name="PortalName.MODAL" />
        <PortalTarget :name="PortalName.LANG_MODAL" />
        <PortalTarget :name="PortalName.LANG_MODAL_SECOND" />
        <PortalTarget :name="PortalName.STICKY_HEADER" />
      </v-app>
    </template>
  </div>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';
import BaseFooter from '@/components/BaseFooter';
import BrowserUpgradeBar from '@/components/BrowserUpgradeBar';
import BaseHeader from '@/components/DefaultLayoutHeader';
import { CountriesMutation, prefixType } from '@/store/modules/countries';
import PortalName from '@/utils/PortalName';
import Spinner from '@/components/Spinner';
import IE from '@/views/IE';
import Navigator from '@/utils/Navigator';
import PageLoader from '@/components/PageLoader';
import Internationalization from '@/utils/Internationalization';
import authentication from '@/utils/authentication';
import AutoLogin from '@/mixins/AutoLogin';
import PushNotifications from '@/mixins/PushNotifications';
import storage from '@/utils/storage';
import AutoLoginStorage from '@/enums/AutoLoginStorage';
import ServicesModal from '@/components/services/ServicesModal.vue';

export default {
  name: 'App',
  components: {
    ServicesModal,
    PageLoader,
    IE,
    Spinner,
    BrowserUpgradeBar,
    BaseHeader,
    BaseFooter,
  },
  mixins: [AutoLogin, PushNotifications],
  metaInfo() {
    return {
      title: this.$t('the_hour_club'),
      meta: [
        {
          property: 'title',
          content: this.$t('meta.title'),
        },
        {
          property: 'description',
          content: this.$t('meta.description'),
        },
        {
          property: 'og:title',
          content: this.$t('meta.title'),
        },
        {
          property: 'og:description',
          content: this.$t('meta.description'),
        },
        {
          property: 'og:image',
          content: '/thc-og-image.png',
        },
      ],
    };
  },
  data() {
    return {
      PortalName,
      Navigator,
      keepLoader: false,
      socialLinks: null,
    };
  },
  computed: {
    showServicesModal() {
      return this.$store.state['services'].showModal;
    },
    hasCountries() {
      return this.$store.state['countries'].countryList.length > 0;
    },
    displayLoading() {
      return this.$store.state.loader.loaderStatus;
    },
  },
  async created() {
    this.$i18n.locale = Internationalization.getCurrentLocale() || 'en';
    this.$store.dispatch('loader/enableLoader');
    const countries = await ContentAPI.getCountries();
    this.$store.commit(prefixType(CountriesMutation.setCountries), countries);
    if (this.$store.state.auth.userInfos) {
      this.$OneSignal.setExternalUserId(this.$store.state.auth.userInfos.id);
    }
    await this.initAutoLogin();
    await this.processWechatBinding();
    if (!this.keepLoader) {
      this.$store.dispatch('loader/disableLoader');
    }
  },
  methods: {
    async processWechatBinding() {
      if (
        this.$route.query.signature &&
        this.$route.query.encryptMsg &&
        !authentication.retrieve.sessionToken
      ) {
        this.keepLoader = true;
        storage.set(AutoLoginStorage.RWF_TOKEN, this.$route.query.encryptMsg);
        await this.loginForCN();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  height: 100vh;

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
