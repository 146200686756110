<template>
  <div v-if="event" :class="blockClasses">
    <router-link
      v-if="!noInteraction"
      class="card-event__link"
      :to="{ name: RouteName.SINGLE_EVENT, params: { eventId: event.ID } }"
    />
    <EventBadge
      v-if="!small && eventStatus"
      class="card-event__badge"
      :status="eventStatus"
    />
    <div class="card-event__image-container">
      <img class="card-event__image" :src="event['images-main'][0].x2" alt="" />
    </div>
    <div class="card-event__infos">
      <div class="card-event__info-block">
        <p v-if="!noInfos" class="card-event__info card-event__info--date">
          {{ eventDate }}
        </p>
        <p class="card-event__info card-event__info--name">{{ event.title }}</p>
        <p v-if="!small" class="card-event__info card-event__info--description">
          {{ formattedDescription }}
        </p>
        <p
          v-if="!noInfos && eventLocation"
          class="card-event__info card-event__info--location"
        >
          {{ eventLocation }}
        </p>
      </div>
      <router-link
        v-if="!noInteraction && !small"
        :to="ctaRoute"
        class="card-event__register"
      >
        <VACButton
          v-if="!small && eventStatus !== 'close'"
          v-bind="ctaAttributes"
          :label="$t(`events.cta.${eventStatus}`)"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'card-event';
import { VACButton } from '@webqam-vac-ui/lib';
import { formatDate } from '@/utils/DateUtils';
import { stripHtml } from '@/utils/String';
import RouteName from '@/utils/RouteName';
import EventBadge from '@/components/badge/EventBadge';
export default {
  name: 'CardEvent',
  components: { EventBadge, VACButton },
  props: {
    event: { type: Object, default: undefined },
    small: { type: Boolean },
    noInteraction: { type: Boolean },
    isBooked: { type: Boolean },
    noInfos: { type: Boolean },
  },
  data() {
    return { RouteName };
  },
  computed: {
    eventStatus() {
      if (this.isBooked) {
        return 'booked';
      }
      return this.event.status;
    },
    eventLocation() {
      let location = '';
      if (this.event.city && this.event.city !== 'null') {
        location += this.event.city;
      }
      if (this.event.country && this.event.country !== 'null') {
        if (location) {
          location += ', ';
        }
        location += this.event.country;
      }
      if (location === '') {
        return undefined;
      }
      return location;
    },
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--with-interaction`]: !this.noInteraction,
          [`${BLOCK_SELECTOR}--small`]: this.small,
          [`${BLOCK_SELECTOR}--no-infos`]: this.noInfos,
          [`${BLOCK_SELECTOR}--closed`]: this.eventStatus === 'close',
        },
      ];
    },
    eventDate() {
      let dateString = '';
      dateString = formatDate(this.event.date[0].value);
      if (this.event.date[0].end_value) {
        dateString +=
          ' ' +
          this.$t('events.to') +
          ' ' +
          formatDate(this.event.date[0].end_value);
      }
      return dateString;
    },
    formattedDescription() {
      return stripHtml(this.event.description);
    },
    ctaAttributes() {
      return {
        'full-width': true,
        appearance:
          this.eventStatus === 'open' || this.eventStatus === 'booked'
            ? 'primary'
            : 'secondary',
      };
    },
    ctaRoute() {
      if (this.eventStatus === 'booked') {
        return {
          name: RouteName.REGISTER_EVENT,
          params: {
            eventId: this.event.ID,
          },
        };
      } else {
        return {
          name: RouteName.SINGLE_EVENT,
          params: {
            eventId: this.event.ID,
          },
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-event {
  $block-selector: &;
  @extend %base-card;
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  &--with-interaction {
    &:hover {
      #{$block-selector}__image {
        transform: scale(1.2);
      }
    }
  }

  &--closed {
    #{$block-selector}__image {
      filter: grayscale(1);
    }
  }

  &__link {
    @extend %block-abs-tlbr-zero;
    z-index: 2;
  }

  &__badge {
    position: absolute;
    right: -2em;
    top: 1em;
    z-index: 1;
  }

  &__image {
    transition: transform $default-transition-duration;
    width: 100%;
    height: 100%;
  }

  &__image-container {
    overflow: hidden;
  }

  &__infos {
    padding: 0.625em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__info {
    display: block;
    color: $color-mine-shaft;
    text-align: left;
    margin-bottom: 0;

    &--date {
      font-size: 0.75em;
      font-weight: 300;
    }

    &--name {
      color: $color-black;
      font-weight: bold;
      font-size: 1.25em;
      text-transform: uppercase;
      display: block;
      line-height: 1.2em;
      font-family: $font-family-prelo-condensed;
    }

    &--location {
      font-weight: 300;
      font-size: 0.938em;
      margin-top: 1em;
    }

    &--description {
      @include line-clamp(3);
    }
  }

  &__register {
    margin-top: 1em;
    z-index: 3;
  }
}
</style>
