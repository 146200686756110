<template>
  <v-form ref="form" class="password-account" @submit.prevent="submitPassword">
    <div class="password-account__form-container">
      <div class="password-account__fields-container">
        <div v-if="!resetPassword" :class="rowClassList">
          <VACInput
            v-model="form.currentPassword"
            name="current_password"
            :type="reveals.currentPassword ? 'text' : 'password'"
            :label="$t('account.form.currentPassword.label')"
            :rules="rules.currentPassword"
            required
          >
            <template #append>
              <VACButton
                appearance="discrete"
                class="password-account__toggle"
                icon=""
                @click.prevent="
                  reveals.currentPassword = !reveals.currentPassword
                "
              >
                <VACIcon
                  :name="reveals.currentPassword ? 'eye-stroked' : 'eye'"
                />
              </VACButton>
            </template>
          </VACInput>
        </div>
        <div :class="rowClassList">
          <VACInput
            v-model="form.newPassword"
            name="new_password"
            :type="reveals.newPassword ? 'text' : 'password'"
            :label="$t('account.form.newPassword.label')"
            :rules="rules.newPassword"
            required
          >
            <template #append>
              <VACButton
                appearance="discrete"
                class="password-account__toggle"
                icon=""
                @click.prevent="reveals.newPassword = !reveals.newPassword"
              >
                <VACIcon :name="reveals.newPassword ? 'eye-stroked' : 'eye'" />
              </VACButton>
            </template>
          </VACInput>
        </div>
        <div :class="rowClassList">
          <VACInput
            v-model="form.confirmPassword"
            name="confirm_password"
            :type="reveals.confirmPassword ? 'text' : 'password'"
            :label="$t('account.form.confirmPassword.label')"
            :rules="rules.confirmPassword"
            required
          >
            <template #append>
              <VACButton
                appearance="discrete"
                class="password-account__toggle"
                icon=""
                @click.prevent="
                  reveals.confirmPassword = !reveals.confirmPassword
                "
              >
                <VACIcon
                  :name="reveals.confirmPassword ? 'eye-stroked' : 'eye'"
                />
              </VACButton>
            </template>
          </VACInput>
        </div>
      </div>

      <PasswordComplexityValidator
        :min-char-number="8"
        :suggestion="form.newPassword"
        at-least-mix-case
        at-least-one-digit
        class="password-account__validator"
        no-twice-same-consecutive-char
      />
      <template v-if="submitted">
        <ErrorAlert v-if="errorMessage" @close="submitted = false">{{
          errorMessage
        }}</ErrorAlert>
        <SuccessAlert v-else @close="submitted = false">{{
          $t('account.passwordInformations.success')
        }}</SuccessAlert>
      </template>
    </div>
    <VACButton
      class="password-account__submit"
      appearance="primary"
      :label="$t('account.passwordInformations.submit')"
    />
  </v-form>
</template>

<script>
import { VACInput, VACButton, VACIcon } from '@webqam-vac-ui/lib';
import PasswordComplexityValidator from '@/components/form/password/PasswordComplexityValidator';
import ErrorAlert from '@/components/alert/ErrorAlert';
import SuccessAlert from '@/components/alert/SuccessAlert';
import RouteName from '@/utils/RouteName';
import { passwordRules } from '@/utils/password';
import AccountAPIWrapper from '@/api/AccountAPIWrapper';
import CommonAccountAPI from '@/api/CommonAccountAPI';

const BLOCK_SELECTOR = 'password-account';

export default {
  name: 'PasswordAccount',
  components: {
    SuccessAlert,
    ErrorAlert,
    PasswordComplexityValidator,
    VACInput,
    VACButton,
    VACIcon,
  },
  props: {
    resetPassword: Boolean,
    handleSubmit: { type: [Function, null], default: null },
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorMessage: null,
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      rules: {
        currentPassword: [(v) => !!v || this.$t('form.rules.required')],
        newPassword: [
          (v) => !!v || this.$t('form.rules.required'),
          ...passwordRules,
        ],
        confirmPassword: [
          (v) => !!v || this.$t('form.rules.required'),
          (v) => v === this.form.newPassword || this.$t('form.rules.confirmed'),
        ],
      },
      reveals: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
      },
      minLength: 8,
    };
  },
  computed: {
    rowClassList() {
      const blockSelector = `${BLOCK_SELECTOR}__row`;

      return [
        blockSelector,
        {
          [`${blockSelector}--reset`]: this.resetPassword,
        },
      ];
    },
  },
  methods: {
    submitPassword() {
      if (this.$refs.form.validate()) {
        this.submitted = false;
        this.$store.dispatch('loader/enableLoader');

        let promise = null;

        if (this.handleSubmit !== null) {
          promise = this.handleSubmit(this.form.newPassword);
        } else {
          if (this.resetPassword) {
            const { token } = this.$route.query;

            promise = CommonAccountAPI.resetPasswordWithEmail({
              encryptedToken: token,
              password: this.form.newPassword,
            })
              .then(() => {
                this.errorMessage = null;
                this.$router.push({ name: RouteName.PREHOME }).then(() => {
                  this.flash(this.$t('reset-password.success'), 'success');
                });
              })
              .catch((err) => {
                this.submitted = true;
                this.errorMessage = err.response.data.message;
              });
          } else {
            promise = AccountAPIWrapper.updatePassword(
              this.form.newPassword,
              this.form.currentPassword
            )
              .then(() => {
                this.submitted = true;
                this.errorMessage = null;
              })
              .catch((err) => {
                this.submitted = true;
                this.errorMessage = err.response.data.message;
              });
          }
        }

        promise
          .then(() => {
            if (!this.resetPassword) {
              this.$refs.form.reset();
            }
          })
          .finally(() => {
            this.$store.dispatch('loader/disableLoader');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password-account {
  margin-top: 1em;

  &__row {
    width: 100%;

    &--reset {
      margin-bottom: 0.75em;
    }
  }

  &__input {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &__validator {
    font-size: 1em;
    font-family: $font-family-prelo-condensed;
  }

  &__submit {
    margin-top: 1.8125em;
    margin-left: auto;
    display: flex;
    margin-right: auto;
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  &__fields-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding-bottom: 2rem;
  }

  &__toggle {
    padding: 0;
    height: 1.5rem;
    width: 1.5rem;
    min-width: unset;
    &::v-deep {
      .vac-icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}
</style>
