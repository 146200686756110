import CommonAccountAPI from '@/api/CommonAccountAPI';
import ContentAPI from '@/api/ContentAPI';
import { Module, createNamespacedHelpers } from 'vuex';

const NAMESPACE = 'profile';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

interface ProfileState {
  pseudo: string | undefined;
  notifications: any;
  notificationsDisplayHelper: any;
  shareSocial: boolean | undefined;
}

const module: Module<ProfileState, any> = {
  namespaced: true,
  state: {
    pseudo: undefined,
    notifications: undefined,
    notificationsDisplayHelper: undefined,
    shareSocial: undefined,
  },
  mutations: {
    setPseudo(state: ProfileState, pseudo: string): void {
      state.pseudo = pseudo;
    },
    setNotificationPreferences(state: ProfileState, preferences: any): void {
      state.notifications = preferences;
    },
    setNotificationDisplayHelper(
      state: ProfileState,
      displayHelper: any
    ): void {
      state.notificationsDisplayHelper = displayHelper;
    },
    setShareSocial(state: ProfileState, share: boolean): void {
      state.shareSocial = share;
    },
  },
  getters: {},
  actions: {
    fetchProfile: async (
      { commit, state },
      { profileId, force = false }
    ): Promise<void> => {
      if (!state.pseudo || force) {
        const { data } = await ContentAPI.getProfile(profileId);
        commit('setPseudo', data.pseudo);
        commit('setNotificationPreferences', data.notifications);
        commit(
          'setNotificationDisplayHelper',
          data.display_helper.notifications_groups
        );
        commit('setShareSocial', data.share_social);
      }
    },
    saveProfile: async ({ state, dispatch }, payload): Promise<void> => {
      const profileData = { ...state, ...payload };
      await CommonAccountAPI.editProfile(
        profileData.pseudo,
        profileData.shareSocial,
        profileData.notifications
      );
      await dispatch('fetchProfile', { profileId: undefined, force: true });
    },
  },
};

export default module;
