<template>
  <footer class="base-footer">
    <div class="base-footer__container">
      <div class="base-footer__section base-footer__section--main">
        <BaseLogo class="base-footer__logo" hasBackground />
        <LanguageSelector class="base-footer__language-selector" />
      </div>
      <div v-if="isMember" class="base-footer__mega-menu">
        <div
          v-for="(navCategory, index) of footerNav"
          :key="`category-${index}`"
          class="base-footer__category"
        >
          <h3 class="base-footer__category-title">
            {{ navCategory.category }}
          </h3>
          <ul class="base-footer__nav">
            <li
              v-for="(navItem, index) in navCategory.links"
              :key="index"
              class="base-footer__nav-item"
            >
              <VACButton
                :to="navItem.to"
                :label="navItem.label"
                icon=""
                class="base-footer__nav-item-link"
                appearance="discrete"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="base-footer__section base-footer__section--bottom">
        <div class="base-footer__nav-section">
          <ul class="base-footer__network-list">
            <li
              v-for="(link, index) in socialLinks"
              :key="index"
              class="base-footer__network-item"
            >
              <VACButton
                :data-tracking-network="link.title"
                :href="link.url"
                class="base-footer__network-item-link rcms_socialFooter"
                appearance="discrete"
                target="_blank"
                icon=""
              >
                <img :src="link.icon" class="base-footer__network-item-img" />
              </VACButton>
            </li>
          </ul>
          <div class="base-footer__legals">
            <VACButton
              appearance="link-discrete"
              v-for="(navItem, index) of footerAdditionalNav"
              :key="`add-nav-${index}`"
              :href="navItem.to"
              icon=""
              class="base-link"
              >{{ navItem.label }}</VACButton
            >
          </div>
        </div>
        <div class="base-footer__add-section">
          <div class="base-footer__wechat">
            <img src="../assets/qrcode.png" class="base-footer__qrcode" />
            <div class="base-footer__wechat-texts">
              <p class="base-footer__wechat-text">
                {{ $t('footer.wechat.label') }}
              </p>
              <p class="base-footer__wechat-text">
                {{ $t('footer.wechat.id') }}
              </p>
            </div>
          </div>
          <div class="base-footer__credits-accessibility">
            <p class="base-footer__credits">{{ $t('footer.credits') }}</p>
            <VACButton
              class="base-footer__accessibility-btn"
              appearance="discrete"
              icon=""
              :href="$t('footer.footerNav.accessibility')"
            >
              <VACIcon
                class="base-footer__accessibility-icon"
                name="accessibility"
              />
            </VACButton>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';
import { VACButton, VACIcon } from '@webqam-vac-ui/lib';
import BaseLogo from '@/components/BaseLogo';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import RouteName from '@/utils/RouteName';
import { generateLocalizedLink } from '@/utils/Lang';

export default {
  name: 'BaseFooter',
  components: { VACButton, BaseLogo, LanguageSelector, VACIcon },
  data() {
    return {
      socialLinks: undefined,
    };
  },
  computed: {
    isMember() {
      return this.$store.state.auth && this.$store.state.auth.currentSession;
    },
    /**
     * @return {Array}
     */
    footerNav() {
      const nav = [
        {
          category: this.$t('footer.footerNav.magazine.title'),
          links: [
            {
              label: this.$t('footer.footerNav.magazine.corporate'),
              to: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 1 },
              }).href,
            },
            {
              label: this.$t('footer.footerNav.magazine.experience'),
              to: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 2 },
              }).href,
            },
            {
              label: this.$t('footer.footerNav.magazine.testimony'),
              to: this.$router.resolve({
                name: RouteName.MAGAZINE,
                params: { preFilter: 3 },
              }).href,
            },
          ],
        },
        {
          category: this.$t('footer.footerNav.events.title'),
          links: [
            {
              label: this.$t('footer.footerNav.events.upcoming'),
              to: `${
                this.$router.resolve({ name: RouteName.EVENTS }).href
              }#upcoming`,
            },
            {
              label: this.$t('footer.footerNav.events.myEvents'),
              to: this.$router.resolve({
                name: RouteName.EVENTS,
                params: { preFilter: 'my_events' },
              }).href,
            },
          ],
        },
        {
          category: this.$t('footer.footerNav.account.title'),
          links: [
            {
              label: this.$t('footer.footerNav.account.myVacheron'),
              to: this.$router.resolve({
                name: RouteName.ACCOUNT_DETAILS,
              }).href,
            },
            {
              label: this.$t('footer.footerNav.account.public'),
              to: this.$router.resolve({
                name: RouteName.ACCOUNT_DETAILS,
              }).href,
            },
            {
              label: this.$t('footer.footerNav.account.personal'),
              to: this.$router.resolve({
                name: RouteName.ACCOUNT_DETAILS,
              }).href,
            },
          ],
        },
        {
          category: this.$t('footer.footerNav.about.title'),
          links: [
            {
              label: this.$t('footer.footerNav.about.contact'),
              to: this.$router.resolve({
                name: RouteName.CONTACT,
              }).href,
            },
            {
              label: this.$t('footer.footerNav.about.find'),
              to: this.$t('footer.store_link'),
              target: '_blank',
            },
          ],
        },
      ];

      if (this.isMember) {
        nav[3].links.push({
          label: this.$t('footer.footerNav.faq'),
          to: { name: RouteName.FAQ },
        });
      }

      return nav;
    },
    footerAdditionalNav() {
      return [
        {
          label: this.$t('footer.footerNav.legal'),
          to: generateLocalizedLink(
            'https://www.vacheron-constantin.{domain}/{realm}/{lang}/legal/privacy-policy.html'
          ),
          target: '_blank',
        },
        {
          label: this.$t('footer.footerNav.cookieConsent'),
          to: generateLocalizedLink(
            'https://www.vacheron-constantin.{domain}/{realm}/{lang}/legal/privacy-policy.html'
          ),
          target: '_blank',
        },
      ];
    },
  },
  methods: {
    getNavItemProps(navItem) {
      const props = {
        title: navItem.title,
        class: 'rcms_linkFooter',
        'data-tracking-footer': navItem.label,
      };

      if (navItem.to) {
        props.to = navItem.to;
      } else {
        props.href = navItem.href;
        props.target = navItem.target;
      }

      return props;
    },
  },
  mounted() {
    ContentAPI.getInitData().then(({ data }) => {
      if (data) {
        this.socialLinks = data.social_links;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.base-footer {
  $base-footer-breakpoint: $breakpoint-tablet;
  $base-footer-padding: 2em;

  padding: $base-footer-padding 1em;
  background-position: center;

  @include breakpoint($breakpoint-tablet) {
    padding: $base-footer-padding;
  }

  &__container {
    @extend %base-container;

    position: relative;
  }

  &__section {
    @include breakpoint($breakpoint-tablet) {
      justify-content: flex-end;
    }

    @include breakpoint($breakpoint-desktop) {
      justify-content: center;
    }

    &--main {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @include breakpoint($base-footer-breakpoint) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    & + & {
      &::before {
        content: '';
        display: block;
        height: 1px;
        background-color: $color-bombay;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
      }
    }
  }

  &__logo {
    margin-bottom: $base-footer-padding;
    display: flex;

    @include breakpoint($base-footer-breakpoint) {
      margin-bottom: 0;
    }
  }

  &__select {
    margin-bottom: $base-footer-padding;

    @include breakpoint($base-footer-breakpoint) {
      margin-bottom: 0;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    font-family: $font-family-prelo-condensed;
    font-size: 1.125em;
    gap: 0.5rem;
    @include breakpoint($breakpoint-l) {
      justify-content: center;
      gap: 1rem;
    }
  }

  &__nav-item {
    &--language-selector {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__language-selector {
    align-self: flex-start;
  }

  &__nav-item-link {
    text-decoration: none;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.5;
    font-size: 0.875rem;
    height: auto;
    text-transform: initial;
    @include breakpoint($breakpoint-l) {
      align-items: center;
      font-size: 1rem;
    }
  }

  &__networks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint($base-footer-breakpoint) {
      max-width: 40em;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__network-separator {
    flex-basis: 100%;
    margin-top: 1.25em;

    @include breakpoint($base-footer-breakpoint) {
      display: none;
    }
  }

  &__network-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    font-family: $font-family-prelo-condensed;
    @include breakpoint($breakpoint-l) {
      justify-content: flex-start;
    }
  }

  &__network-item-link {
    display: flex;
    justify-content: flex-start;
  }

  &__network-item-img {
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  &__mega-menu {
    display: grid;
    padding: 2rem 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @include breakpoint($breakpoint-l) {
      grid-template-columns: repeat(4, 1fr);
      padding: 3.5rem 0;
    }
  }

  &__category-title {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    color: $color-black;
    margin-bottom: 0.75rem;
    @include breakpoint($breakpoint-l) {
      margin-bottom: 1.5rem;
    }
  }

  &__nav-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include breakpoint($breakpoint-l) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__add-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include breakpoint($breakpoint-l) {
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__wechat {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__wechat-text {
    font-size: 0.75rem;
    margin: 0;
    flex-shrink: 0;
  }

  &__credits-accessibility {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;

    @include breakpoint($breakpoint-l) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__credits {
    margin-bottom: 0;
  }

  &__accessibility-btn {
    padding: 0;
    width: fit-content;
    aspect-ratio: 1/1;
    &::v-deep {
      .vac-icon {
        width: 8rem;
        height: 4rem;
        display: flex;
        justify-content: flex-end;
        fill: $color-mine-shaft;
      }
    }
  }
}
</style>
