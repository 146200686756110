import { mapState, mapGetters, mapActions } from '@/store/modules/onboarding';
export default {
  computed: {
    ...mapState({
      onboardingCurrentStep: 'currentStep',
      onboardingData: 'data',
      draft: 'draft',
    }),
    ...mapGetters([
      'isOnboardingCompleted',
      'isOnboardingNecessary',
      'onboardingQuestionCount',
      'onboardingTime',
      'onboardingCurrentQuestion',
      'onboardingCurrentQuestionIndex',
    ]),
  },
  methods: {
    ...mapActions({
      fetchOnboardingData: 'fetchData',
      setOnboardingCurrentStep: 'setOnboardingStep',
      onboardingNextStep: 'nextStep',
      onboardingPreviousStep: 'previousStep',
    }),
  },
  mounted() {
    this.fetchOnboardingData();
  },
};
