enum PortalName {
  APP_FOOTER = 'portal-container--app-footer',
  MODAL = 'portal-container--modal',
  SERVICES = 'portal-container--services',
  LANG_MODAL = 'portal-container--lang-modal',
  LANG_MODAL_SECOND = 'portal-container--lang-modal-second',
  STEPPER_FOOTER = 'portal-container-stepper-footer',
  MAIN_TITLE = 'portal-container-main-title',
  STICKY_HEADER = 'portal-container-sticky-header',
  MODAL_ACTIONS = 'portal-container--modal-actions',
}

export default PortalName;
