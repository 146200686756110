<template>
  <div v-if="gridLength > 0" class="social-networks">
    <div class="social-networks__container">
      <BaseTitle class="social-networks__title" :level="4">
        {{ $t('socialNetworks.title') }}
      </BaseTitle>

      <div class="social-networks__description">
        {{ $t('socialNetworks.description') }}
      </div>
      <div :class="gridClasses">
        <div
          v-for="(item, index) of socialNetworks"
          :key="`social-network-${index}`"
          class="social-networks__item"
        >
          <img
            :src="item.image"
            :alt="item.title"
            class="social-networks__image"
          />
          <a :href="item.url" class="base-link social-networks__link">{{
            item.title
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from '@/components/BaseTitle';
const BASE_CLASS = 'social-networks';
export default {
  name: 'SocialNetworks',
  components: { BaseTitle },
  props: {
    socialNetworks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    gridLength() {
      return this.socialNetworks.length;
    },
    gridClasses() {
      return [
        `${BASE_CLASS}__grid`,
        `social-networks__grid--${this.gridLength}`,
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.social-networks {
  $block-selector: &;
  padding: 2rem 0;
  @include breakpoint($breakpoint-l) {
    padding: 5rem 0;
  }
  &__container {
    @extend %base-container;
  }

  &__title {
    text-align: center;
    font-family: $font-family-prelo-condensed;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  &__description {
    margin: 0 auto;
    text-align: center;
    max-width: 36.375rem;
    padding: 0 1rem;
    margin-bottom: 2rem;
  }
  &__grid {
    display: grid;
    gap: 0.5rem 2rem;
    grid-template-columns: repeat(2, 1fr);
    @include breakpoint($breakpoint-l) {
      gap: 2rem 4rem;
      grid-template-columns: repeat(4, 1fr);
    }
    &--5 {
      @include breakpoint($breakpoint-l) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
    &--7 {
      grid-template-rows: repeat(4, 1fr);
      @include breakpoint($breakpoint-l) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    place-self: stretch;
    position: relative;
    &:nth-of-type(1) {
      grid-column: span 2;
      grid-row: 1;
      @include breakpoint($breakpoint-l) {
        grid-row: span 2;
        justify-content: stretch;
      }
    }
  }

  &__image {
    object-fit: cover;
    aspect-ratio: 1 / 1;
    @include breakpoint($breakpoint-l) {
      flex-grow: 1;
    }
    #{$block-selector}__item:nth-of-type(1) & {
      aspect-ratio: 2 / 1;
    }
  }

  &__link {
    height: 1.75rem;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-indx: 2;
    }
  }
}
</style>
