<template>
  <CardPlaceholder height="28em">
    <template #rows>
      <CardPlaceholderRow height="1.125em" width="40%" />
      <CardPlaceholderRow height="1.125em" />
      <CardPlaceholderRow height="1.125em" />
      <CardPlaceholderRow height="0.9375em" />
      <CardPlaceholderRow height="0.9375em" />
      <CardPlaceholderRow height="0.9375em" width="80%" />
      <!-- eslint-disable vue/no-static-inline-styles -->
      <CardPlaceholderRow
        height="0.75em"
        width="30%"
        style="margin-top: 1.3em"
      />
      <CardPlaceholderRow height="2.875em" style="margin-top: 1.3em" />
      <!-- eslint-enable vue/no-static-inline-styles -->
    </template>

    <CardEvent
      v-if="event.ID"
      class="event-grid__card"
      :event="event"
      :is-booked="isBooked"
    />
  </CardPlaceholder>
</template>

<script>
import CardEvent from '@/components/card/CardEvent';
import CardPlaceholder from '@/components/card/CardPlaceholder';
import CardPlaceholderRow from '@/components/card/CardPlaceholderRow';

export default {
  name: 'EventCard',
  components: {
    CardEvent,
    CardPlaceholder,
    CardPlaceholderRow,
  },
  props: {
    event: {
      type: Object,
      default: undefined,
    },
    isBooked: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
