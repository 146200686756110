<template>
  <div class="event-store">
    <p class="event-store__name">{{ event.addressTitle }}</p>
    <!--eslint-disable-next-line vue/no-v-html-->
    <p>{{ event.addressText }}</p>
    <!--eslint-disable-next-line vue/no-v-html-->
    <p class="event-store__location">
      <a v-if="event.address" :href="mapsLink" target="_blank">
        {{ event.address.address_line1 }}<br />
        <template v-if="event.address.address_line2">
          {{ event.address.address_line2 }}<br />
        </template>
        <template v-if="event.address.postal_code">
          {{ `${event.address.postal_code}, ${event.address.locality}` }}
        </template>
        <br />
      </a>
      <a :href="`tel:${event.prefixPhone}${event.phone}`">{{
        `${event.prefixPhone}${event.phone}`
      }}</a
      ><br />
      <a :href="`mailto:${event.email}`">{{ event.email }}</a
      ><br />
    </p>
  </div>
</template>

<script>
export default {
  name: 'EventStore',
  props: {
    event: { type: Object, default: undefined },
  },
  computed: {
    mapsLink() {
      let link = 'https://google.fr/maps/?q=';
      if (this.event.address) {
        if (this.event.address.address_line1) {
          link += this.event.address.address_line1;
        }
        if (this.event.address.address_line2) {
          link += ' ' + this.event.address.address_line2;
        }
        if (this.event.address.postal_code) {
          link += ` ${this.event.address.postal_code}`;
        }
        if (this.event.address.locality) {
          link += ` ${this.event.address.locality}`;
        }
      }
      return link;
    },
  },
};
</script>

<style scoped lang="scss">
.event-store {
  position: relative;
  font-family: $font-family-prelo-condensed;
  background-color: $color-wild-sand;
  padding: 2em;
  margin-bottom: 3em;

  &::after {
    @include breakpoint($breakpoint-desktop) {
      content: '';
      position: absolute;
      top: 0;
      left: 100%;
      bottom: 0;
      width: 100vw;
      background-color: $color-wild-sand;

      .dir-rtl & {
        left: unset;
        right: 100%;
      }
    }
  }

  @include breakpoint($breakpoint-desktop) {
    padding: 2em 0 2em 2em;
  }

  &__name {
    font-weight: bold;
    margin-bottom: 0;
  }

  &__location {
    text-decoration: underline;
    margin-bottom: 0;
  }
}
</style>
