<template>
  <div class="vag-main">
    <BaseBtn class="vag-main__back" link @click="handleBackClick">
      <span class="vag-main__back-icon icon icon--back-arrow"></span>
    </BaseBtn>
    <VAGCamera
      v-if="!hasRecordedData"
      @video-recorded="handleVideoRecorded($event)"
      @photo-taken="handlePhotoTaken"
      @thumbnail-taken="handleThumbnail"
    />
    <template v-else>
      <video
        v-if="videoData"
        class="vag-main__media main__media--video"
        loop="true"
        autoplay="true"
        :src="videoLink"
      ></video>
      <img v-if="imageData" :src="imageData" class="vag-main__media" alt="" />
    </template>
  </div>
</template>
<script>
import VAGCamera from './VAGCamera.vue';
import BaseBtn from '@/components/BaseBtn';
import RouteName from '@/utils/RouteName';
export default {
  name: 'VAGMain',
  components: { VAGCamera, BaseBtn },
  data() {
    return {
      videoData: undefined,
      imageData: undefined,
      thumbnail: undefined,
    };
  },
  computed: {
    hasRecordedData() {
      return this.videoData
        ? { video: this.videoData, thumbnail: this.thumbnail }
        : this.imageData;
    },
    videoLink() {
      return this.videoData ? URL.createObjectURL(this.videoData) : undefined;
    },
  },
  methods: {
    clearRecord() {
      this.videoData = undefined;
      this.imageData = undefined;
      this.$emit('clear', this.hasRecordedData);
    },
    handleVideoRecorded(payload) {
      this.videoData = payload.video;
      this.$emit('recorded', { data: this.hasRecordedData, isVideo: true });
    },
    handlePhotoTaken(dataUri) {
      this.imageData = dataUri;
      this.$emit('recorded', { data: this.hasRecordedData, isVideo: false });
    },
    handleThumbnail(dataUri) {
      this.thumbnail = dataUri;
    },
    handleBackClick() {
      this.hasRecordedData
        ? this.clearRecord()
        : this.$router.push({ name: RouteName.GOLDEN_BOOK });
    },
  },
};
</script>
<style scoped lang="scss">
.vag-main {
  position: fixed;
  inset: 0;
  background-color: black;

  &__media {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    &--video {
      object-fit: cover;
    }
  }

  &__close {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 2;
  }

  &__type {
    position: absolute;
    top: 2em;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    padding: 1em;
    background-color: black;
  }

  &__back {
    position: absolute;
    top: 0.5rem;
    left: 0.35rem;
    z-index: 2;
  }

  &__back-icon {
    color: $color-wild-sand;
    font-size: 1em;
  }
}
</style>
