import i18n from '@/i18n';

const MIN_CHAR_NUMBER = 8;
const AUTHORIZED_CHARACTERS = '~!@#$%&_\\-+=`';

export const passwordRules = [
  (v) => (v && v.length >= MIN_CHAR_NUMBER) || i18n.t('errors.Customer_8'), // 8 characters minimum
  (v) => /.*[0-9]/.test(v) || i18n.t('errors.Customer_9'), // At least one digit
  (v) =>
    (/.*[A-Z]/.test(v) && /.*[a-z]/.test(v)) || i18n.t('errors.Customer_9'), // At least one uppercase and one lowercase character
  (v) => !/([a-zA-Z0-9])\1\1/.test(v) || i18n.t('errors.Customer_9'),
  (v) => {
    const nonAuthorizedCharacters: Array<string> = [];
    [...v].forEach((item) => {
      if (
        AUTHORIZED_CHARACTERS.indexOf(item) < 0 &&
        !/[A-Za-z0-9]/.test(item)
      ) {
        nonAuthorizedCharacters.push(item);
      }
    });
    if (nonAuthorizedCharacters.length > 0) {
      return i18n.t('registration-form.password.spe-char-non-authorized', {
        character: nonAuthorizedCharacters[0],
      });
    }
    return true;
  },
];

/*

 */
