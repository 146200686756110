<template>
  <div class="golden-book-vag-media">
    <FieldFile
      v-if="!registeredFile"
      class="golden-book-vag-media__file"
      ref="fileField"
      :accept="accept"
      show-drop-zone
      is-gallery
      @change="setUploadedFile"
      @error="uploadError = true"
    >
      <template #buttonLabel
        ><span
          class="golden-book-vag-media__gallery-icon icon icon--gallery"
        ></span
      ></template>

      <template #separatorLabel>{{
        $t('goldenBook.new.fields.file.separator')
      }}</template>

      <template #dropLabel>
        <div>
          <span
            v-for="(label, index) in $t('goldenBook.new.fields.file.drop')"
            :key="index"
            :class="`golden-book-form__drop-label--${index}`"
            class="golden-book-form__drop-label"
            >{{ label }}
          </span>
        </div>
      </template>
    </FieldFile>

    <ErrorAlert v-if="uploadError" @close="uploadError = false">
      {{ $t('goldenBook.new.fields.file.drop.2') }}
    </ErrorAlert>
    <VAGMain @recorded="handleRecorded($event)" @clear="handleClear" />
    <VACButton
      v-if="registeredFile"
      appearance="primary"
      :label="$t('goldenBook.continue')"
      icon="arrow-right"
      @click="handleContinue"
      class="golden-book-vag-media__continue-btn"
    />
    <GoldenBookCropModal
      v-if="modal"
      :file="uploadedFile"
      @close="closeCropModal"
      @crop="setCroppedFile"
    />
  </div>
</template>
<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { VACButton } from '@webqam-vac-ui/lib';
import VAGMain from '@/components/vag/VAGMain';
import FieldFile from '@/components/form/FieldFile';
import GoldenBookCropModal from '@/components/golden-book/GoldenBookCropModal';
import RouteName from '@/utils/RouteName';
import { randomStr } from '@/utils/String';

export default {
  name: 'GoldenBookVagMedia',
  components: {
    VAGMain,
    FieldFile,
    GoldenBookCropModal,
    VACButton,
  },
  data() {
    return {
      modal: false,
      uploadError: false,
      uploadedFile: null,
      croppedFile: null,
      accept: ['image/jpeg', 'image/png'],
      recordedFile: null,
    };
  },
  computed: {
    registeredFile() {
      return this.recordedFile ?? this.croppedFile;
    },
  },
  methods: {
    handleRecorded(payload) {
      if (payload.isVideo) {
        this.recordedFile = {
          name: randomStr(),
          thumbnail: payload.data.thumbnail,
          data: payload.data.video,
          isVideo: payload.isVideo,
        };
      } else {
        this.recordedFile = {
          name: randomStr(),
          data: payload.data,
          isVideo: payload.isVideo,
        };
      }
    },
    handleContinue() {
      this.$router.push({
        name: RouteName.GOLDEN_BOOK_SHARE_STORY,
        params: { media: this.registeredFile },
      });
    },
    handleClear() {
      this.recordedFile = null;
      this.croppedFile = null;
      this.uploadedFile = null;
    },
    setUploadedFile(files) {
      this.uploadedFile = files[0];
      this.modal = true;
      this.uploadError = false;

      disableBodyScroll(document.body);
    },
    closeCropModal() {
      this.$refs.fileField.reset();
      this.modal = false;

      enableBodyScroll(document.body);
    },
    setCroppedFile(file) {
      this.croppedFile = {
        name: this.uploadedFile.name,
        data: file,
      };
      this.uploadedFile = null;
      this.closeCropModal();
      this.handleRecorded();
    },
  },
};
</script>
<style lang="scss">
.golden-book-vag-media {
  position: fixed;
  inset: 0;
  z-index: 200;
  display: grid;
  grid-template-areas: 'box';
  padding: 1em 1em 1em 1.5em;

  &__file {
    z-index: 10;
    place-self: end start;
  }

  &__gallery-icon {
    font-size: 2.25rem;
    color: white;
  }

  &__continue-btn {
    place-self: end stretch;
  }
}
</style>
