<template>
  <header :class="classList">
    <transition name="fade">
      <PageLoader v-if="isLoading" />
    </transition>
    <div class="header-prehome__container">
      <div class="header-prehome__top">
        <RouterLink
          :to="{ name: RouteName.PREHOME }"
          class="header-prehome__logo"
        >
          <BaseLogo hide-tag-line-until-s />
        </RouterLink>
        <div class="header-prehome__body">
          <AccountButton is-guest />

          <AuthRegister v-slot="{ open }">
            <VACButton
              class="header-prehome__btn header-prehome__btn--register"
              appearance="primary"
              @click="open"
            >
              {{ $t('header.visitors.register') }}
            </VACButton>
          </AuthRegister>

          <LanguageSelector
            v-if="this.$matchMedia.xl"
            second-target
            class="header-prehome__language-selector"
          />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import BaseLogo from '@/components/BaseLogo';
import PageLoader from '@/components/PageLoader';
import AccountButton from '@/components/AccountButton';
import AuthRegister from '@/components/auth/AuthRegister';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import Internationalization from '@/utils/Internationalization';
import RouteName from '@/utils/RouteName';

const BLOCK_SELECTOR = 'header-prehome';

export default {
  name: 'HeaderPrehome',
  components: {
    AccountButton,
    AuthRegister,
    VACButton,
    BaseLogo,
    LanguageSelector,
    PageLoader,
  },
  props: {
    hideLogin: { type: Boolean },
  },
  data() {
    return {
      RouteName,
      isLoading: false,
    };
  },
  computed: {
    classList() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--sticky`]: this.isSticky,
        },
      ];
    },
    isSticky() {
      return this.$route.name !== RouteName.PREHOME;
    },
    isCNRealm() {
      return Internationalization.isCnCountry();
    },
  },
  methods: {
    startHydraFlow() {
      this.$router.push({ name: RouteName.ACCOUNT_ASK_LOGIN });
    },
  },
};
</script>

<style scoped lang="scss">
.header-prehome {
  $block-selector: &;
  $button-margin: 0.25em;

  background-color: $color-white;

  &--sticky {
    position: sticky;
    z-index: 5;
    top: 0;
  }

  // Override vuetify styles
  a {
    color: inherit;
  }

  &__container {
    @extend %base-container;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    color: $color-default;
    padding: 1em 0;
    padding-bottom: 2.5em;

    @include breakpoint($breakpoint-tablet) {
      padding-top: 1.625em;
      padding-left: 3.125em;
      padding-right: 3.125em;
      padding-bottom: 1.875em;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
  }
}
</style>
