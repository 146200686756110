<template>
  <div class="faq-question">
    <div class="faq-question__title">{{ question.title }}</div>
    <!--eslint-disable vue/no-v-html-->
    <div class="faq-question__answer" v-html="question.description"></div>
    <!--eslint-enable vue/no-v-html-->
    <div
      v-if="question.button && question.button.link"
      class="faq-question__footer"
    >
      <VACButton
        appearance="primary"
        full-width
        :label="question.button.label"
        class="faq-question__button"
        @click="questionClick"
      />
    </div>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import { ServicesMutations } from '@/store/modules/services';
export default {
  name: 'FaqQuestion',
  components: { VACButton },
  props: { question: { type: Object, required: true } },
  methods: {
    questionClick() {
      const { type, link } = this.question.button;

      if (link.includes('#services')) {
        this.$store.commit(ServicesMutations.toggleModal, true);
      } else {
        switch (type) {
          case 'url':
            window.open(link, '_blank');
            break;

          case 'internal':
            try {
              this.$router.push(new URL(link).pathname);
            } catch (e) {
              this.$router.push(link);
            }
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-question {
  color: $color-black;
  &__title {
    font-weight: bold;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  &__answer {
    font-size: 1rem;
    margin-bottom: 1.25em;
    font-weight: 200;

    ::v-deep {
      p {
        margin: 0;
      }
    }
  }

  &__footer {
    max-width: 16.375rem;
  }
}
</style>
