<template>
  <div class="card-placeholder" :style="styles">
    <div
      :class="{ 'card-placeholder__body--hidden': hideBody }"
      class="card-placeholder__body"
    >
      <div
        class="card-placeholder__background-wrapper"
        :class="{
          'card-placeholder__background-wrapper--animated': isAnimated,
        }"
      >
        <div class="card-placeholder__background"></div>
      </div>

      <div v-if="$slots.rows" class="card-placeholder__rows">
        <slot name="rows"></slot>
      </div>
    </div>

    <div class="card-placeholder__content">
      <transition
        name="fade"
        @before-enter="beforeEnter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
      >
        <slot></slot>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardPlaceholder',
  props: {
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      minHeight: this.height,
      hideBody: false,
      isLoaded: false,
      isAnimated: true,
    };
  },
  computed: {
    /**
     * @returns {Object}
     */
    styles() {
      const styles = {};

      if (this.minHeight) {
        styles.minHeight = this.minHeight;
      }

      if (this.height) {
        styles.height = this.height;
      }

      if (this.width) {
        styles.width = this.width;
      }

      return styles;
    },
  },
  methods: {
    beforeEnter() {
      this.isAnimated = false;
      this.isLoaded = false;
    },
    afterEnter() {
      this.isLoaded = true;

      /*this.minHeight = `${
        this.$el.querySelector('.card-placeholder__content').offsetHeight
      }px`;*/
    },
    beforeLeave() {
      this.isAnimated = true;
      this.minHeight = this.height;
    },
  },
};
</script>

<style scoped lang="scss">
.card-placeholder {
  $block-selector: &;

  position: relative;
  height: 100%;
  width: 100%;

  &__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    transition: opacity 250ms ease-in;

    &--hidden {
      opacity: 0;
    }
  }

  &__background-wrapper {
    $color-base: $color-wild-sand;

    position: relative;
    flex: 1;
    background-color: $color-base;

    &--animated {
      @include placeholder-animation($color-base);

      #{$block-selector} {
        &__background {
          z-index: 1;
        }
      }
    }
  }

  &__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('../../assets/placeholder.svg');
    background-position: center;
  }

  &__rows {
    background-color: $color-white;
    padding: 0.625em;

    .card-placeholder-row {
      + .card-placeholder-row {
        margin-top: 0.4em;
      }
    }
  }

  &__content {
    height: 100%;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
