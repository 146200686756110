import { render, staticRenderFns } from "./GoldenBookHeader.vue?vue&type=template&id=523afb25&scoped=true&"
import script from "./GoldenBookHeader.vue?vue&type=script&lang=js&"
export * from "./GoldenBookHeader.vue?vue&type=script&lang=js&"
import style0 from "./GoldenBookHeader.vue?vue&type=style&index=0&id=523afb25&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523afb25",
  null
  
)

export default component.exports