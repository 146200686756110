<template>
  <div class="account-login-callback">
    <div class="raccount-login-callback__container">
      <WizardStepper
        :steps="1"
        :start="1"
        :left-image="leftImage"
        :right-image="rightImage"
      >
        <template #step-1>
          <WizardStep :title="$t('auth.title')" no-step bold-title>
            <EmailAuthForm
              :force-error="showError ? $t('auth.error') : undefined"
            />
            <AuthFooter />
          </WizardStep>
        </template>
      </WizardStepper>
    </div>
  </div>
</template>

<script>
import leftImage from '@/assets/stepper/password-forgot-left.png';
import rightImage from '@/assets/stepper/password-forgot-right.png';
import WizardStepper from '@/components/wizard/WizardStepper';
import WizardStep from '@/components/wizard/WizardStep';
import EmailAuthForm from '@/components/form/EmailAuthForm';
import AuthFooter from '@/components/auth/AuthFooter';
import storage from '@/utils/storage';
import Internationalization from '@/utils/Internationalization';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountLoginCallback',
  components: {
    AuthFooter,
    EmailAuthForm,
    WizardStepper,
    WizardStep,
  },
  mixins: [Tracking],
  data() {
    return {
      leftImage,
      rightImage,
      showError: false,
    };
  },
  mounted() {
    if (Internationalization.isCnCountry()) {
      this.$router.push({ name: RouteName.PREHOME });
    }
    if (storage.get('SSO_ERROR')) {
      this.showError = true;
      storage.remove('SSO_ERROR');
    }
    setTimeout(() => {
      if (this.$route.name === RouteName.ACCOUNT_LOGIN_CALLBACK) {
        this.trackPageView('Login', { page: '/login/callback' });
      }
    }, 2000);
  },
};
</script>
