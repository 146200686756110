<template>
  <div class="conversation-grid">
    <div class="conversation-grid__header">
      <VACButton
        appearance="secondary"
        icon="plus"
        @click="filtersOpen = true"
        :label="$t('conversation.filters')"
      />
      <div class="conversation-grid__counters">
        {{ $tc('conversation.conversationCount', conversationCount) }}
        <span class="conversation-grid__counter-separator"></span>
        {{ $tc('conversation.replyCount', replyCount) }}
      </div>
      <Portal :to="PortalName.MODAL">
        <VACSideModal
          :title="$t('goldenBook.filters.modal.title')"
          :count="[
            $tc('conversation.conversationCount', conversationCount),
            $tc('conversation.replyCount', replyCount),
          ]"
          :is-open="filtersOpen"
          :cancelLabel="$t('goldenBook.filters.modal.cancel')"
          :okLabel="$t('goldenBook.filters.modal.done')"
          @close="filtersOpen = false"
          @apply="applyFilters"
        >
          <div class="conversation-grid__fields">
            <VACSelect
              v-model="filter"
              :options="filters"
              name="filters"
              :label="$t('conversation.filters')"
              class="conversation-grid__filter"
            />
            <VACSelect
              id="orderby"
              v-model="sort"
              name="sortBy"
              :options="sortOptions"
              :label="$t('conversation.sort')"
              class="conversation-grid__sorter"
            />
          </div>
        </VACSideModal>
      </Portal>
    </div>
    <div
      v-if="conversationCount > 0"
      class="conversation-grid__conversation-container"
    >
      <PageLoader v-if="isFetching" relative translucide />
      <div
        v-masonry="gridId"
        :gutter="32"
        class="conversation-grid__conversations"
        column-width=".conversation-grid__card"
        horizontal-order="true"
        item-selector=".conversation-grid__card"
        percent-position
        transition-duration="0.3s"
      >
        <div
          v-for="conversation in conversations"
          :key="conversation.ID"
          v-masonry-tile
          class="conversation-grid__card"
        >
          <ConversationsCard
            :conversation="conversation"
            @deleteReply="handleDeleteReply"
            @deleted="handleCardDeletion"
            @editReply="handleEditReply"
            @edited="updateEditedComment"
            @newReply="updateNewReply"
            @extended="requestMasonryRedraw"
          />
        </div>
      </div>
    </div>
    <Spinner
      v-if="isFull && currentPage < totalPages"
      @reached="fetchNodeConversations"
    />
    <RouterLink v-if="!isFull && conversationCount > 4" :to="singleRoute">
      <VACButton class="conversation-grid__read-all" appearance="primary">
        {{ $t('conversation.readAll') }}
      </VACButton>
    </RouterLink>
  </div>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';

import ConversationsCard from '@/components/conversation/ConversationsCard';
import PageLoader from '@/components/PageLoader';
import Spinner from '@/components/Spinner';
import PortalName from '@/utils/PortalName';
import { VACSideModal, VACButton, VACSelect } from '@webqam-vac-ui/lib';
import BusName from '@/utils/BusName';
import {
  ConversationFilters,
  ConversationSort,
} from '@/utils/ConversationConstants';
import RouteName from '@/utils/RouteName';
import NodeType from '@/utils/NodeType';
import MasonryConversationGrid from '@/mixins/MasonryConversationGrid';

export default {
  name: 'ConversationGrid',
  components: {
    Spinner,
    VACButton,
    VACSideModal,
    VACSelect,
    PageLoader,
    ConversationsCard,
  },
  mixins: [MasonryConversationGrid],
  props: {
    nodeId: { type: String, required: true },
    nodeType: { type: String, required: true },
    isFull: Boolean,
  },
  data() {
    return {
      PortalName,
      currentPage: 0,
      totalPages: 0,
      conversations: [],
      conversationCount: 0,
      replyCount: 0,
      containerId: 'gridconv',
      filter: ConversationFilters.ALL,
      sort: ConversationSort.DATE,
      isFetching: false,
      filtersOpen: false,
      RouteName,
    };
  },
  computed: {
    totalCount() {
      return this.replyCount + this.conversationCount;
    },
    filters() {
      const filters = [];
      for (const item in ConversationFilters) {
        filters.push({
          value: ConversationFilters[item],
          label: this.$t(
            `conversation.filtersLabel.${ConversationFilters[item]}`
          ),
        });
      }
      return filters;
    },
    sortOptions() {
      const filters = [];
      for (const item in ConversationSort) {
        filters.push({
          value: ConversationSort[item],
          label: this.$t(`conversation.sortLabel.${ConversationSort[item]}`),
        });
      }
      return filters;
    },
    itemsPerPage() {
      return this.isFull ? 16 : 4;
    },
    singleRoute() {
      if (this.nodeType === NodeType.STORY) {
        return {
          name: RouteName.GOLDEN_BOOK_CONVERSATION,
          params: { nodeId: this.nodeId },
        };
      }

      if (this.nodeType === NodeType.ARTICLE) {
        return {
          name: RouteName.ARTICLE_CONVERSATION,
          params: { nodeId: this.nodeId },
        };
      }
      return undefined;
    },
  },
  async mounted() {
    if (this.$route.query.onlyMe) {
      this.filter = ConversationFilters.ONLY_ME;
    }
    this.fetchNodeConversations(true);
    this.$root.$on(BusName.NEW_CONVERSATION, this.pushConversation);
  },
  methods: {
    pushConversation(conversation) {
      this.conversations.unshift(conversation);
      this.requestMasonryRedraw();
      this.conversationCount++;
      this.emitNewCount();
    },
    fetchNodeConversations(resetCurrentPage = false) {
      this.isFetching = true;
      if (resetCurrentPage) {
        this.currentPage = 0;
      }
      this.currentPage++;
      ContentAPI.getConversations({
        parentId: this.nodeId,
        itemPerPage: this.itemsPerPage,
        page: this.currentPage,
        filterAuthor: this.filter,
        sort: this.sort,
      }).then(({ data }) => {
        this.totalPages = data.nbPage;
        this.currentPage = data.currentPage;
        if (this.currentPage === 1) {
          this.conversations = [];
          this.$nextTick(() => {
            this.conversations = [...data.paragraphs];
            this.requestMasonryRedraw();
          });
        } else {
          this.conversations = [...this.conversations, ...data.paragraphs];
        }
        this.conversationCount = data.count;
        this.replyCount = data.count_replies;
        this.requestMasonryRedraw();
        this.isFetching = false;
        this.$emit('loaded');
      });
    },
    handleCardDeletion(id) {
      this.replyCount -= this.conversations.find(
        (conversation) => conversation.ID === id
      ).replies.length;
      this.conversations = this.conversations.filter(
        (conversation) => conversation.ID !== id
      );
      this.conversationCount--;
      this.requestMasonryRedraw();
      this.emitNewCount();
    },
    updateEditedComment(payload) {
      const element = this.conversations.findIndex(
        (item) => item.ID === payload.id
      );
      if (element >= 0) {
        this.conversations[element].texte = payload.text;
      }
      this.requestMasonryRedraw();
    },
    handleDeleteReply(payload) {
      const itemIndex = this.conversations.findIndex(
        (item) => item.ID === payload.conversationId
      );
      if (itemIndex > -1) {
        this.conversations[itemIndex].replies = this.conversations[
          itemIndex
        ].replies.filter((item) => item.ID !== payload.replyId);
        this.requestMasonryRedraw();
        this.emitNewCount();
      }
    },
    handleEditReply(payload) {
      const itemIndex = this.conversations.findIndex(
        (item) => item.ID === payload.conversationId
      );
      if (itemIndex > -1) {
        const replyIndex = this.conversations[itemIndex].replies.findIndex(
          (item) => item.ID === payload.replyId
        );
        if (replyIndex > -1) {
          this.conversations[itemIndex].replies[replyIndex].texte =
            payload.text;
        }
        this.requestMasonryRedraw();
      }
    },
    updateNewReply() {
      this.requestMasonryRedraw();
      this.replyCount++;
      this.emitNewCount();
    },
    emitNewCount() {
      this.$emit('conversationCountEdit', this.totalCount);
      this.$store.dispatch('stories/updateCommentCount', {
        nodeId: this.nodeId,
        count: this.replyCount + this.conversationCount,
      });
    },
    applyFilters() {
      this.fetchNodeConversations(true);
      this.filtersOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-grid {
  @extend %base-container;
  margin-top: 1em;
  margin-bottom: 3.125em;

  @include breakpoint($breakpoint-tablet) {
    margin-top: 2em;
  }

  &__card {
    width: 100%;
    margin-bottom: 2em;
    max-height: 530px;

    @include breakpoint($breakpoint-tablet) {
      width: calc(50% - 1em);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    position: relative;

    @include breakpoint($breakpoint-tablet) {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }
  }

  &__counters {
    font-family: $font-family-prelo;
    font-size: 0.875em;
    place-self: end center;
  }

  &__counter-separator {
    &::before {
      content: ' · ';
    }
  }

  &__conversation-container {
    position: relative;
    min-height: 20em;

    & .page-loader {
      z-index: 10;
    }
  }

  &__read-all {
    width: 100%;

    @include breakpoint($breakpoint-tablet) {
      width: 20em;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
