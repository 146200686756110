<template>
  <div :class="classList">
    <Spinner />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner';

const BLOCK_SELECTOR = 'page-loader';
export default {
  name: 'PageLoader',
  components: { Spinner },
  props: {
    translucide: Boolean,
    relative: Boolean,
  },
  computed: {
    classList() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--translucide`]: this.translucide,
          [`${BLOCK_SELECTOR}--relative`]: this.relative,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-white;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;

  &--translucide {
    opacity: 0.7;
  }

  &--relative {
    position: absolute;
  }
}
</style>
