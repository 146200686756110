<template>
  <div class="account-custom-profile">
    <AccountQuestionnaire class="account-custom-profile__section" />
    <AccountBoutique class="account-custom-profile__section" />
    <AccountUsefulLinks :cards="cards" />
  </div>
</template>

<script>
import AccountQuestionnaire from '@/components/account/AccountQuestionnaire';
import AccountUsefulLinks from '@/components/account/AccountUsefulLinks';
import AccountBoutique from '@/components/account/AccountBoutique';
export default {
  name: 'AccountCustomProfile',
  components: { AccountQuestionnaire, AccountUsefulLinks, AccountBoutique },
  data() {
    return {
      cards: [
        {
          image: 'https://place-hold.it/310x250',
          alt: 'placeholder',
          button: {
            label: 'Label',
            link: 'link',
          },
        },
        {
          image: 'https://place-hold.it/310x250',
          alt: 'placeholder',
          button: {
            label: 'Label',
            link: 'link',
          },
        },
        {
          image: 'https://place-hold.it/310x250',
          alt: 'placeholder',
          button: {
            label: 'Label',
            link: 'link',
          },
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.account-custom-profile {
  &__section {
    margin-bottom: 2.5rem;
  }
}
</style>
