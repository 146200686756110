<template>
  <div class="conversation-summary-card">
    <div class="conversation-summary-card__header">
      <img
        :src="image"
        alt="conversation preview"
        class="conversation-summary-card__image"
      />
      <div class="conversation-summary-card__infos">
        <p
          class="conversation-summary-card__text conversation-summary-card__text--type"
        >
          {{ nodeType }}
        </p>
        <p
          class="conversation-summary-card__text conversation-summary-card__text--title"
        >
          {{ name }}
        </p>
        <RouterLink v-if="nodeId && nodeLink" :to="nodeLink">
          <VACButton
            class="conversation-summary-card__cta"
            appearance="secondary"
            @click="trackReadAll"
            :label="$t('accountPosts.readAll')"
          />
        </RouterLink>
      </div>
    </div>
    <div class="conversation-summary-card__interactions">
      <div
        v-for="interaction in interactions"
        :key="interaction.ID"
        class="conversation-summary-card__interaction"
      >
        <div class="conversation-summary-card__body">
          <p
            v-if="interaction.deleted === '1'"
            class="conversation-summary-card__deleted"
          >
            {{ $t(contentModerator(interaction)) }}
          </p>
          <p :class="interactionClasses(interaction)">
            {{ interaction.texte }}
          </p>
        </div>
        <div
          v-if="interaction.deleted === '1'"
          class="conversation-summary-card__footer"
        >
          <p
            class="conversation-summary-card__text conversation-summary-card__text--author"
          >
            <RouterLink
              :to="{
                name: RouteName.USER_PROFILE,
                params: { profileId: interaction.author.ID },
              }"
              class="conversation-summary-card__text conversation-summary-card__text--author"
            >
              {{
                $t('accountPosts.authorMe', {
                  username: interaction.author.pseudo,
                })
              }}
            </RouterLink>
            <span class="conversation-summary-card__date">
              {{ conversationDate(interaction.date) }}
            </span>
          </p>
          <p
            class="conversation-summary-card__text conversation-summary-card__text--replies"
          >
            {{ $tc('conversation.replyCount', interaction.number_reply) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';

import { timestampToRelativeDate } from '@/utils/DateUtils';
import { ConversationNodeType } from '@/utils/ConversationConstants';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

const BLOCK_SELECTOR = 'conversation-summary-card';

const POST_TYPE = Object.freeze({
  CONVERSATION: 'conversation',
  REPLY: 'conversation_reply',
});
const DELETION_AUTHOR = Object.freeze({
  ADMIN: 'by admin',
  AUTHOR: 'by author',
});
export default {
  name: 'ConversationSummaryCard',
  components: { VACButton },
  mixins: [Tracking],
  props: {
    nodeId: { type: String, default: undefined },
    nodeType: { type: String, required: true },
    name: { type: String, required: true },
    image: { type: String, required: true },
    interactions: { type: Array, default: () => [] },
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    isProfilePreview() {
      return this.$route.name === RouteName.ACCOUNT_POSTS;
    },
    nodeLink() {
      switch (this.nodeType) {
        case ConversationNodeType.ARTICLE:
          return {
            name: RouteName.ARTICLE_CONVERSATION,
            params: {
              nodeId: this.nodeId,
            },
            query: {
              onlyMe: this.isProfilePreview,
            },
          };
        case ConversationNodeType.STORY:
          return {
            name: RouteName.GOLDEN_BOOK_CONVERSATION,
            params: {
              nodeId: this.nodeId,
            },
            query: {
              onlyMe: this.isProfilePreview,
            },
          };
        default:
          return undefined;
      }
    },
  },
  methods: {
    conversationDate(date) {
      return timestampToRelativeDate(date);
    },
    interactionClasses(data) {
      return [
        `${BLOCK_SELECTOR}__interaction-content`,
        {
          [`${BLOCK_SELECTOR}__interaction-content--deleted`]:
            data.deleted === true || data.deleted === '1',
          [`${BLOCK_SELECTOR}__interaction-content--reply`]:
            data.type === POST_TYPE.REPLY,
        },
      ];
    },
    contentModerator(data) {
      switch (data.parent_deleted) {
        case DELETION_AUTHOR.ADMIN:
          return 'accountPosts.deletedByAdmin';
        case DELETION_AUTHOR.AUTHOR:
          return 'accountPosts.deletedByAuthor';
        default:
          return 'accountPosts.deletedByUnknown';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-summary-card {
  $block-selector: &;

  background-color: $color-wild-sand;
  padding: 1em;

  &__image {
    width: 5.813em;
  }

  &__header {
    display: flex;
  }

  &__infos {
    margin-left: 1em;
    position: relative;
  }

  &__text {
    &--type {
      font-weight: 200;
      margin-bottom: 0.625em;
    }

    &--title {
      font-family: $font-family-prelo-condensed;
      font-size: 1.375em;
      font-weight: bold;
      line-height: 1.2em;
    }

    &--author {
      font-weight: bold;
      margin-bottom: 0;
    }
    &--replies {
      margin-bottom: 0;
    }
  }

  &__body {
    margin-top: 1em;
    margin-bottom: 0.25em;
    color: $color-black;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    @extend %conversation-card__date;
  }

  &__cta {
    position: absolute;
    bottom: 0;
    left: 0;
    min-height: 0;
    justify-content: flex-start;
    background-color: transparent;

    @include breakpoint($breakpoint-tablet) {
      min-height: 2.8125em;
      justify-content: center;
      white-space: nowrap;
    }
  }

  &__deleted {
    margin-bottom: 0.5em;
    font-weight: bold;
    color: $color-silver;
  }

  &__interaction-content {
    margin-bottom: 0;
    &:not(:last-child) {
      padding-bottom: 1em;
      border-bottom: 1px solid $color-wild-sand;
    }

    &--reply {
      border-left: 1px solid $color-beaver;
      padding-left: 1em;
      &:not(:last-child) {
        border-bottom: 0;
      }
    }

    &--deleted {
      color: $color-silver;

      #{$block-selector}__text {
        color: $color-silver;
      }

      #{$block-selector}__reply--deleted {
        border-left-color: $color-silver;
      }
    }
  }
  body.dir-rtl & {
    &__infos {
      margin-left: 0;
      margin-right: 1em;
    }
  }
}
</style>
