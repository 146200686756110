<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal
      is-active
      class="auth-modal"
      transition-name="auth-modal-"
      @close="close"
    >
      <template #container>
        <div class="auth-modal__body">
          <transition name="auth-modal__inner-">
            <div v-if="loading" class="auth-modal__loading-overlay">
              <Spinner />
            </div>
          </transition>

          <div class="auth-modal__header">
            <BaseBtn class="auth-modal__close" discrete @click="close" />
          </div>

          <div class="auth-modal__title">
            {{ $t('auth.title') }}
          </div>

          <div class="auth-modal__content">
            <PhoneAuthForm
              :wechat-association="isInWeChat && hasToken !== null"
              @submit="loading = true"
              @error="loading = false"
              @close="close"
            />
            <a :href="link">{{ link }}</a>

            <RouterLink
              :to="to"
              class="auth-modal__link"
              @click.native="closeIfNoRedirect"
            >
              {{ $t('auth.alreadyEmailAccount') }}
            </RouterLink>
          </div>

          <div class="auth-modal__footer">
            <div class="auth-modal__footer-title">
              {{ $t('auth.form.footer.title') }}
            </div>

            <BaseBtn
              block
              border
              class="auth-modal__footer-btn"
              @click="handleSignup"
            >
              {{ $t('auth.signUp') }}
            </BaseBtn>
          </div>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import BaseModal from '@/components/BaseModal';
import PortalName from '@/utils/PortalName';
import BusName from '@/utils/BusName';
import Spinner from '@/components/Spinner';
import BaseBtn from '@/components/BaseBtn';
import PhoneAuthForm from '@/components/form/PhoneAuthForm';
import RouteName from '@/utils/RouteName';
import IsInWeChat from '@/utils/IsInWeChat';
import storage from '@/utils/storage';
import AutoLoginStorage from '@/enums/AutoLoginStorage';

export default {
  name: 'AuthCNModal',
  components: {
    PhoneAuthForm,
    Spinner,
    BaseModal,
    BaseBtn,
  },
  data() {
    return {
      loading: false,
      PortalName,
      BusName,
      tab: this.$t('authTypeSwitcher.email'),
      to: { name: RouteName.ALREADY_EMAIL_ACCOUNT },
      link: '',
    };
  },
  computed: {
    isInWeChat() {
      return IsInWeChat();
    },
    hasToken() {
      return storage.get(AutoLoginStorage.RWF_TOKEN);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleSignup() {
      this.$emit('register');
    },
    closeIfNoRedirect() {
      if (this.to.name === this.$router.currentRoute.name) {
        this.$root.$emit(BusName.RESET_ALREADY_EMAIL_ACCOUNT_STEPPER);
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth-modal {
  @extend %auth-modal-transition;

  &__body {
    padding: 2.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: block;
    font-family: $font-family-prelo-condensed;
    margin-top: 0.25em;
    text-decoration: underline;
    text-align: center;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    @extend %auth-modal__title;
  }

  &__loading-overlay {
    @extend %loading-overlay;
  }

  &__sso-login {
    margin-top: 1em;
  }

  &__footer {
    flex: 1;
    margin: 1.75em -2.5em -2.5em;
    padding: 1.25em 2.5em 2.5em;
    border-top: 1px solid $color-default;
    background-color: $color-wild-sand;

    &-title {
      font-family: $font-family-prelo-condensed;
      font-size: 1.125em;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 1em;
    }

    &-btn {
      font-family: $font-family-prelo-condensed;
    }
  }

  &__inner {
    &--enter-active,
    &--leave-active {
      transition: opacity $default-transition-duration;
    }

    &--enter,
    &--leave-to {
      opacity: 0;
    }

    &--enter-to,
    &--leave {
      opacity: 1;
    }
  }

  &::v-deep {
    .base-modal {
      &__container {
        width: 100%;
        max-width: 25em;
        overflow: auto;
      }
    }
  }
}
</style>
