<template>
  <DefaultLayout class="ie">
    <template #header>
      <header class="ie__header">
        <BaseLogo />
      </header>
    </template>
    <template #default>
      <div class="ie__body">
        <BaseTitle :level="1" class="ie__title">
          {{ $t('ie.title') }}
        </BaseTitle>
        <p class="ie__description">
          {{ $t('ie.description') }}
        </p>
        <ul class="ie__list">
          <li
            v-for="browser in availableBrowsers"
            :key="browser"
            class="ie__item"
          >
            <BaseBtn
              primary
              :href="link(browser)"
              :attributes="{ target: '_blank' }"
              class="ie__button"
            >
              <img
                :src="image(browser)"
                :alt="text(browser)"
                class="ie__logo"
              />
              {{ text(browser) }}
            </BaseBtn>
          </li>
        </ul>
        <div class="ie__banner"></div>
      </div>
    </template>
    <template #footer>
      <footer class="ie__footer"></footer>
    </template>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout';
import BaseLogo from '@/components/BaseLogo';
import BaseTitle from '@/components/BaseTitle';
import BaseBtn from '@/components/BaseBtn';
export default {
  name: 'IE',
  components: { BaseBtn, BaseTitle, BaseLogo, DefaultLayout },
  data() {
    return {
      browsers: ['chrome', 'firefox', 'safari', 'edge'],
    };
  },
  computed: {
    availableBrowsers() {
      return this.browsers.filter((l) => this.link(l));
    },
  },
  methods: {
    text(attr) {
      return this.$t(`ie.browsers.label.${attr}`);
    },
    link(attr) {
      return this.$t(`ie.browsers.link.${attr}`);
    },
    image(attr) {
      return require(`../assets/browsers/${attr}.png`);
    },
  },
};
</script>

<style lang="scss" scoped>
.ie {
  &__header {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    min-height: $header-member-min-height;
    background-color: $color-white;
  }

  &__header,
  &__body {
    @extend %base-container;
    padding-left: 1em;
    padding-right: 1em;
  }

  &__title {
    text-align: center;
    text-transform: uppercase;
    font-family: $font-family-prelo-condensed;
    font-size: 1.5625em;
  }

  &__description {
    text-align: center;
    max-width: 40.25em;
    margin: 2.5em auto 0;
    padding-left: 1em;
    padding-right: 1em;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__item {
    display: block;
    margin: 0.625em;
  }

  &__button {
    font-family: $font-family-prelo;
  }

  &__logo {
    display: block;
    margin-right: 0.5em;
    max-height: 2.1875em;
    width: auto;
  }

  &__banner {
    display: block;
    margin-top: 2.5em;
    width: 100%;
    padding-top: 40%;
    background-image: url('../assets/news3.png');
    background-position: center;
    background-size: cover;
  }
}
</style>
