<template>
  <div class="video-icon">
    <div class="video-icon__arrow"></div>
  </div>
</template>

<script>
export default {
  name: 'VideoIcon',
};
</script>

<style lang="scss" scoped>
.video-icon {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 16.5625em;
  border: 1em solid $color-white;
  border-radius: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.5;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    left: calc(50% + 1em);
    width: 0;
    height: 0;
    border-top: 3.75em solid transparent;
    border-bottom: 3.75em solid transparent;
    border-left: 5.625em solid $color-white;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>
