<template>
  <div :class="blockClasses">
    <div class="conversation-reply__header">
      <p class="conversation-reply__infos">
        <RouterLink
          :to="{
            name: RouteName.USER_PROFILE,
            params: { profileId: reply.author.ID },
          }"
          class="conversation-reply__infos"
          >{{ authorName }}
        </RouterLink>
        <span class="conversation-reply__date">
          {{ conversationDate }}
        </span>
      </p>
      <div v-if="canDoAction" class="conversation-reply__actions">
        <BaseBtn
          discrete
          class="conversation-reply__action conversation-reply__action--edit"
          @click="toggleEditMode"
        />
        <BaseBtn
          discrete
          class="conversation-reply__action conversation-reply__action--delete"
          @click="showDeleteModal = true"
        />
      </div>
    </div>
    <p v-if="!editMode">
      {{ reply.texte }}
    </p>
    <VForm
      v-else
      ref="editForm"
      class="conversation-reply__edit"
      @submit.prevent="editComment"
    >
      <FieldTextWithEmoji
        v-model="replyText"
        :disabled="isSubmitting"
        text-area
        name="commentreply"
        :rules="messageRules"
        @extended="handleOverflowing"
        counter="300"
      />
      <Spinner v-if="isSubmitting" small class="conversation-reply__spinner" />
      <template v-else>
        <VACButton
          appearance="secondary"
          type="submit"
          :disabled="isSubmitting"
          class="conversation-reply__edit-btn"
          :label="$t('conversation.saveEdits')"
        />
        <VACButton
          :disabled="isSubmitting"
          appearance="discrete"
          class="conversation-reply__edit-btn conversation-reply__edit-btn--discard"
          :label="$t('conversation.discardChanges')"
          @click="disableEditMode"
        />
      </template>
    </VForm>
    <DeleteConversationModal
      v-if="showDeleteModal"
      @close="showDeleteModal = false"
      @cancel="showDeleteModal = false"
      @delete="deleteReply"
    />
  </div>
</template>

<script>
import ContentAPI from '@/api/ContentAPI';

import { timestampToRelativeDate } from '@/utils/DateUtils';
import BaseBtn from '@/components/BaseBtn';
import FieldTextWithEmoji from '@/components/form/FieldTextWithEmoji';
import Spinner from '@/components/Spinner';
import DeleteConversationModal from '@/components/conversation/DeleteConversationModal';
import MasonryConversationGrid from '@/mixins/MasonryConversationGrid';
import RouteName from '@/utils/RouteName';
const BLOCK_SELECTOR = 'conversation-reply';
export default {
  name: 'ConversationReply',
  components: { BaseBtn, FieldTextWithEmoji, Spinner, DeleteConversationModal },
  mixins: [MasonryConversationGrid],
  props: {
    reply: { type: Object, required: true },
    parentId: { type: String, required: true },
  },
  data() {
    return {
      RouteName,
      editMode: false,
      editText: this.reply.texte,
      isSubmitting: false,
      isOverflowing: false,
      messageRules: [
        (v) => !!v || this.$t('conversation.message.required'),
        (v) =>
          (v && v.length <= 300) || this.$t('conversation.message.maxLength'),
      ],
      replyText: this.reply.texte,
      showDeleteModal: false,
    };
  },
  computed: {
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--edit`]: this.editMode,
        },
      ];
    },
    conversationDate() {
      return timestampToRelativeDate(this.reply.date);
    },
    canDoAction() {
      return this.reply.author.ID === this.$store.state.auth.userInfos.userId;
    },
    authorName() {
      if (this.reply.author.psuedo === 'Me') {
        return this.$t('conversation.me');
      }
      return this.reply.author.pseudo;
    },
  },
  methods: {
    async deleteReply() {
      this.$emit('delete', this.reply.ID);
      await ContentAPI.deleteReply(this.reply.ID);
    },
    toggleEditMode() {
      this.editMode = true;
      this.requestMasonryRedraw();
    },
    async editComment() {
      if (this.$refs.editForm.validate()) {
        this.isSubmitting = true;
        await ContentAPI.editReply(this.reply.ID, this.replyText);
        this.isSubmitting = false;
        this.editMode = false;
        this.$emit('edited', {
          id: this.reply.ID,
          text: this.replyText,
        });
        this.$refs.editForm.reset();
        this.requestMasonryRedraw();
      }
    },
    disableEditMode() {
      this.editMode = false;
      this.requestMasonryRedraw();
    },
    handleOverflowing() {
      this.isOverflowing = true;
      this.$emit('overflowing');
    },
  },
};
</script>

<style scoped lang="scss">
.conversation-reply {
  $block-selector: &;

  border-left: 1px solid $color-black;
  padding-left: 1em;

  &--edit {
    border-left: none;
    margin-bottom: 1em;
  }

  &__edit {
    padding: 0.5em 1.875em;
    margin: 0.5em -1.875em;
    margin-left: -2.875em;
    border-left: none;
    background-color: $color-wild-sand;
  }

  &:hover {
    #{$block-selector}__actions {
      display: block;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__infos {
    @extend %conversation-card__infos;
  }

  &__date {
    @extend %conversation-card__date;
  }

  &__actions {
    display: none;
  }

  &__action {
    &::before {
      @extend %icon;
    }

    &--edit {
      margin-right: 0.5em;

      &::before {
        @extend %icon--pen;
      }
    }

    &--delete::before {
      @extend %icon--bin;
    }
  }

  &__edit-btn {
    background-color: $color-wild-sand;
    margin-top: 1em;

    @include breakpoint($breakpoint-tablet) {
      width: 14.375em;
      margin-left: auto;
      margin-right: auto;
    }

    &--discard {
      color: $color-crown-of-thorns;
    }
  }

  &__spinner {
    height: 1em;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -1em;
    ::v-deep {
      .spinner__icon {
        font-size: 1.5em;
      }
    }
  }
}
</style>
