<template>
  <div class="onboarding-end">
    <div class="onboarding-end__images">
      <img
        :src="backImage"
        class="onboarding-end__image onboarding-end__image--back"
        alt=""
      />
      <img
        :src="frontImage"
        class="onboarding-end__image onboarding-end__image--front"
        alt=""
      />
    </div>
    <div class="onboarding-end__content">
      <span class="onboarding-end__icon" />
      <p class="onboarding-end__title">
        {{ $t('onboarding.end.title') }}
      </p>
      <p class="onboarding-end__intro">
        {{ $t('onboarding.end.content') }}
      </p>
      <VACButton
        v-if="$matchMedia.xl"
        class="onboarding-end__cta"
        :label="$t('onboarding.end.back')"
        appearance="primary"
        @click="emitClose"
      />
    </div>
    <VACButton
      v-if="!$matchMedia.xl"
      class="onboarding-end__cta"
      :label="$t('onboarding.end.back')"
      appearance="primary"
      full-width
      @click="emitClose"
    />
  </div>
</template>
<script>
import frontImage from '@/assets/onboarding/end-front.jpg';
import backImage from '@/assets/onboarding/end-back.png';
import { VACButton } from '@webqam-vac-ui/lib';
export default {
  name: 'OnboardingEnd',
  components: { VACButton },
  data() {
    return {
      frontImage,
      backImage,
    };
  },
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
.onboarding-end {
  display: flex;
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 100%;

  @include breakpoint($breakpoint-desktop) {
    flex-direction: row;
    gap: 0;
  }

  &__images {
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    min-height: 19.375rem;

    @include breakpoint($breakpoint-desktop) {
      margin-left: 0;
      margin-right: 0;
      flex-basis: 40%;
      flex-shrink: 0;
    }
  }

  &__image {
    position: absolute;
    height: 16.0625rem;
    width: 12.0625rem;

    @include breakpoint($breakpoint-desktop) {
      width: 90%;
      height: 90%;
    }

    &--back {
      z-index: 1;
      bottom: 0;
      right: 0;
    }

    &--front {
      z-index: 2;
      top: 0;
      left: 0;
    }
  }

  &__content {
    text-align: center;
    margin-top: 3.125rem;

    @include breakpoint($breakpoint-desktop) {
      text-align: left;
      margin-left: 4.25rem;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.25rem;
    color: $color-black;
    width: 18.75rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.9375rem;

    @include breakpoint($breakpoint-desktop) {
      margin-left: 0;
      margin-right: 0;
      font-size: 1.875rem;
      width: 28.125rem;
    }
  }

  &__icon {
    font-size: 4em;
    display: inline-flex;

    @include breakpoint($breakpoint-desktop) {
      font-size: 6em;
    }

    &::before {
      @extend %icon;
      @extend %icon--thc-big;
    }
  }

  &__cta {
    margin-top: auto;
  }

  &__intro {
    display: none;

    @include breakpoint($breakpoint-desktop) {
      display: block;
      width: 28.125rem;
    }
  }
}
</style>
