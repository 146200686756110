import { render, staticRenderFns } from "./GoldenBookCropperWarning.vue?vue&type=template&id=d859d190&scoped=true&"
import script from "./GoldenBookCropperWarning.vue?vue&type=script&lang=js&"
export * from "./GoldenBookCropperWarning.vue?vue&type=script&lang=js&"
import style0 from "./GoldenBookCropperWarning.vue?vue&type=style&index=0&id=d859d190&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d859d190",
  null
  
)

export default component.exports