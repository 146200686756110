export default {
  data() {
    return {
      AIShowModal: false,
      AICallback: undefined,
    };
  },
  methods: {
    aiSubmitEvent(callback) {
      this.AIShowModal = true;
      this.AICallback = callback;
    },
    aiModalClosed() {
      this.AIShowModal = false;
      if (this.AICallback) {
        this.AICallback();
      } else {
        throw new Error(
          'Callback for AuthorizationInterceptor must be defined'
        );
      }
    },
  },
};
