<template>
  <Portal :to="portalTarget">
    <VACSideModal
      class="language-selector-modal"
      :is-open="isOpen"
      :disabled="!canSubmit"
      :title="modalTitle"
      :okLabel="$t('goldenBook.filters.modal.done')"
      :cancelLabel="$t('goldenBook.filters.modal.cancel')"
      right
      @apply="() => submit()"
      @close="close"
    >
      <div class="language-selector-modal__content">
        <div
          v-if="!regionDisabled"
          class="language-selector-modal__region-input"
        >
          <VACSelect
            v-model="regionValue"
            :label="regionLabel"
            name="region"
            :disabled="regionDisabled"
            :options="regionsOptions"
          />
        </div>

        <VACSelect
          v-model="languageValue"
          :options="languagesOptions"
          name="language"
          :label="`${$t('languageSelector.modal.fields.language')} *`"
          required
        />
      </div>
    </VACSideModal>
  </Portal>
</template>

<script>
import { VACSelect, VACSideModal } from '@webqam-vac-ui/lib';
import languageMixin from '@/mixins/Language';
import PortalName from '@/utils/PortalName';
import Internationalization, {
  RTL_LANGUAGES,
} from '@/utils/Internationalization';

const REGIONS_MAPPING = { int: 'en', cn: 'cn' };

export default {
  name: 'LanguageSelectorModal',
  components: {
    VACSideModal,
    VACSelect,
  },
  mixins: [languageMixin],
  props: {
    regionDisabled: Boolean,
    isOpen: Boolean,
    secondTarget: Boolean,
  },
  data() {
    return {
      PortalName,
      selectedRegion: null,
      selectedLanguage: null,
      rtlLanguages: RTL_LANGUAGES,
    };
  },

  computed: {
    /**
     * @return {string}
     */
    modalTitle() {
      return this.regionDisabled
        ? this.$t('languageSelector.modal.titleLanguage')
        : this.$t('languageSelector.modal.titleRegion');
    },
    /**
     * @return {string}
     */ portalTarget() {
      return this.secondTarget
        ? PortalName.LANG_MODAL
        : PortalName.LANG_MODAL_SECOND;
    },
    /**
     * @return {string}
     */
    regionLabel() {
      return `${this.$t('languageSelector.modal.fields.region')}\u00A0*`;
    },
    regionValue: {
      /**
       * @return {string}
       */
      get() {
        return null !== this.selectedRegion ? this.selectedRegion : this.region;
      },
      /**
       * @param {string} value
       */
      set(value) {
        this.selectedRegion = value;
      },
      rtl() {
        return this.rtlLanguages.includes(this.selectedLanguage) > 0;
      },
    },
    /**
     * @return {Array}
     */
    regionsOptions() {
      return Object.keys(this.regionsLanguages).map((value) => ({
        id: value,
        label: this.$t(`region.${value}`),
        value,
      }));
    },
    /**
     * @return {string}
     */
    formattedRegionValue() {
      return REGIONS_MAPPING[this.regionValue.toLowerCase()];
    },
    languageValue: {
      /**
       * @return {string}
       */
      get() {
        return this.selectedLanguage ? this.selectedLanguage : this.language;
      },
      /**
       * @param {string} value
       */
      set(value) {
        this.selectedLanguage = value;
      },
    },
    /**
     * @return {Array}
     */
    languagesOptions() {
      return this.regionsLanguages[this.regionValue].map((value) => ({
        id: value,
        label: this.$t(`language.${value}`),
        value,
      }));
    },
    /**
     * @return {boolean}
     */
    canSubmit() {
      return (
        null !== this.regionValue &&
        -1 !==
          this.regionsLanguages[this.regionValue].indexOf(this.languageValue)
      );
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$emit('update:is-open', false);
    },
    submit() {
      if (!this.regionDisabled) {
        Internationalization.setCountry(this.formattedRegionValue);
      }
      Internationalization.setLocale(this.languageValue);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selector-modal {
  &__body {
    min-width: 25em;
    padding: 2.5em 1.25em;
    overflow-x: hidden;
    overflow-y: auto;

    @include breakpoint($breakpoint-tablet) {
      padding: 2.5em;
    }
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    @extend %auth-modal__title;
  }

  &__region-input {
    margin-bottom: 1.25em;
  }

  &__region-label {
    margin-bottom: 0.25em;
    transform: scale(0.75);
    transform-origin: left;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__button {
    &--cancel {
      margin-top: 0.75em;
    }
  }
}
</style>
