<template>
  <div :class="blockClasses">
    <BaseBtn
      v-if="!story"
      discrete
      class="video-player__close"
      @click="close"
    />
    <button
      v-if="story && !storyPlaying"
      class="video-player__play-btn"
      @click="play"
    >
      <i class="icon icon--play"></i>
    </button>
    <video
      ref="playerVideo"
      :controlsList="'nodownload'"
      :disablePictureInPicture="true"
      :autoplay="!story"
      class="video-player__video"
      :controls="showControls"
    >
      <source :src="video" type="video/mp4" />
      {{ $t('no_video_support') }}
    </video>
  </div>
</template>

<script>
import BaseBtn from '@/components/BaseBtn';

const BLOCK_SELECTOR = 'video-player';
export default {
  name: 'VideoPlayer',
  components: { BaseBtn },
  props: {
    video: { type: String, required: true },
    story: { type: Boolean },
  },
  data() {
    return {
      showControls: !this.story,
      storyPlaying: false,
    };
  },
  computed: {
    player() {
      return this.$refs.playerVideo;
    },
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--story`]: this.story,
        },
      ];
    },
  },
  methods: {
    close() {
      this.player.pause();
      this.$emit('close');
    },
    play() {
      this.player.play();
      this.showControls = true;
      this.storyPlaying = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-player {
  $block-selector: &;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $color-black;
  display: flex;
  align-items: center;
  justify-content: center;
  &--story {
    position: static;
    display: grid;
    grid-template-areas: 'box';
    object-fit: cover;
  }

  &__close {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 1;
    padding: 0.5em;
    background-color: rgba($color-white, 0.15);

    &:hover {
      &::before {
        transform: scale(1);
      }
    }

    &::before {
      @extend %icon;
      @extend %icon--close;

      font-size: 1.5em;
      font-weight: 600;
      transform: scale(0.8);
      transition: transform $default-transition-duration;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    grid-area: box;
  }

  &__play-btn {
    position: relative;
    z-index: 2;
    grid-area: box;
    place-self: center center;
    font-size: 7rem;
    color: $color-white;
    opacity: 0.6;
  }
}
</style>
