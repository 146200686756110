<template>
  <Portal :to="PortalName.MODAL">
    <BaseModal
      is-active
      class="golden-book-crop-modal"
      transition-name="golden-book-crop-modal-"
      @close="close"
    >
      <template #container>
        <div class="golden-book-crop-modal__header">
          <VACButton
            class="golden-book-crop-modal__close"
            discrete
            icon="cross"
            @click="close"
          />

          <div class="golden-book-crop-modal__title">
            {{ $t('goldenBook.new.crop.title') }}
          </div>
        </div>

        <div class="golden-book-crop-modal__body">
          <div class="golden-book-crop-modal__image">
            <VueCropper
              ref="cropper"
              :aspect-ratio="aspectRatio"
              drag-mode="move"
              @ready="onCropperReady"
              @zoom="onZoom"
            />
          </div>

          <div class="golden-book-crop-modal__actions">
            <div class="golden-book-crop-modal__options">
              <FieldSlider
                v-model="zoomValue"
                :label="$t('goldenBook.new.crop.zoom')"
                class="golden-book-crop-modal__action golden-book-crop-modal__action--zoom"
                max="100"
                min="-100"
                show-value
                value-suffix="%"
                @input="zoom"
              />

              <FieldSlider
                v-model="rotationValue"
                :label="$t('goldenBook.new.crop.rotation')"
                class="golden-book-crop-modal__action golden-book-crop-modal__action--rotation"
                max="180"
                min="-180"
                show-value
                value-suffix="°"
                @input="rotate"
              />
            </div>

            <div class="golden-book-crop-modal__buttons">
              <VACButton
                @click="crop"
                :label="$t('goldenBook.new.crop.save')"
              ></VACButton>

              <VACButton
                class="golden-book-crop-modal__button golden-book-crop-modal__button--cancel"
                appearance="link"
                :label="$t('goldenBook.new.crop.cancel')"
                @click="close"
              />
            </div>
          </div>
        </div>
      </template>
    </BaseModal>
  </Portal>
</template>

<script>
import Vue from 'vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { VACButton } from '@webqam-vac-ui/lib';
import BaseModal from '@/components/BaseModal';
import GoldenBookCropperWarning from '@/components/golden-book/GoldenBookCropperWarning';
import FieldSlider from '@/components/form/FieldSlider';
import PortalName from '@/utils/PortalName';

const CROP_IMAGE_HEIGHT = 1254;
const CROP_IMAGE_WIDTH = 840;

export default {
  name: 'GoldenBookCropModal',
  components: {
    VACButton,
    BaseModal,
    FieldSlider,
    VueCropper,
  },
  props: {
    file: {
      type: File,
      required: true,
    },
  },
  data() {
    return {
      PortalName,
      aspectRatio: CROP_IMAGE_WIDTH / CROP_IMAGE_HEIGHT,
      zoomValue: 0,
      rotationValue: 0,
    };
  },
  mounted() {
    this.setCropper(this.file);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    /**
     * @param {File} file
     */
    setCropper(file) {
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    onCropperReady() {
      const el = document.createElement('div');
      el.id = `cropper-warning-${Math.random().toString(36).substr(2, 9)}`;

      document.querySelector('.cropper-view-box').appendChild(el);

      const image = this.$refs.cropper.getImageData();
      const zoom = (image.naturalWidth - image.width) / image.naturalWidth;
      this.zoomValue = -100 - Math.round((zoom - 1) * 100);

      const instance = Vue.extend(GoldenBookCropperWarning);
      new instance().$mount(el);
    },
    /**
     * @param {CustomEvent} event
     */
    onZoom(event) {
      this.zoomValue = Math.round((event.detail.ratio - 1) * 100);
    },
    zoom() {
      const cropper = this.$refs.cropper;
      const containerData = cropper.getContainerData();

      cropper.zoomTo(Math.max(1, 100 + this.zoomValue) / 100, {
        x: containerData.width / 2,
        y: containerData.height / 2,
      });
    },
    rotate() {
      this.$refs.cropper.rotateTo(this.rotationValue);
    },
    crop() {
      this.$emit(
        'crop',
        this.$refs.cropper
          .getCroppedCanvas({
            height: CROP_IMAGE_HEIGHT,
            width: CROP_IMAGE_WIDTH,
          })
          .toDataURL('image/jpeg', 1)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.golden-book-crop-modal {
  $block-selector: &;
  $mobile-body-padding: 1em;
  $desktop-body-padding: 2.5em;

  &__header {
    @extend %base-modal__header;
  }

  &__close {
    @extend %base-modal__close;
  }

  &__title {
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: $mobile-body-padding;

    @include breakpoint($breakpoint-l) {
      flex-direction: row;
      padding: $desktop-body-padding 0;
    }
  }

  &__image,
  &__actions {
    @include breakpoint($breakpoint-l) {
      width: 50%;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    max-height: 50vh;
    height: 28.75em;

    @include breakpoint($breakpoint-l) {
      max-height: 100%;
      padding-left: $desktop-body-padding;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-top: 0.625em;

    @include breakpoint($breakpoint-l) {
      padding: 0 $mobile-body-padding;
    }
  }

  &__options {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.25rem;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint($breakpoint-l) {
      margin-bottom: 1em;
    }
  }

  &__button {
    &--cancel {
      margin-top: 1em;
    }
  }

  &::v-deep {
    .base-modal {
      &__container {
        width: 45em;
        overflow: auto;
      }
    }

    .cropper-bg {
      background-repeat: repeat;
    }

    .cropper-line,
    .cropper-point {
      background-color: $color-white;
    }

    .cropper-view-box {
      position: relative;
      outline: 1px solid $color-white;
      outline-color: $color-white;
    }

    .golden-book-crop-warning {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 25%;
    }
  }
}
</style>
