<template>
  <div :class="classList" v-bind="blockBindings">
    <div class="base-grid-item__container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'base-grid-item';
export default {
  name: 'BaseGridItem',
  props: {
    size: { type: Number, default: undefined },
    blur: { type: Boolean },
    grow: { type: Boolean },
  },
  computed: {
    classList() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--blurred`]: this.blur,
          [`${BLOCK_SELECTOR}--grow`]: this.grow,
        },
      ];
    },
    blockBindings() {
      const bindings = {};

      if (!this.$matchMedia.s) {
        bindings.style = 'width: 100%;';
      }

      if (this.size && this.$matchMedia.s) {
        bindings.style = 'width: calc(' + this.size + '% - 1.375em * 2);';
        if (this.size === 100) {
          bindings.style += ' flex-grow: 1;';
        }
      }

      return bindings;
    },
  },
};
</script>

<style scoped lang="scss">
%item-full {
  flex-basis: 100%;
}

.base-grid-item {
  $block-selector: &;
  position: relative;
  margin-top: 0.5em;
  margin-bottom: 0.5em;

  @include breakpoint($breakpoint-tablet) {
    margin: 1.656em 1.375em;
  }

  &--grow {
    flex-grow: 1;
  }

  &--blurred {
    filter: blur(4px);

    #{$block-selector}__container {
      &:hover {
        transform: none;
      }
    }
  }

  &__container {
    height: 100%;
  }
}
</style>
