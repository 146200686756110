<template>
  <div class="emoji-picker">
    <VMenu v-model="isOpen" :close-on-content-click="false" max-width="100%">
      <template #activator="{ on }">
        <BaseBtn class="emoji-picker__button" discrete v-on="on">{{
          label
        }}</BaseBtn>
      </template>

      <Picker
        :data="emojiIndex"
        :show-categories="false"
        :show-preview="false"
        :show-search="false"
        :i18n="lang"
        native
        @select="insert"
      />
    </VMenu>
  </div>
</template>

<script>
import { EmojiIndex, Picker } from 'emoji-mart-vue-fast';
import 'emoji-mart-vue-fast/css/emoji-mart.css';
import data from 'emoji-mart-vue-fast/data/all.json';

import BaseBtn from '@/components/BaseBtn';

const emojiIndex = new EmojiIndex(data);

export default {
  name: 'EmojiPicker',
  components: { Picker, BaseBtn },
  data() {
    return {
      isOpen: false,
      label: '😄',
      emojiIndex,
    };
  },
  computed: {
    lang() {
      return this.$t('emoji');
    },
  },
  methods: {
    /**
     * @param {Object} emoji
     */
    insert(emoji) {
      this.$emit('insert', emoji);
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.emoji-picker {
  position: absolute;
  bottom: 0;
  right: 0;
  &__button {
    $size: 2em;

    height: $size;
    width: $size;
    border: 1px solid $color-gallery;
    transition: border-color $default-transition-duration;

    &:hover {
      border-color: darken($color-gallery, 10%);
    }

    ::v-deep .base-btn__body {
      width: 100%;
    }
  }
}
</style>
