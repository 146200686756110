<template>
  <div :class="blockClasses">{{ type }}</div>
</template>

<script>
const BLOCK_SELECTOR = 'article-badge';

export default {
  name: 'ArticleBadge',
  props: {
    type: { type: String, default: undefined },
  },
  computed: {
    blockClasses() {
      return [BLOCK_SELECTOR, `${BLOCK_SELECTOR}--${this.type}`];
    },
  },
};
</script>

<style scoped lang="scss">
.article-badge {
  width: 7em;
  height: 1.5em;
  clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
  color: $color-white;
  font-family: $font-family-prelo-condensed;
  padding-left: 1em;
  text-align: initial;
  background-color: $color-beaver;
}
</style>
