<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="datepicker-range">
    <div class="datepicker-range__container">
      <VTextField
        v-model="dateStart"
        :label="labelStart"
        :placeholder="$t('events.search.date.label.format')"
        class="datepicker-range__cell"
        readonly
        @focus="modal = true"
      />
      <VTextField
        v-model="dateEnd"
        :label="labelEnd"
        :placeholder="$t('events.search.date.label.format')"
        class="datepicker-range__cell"
        readonly
        @focus="modal = true"
      />
    </div>

    <template v-if="modal">
      <div v-if="$matchMedia.xl" class="datepicker-range__pickers">
        <VDatePicker v-model="localDates" :locale="locale" range scrollable>
          <div class="datepicker-range__footer">
            <VACButton
              class="datepicker-range__button datepicker-range__button--deny"
              appearance="discrete"
              icon=""
              :label="$t('datepicker_range.cancel')"
              @click="modal = false"
            />
            <div class="datepicker-range__separator"></div>
            <VACButton
              class="datepicker-range__button datepicker-range__button--agree"
              appearance="discrete"
              icon=""
              :label="$t('datepicker_range.ok')"
              @click="updateData"
            />
          </div>
        </VDatePicker>
      </div>

      <VDialog
        v-else
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        persistent
      >
        <VDatePicker v-model="localDates" :locale="locale" range scrollable>
          <div class="datepicker-range__footer">
            <VACButton
              class="datepicker-range__button datepicker-range__button--deny"
              appearance="discrete"
              icon=""
              :label="$t('datepicker_range.cancel')"
              @click="modal = false"
            />
            <div class="datepicker-range__separator"></div>
            <VACButton
              class="datepicker-range__button datepicker-range__button--agree"
              appearance="discrete"
              icon=""
              :label="$t('datepicker_range.ok')"
              @click="updateDataDialog"
            />
          </div>
        </VDatePicker>
      </VDialog>
    </template>
  </div>
</template>

<script>
import { VACButton } from '@webqam-vac-ui/lib';
import Internationalization from '@/utils/Internationalization';

export default {
  name: 'FieldDatepickerRange',
  components: { VACButton },
  model: {
    prop: 'dates',
    event: 'input',
  },
  props: {
    labelStart: { type: String, default: undefined },
    labelEnd: { type: String, default: undefined },
    dates: { type: Array, default: undefined },
    placeholder: { type: Array, default: undefined },
  },
  data() {
    return {
      localDates: null,
      locale: Internationalization.getCurrentLocale(),
      modal: false,
    };
  },
  computed: {
    dateStart() {
      return this.dates && this.formatDate(this.dates[0]);
    },
    dateEnd() {
      return this.dates && this.formatDate(this.dates[1]);
    },
  },
  watch: {
    /**
     * @param {Array} newValue
     * @param {Array|null} oldValue
     */
    localDates(newValue, oldValue) {
      this.updateData(null === oldValue);
    },
  },
  mounted() {
    this.localDates = this.dates;
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      if (this.locale === 'en') {
        return `${month}/${day}/${year}`;
      } else {
        return `${day}/${month}/${year}`;
      }
    },
    updateDataDialog() {
      this.checkDateOrder();
      this.$refs.dialog.save(this.localDates);
      this.$emit('input', this.localDates);
      this.$emit('change');
    },
    updateData(closeModal = true) {
      this.checkDateOrder();
      this.$emit('input', this.localDates);
      this.$emit('change');
      this.modal = !closeModal;
    },
    checkDateOrder() {
      const dateOne = this.localDates[0];
      const dateTwo = this.localDates[1];
      if (new Date(dateOne) > new Date(dateTwo)) {
        this.localDates[0] = dateTwo;
        this.localDates[1] = dateOne;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker-range {
  &__container {
    display: flex;
    align-items: center;
    background-color: $color-wild-sand;
    padding: 0.15rem 1rem 0;
  }

  &__separator {
    width: 1px;
    height: 1em;
    background-color: $color-default;
  }

  &__cell {
    &:last-child {
      margin-left: -1px;
      margin-right: -1px;

      ::v-deep .v-input__slot {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
  }

  &__pickers {
    position: absolute;
    margin-top: 5px;
    z-index: 4;

    @include breakpoint($breakpoint-desktop) {
      width: 22.5em;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__button {
    text-align: center;
    width: 100%;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.5rem;

    &:hover {
      background-color: darken($color-wild-sand, 5%);
    }

    &--deny {
      font-weight: 200;
    }

    &--agree {
      font-weight: bold;
    }
  }
}

::v-deep {
  .v-label {
    padding-bottom: 0;
    color: $color-black;
    font-size: 1.125rem;
    transform: translateY(-100%) scale(1);
    &--active {
      transform: translateY(-100%) scale(1);
    }
  }

  .v-input {
    &__slot {
      margin: 0;
    }
  }
  .v-picker {
    background-color: $color-wild-sand !important;
    border-bottom: 2px solid $color-black;
    border-radius: 0;
    &__title {
      display: none;
    }

    &__actions {
      padding: 0;
      background: $color-white;
    }
  }

  .v-dialog {
    max-width: 20em;
  }

  .v-date-picker-table__current {
    border: none;
    font-weight: bold;
  }

  .v-date-picker-header {
    font-family: $font-prelo;
    background-color: $color-wild-sand !important;
    &__value {
      font-weight: bold;
      button {
        background-color: transparent;
      }
    }
  }

  .v-date-picker-table {
    background-color: $color-wild-sand !important;
    &--date th {
      font-weight: normal;
    }

    .v-btn.v-btn--active {
      color: $color-black;
      font-weight: bold;
      background-color: $color-white !important;
      border: none !important;
      &::before {
        display: none;
      }
    }
  }
}
</style>
