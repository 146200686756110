<template>
  <PageLoader v-if="!userProfile" />
  <div v-else class="account-stories">
    <BaseBtn discrete class="account-stories__back-btn" @click="$router.go(-1)">
      {{ backText }}
    </BaseBtn>
    <BaseTitle :level="1" center class="account-stories__title">
      {{ pageTitle }}
    </BaseTitle>
    <div class="account-stories__posts">
      <div
        v-for="story in stories"
        :key="story.id"
        class="account-stories__post-container"
      >
        <GoldenBookCard
          :id="story.ID"
          class="account-stories__post"
          :title="story.title"
          with-counters
          :comment-counter="story.count_conversation"
          :like-counter="story.count_like"
          :author="story.author.pseudo"
          :author-id="story.author.ID"
          :background-image="story['images-portrait'][0].x1"
          light-social
          :with-story="!!story.description"
          :i-like="story.i_like"
        />
      </div>
    </div>
    <Spy v-if="!isEverythingLoaded" @reached="loadMoreStories" />
    <Spinner v-if="!isEverythingLoaded || isFetching" />
  </div>
</template>

<script>
import RouteName from '@/utils/RouteName';
import BaseTitle from '@/components/BaseTitle';
import Spinner from '@/components/Spinner';
import Spy from '@/components/Spy';
import GoldenBookCard from '@/components/golden-book/GoldenBookCard';

import {
  prefixType,
  StoriesActions,
  StoriesGetters,
  StoriesMutations,
} from '@/store/modules/stories';

import ContentAPI from '@/api/ContentAPI';
import PageLoader from '@/components/PageLoader';
import BaseBtn from '@/components/BaseBtn';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountStories',
  components: { BaseBtn, PageLoader, BaseTitle, Spinner, Spy, GoldenBookCard },
  mixins: [Tracking],
  props: {
    profileId: { type: String, default: undefined },
  },
  data() {
    return {
      RouteName,
      userProfile: undefined,
    };
  },
  computed: {
    backText() {
      if (this.isUserProfile) {
        return this.$t('accountPosts.backToPosts');
      }
      return this.$t('accountPosts.back');
    },
    pageTitle() {
      if (this.isUserProfile) {
        return this.$t('accountPosts.myStories');
      }
      return this.$t('accountPosts.storiesOf', {
        name: this.userProfile.pseudo,
      });
    },
    isUserProfile() {
      return this.$route.name === RouteName.ACCOUNT_STORIES;
    },
    stories() {
      return this.$store.state.stories.stories;
    },
    isFetching() {
      return this.$store.state.stories.isFetching;
    },
    isEverythingLoaded() {
      return this.$store.getters[prefixType(StoriesGetters.allStoriesLoaded)];
    },
    userProfileId() {
      return this.isUserProfile
        ? this.$store.state.auth.userInfos.userId
        : this.profileId;
    },
  },
  mounted() {
    this.trackPageView();
    this.$store.commit(
      prefixType(StoriesMutations.setUserFilter),
      this.userProfileId
    );
    if (this.isUserProfile) {
      this.userProfile = this.$store.state.auth.userInfos;
    } else {
      ContentAPI.getProfile(this.userProfileId).then(({ data }) => {
        this.userProfile = data;
      });
    }

    this.loadStories();
  },
  beforeDestroy() {
    this.$store.commit(prefixType(StoriesMutations.resetState));
  },
  methods: {
    async loadStories() {
      await this.$store.dispatch(prefixType(StoriesActions.fetchStories));
    },
    loadMoreStories() {
      if (this.$store.state.stories.currentPage > 0) {
        this.loadStories();
      }
    },
  },
  metaInfo() {
    return {
      titleTemplate: `%s | ${this.userProfile ? this.pageTitle : ''}`,
    };
  },
};
</script>

<style scoped lang="scss">
.account-stories {
  @extend %base-container;
  position: relative;

  &__title {
    font-family: $font-family-prelo-condensed;
    font-size: 1.875em;
    margin-bottom: 2.233em;
  }

  &__posts {
    @extend %story-card-grid;
    margin-bottom: 2em;
  }

  &__post-container {
    @extend %story-card-container;
  }

  &__post {
    @extend %story-card;
  }

  &__back-btn {
    @extend %back-btn;

    display: none;
    position: absolute;
    top: 0.25em;
    left: 0;

    @include breakpoint($breakpoint-tablet) {
      left: 3.438em;
      display: flex;
    }

    @include breakpoint($breakpoint-desktop) {
      left: 0;
    }
  }
}
</style>
