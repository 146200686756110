<template>
  <div class="flashes">
    <transition-group tag="ul" name="flashes-" class="flashes__list" appear>
      <li
        v-for="(flash, index) in flashes"
        :key="`${flash.type}-${index}`"
        class="flashes__item"
      >
        <BaseAlert :type="flash.type || 'info'" @close="closeAlert(index)">
          {{ flash.message }}
        </BaseAlert>
      </li>
    </transition-group>
  </div>
</template>

<script>
import BaseAlert from '@/components/alert/BaseAlert';
import { FlashActions, NAMESPACE, prefixType } from '@/store/modules/flash';
export default {
  name: 'FlashContainer',
  components: { BaseAlert },
  computed: {
    storeFlashes() {
      return this.$store.state[NAMESPACE].flashes;
    },
    flashes() {
      return this.storeFlashes && this.storeFlashes.filter((f) => !f.disabled);
    },
  },
  methods: {
    closeAlert(flashIndex) {
      this.$store.dispatch(prefixType(FlashActions.removeFlash), flashIndex);
    },
  },
};
</script>

<style lang="scss" scoped>
.flashes {
  &__list {
    @extend %base-container;
  }

  &__item {
    display: block;
  }

  &--move {
    transition: opacity 0.5s linear, transform 0.5s ease-in-out;
  }

  &--leave-active {
    transition: opacity 0.4s linear,
      transform 0.4s cubic-bezier(0.5, 0, 0.7, 0.4);
  }

  &--enter-active {
    transition: opacity 0.5s linear,
      transform 0.5s cubic-bezier(0.2, 0.5, 0.1, 1);
  }

  &--enter,
  &--leave-to {
    opacity: 0;
  }

  &--enter {
    transform: translateY(50%);
  }

  &--leave-to {
    transform: translateX(-50%);
  }
}
</style>
