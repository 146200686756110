<template>
  <div class="default-layout">
    <slot name="header">
      <DefaultLayoutHeader />
    </slot>

    <div class="default-layout__title">
      <PortalTarget :name="PortalName.MAIN_TITLE" />
    </div>
    <div class="default-layout__flashes">
      <FlashContainer />
    </div>
    <div class="default-layout__content">
      <slot></slot>
    </div>

    <slot name="footer">
      <BaseFooter />
    </slot>
  </div>
</template>

<script>
import DefaultLayoutHeader from '@/components/DefaultLayoutHeader';
import BaseFooter from '@/components/BaseFooter';
import FlashContainer from '@/components/flash/FlashContainer';
import PortalName from '@/utils/PortalName';

export default {
  name: 'DefaultLayout',
  components: {
    FlashContainer,
    BaseFooter,
    DefaultLayoutHeader,
  },
  data() {
    return {
      PortalName,
    };
  },
};
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__title {
    margin-top: 2rem;
  }

  &__content {
    flex: 1;
    min-height: 0;
  }
}
</style>
