<template>
  <VACExpandableSection is-open large class="personal-account">
    <template #title>
      {{ $t('account.personalInformations.title') }}
    </template>
    <template #body>
      <PageLoader v-if="loading" translucide />
      <VForm class="personal-account__form" @submit.prevent="submitUser">
        <PageLoader v-if="isSubmitting" translucide relative />
        <div class="personal-account__form-container">
          <div class="personal-account__row">
            <VACSelect
              name="gender"
              v-model="form.title"
              class="personal-account__form-field"
              :label="$t('auth.form.title.label')"
              :disabled="!isPhoneNumberFieldVisible"
              :options="[
                { label: $t('auth.form.title.mr'), value: '0001' },
                { label: $t('auth.form.title.mrs'), value: '0002' },
                { label: $t('auth.form.title.ms'), value: '0003' },
              ]"
            />
            <VACInput
              v-model="form.lastname"
              name="lastname"
              class="personal-account__form-field"
              :label="$t('auth.form.lastname.label')"
              :disabled="!isPhoneNumberFieldVisible"
            />
            <VACInput
              v-model="form.firstname"
              name="firstname"
              class="personal-account__form-field"
              :label="$t('auth.form.firstname.label')"
              :disabled="!isPhoneNumberFieldVisible"
            />
          </div>
          <div class="personal-account__row">
            <VACInput
              v-if="!isPhoneNumberFieldVisible"
              v-model="form.email"
              name="email"
              :label="$t('auth.form.email.label')"
              class="personal-account__form-field"
              disabled
            />
            <FieldPhoneNumber
              v-else
              v-model="form.phone"
              :label="$t('auth.form.phone.label')"
              disabled
              required
            />
          </div>
          <VACCheckbox
            name="marketing_infos"
            v-model="form.allowMarketingInformations"
            :input-value="form.allowMarketingInformations"
            :label="$t('auth.form.marketing-informations.label')"
          />
          <div class="personal-account__infos-row">
            <div class="personal-account__marketing-format">
              {{ $t('auth.form.marketing-format') }}
              <a :href="privacyPolicyLink" target="_blank">{{
                $t('auth.form.privacy-policy')
              }}</a>
            </div>
          </div>
          <div class="personal-account__btn-container">
            <VACButton
              appearance="primary"
              type="submit"
              class="personal-account__submit-btn"
              :label="$t('auth.form.save')"
            />
          </div>
          <template v-if="submitted">
            <ErrorAlert v-if="errorMessage" @close="submitted = false">
              {{ errorMessage }}
            </ErrorAlert>
            <SuccessAlert v-else @close="submitted = false">
              {{ $t('account.personalInformations.success') }}
            </SuccessAlert>
          </template>
        </div>
      </VForm>
    </template>
  </VACExpandableSection>
</template>

<script>
import {
  VACSelect,
  VACButton,
  VACInput,
  VACExpandableSection,
  VACCheckbox,
} from '@webqam-vac-ui/lib';
import FieldPhoneNumber from '@/components/form/FieldPhoneNumber';
import { CountriesGetters, prefixType } from '@/store/modules/countries';
import RouteName from '@/utils/RouteName';
import ErrorAlert from '@/components/alert/ErrorAlert';
import SuccessAlert from '@/components/alert/SuccessAlert';
import PageLoader from '@/components/PageLoader';
import UserData from '@/mixins/UserData';
import AccountAPIWrapper from '@/api/AccountAPIWrapper';
import Internationalization from '@/utils/Internationalization';

export default {
  name: 'PersonalAccount',
  components: {
    PageLoader,
    SuccessAlert,
    ErrorAlert,
    FieldPhoneNumber,
    VACSelect,
    VACButton,
    VACInput,
    VACCheckbox,
    VACExpandableSection,
  },
  mixins: [UserData],
  data() {
    return {
      loading: true,
      submitted: false,
      isSubmitting: false,
      errorMessage: null,
      RouteName,
      form: {
        title: '',
        lastname: '',
        firstname: '',
        country: '',
        email: '',
        phone: { prefix: '', number: '' },
        allowMarketingInformations: true,
      },
      countries: [],
    };
  },
  computed: {
    isCN() {
      return Internationalization.isCnCountry();
    },
    privacyPolicyLink() {
      return Internationalization.getPrivacyPolicyLink();
    },
    /**
     * @return {boolean}
     */
    isPhoneNumberFieldVisible() {
      return 'CN' === this.form.country;
    },
    storedUser() {
      return this.$store.state.auth.userInfos.user;
    },
  },
  mounted() {
    this.countries =
      this.$store.getters[prefixType(CountriesGetters.getCountries)];

    const country = this.storedUser && this.storedUser.websiteCountry;

    this.form = {
      title: this.storedUser && this.storedUser.title,
      firstname: this.getFirstName(this.storedUser, country),
      lastname: this.getLastName(this.storedUser, country),
      country: country,
      email: this.storedUser && this.storedUser.emailAddress,
      phone: this.parsePhoneNumber(this.storedUser),
      allowMarketingInformations:
        this.storedUser && this.storedUser.newsletterAccount,
    };
    this.loading = false;
  },
  methods: {
    submitUser() {
      if (this.isSubmitting) {
        return;
      }

      this.submitted = false;
      this.isSubmitting = true;

      const params = {
        gender: this.form.title === '0001' ? '0002' : '0001',
        title: this.form.title,
        firstName: this.form.firstname,
        lastName: this.form.lastname,
        email: this.form.email,
        phone: `${this.form.phone.prefix}${this.form.phone.number}`,
        allowBrandContact:
          typeof this.form.allowMarketingInformations === 'undefined'
            ? false
            : this.form.allowMarketingInformations,
      };

      AccountAPIWrapper.updateAccount(params)
        .then(() => {
          this.errorMessage = null;
        })
        .catch((err) => {
          this.errorMessage = err.response.data.message;
        })
        .finally(() => {
          this.submitted = true;
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-account {
  position: relative;

  &__row {
    width: 100%;
    gap: 1rem;

    @include breakpoint($breakpoint-l) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 1rem;
    }

    &--footer {
      margin-top: 1.25em;
      justify-content: center;
    }
  }

  &__marketing-format {
    font-family: $font-family-prelo-condensed;
    padding-top: 1rem;
  }

  &::v-deep {
    .v-input--radio-group__input {
      display: flex;
      justify-content: space-between;
    }
  }

  &__form-field {
    width: 100%;
  }

  &__btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.25rem;
  }
}
</style>
