import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import { prefixTypeFactory } from '@/utils/StoreUtils';
import ContentAPI from '@/api/ContentAPI';

export const NAMESPACE = 'articles';

export const { mapState, mapGetters, mapActions, mapMutations } =
  createNamespacedHelpers(NAMESPACE);

/**
 * @type {function(string): string}
 */
export const prefixType = prefixTypeFactory(NAMESPACE);

export enum ArticlesGetters {
  getArticles = 'getArticles',
}

export enum ArticlesMutation {
  createCategory = 'createCategory',
  updateCategory = 'updateCategory',
}

export enum ArticlesActions {
  fetchArticles = 'fetchArticles',
}

const module = {
  namespaced: true,
  state() {
    return {
      categories: [],
    };
  },
  getters: {
    [ArticlesGetters.getArticles]: (state) => (category) => {
      return (
        state.categories &&
        state.categories.find((cat) => cat.category === category)
      );
    },
  },
  actions: {
    [ArticlesActions.fetchArticles]: async (
      { state, commit, getters },
      { category, page = 1, itemPerPage = 6, forceRefresh = false }
    ) => {
      const catObject = getters[ArticlesGetters.getArticles](category);

      if (!catObject || catObject.currentPage < page || forceRefresh) {
        const data = await ContentAPI.getArticles({
          category,
          page,
          itemPerPage,
        });

        if (catObject) {
          const index = state.categories.findIndex(
            (cat) => cat.category === category
          );

          commit(ArticlesMutation.updateCategory, {
            index,
            value: {
              ...catObject,
              articles:
                catObject.articles === null
                  ? data.articles
                  : catObject.articles.concat(data.articles),
              currentPage: page,
              total: data.count,
            },
          });
        } else {
          commit(ArticlesMutation.createCategory, {
            category,
            articles: data.articles,
            currentPage: page,
            total: data.count,
          });
        }
      }
    },
  },
  mutations: {
    [ArticlesMutation.createCategory](state, value) {
      state.categories.push(value);
    },
    [ArticlesMutation.updateCategory](state, { index, value }) {
      Vue.set(state.categories, index, value);
    },
  },
};

export default module;
