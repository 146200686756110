<template>
  <div class="account">
    <transition name="fade">
      <PageLoader v-if="doingLogout" translucide />
    </transition>
    <MainTitle>{{ $t('account.title') }}</MainTitle>
    <div class="account__container">
      <VACTabs :tabs="tabs" />
      <VACButton
        class="account__logout"
        appearance="link"
        :label="$t('account.logout')"
        @click="logout"
      />
    </div>
  </div>
</template>

<script>
import MainTitle from '@/components/MainTitle';
import AccountCustomProfile from '@/views/account/AccountCustomProfile';
import AccountPublicProfile from '@/views/account/AccountPublicProfile';
import AccountPersonalInformations from '@/views/account/AccountPersonalInformations';
import PageLoader from '@/components/PageLoader';
import { VACTabs, VACButton } from '@webqam-vac-ui/lib';
import AccountAPIWrapper from '@/api/AccountAPIWrapper';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'AccountDetails',
  components: {
    PageLoader,
    MainTitle,
    VACTabs,
    VACButton,
  },
  mixins: [Tracking],
  data() {
    return {
      doingLogout: false,
      tabs: [
        {
          label: this.$t('account.tabs.myVacheronLabel'),
          component: AccountCustomProfile,
        },
        {
          label: this.$t('account.tabs.publicProfileLabel'),
          component: AccountPublicProfile,
        },
        {
          label: this.$t('account.tabs.personalInformationsLabel'),
          component: AccountPersonalInformations,
        },
      ],
    };
  },
  mounted() {
    this.trackPageView('My Account');
  },
  methods: {
    async logout() {
      await AccountAPIWrapper.logout();
      this.$router.push({ name: RouteName.PREHOME });
    },
  },
};
</script>

<style lang="scss" scoped>
.account {
  margin-bottom: 2em;

  &__container {
    @extend %base-container;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    margin-bottom: 3.125em;
  }

  &__logout {
    margin-top: 2.5em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  &__section {
    position: relative;
  }

  &::v-deep {
    .password-account {
      margin-bottom: 0;

      &__form-container {
        @include breakpoint($breakpoint-tablet) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }

    .complexity-validator {
      width: calc(100% + 4.5em);
      margin: 0 -2.25em;
      background-color: $color-wild-sand;
      padding: 0.8125em 2.25em;

      &__intro {
        font-weight: normal;
      }

      &__list {
        @include breakpoint($breakpoint-tablet) {
          display: flex;
          justify-content: space-between;
        }
      }

      &__item {
        @include breakpoint($breakpoint-tablet) {
          width: 50%;
        }
      }
    }
  }
}
</style>
