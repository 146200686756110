export enum ServicesMutations {
  toggleModal = 'toggleModal',
}

const module = {
  state() {
    return {
      showModal: false,
    };
  },
  mutations: {
    [ServicesMutations.toggleModal](state, value) {
      state.showModal = value;
    },
  },
};

export default module;
