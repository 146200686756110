import Auth from '@/utils/authentication';
import RouteName from '@/utils/RouteName';
import { flash } from '@/plugins/flash';
import i18n from '@/i18n';

export default async function ({ to, router }) {
  const user = await Auth.retrievePersistentUser();
  const currentSession = Auth.retrievePersistentSession;
  const isMember = currentSession && currentSession.hasTimepieces;

  if (user) {
    if (to.meta.memberRequired && !isMember) {
      return router.push({ name: RouteName.HOME }).then(() => {
        flash(i18n.t('auth.forbiddenLight'), 'error');
      });
    }
  }
}
