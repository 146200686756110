<template>
  <component :is="component" v-bind="boundProps" v-on="$listeners">
    <svg v-if="isSvgVisible" class="base-btn__svg">
      <rect
        x="0"
        y="0"
        fill="none"
        width="100%"
        height="100%"
        class="base-btn__rect"
      />
    </svg>

    <slot name="outbody"></slot>

    <span v-if="hasLabel" class="base-btn__body">
      <slot>{{ label }}</slot>
    </span>

    <slot name="content"></slot>
    <div v-if="icon" class="base-btn__icon">
      <slot name="icon"></slot>
    </div>
    <span v-if="direction" class="icon icon--triangle-right"></span>
  </component>
</template>

<script>
/** @typedef {'button', 'submit', 'a'} ButtonType */
export const ButtonType = Object.freeze({
  BUTTON: 'button',
  SUBMIT: 'submit',
  LINK: 'a',
  ROUTER_LINK: 'RouterLink',
});

export default {
  name: 'BaseBtn',
  props: {
    type: {
      type: String,
      default: ButtonType.LINK,
      validator(v) {
        return Object.values(ButtonType).includes(v);
      },
    },
    label: { type: String, default: undefined },
    href: { type: String, default: undefined },
    to: { type: [String, Object], default: undefined },
    block: Boolean,
    default: Boolean,
    primary: Boolean,
    secondary: Boolean,
    tertiary: Boolean,
    danger: Boolean,
    discrete: Boolean,
    rounded: Boolean,
    link: Boolean,
    icon: Boolean,
    disabled: Boolean,
    large: Boolean,
    border: Boolean,
    direction: Boolean,
    dark: Boolean,
    underlined: Boolean,
    attributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    typeAttr() {
      if ([ButtonType.BUTTON, ButtonType.SUBMIT].includes(this.type)) {
        return this.type;
      }

      // Default to type="button" when href is empty
      if (!this.href || !this.to) {
        return ButtonType.BUTTON;
      }

      return null;
    },
    component() {
      return this.to
        ? ButtonType.ROUTER_LINK
        : this.href
        ? ButtonType.LINK
        : ButtonType.BUTTON;
    },
    classList() {
      const blockSelector = 'base-btn';

      return [
        blockSelector,
        {
          [`${blockSelector}--block`]: this.block,
          [`${blockSelector}--default`]:
            this.default ||
            (!this.primary &&
              !this.secondary &&
              !this.tertiary &&
              !this.danger &&
              !this.discrete &&
              !this.rounded &&
              !this.link),
          [`${blockSelector}--primary`]: this.primary,
          [`${blockSelector}--secondary`]: this.secondary,
          [`${blockSelector}--disabled`]: this.disabled,
          [`${blockSelector}--tertiary`]: this.tertiary,
          [`${blockSelector}--danger`]: this.danger,
          [`${blockSelector}--discrete`]: this.discrete,
          [`${blockSelector}--rounded`]: this.rounded,
          [`${blockSelector}--link`]: this.link,
          [`${blockSelector}--icon`]: this.icon,
          [`${blockSelector}--large`]: this.large,
          [`${blockSelector}--border`]: this.border,
          [`${blockSelector}--direction`]: this.direction,
          [`${blockSelector}--dark`]: this.dark,
          [`${blockSelector}--underlined`]: this.underlined,
        },
      ];
    },
    boundProps() {
      return {
        type: this.component === ButtonType.BUTTON ? this.typeAttr : null,
        class: this.classList,
        href: this.href,
        to: this.to,
        disabled: this.disabled,
        ...this.attributes,
      };
    },
    /**
     * @return {boolean}
     */
    isSvgVisible() {
      return !this.discrete && !this.link;
    },
    /**
     * @return {boolean}
     */
    hasLabel() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
$color-btn: $color-primary;
$color-btn-bg: $color-white;

.base-btn {
  $block-selector: &;

  @extend %base-btn;

  &--default {
    @extend %base-btn--default;
  }

  &--disabled {
    @extend %base-btn--disabled;
  }

  &--primary {
    @extend %base-btn--primary;
  }

  &--secondary {
    @extend %base-btn--secondary;
  }

  &--block {
    @extend %base-btn--block;
  }

  &--tertiary {
    @extend %base-btn--tertiary;
  }

  &--large {
    @extend %base-btn--large;
  }

  &--discrete {
    @extend %base-btn--discrete;
  }

  &--link {
    @extend %base-btn--link;
  }

  &--underlined {
    @extend %base-btn--underlined;
  }

  &--rounded {
    @extend %base-btn--rounded;
  }

  &--direction {
    @extend %base-btn--direction;
  }

  &--dark {
    @extend %base-btn--dark;
  }

  &--danger {
    @extend %base-btn--danger;

    &#{$block-selector} {
      &--discrete {
        &:hover {
          color: $color-danger;
          text-decoration: underline;
        }
      }
    }
  }

  &__svg {
    @extend %base-btn__svg;
  }

  &__rect {
    @extend %base-btn__rect;
  }

  &__body {
    @extend %base-btn__body;
  }
}
</style>
