<template>
  <div :class="blockClasses">
    <router-link
      v-if="isLoggedIn"
      class="dynamic-event__link"
      :to="{ name: RouteName.SINGLE_EVENT, params: { eventId: event.ID } }"
    />
    <div class="dynamic-event__header">
      <div class="dynamic-event__info dynamic-event__info--month">
        {{ eventMonth }}
      </div>
      <div class="dynamic-event__info dynamic-event__info--days">
        {{ eventDays }}
      </div>
    </div>
    <div class="dynamic-event__logo">
      <img alt="" src="../../assets/dynamic-logo.svg" />
    </div>
    <div class="dynamic-event__footer">
      <div class="dynamic-event__footer-infos">
        <img
          alt=""
          class="dynamic-event__footer-img"
          src="../../assets/store-demo.png"
        />
        <span class="dynamic-event__footer-name">
          {{ `${event.city}, ${event.country}` }}
        </span>
      </div>
      <div class="dynamic-event__footer-description">
        {{ eventDescription }}
      </div>
    </div>
  </div>
</template>

<script>
import { stripHtml } from '@/utils/String';
import Internationalization from '@/utils/Internationalization';
import RouteName from '@/utils/RouteName';

const BLOCK_SELECTOR = 'dynamic-event';

export default {
  name: 'DynamicEvent',
  props: {
    event: { type: Object, default: undefined },
    isLoggedIn: Boolean,
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    /**
     * @return {Date}
     */
    startDate() {
      return new Date(Date.parse(this.event.date[0].value));
    },
    /**
     * @return {Date}
     */
    endDate() {
      return new Date(Date.parse(this.event.date[0].end_value));
    },
    /**
     * @return {boolean}
     */
    isSeveralMonthsEvent() {
      return (
        this.startDate.getFullYear() !== this.endDate.getFullYear() ||
        this.startDate.getMonth() !== this.endDate.getMonth()
      );
    },
    /**
     * @return {string}
     */
    eventMonth() {
      const locales = Internationalization.getCurrentLocale();
      const options = {
        month: 'long',
      };
      const startDateMonth = this.startDate.toLocaleDateString(
        locales,
        options
      );

      if (this.isSeveralMonthsEvent) {
        return `${startDateMonth} / ${this.endDate.toLocaleDateString(
          locales,
          options
        )}`;
      }

      return startDateMonth;
    },
    /**
     * @return {boolean}
     */
    isTwoDaysEvent() {
      const nextDate = this.startDate;
      nextDate.setDate(nextDate.getDate() + 1);

      return nextDate.toDateString() === this.endDate.toDateString();
    },
    /**
     * @return {string}
     */
    eventDays() {
      if (this.event && this.event.date[0].value) {
        if (this.isSeveralMonthsEvent) {
          const startDay = this.startDate.getDate();
          const startMonth = this.formatMonth(this.startDate.getMonth());
          const endDay = this.endDate.getDate();
          const endMonth = this.formatMonth(this.endDate.getMonth());

          return this.$t('events.dynamic.fromTo', {
            start: `${startDay}.${startMonth}`,
            end: `${endDay}.${endMonth}`,
          });
        }

        if (this.isTwoDaysEvent) {
          return `${this.startDate.getDate()} & ${this.endDate.getDate()}`;
        }

        return this.$t('events.dynamic.fromTo', {
          start: this.startDate.getDate(),
          end: this.endDate.getDate(),
        });
      }

      return '';
    },
    eventDescription() {
      return stripHtml(this.event.description);
    },
    blockClasses() {
      return [
        BLOCK_SELECTOR,
        {
          [`${BLOCK_SELECTOR}--animated`]: this.isLoggedIn,
        },
      ];
    },
  },
  methods: {
    /**
     * @param {number} value
     * @return {number}
     */
    formatMonth(value) {
      value = 1 + value;

      return value > 9 ? value : `0${value}`;
    },
  },
};
</script>

<style scoped lang="scss">
.dynamic-event {
  $block-selector: &;
  background-color: $color-wild-sand;
  height: 31.563em;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  &--animated {
    @include breakpoint($breakpoint-desktop) {
      &:hover {
        #{$block-selector}__logo {
          transform: translateX(45%);
        }

        #{$block-selector}__footer {
          transform: translateY(0);
        }

        #{$block-selector}__header {
          padding-top: 1em;
        }

        #{$block-selector}__info--month {
          font-size: 2.25em;
        }

        #{$block-selector}__info--days {
          font-size: 4em;
        }
      }
    }
  }

  &__link {
    @extend %block-abs-tlbr-zero;
    z-index: 2;
  }

  &__header {
    transition: padding-top $default-transition-duration;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 1em;
  }

  &__logo {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform $default-transition-duration;
    transform: translateX(45%);
    margin-top: -8em;

    @include breakpoint($breakpoint-desktop) {
      transform: translateX(0);
      margin-top: 0;
    }
  }

  &__footer {
    transition: transform $default-transition-duration;
    padding-left: 1em;
    padding-right: 1em;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 1em;

    @include breakpoint($breakpoint-desktop) {
      padding-left: 3.125em;
      padding-right: 3.125em;
      margin-bottom: 1em;
      transform: translateY(120%);
    }
  }

  &__info {
    text-align: center;
    font-family: $font-prelo-condensed;
    color: $color-black;
    transition: font-size $default-transition-duration;

    &--month {
      text-transform: uppercase;
      font-size: 1.75em;
    }

    &--days {
      font-size: 3.125em;
      color: $color-dusty-gray;
      font-weight: bold;
      margin-top: -0.25em;
    }
  }

  &__footer-img {
    width: 4.938em;
    height: 4.938em;
    margin-right: 1em;
  }

  &__footer-name {
    font-family: $font-prelo-condensed;
    font-size: 1.563em;
    max-width: 5em;
    text-transform: uppercase;

    @include breakpoint($breakpoint-desktop) {
      max-width: max-content;
    }
  }

  &__footer-description {
    @include line-clamp(2);

    margin-top: 0.5em;
    text-align: left;
  }

  &__footer-infos {
    display: flex;
    align-items: center;
  }
}
</style>
