<template>
  <VForm ref="form" class="phone-auth-form" @submit.prevent="processForm">
    <FieldPhoneNumber
      v-model="form.phone"
      :label="$t('auth.form.phone.label')"
      required
      prefix-disabled
    />
    <VTextField
      v-model="form.password"
      :label="$t('auth.form.password.label')"
      :append-icon="revealPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="revealPassword ? 'text' : 'password'"
      name="phone_auth_form_password"
      @click:append="revealPassword = !revealPassword"
    />
    <BaseBtn
      link
      block
      class="phone-auth-form__forgot-password"
      @click="forgotPassword"
    >
      {{ $t('auth.form.generatePassword') }}
    </BaseBtn>
    <div class="phone-auth-form__robot">
      <VueHcaptcha
        v-if="isHCaptcha"
        ref="captcha"
        :sitekey="siteKey"
        @verify="onVerify"
      />
      <div v-if="errorMessage" class="phone-auth-form__error">
        {{ errorMessage }}
      </div>
    </div>
    <BaseBtn class="phone-auth-form__login-button" primary block type="submit">
      {{ $t('auth.form.submit') }}
    </BaseBtn>
    <p class="phone-auth-form__privacy">
      {{ $t('auth.form.privacy.text') }}
      <a :href="$t('auth.form.privacy.link')" target="_blank">{{
        $t('auth.form.privacy.button')
      }}</a>
    </p>
  </VForm>
</template>

<script>
import BaseBtn from '@/components/BaseBtn';
import RouteName from '@/utils/RouteName';
import FieldPhoneNumber from '@/components/form/FieldPhoneNumber';
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import LoginForm from '@/mixins/Auth/LoginForm';
import Captcha from '@/mixins/Captcha';
import WeChatClient from '@/api/WeChatClient';
import AutoLogin from '@/mixins/AutoLogin';
import storage from '@/utils/storage';
import AutoLoginStorage from '@/enums/AutoLoginStorage';

export default {
  name: 'PhoneAuthForm',
  components: { FieldPhoneNumber, BaseBtn, VueHcaptcha },
  mixins: [Captcha, LoginForm, AutoLogin],
  props: {
    wechatAssociation: Boolean,
  },
  data() {
    return {
      form: {
        phone: { prefix: '+86', number: '' },
        password: '',
      },
      revealPassword: false,
      errorMessage: null,
      passwordForgotRouteName: RouteName.PASSWORD_FORGOT_CN,
    };
  },
  computed: {
    /**
     * @return {Object}
     */
    formData() {
      return {
        phone: `${this.form.phone.prefix}${this.form.phone.number}`,
        password: this.form.password,
      };
    },
  },
  methods: {
    /**
     * @return {Promise<void>}
     */
    callApi() {
      return WeChatClient.loginWithPhone(
        this.formData.phone,
        this.formData.password,
        this.captchaHeader
      );
    },
    processForm() {
      if (this.wechatAssociation) {
        this.processBinding();
      } else {
        this.login();
      }
    },
    async processBinding() {
      this.errorMessage = null;

      if (this.$refs.form.validate()) {
        if (this.hasCaptcha) {
          this.$emit('submit');
          try {
            await WeChatClient.loginPhoneWechat(
              this.formData.phone,
              this.formData.password,
              storage.get(AutoLoginStorage.RWF_TOKEN),
              this.captchaHeader
            );
            this.redirectAfterAutoLogin();
            this.close();
          } catch (e) {
            this.$emit('error');
            this.errorMessage = this.$t('auth.error');
            this.resetCaptcha();
          }
        } else {
          this.errorMessage = this.$t('cms.contact.form.error.recaptcha');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-auth-form {
  &__forgot-password {
    font-family: $font-family-prelo-condensed;
    margin-bottom: 1em;
    text-decoration: underline;
    text-align: center;

    ::v-deep .base-btn__body::after {
      content: none;
    }
  }

  &__robot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }

  &__error {
    font-size: 0.75em;
    font-style: italic;
    color: $color-danger;
    display: block;
    margin-top: 0.5em;
    text-align: center;
  }

  &__login-button {
    font-weight: bold;
  }

  &__privacy {
    text-align: center;
    margin-top: 0.5em;
  }
}
</style>
