import Internationalization from '@/utils/Internationalization';

declare global {
  interface Window {
    dataLayer: {
      length: number;
      push;
      get: (item: string) => string;
      find(param: (item) => boolean): never;
    };
    google_tag_manager: Record<never, never>;
  }
}

function shouldUseGtm() {
  return Internationalization.getCountry() !== 'cn';
}

export default function (gtmId: string | undefined): void {
  if (shouldUseGtm()) {
    if (gtmId) {
      const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`;
      const domScript = document.createElement('script');
      domScript.innerText = gtmScript;
      window.document.body.append(domScript);
    }
  }
}

export function clearDataLayer() {
  if (window.dataLayer && shouldUseGtm()) {
    const timer = setInterval(() => {
      const oldEvent = window.dataLayer.find(
        (item) => item.event === 'tcConsentChanged'
      );
      if (oldEvent) {
        if (
          window.dataLayer !== undefined &&
          window.google_tag_manager !== undefined
        ) {
          window.dataLayer.length = 0;
          const gtmContainerReg = /GTM-/i;

          for (const gtmKey of Object.keys(window.google_tag_manager)) {
            if (
              gtmContainerReg.test(gtmKey) &&
              window.google_tag_manager[gtmKey].dataLayer &&
              window.google_tag_manager[gtmKey].dataLayer.reset
            ) {
              window.google_tag_manager[gtmKey].dataLayer.reset();
            }
          }
        }
        window.dataLayer.push(oldEvent);
        clearInterval(timer);
      }
    });
  }
}

export function pushToDataLayer(data) {
  if (shouldUseGtm()) {
    if (window.dataLayer) {
      window.dataLayer.push(data);
    } else {
      setTimeout(() => {
        pushToDataLayer(data);
      }, 1000);
    }
  }
}
