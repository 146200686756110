<template>
  <div class="reset-password">
    <div class="reset-password__container">
      <WizardStepper
        :steps="2"
        :start="2"
        :left-image="leftImage"
        :right-image="rightImage"
      >
        <template #step-1></template>
        <template #step-2="stepProps">
          <WizardStep
            v-bind="stepProps"
            :title="$t('reset-password.set-password.title')"
          >
            <PasswordAccount reset-password />
          </WizardStep>
        </template>
      </WizardStepper>
    </div>
  </div>
</template>

<script>
import PasswordAccount from '@/components/account/PasswordAccount';
import WizardStepper from '@/components/wizard/WizardStepper';
import leftImage from '../../assets/stepper/password-forgot-left.png';
import rightImage from '../../assets/stepper/password-forgot-right.png';
import WizardStep from '@/components/wizard/WizardStep';
import RouteName from '@/utils/RouteName';
import Tracking from '@/mixins/Tracking';

export default {
  name: 'ResetPassword',
  components: { WizardStep, WizardStepper, PasswordAccount },
  mixins: [Tracking],
  data() {
    return {
      leftImage,
      rightImage,
    };
  },
  /**
   * @param {Object} to
   * @param {Object} from
   * @param {function} next
   */
  beforeRouteEnter(to, from, next) {
    const { token } = to.query;

    if (token) {
      next();
    } else {
      next({ path: RouteName.PREHOME });
    }
  },
  mounted() {
    this.trackPageView();
  },
};
</script>
