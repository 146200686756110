<template>
  <div class="field-slider">
    <div v-if="showInfo" class="field-slider__info">
      <div class="field-slider__label">{{ label }}</div>
    </div>

    <VSlider
      v-model="slider"
      :max="max"
      :min="min"
      height="10"
      thumb-color="#000000"
      track-color="#f5f5f5"
      track-fill-color="#000000"
    />
    <div v-if="showValue" class="field-slider__value">
      {{ value }}{{ valueSuffix }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldSlider',
  props: {
    value: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    showValue: Boolean,
    valueSuffix: {
      type: String,
      default: null,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
  },
  computed: {
    /**
     * @return {Boolean}
     */
    showInfo() {
      return (null !== this.label && this.label.length > 0) || this.showValue;
    },
    slider: {
      get() {
        return this.value;
      },
      /**
       * @param {Number} value
       */
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.field-slider {
  background-color: $color-wild-sand;
  padding: 1rem;
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label,
  &__value {
    color: $color-black;
  }

  &__label {
    font-weight: bold;
    font-size: 1.125rem;
  }

  &__value {
    text-align: right;
  }

  ::v-deep .v-messages {
    min-height: 0;
  }

  ::v-deep .v-input {
    &__slot {
      margin-bottom: 0;
    }
  }

  ::v-deep .v-slider {
    $border-radius: 5px;

    &__track-container {
      height: 1px;
    }

    &__track-background {
      background-color: $color-black !important;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &__track-fill {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      height: 6px;
      transform: translateY(-2px);
    }

    &__thumb {
      $size: 14px;

      left: -$size / 2;
      height: $size;
      width: $size;
      background-color: $color-black !important;
    }
  }
}
</style>
