<template>
  <div class="golden-book-filters">
    <Portal :to="PortalName.MODAL">
      <VACSideModal
        :title="$t('goldenBook.filters.modal.title')"
        :count="storiesCount"
        :is-open="filtersOpen"
        can-reset
        :resetLabel="$t('goldenBook.filters.modal.reset')"
        :okLabel="$t('goldenBook.filters.modal.done')"
        @close="filtersOpen = false"
        @apply="applyFilters"
        @reset="resetFilters"
      >
        <VACMultiSelect
          v-if="themes"
          v-model="filtersThemes"
          name="themes"
          :label="$t('goldenBook.themeFilter')"
          :options="themes"
          :placeholder="$t('goldenBook.themeFilter')"
          class="golden-book-filters__field"
          :max="280"
          accordion
          input
          @change="handleNewItemForFilters"
        />
        <VACMultiSelect
          v-if="collections && collections.options"
          v-model="filtersCollections"
          name="collections"
          :label="$t('goldenBook.collectionsFilter')"
          :options="collections.options"
          :placeholder="$t('goldenBook.collectionsFilter')"
          class="golden-book-filters__field"
          :max="280"
          accordion
          input
          @change="handleNewItemForFilters"
        />
        <VACSelect
          v-if="sortOptions"
          id="orderby"
          v-model="storiesOrder"
          :options="sortOptions"
          name="sortBy"
          :label="$t('goldenBook.sortBy')"
        />
      </VACSideModal>
    </Portal>
    <VACButton
      v-if="this.$matchMedia.s"
      icon="plus"
      appearance="secondary"
      :label="$t('conversation.filters')"
      @click="filtersOpen = true"
    />
    <VACButton
      v-else
      class="golden-book-filters__open-filters"
      appearance="secondary"
      icon="filters"
      :badge="filterCount"
      @click="filtersOpen = true"
    />
  </div>
</template>

<script>
import { StoriesOrder } from '@/utils/StoriesConstants';

import {
  prefixType,
  StoriesMutations,
  StoriesActions,
  StoriesGetters,
} from '@/store/modules/stories';
import {
  VACMultiSelect,
  VACSelect,
  VACButton,
  VACSideModal,
} from '@webqam-vac-ui/lib';
import PortalName from '@/utils/PortalName';
import { pushToDataLayer } from '@/utils/GTMMaker';
export default {
  name: 'GoldenBookFilters',
  components: { VACMultiSelect, VACSelect, VACButton, VACSideModal },
  data() {
    return {
      PortalName,
      filtersOpen: false,
      sortByInitialValue: 'date',
      themesFilters: {},
      collectionsFilters: {},
      filters: {
        categories: [],
        order: StoriesOrder.POPULARITY,
      },
      sortOptions: [
        {
          value: StoriesOrder.POPULARITY,
          label: this.$t(`conversation.sortLabel.${StoriesOrder.POPULARITY}`),
        },
        {
          value: StoriesOrder.DATE,
          label: this.$t(`conversation.sortLabel.${StoriesOrder.DATE}`),
        },
      ],
    };
  },
  computed: {
    themeOptions() {
      return this.$store.state.stories.availableFilters;
    },
    collections() {
      return this.themeOptions.filter((item) => item.prefix)[0];
    },
    themes() {
      return this.themeOptions.filter((item) => !item.prefix);
    },
    themesInitialValue() {
      return this.themes.reduce((obj, item) => {
        obj[item.value] = false;
        return obj;
      }, {});
    },
    collectionsInitialValue() {
      return this.collections.options.reduce((obj, item) => {
        obj[item.value] = false;
        return obj;
      }, {});
    },
    filterCount() {
      return this.$store.getters[prefixType(StoriesGetters.getFilterCount)];
    },
    filtersCollections: {
      get() {
        return this.collectionsFilters;
      },
      set(value) {
        const selectedCollections = [];
        this.collections.options.forEach((item) => {
          if (value[item.value]) {
            selectedCollections.push(item);
          }
        });
        this.collectionsFilters = value;
        this.$store.commit(
          prefixType(StoriesMutations.setCollectionFilters),
          selectedCollections
        );
      },
    },
    filtersThemes: {
      get() {
        return this.themesFilters;
      },
      set(value) {
        const selectedThemes = [];
        this.themes.forEach((item) => {
          if (value[item.value]) {
            selectedThemes.push(item);
          }
        });
        this.themesFilters = value;
        this.$store.commit(
          prefixType(StoriesMutations.setThemeFilters),
          selectedThemes
        );
      },
    },
    storiesOrder: {
      get() {
        return this.$store.state.stories.order;
      },
      set(value) {
        this.$store.commit(prefixType(StoriesMutations.setOrder), value);
        this.$store.dispatch(prefixType(StoriesActions.refreshStories));
        pushToDataLayer({
          event: 'sortBy',
          filterCat: value,
        });
      },
    },
    storiesCount() {
      if (this.$store.state.stories.totalItems > 0) {
        return this.$t('goldenBook.count', {
          number: this.$store.state.stories.totalItems,
        });
      }
      return undefined;
    },
  },
  methods: {
    handleNewItemForFilters(value) {
      if (!this.storiesThemes.includes(value.value) || value.force) {
        pushToDataLayer({
          event: 'filterBy',
          filterCat: value.filterCat,
          filterSubCat: value.filterSubCat,
        });
      }
    },
    applyFilters() {
      this.$store.dispatch(prefixType(StoriesActions.refreshStories));
      this.filtersOpen = false;
    },
    resetFilters() {
      this.filtersThemes = { ...this.themesInitialValue };
      this.filtersCollections = { ...this.collectionsInitialValue };
      this.storiesOrder = this.sortByInitialValue;
    },
  },
};
</script>

<style scoped lang="scss">
.golden-book-filters {
  font-family: $font-family-prelo-condensed;

  @include breakpoint($breakpoint-tablet) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  &__count {
    display: none;

    @include breakpoint($breakpoint-tablet) {
      display: block;
      margin-bottom: 0;
    }
  }

  &__select {
    @include breakpoint($breakpoint-tablet) {
      position: absolute;
      left: 0;
    }

    @include breakpoint($breakpoint-desktop) {
      width: 20.688em;
    }
  }

  &__field {
    margin-bottom: 1rem;
  }
}
</style>
