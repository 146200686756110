import CountryCode from '@/types/CountryCode';
import { ImageSet } from '@/types/ImageSet';

export enum EventStatus {
  OPEN = 'open',
  FULL = 'full',
  CLOSED = 'close',
}

export interface EventNode {
  ID: string; // Should be a number
  address: string | null;
  addressText: string | null;
  addressTitle: string | null;
  allowed_guests: string; // Should be a number
  city: string | null;
  country: string | null;
  date: {
    value: string;
    end_value: string;
  }[];
  description: string | null;
  email: string | null;
  'images-main': ImageSet[];
  'images-thumbnail': ImageSet[];
  'landscape-single': ImageSet[];
  latitude: unknown | null;
  longitude: unknown | null;
  phone: string | null;
  register: unknown | null;
  registration_slot: [];
  'small-square': ImageSet[];
  status: EventStatus;
  'thumbnail-web': ImageSet[];
  title: string;
}

export interface EventListResponse {
  count: string;
  currentPage: number;
  filterCountry: { [key in keyof typeof CountryCode]: string };
  nbPage: 1;
  nodes: EventNode[];
}
