<template>
  <div class="language-selector">
    <VACButton
      appearance="discrete"
      class="language-selector__button"
      icon=""
      @click="open"
    >
      <span
        :class="{ 'language-selector__region-label--disabled': regionDisabled }"
        class="language-selector__region-label"
        >{{ regionLabel }}</span
      >{{ separator
      }}<span class="language-selector__language-label">{{
        languageLabel
      }}</span>
    </VACButton>
    <LanguageSelectorModal
      :is-open="modalOpen"
      :region-disabled="regionDisabled"
      :second-target="secondTarget"
      @close="close"
      @update:is-open="modal = $event"
    />
  </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { VACButton } from '@webqam-vac-ui/lib';
import Internationalization, {
  RTL_LANGUAGES,
} from '@/utils/Internationalization';
import LanguageSelectorModal from '@/components/language-selector/LanguageSelectorModal';
import languageMixin from '@/mixins/Language';
import Auth from '@/utils/authentication';

export default {
  name: 'LanguageSelector',
  components: { VACButton, LanguageSelectorModal },
  mixins: [languageMixin],
  props: {
    secondTarget: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
      user: null,
      rtlLanguages: RTL_LANGUAGES,
    };
  },
  computed: {
    /**
     * @return {boolean}
     */
    regionDisabled() {
      return null !== this.user;
    },
    /**
     * @return {string}
     */
    regionLabel() {
      return this.region.toUpperCase();
    },
    /**
     * @return {string}
     */
    separator() {
      return `\u00A0/\u00A0`;
    },
    /**
     * @return {string}
     */
    languageLabel() {
      let lang = this.language.toUpperCase();

      if (lang.toUpperCase() === 'ZH') {
        return 'CHT';
      }

      if (this.region.toUpperCase() === 'CN' && lang === 'CN') {
        lang = 'ZH';
      }

      return lang;
    },
    modalOpen() {
      return this.modal;
    },
  },
  created() {
    Auth.retrievePersistentUser().then((user) => {
      this.user = user;
    });
  },
  mounted() {
    this.rtl = Internationalization.isRtlCountry();
    if (this.rtl) {
      document.body.classList.add('dir-rtl');
    }
  },
  methods: {
    open() {
      this.modal = true;
      disableBodyScroll(document.body);
    },
    close() {
      console.log('close');
      enableBodyScroll(document.body);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  $block-selector: &;

  &__button {
    font-family: $font-family-prelo;
    font-size: 0.75rem;
    font-weight: bold;
    padding: $base-btn-vertical-padding $base-btn-horizontal-padding;

    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 0.5em;
      border-left: 0.4em solid transparent;
      border-right: 0.4em solid transparent;
      border-top: 0.4em solid $color-default;
      vertical-align: middle;
    }
  }

  &__region-label {
    &--disabled {
      color: $color-black;
    }
  }
  body.dir-rtl & {
    &__button {
      &::after {
        margin-right: 0.5em;
        margin-left: 0;
      }
    }
  }
}
</style>
