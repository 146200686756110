<template>
  <div class="spinner">
    <VACLoader />
    <Spy @reached="onReach" />
  </div>
</template>

<script>
const BLOCK_SELECTOR = 'spinner';
import { VACLoader } from '@webqam-vac-ui/lib';
import Spy from '@/components/Spy';

export default {
  name: 'Spinner',
  components: { Spy, VACLoader },
  props: {
    small: Boolean,
  },
  computed: {
    blockClasses() {
      return [
        `${BLOCK_SELECTOR}__container`,
        {
          [`${BLOCK_SELECTOR}__container--small`]: this.small,
        },
      ];
    },
  },
  methods: {
    onReach() {
      this.$emit('reached');
    },
  },
};
</script>

<style scoped lang="scss">
.spinner {
  display: flex;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;

  &__container {
    font-size: 1em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4em;
    height: 4em;

    &--small {
      width: 2em;
      height: 2em;
    }
  }

  &__ring {
    @extend %block-abs-tlbr-zero;
    height: 100%;
    width: 100%;
    animation: rotating 3s linear infinite;
  }

  &__icon {
    line-height: 0;
    font-size: 2em;
    &::before {
      @extend %icon;
      @extend %icon--thc-big;
    }
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
